import React, { FC } from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Footer as IFooter, FooterLinks } from '../../../domain/models/commons/Footer'
import IMedLogo from '../../atoms/IMedLogo'
import { ContentLinkModel } from '../../../domain/models/commons/Link'
import text from '../../../_constants/text'
import { connect } from 'react-redux'
import FooterLink from './FooterLink'
import { history } from '../../../reducers/store'

export interface IFooterProps extends IFooter {
  useAdminLinks: boolean
  className?: string
  links: FooterLinks
}

const Footer: FC<IFooterProps> = ({ useAdminLinks, links }) => {
  const themeClass = ' footer-secondary-theme '
  const pagePath = history.location?.pathname
  return (
    <footer className={`d-flex flex-row justify-content-middle${themeClass}`}>
      <Container className="footer-spacing max-grid-width" fluid>
        <Row className="footer-size flex-column flex-lg-row align-items-stretch">
          <Col xs={12} lg={3} className="d-flex flex-column">
            {links?.leftColumn.map((link: ContentLinkModel) => (
              <FooterLink key={`rc_${link.id}`} link={link} pagePath={pagePath} />
            ))}
          </Col>
          <Col xs={12} lg={3} className="d-flex flex-column">
            {links?.rightColumn.map((link: ContentLinkModel) => (
              <FooterLink key={`rc_${link.id}`} link={link} pagePath={pagePath} />
            ))}
          </Col>
          <Col
            xs={12}
            lg={6}
            className="mt-3 mt-lg-none d-flex flex-column justify-content-end align-items-end justify-content-lg-end align-items-lg-end"
          >
            <div className="float-right float-lg-left my-3">
              <IMedLogo useSecondaryTheme={useAdminLinks} isFooter={true} linkUrl="/" />
            </div>
            <span>{text.FOOTER_COPYRIGHT}</span>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

const mapStateToProps = (state) => {
  const { useAdminLinks } = state.admin
  return { useAdminLinks }
}

export default connect(mapStateToProps)(Footer)
