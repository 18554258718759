import RequestError from '../../errors/RequestError'
import { Practice } from '../../reducers/AccountReducer/AccountReducer'
import {
  BaseAction,
  DataAction,
  InitialSearch,
  SearchFormFieldType,
  createDataAction,
  createErrorAction,
  createSearchValidationErrorsAction,
  createUpdateFieldAction,
  searchActionCreator,
} from '../commons'
import { Search } from '../../reducers/commons/types'
import { Study } from '../../domain/models/commons/Study'

export const patientSearchActionCreator = searchActionCreator('PATIENT')

export const PATIENT_SEARCH_SEARCH = patientSearchActionCreator('SEARCH')
export const PATIENT_SEARCH_SET_FIRST_LOAD = patientSearchActionCreator('SET_FIRST_LOAD')
export const PATIENT_SEARCH_CLEAR_ALL = patientSearchActionCreator('CLEAR_ALL')
export const PATIENT_SEARCH_SEARCH_SUCCESS = patientSearchActionCreator('SEARCH_SUCCESS')
export const PATIENT_SEARCH_SEARCH_FAIL = patientSearchActionCreator('SEARCH_FAIL')
export const PATIENT_SEARCH_SEARCH_ALL = patientSearchActionCreator('SEARCH_ALL')
export const PATIENT_SEARCH_SET_CURRENT_SEARCH = patientSearchActionCreator('SET_CURRENT_SEARCH')
export const PATIENT_SEARCH_SAVE_DEFAULT_SEARCH_FILTERS = patientSearchActionCreator('SAVE_DEFAULT_SEARCH_FILTERS')
export const PATIENT_SEARCH_DEFAULT_SEARCH_FILTERS_SAVED = patientSearchActionCreator('DEFAULT_SEARCH_FILTERS_SAVED')

export const PATIENT_SEARCH_UPDATE_INPUTS = patientSearchActionCreator('UPDATE_INPUTS')
export const PATIENT_SEARCH_UPDATE_MODALITY_FILTER = patientSearchActionCreator('UPDATE_MODALITY_FILTER')
export const PATIENT_SEARCH_UPDATE_PRACTICE_FILTER = patientSearchActionCreator('UPDATE_PRACTICE_FILTER')
export const PATIENT_SEARCH_UPDATE_ERRORS = patientSearchActionCreator('UPDATE_ERRORS')
export const PATIENT_SEARCH_SET_SEARCH_TYPE = patientSearchActionCreator('SET_SEARCH_TYPE')
export const PATIENT_SEARCH_UPDATE_SELECTED_PRACTICE_LIST = patientSearchActionCreator('UPDATE_SELECTED_PRACTICE_LIST')

export const PATIENT_SEARCH_ABORT_SEARCH = patientSearchActionCreator('ABORT_SEARCH')

export interface PatientSearchSetSearchAction extends BaseAction {
  active: string
}

export interface PatientSearchAllActionData {
  patientId: string
  dob: string
}

export const patientSearch = createDataAction<InitialSearch>(PATIENT_SEARCH_SEARCH, { isInitialSearch: false })

export const clearAllSearch = (): BaseAction => ({ type: PATIENT_SEARCH_CLEAR_ALL })

export const patientSearchSuccess = createDataAction<Study[]>(PATIENT_SEARCH_SEARCH_SUCCESS)

export const patientSearchFail = createErrorAction<RequestError>(
  PATIENT_SEARCH_SEARCH_FAIL,
  new RequestError('Error while searching patient results'),
)

export const updateField = createUpdateFieldAction<SearchFormFieldType>(PATIENT_SEARCH_UPDATE_INPUTS)

export const updateModalityFilter = createDataAction<string>(PATIENT_SEARCH_UPDATE_MODALITY_FILTER)

export const updatePracticeFilter = createDataAction<string>(PATIENT_SEARCH_UPDATE_PRACTICE_FILTER)

export const updateSelectedPracticeList = createDataAction<Practice[] | string[]>(
  PATIENT_SEARCH_UPDATE_SELECTED_PRACTICE_LIST,
)

export const patientSearchValidationErrors = createSearchValidationErrorsAction(PATIENT_SEARCH_UPDATE_ERRORS)

export const patientSearchSetSearchType = createDataAction<PatientSearchSetSearchAction>(PATIENT_SEARCH_SET_SEARCH_TYPE)

export const patientSearchAll = createDataAction<PatientSearchAllActionData>(PATIENT_SEARCH_SEARCH_ALL)

export const setCurrentSearch = createDataAction<Search>(PATIENT_SEARCH_SET_CURRENT_SEARCH)

export const patientSearchSetFirstLoad = createDataAction<boolean>(PATIENT_SEARCH_SET_FIRST_LOAD)

export const saveDefaultSearchFilters = (): BaseAction => {
  return {
    type: PATIENT_SEARCH_SAVE_DEFAULT_SEARCH_FILTERS,
  }
}

export const defaultSearchFiltersSaved = (saved: boolean): DataAction<boolean> => {
  return {
    type: PATIENT_SEARCH_DEFAULT_SEARCH_FILTERS_SAVED,
    data: saved,
  }
}

export const abortSearch = (): BaseAction => {
  return {
    type: PATIENT_SEARCH_ABORT_SEARCH,
  }
}
