import React, { FC, PropsWithChildren } from 'react'
import { useLocation } from 'react-router-dom'
import Footer from '../../organisms/Footer'
import { noAuthFooterRoutes, noFooterRoutes } from '../../../router/routes'
import footerLinks from '../../../_constants/footerLinks'
import FooterlessLayout from './FooterlessLayout'
import { FooterLinks } from '../../../domain/models/commons/Footer'

const mockFooterData = {
  id: 'footer_0e37af3_0e37af3b-1b1d-4c03-8fdb-20fdef8c0bcf',
  type: 'root_footer',
  value: {
    adminLinks: footerLinks.ADMIN_LINKS,
    referrerLinks: footerLinks.REFERRER_LINKS,
    noAuthLinks: footerLinks.NO_AUTH_LINKS,
  },
  useAdminLinks: false,
}

type LayoutProps = {
  hideFooter?: boolean
} & PropsWithChildren

function getFooterLinks(pathname: string): FooterLinks {
  if (noAuthFooterRoutes.includes(pathname)) {
    return mockFooterData.value.noAuthLinks
  } else if (mockFooterData.useAdminLinks) {
    return mockFooterData.value.adminLinks
  }
  return mockFooterData.value.referrerLinks
}

const Layout: FC<LayoutProps> = ({ children, hideFooter = false }) => {
  const { pathname } = useLocation()
  const showFooter = !noFooterRoutes.includes(pathname) && !hideFooter
  return (
    <>
      <FooterlessLayout>{children}</FooterlessLayout>
      {showFooter && <Footer {...mockFooterData} links={getFooterLinks(pathname)} />}
    </>
  )
}

export default Layout
