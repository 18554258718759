import React, { FC, useEffect } from 'react'
import Loader from '../../../components/wrappers/Loader'
import text from '../../../_constants/text'
import Layout from '../../../components/wrappers/Layout'
import WebcomradResetPasswordPage from '../WebcomradResetPasswordPage'
import {
  WebcomradPasswordResetErrorModel,
  WebcomradPasswordResetFormDataModel,
} from '../../../reducers/WebcomradPasswordResetReducer/WebcomradPasswordResetReducer'
import { connect } from 'react-redux'
import {
  clearWebcomradPasswordResetForm,
  submitWebcomradPasswordResetForm,
  updateWebcomradPasswordResetFieldValue,
  setErrorWebcomradPasswordResetFormField,
} from '../../../actions/WebcomradPasswordResetActions/webcomradPasswordResetActions'
import { WebcomradPasswordResetFieldName } from '../../../actions/WebcomradPasswordResetActions/webcomradPasswordResetActions.types'
import { FieldValue } from '../../../actions/registerAccountActions.types'
import { SubmitControls } from '../../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { validateWebcomradPasswordResetField } from '../../../components/forms/validations/WebcomradPasswordReset'
import { getRecaptcha, setRecaptcha } from '../../../components/forms/captcha'

interface WebcomradResetPasswordProps {
  formData: WebcomradPasswordResetFormDataModel
  errors: WebcomradPasswordResetErrorModel
  updateFieldValue: (type: WebcomradPasswordResetFieldName, value: FieldValue) => void
  setInputError: (value: boolean, type: WebcomradPasswordResetFieldName) => void
  submit: SubmitControls
  submitForm: (formData: WebcomradPasswordResetFormDataModel) => void
  clearForm: () => void
}

const WebcomradPasswordResetContainer: FC<WebcomradResetPasswordProps> = ({
  formData,
  errors,
  updateFieldValue,
  setInputError,
  submit,
  submitForm,
  clearForm,
}) => {
  useEffect(() => {
    return () => {
      clearForm()
    }
  }, [])

  useEffect(() => {
    setRecaptcha()
  }, [])

  const onValueChange = (fieldName: WebcomradPasswordResetFieldName, value: FieldValue) => {
    const isError = validateWebcomradPasswordResetField(fieldName, value)
    setInputError(isError, fieldName)
    updateFieldValue(fieldName, value)
  }

  const onSubmit = (): void => {
    getRecaptcha(() => submitForm(formData))
  }

  return (
    <Layout>
      <Loader isLoading={submit.inProgress} message={text.SUBMITTING_FORM_MESSAGE}>
        <WebcomradResetPasswordPage
          submissionSuccess={submit.success}
          submissionFailed={submit.fail}
          formData={formData}
          errors={errors}
          onValueChange={onValueChange}
          onSubmit={onSubmit}
          clearForm={clearForm}
        />
      </Loader>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { formData, errors, submit } = state.webcomradPasswordReset
  return { formData, errors, submit }
}

const mapDispatchToProps = (dispatch) => ({
  submitForm: (formData: WebcomradPasswordResetFormDataModel) => dispatch(submitWebcomradPasswordResetForm(formData)),
  updateFieldValue: (type: WebcomradPasswordResetFieldName, value: FieldValue) =>
    dispatch(updateWebcomradPasswordResetFieldValue(type, value)),
  setInputError: (value: boolean, type: WebcomradPasswordResetFieldName) =>
    dispatch(setErrorWebcomradPasswordResetFormField(value, type)),
  clearForm: () => dispatch(clearWebcomradPasswordResetForm()),
})

export default connect(mapStateToProps, mapDispatchToProps)(WebcomradPasswordResetContainer)
