import { flatMap, sortBy } from 'lodash'
import text from '../_constants/text'
import { Patient, PatientId } from '../domain/models/commons/Patient'
import { Procedure } from '../domain/models/commons/Procedure'
import { Study } from '../domain/models/commons/Study'
import { RootState } from '../reducers/store'
import { Status } from '../domain/models/commons/Report'
import { SearchName } from '../reducers/commons/createSearchReducer'

const mapModalities = (modalities: string[]): string[] => {
  return modalities.map((modality) => {
    switch (modality) {
      case text.NUCLEAR_MEDICINE:
        return 'NM'
      case text.MAMMOGRAPHY:
        return 'MG'
      case text.ULTRASOUND:
        return 'US'
      default:
        return modality
    }
  })
}

// This is used for determining whether returned studies match the current
// modalities search. That is, if no study matches selected modalities, it is not injected into the search page component.
// This approach is not ideal and rather temporary though as the state shoul be normalized and procedures lifted up into
// their own state.
export const maybeFilterStudiesByModalities = (state: RootState, modalities: string[]): Record<PatientId, Study[]> => {
  const { byPatientId: studies } = state.patientStudies
  const modalityFiltersApplied = modalities && modalities[0] !== text.ALL_MODALITIES
  if (!modalityFiltersApplied) {
    return studies
  }
  const mappedModalities = mapModalities(modalities)

  const filteredStudies = {}

  Object.keys(studies).forEach((patientId: PatientId) => {
    studies[patientId].forEach((study: Study) => {
      const filteredProcedures = study.procedures.filter((procedure: Procedure) =>
        mappedModalities.includes(procedure.modality),
      )

      if (filteredProcedures.length > 0) {
        filteredStudies[patientId] = [
          ...(filteredStudies[patientId] || []),
          {
            ...study,
            procedures: filteredProcedures,
          },
        ]
      }
    })
  })

  return filteredStudies
}

export type ProcedureWithStudyInfo = Procedure & {
  status: Status
  practice: string
  accessionNumber: string
}

export const sortProceduresByAppointmentDate = (studies: Study[]): ProcedureWithStudyInfo[] => {
  const procedures = flatMap(studies, (study: Study) =>
    study.procedures?.map((procedure) => ({
      ...procedure,
      status: study.status,
      practice: study.facility,
      accessionNumber: procedure.accessionNumber || study.accessionNumber,
    })),
  )
  return sortBy(procedures, (procedure) => new Date(procedure.appointment))
}

export const selectProceduresWithStudyInfo = (
  state: RootState,
  patientId: PatientId,
  searchName: SearchName,
): ProcedureWithStudyInfo[] => {
  const studiesByPatient = maybeFilterStudiesByModalities(state, state[`${searchName}Search`]?.activeSearch?.modalities)
  return sortProceduresByAppointmentDate(studiesByPatient[patientId])
}

export type PatientWithNextAppointmentInfo = Patient & {
  referrerName: string
  nextAppointmentDate: string
}

export const selectPatientsWithNextAppointmentInfo = (state: RootState): PatientWithNextAppointmentInfo[] => {
  const studiesByPatient = maybeFilterStudiesByModalities(state, state.appointmentSearch?.activeSearch?.modalities)

  return Object.values(studiesByPatient)
    .map((studies: Study[]) => {
      const patient = studies?.[0]?.patient
      const referrerName = studies?.[0]?.referrer.name
      const sortedProcedures = sortProceduresByAppointmentDate(studiesByPatient[patient.id])
      const nextAppointmentDate = sortedProcedures?.[0]?.appointment

      return {
        ...patient,
        referrerName,
        nextAppointmentDate,
      }
    })
    .filter((patientWithNextAppointmentDate) => Boolean(patientWithNextAppointmentDate.nextAppointmentDate))
}

export type PatientWithStudies = Patient & {
  studies: Study[]
  accessible: boolean
}

export const selectPatientsWithStudies = (state: RootState, searchName: SearchName): PatientWithStudies[] => {
  const studiesByPatient = maybeFilterStudiesByModalities(state, state[`${searchName}Search`]?.activeSearch?.modalities)
  return Object.values(studiesByPatient).map((studies: Study[]) => {
    const patient = studies?.[0]?.patient
    const sortedStudies = sortBy(studies, [(i: Study) => i.date]).reverse()
    const accessible = studies?.[0]?.accessible

    return {
      ...patient,
      studies: sortedStudies,
      accessible,
      latestStudyDate: sortedStudies?.[0]?.date,
    }
  })
}
