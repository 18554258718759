import React from 'react'
import { Col, Row } from 'react-bootstrap'
import text from '../../../_constants/text'
import { ProcedureRowKeys } from '../ProcedureTable/index.types'
import ProcedureTableColumnLabel from '../ProcedureTableColumnLabel'

type StudyHeaderRowProps = {
  onSort: (sortValue: ProcedureRowKeys) => void
}

const ProcedureTableHeaderRow: React.FC<StudyHeaderRowProps> = ({ onSort }) => (
  <Row data-testid="procedureHeaderRow" className="mx-0 px-0 mb-2 procedure-table-row procedure-header-row">
    <Col className="procedure-table-col">
      <ProcedureTableColumnLabel onSortCb={() => onSort('practice')}>
        {text.PROCEDURE_TABLE_APPOINTMENT_DATE}
      </ProcedureTableColumnLabel>
    </Col>
    <Col className="procedure-table-col">
      <ProcedureTableColumnLabel onSortCb={() => onSort('appointment')}>
        {text.PROCEDURE_TABLE_APPOINTMENT_TIME}
      </ProcedureTableColumnLabel>
    </Col>
    <Col className="procedure-table-col" xs={3}>
      <ProcedureTableColumnLabel onSortCb={() => onSort('practice')}>
        {text.PROCEDURE_TABLE_PRACTICE}
      </ProcedureTableColumnLabel>
    </Col>
    <Col className="procedure-table-col">
      <ProcedureTableColumnLabel onSortCb={() => onSort('modality')}>
        {text.STUDY_TABLE_MODALITY}
      </ProcedureTableColumnLabel>
    </Col>
    <Col className="procedure-table-col" xs={2}>
      <ProcedureTableColumnLabel>{text.STUDY_TABLE_DESCRIPTION}</ProcedureTableColumnLabel>
    </Col>
    <Col className="procedure-table-col">
      <ProcedureTableColumnLabel>{text.STUDY_TABLE_ACCESSION_NO}</ProcedureTableColumnLabel>
    </Col>
    <Col className="procedure-table-col">
      <ProcedureTableColumnLabel onSortCb={() => onSort('status')}>
        {text.PROCEDURE_TABLE_STATUS}
      </ProcedureTableColumnLabel>
    </Col>
  </Row>
)

export default ProcedureTableHeaderRow
