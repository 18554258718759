import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'
import {
  submitAlfredMaterImagingLoading,
  submitAlfredMaterImagingSubmitFail,
  submitAlfredMaterImagingSubmitSuccess,
  ALFRED_MATER_IMAGING_REGISTRATION_SUBMIT,
} from '../../actions/AlfredMaterImagingActions/alfredMaterImagingActions'
import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import runWithRefresh from '../RefreshAuthTokenSaga/runWithRefresh'

const ALFRED_MATER_IMAGING_PATH = '/portal/register/alfred-mater-imaging'

type SubmitAlfredMaterImagingGenerator = Generator<
  SelectEffect | Promise<ResponseWithStatus<void>>,
  ResponseWithStatus<void>,
  ResponseWithStatus<void> | AccessToken
>

export function* submitAlfredMaterImagingForm(): SubmitAlfredMaterImagingGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken
  const formData = yield select((state) => state.alfredMaterImaging.formData)

  return (yield post(
    `${process.env.REACT_APP_API_URL}${ALFRED_MATER_IMAGING_PATH}`,
    { Authorization: `${jwt.type} ${jwt.token}` },
    formData,
  )) as ResponseWithStatus<void>
}

type SubmitAlfredMaterImagingSagaGenerator = Generator<
  PutEffect | SelectEffect | unknown,
  void,
  ResponseWithStatus<void>
>

export function* submitAlfredMaterImagingSaga(): SubmitAlfredMaterImagingSagaGenerator {
  yield put(submitAlfredMaterImagingLoading())
  const response = (yield runWithRefresh(
    submitAlfredMaterImagingForm,
  ) as SubmitAlfredMaterImagingGenerator) as ResponseWithStatus<void>

  if (response.status === ResponseStatus.SUCCESS) {
    yield put(submitAlfredMaterImagingSubmitSuccess())
    return
  }
  yield put(submitAlfredMaterImagingSubmitFail())
}

function* watchSubmitAlfredMaterImagingRegistration(): Generator<ForkEffect<never>, void> {
  yield takeEvery([ALFRED_MATER_IMAGING_REGISTRATION_SUBMIT], submitAlfredMaterImagingSaga)
}

const alfredMaterImagingSagas = [fork(watchSubmitAlfredMaterImagingRegistration)]

export default alfredMaterImagingSagas
