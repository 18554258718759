import React, { FC, useEffect } from 'react'
import Card from 'react-bootstrap/Card'

interface SubmitAlfredMaterImagingSuccessProps {
  messageOne: string
  messageTwo: string
  clearForm: () => void
}

const SubmitAlfredMaterImagingSuccess: FC<SubmitAlfredMaterImagingSuccessProps> = ({
  messageOne,
  messageTwo,
  clearForm,
}) => {
  useEffect(() => {
    clearForm()
  }, [])

  return (
    <Card as="section" className="my-3 my-lg-4" data-testid="submit-alfred-mater-imaging-success">
      <Card.Body className="p-3 p-lg-4">
        <Card.Text as="div" data-testid="subtitle" className="mt-3 success-registration">
          <p data-testid="alfred-mater-imaging-success-message-1">{messageOne}</p>
          <p data-testid="alfred-mater-imaging-success-message-2">{messageTwo}</p>
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default SubmitAlfredMaterImagingSuccess
