import { FC, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'

import WarningAlert from '../../atoms/WarningAlert'
import text from '../../../_constants/text'

const inteleConnectEVLink = 'https://pacs.i-med.com.au/Portal/app#/'

const MaintenanceModal2216: FC<{}> = () => {
  const shouldShowModal = JSON.parse(process.env.REACT_APP_SHOW_MAINTENANCE_MODAL)
  const [show, setShow] = useState(shouldShowModal)

  return (
    <Modal show={show} centered data-testid="maintenance-modal-1457" size="lg">
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-row align-items-center mb-3">
            <WarningAlert className="mt-4 ml-4" />
          </div>

          <h2 className="mt-3">{text.MAINTENANCE_MODAL_TITLE}</h2>

          <p className="mt-3" dangerouslySetInnerHTML={{ __html: text.MAINTENANCE_MODAL_MESSAGE_4 }} />
          <p
            className="mt-3"
            dangerouslySetInnerHTML={{ __html: text.MAINTENANCE_MODAL_MESSAGE_2(inteleConnectEVLink) }}
          />

          <div className="d-flex flex-row justify-content-end">
            <Button className="mt-4" data-testid="maintenance-modal-1457-close" onClick={() => setShow(false)}>
              {text.CLOSE}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default MaintenanceModal2216
