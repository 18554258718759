import React from 'react'
import { ReactComponent as Logo } from './logo.svg'
import { ReactComponent as LogoReversed } from './logo-reversed.svg'
import { ReactComponent as LogoFooter } from './logo-footer.svg'
import InternalLink from '../InternalLink'

type props = {
  useSecondaryTheme: boolean
  isFooter?: boolean
  linkUrl: string
  className?: string
  onLinkClick?: () => void
}
const IMedLogo: React.FC<props> = ({ useSecondaryTheme, linkUrl, className, isFooter, onLinkClick }) => {
  return (
    <InternalLink href={linkUrl} className={`align-self-center mb-0 py-2 ${className}`}>
      {isFooter ? (
        <LogoFooter onClick={onLinkClick} />
      ) : useSecondaryTheme ? (
        <LogoReversed onClick={onLinkClick} />
      ) : (
        <Logo onClick={onLinkClick} />
      )}
    </InternalLink>
  )
}

IMedLogo.defaultProps = {
  className: '',
}
export default IMedLogo
