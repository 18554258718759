import { BaseAction } from '../commons'
import {
  AddPracticeDataAction,
  AddPracticeFormErrorsAction,
  AddPracticeFormFieldType,
  FieldValue,
} from './addPracticeActions.types'

export const ADD_PRACTICE_UPDATE_DETAILS = 'ADD_PRACTICE/UPDATE_DETAILS'
export const ADD_PRACTICE_SUBMIT = 'ADD_PRACTICE/ADD_PRACTICE'
export const ADD_PRACTICE_SUBMIT_FAIL = 'ADD_PRACTICE/ADD_PRACTICE_FAIL'
export const ADD_PRACTICE_SUBMIT_SUCCESS = 'ADD_PRACTICE/ADD_PRACTICE_SUCCESS'
export const ADD_PRACTICE_FORM_VALIDATION_FAIL = 'ADD_PRACTICE/SUBMIT'
export const ADD_PRACTICE_RESET = 'ADD_PRACTICE/RESET'

export const updateField = (type: AddPracticeFormFieldType, value: FieldValue): AddPracticeDataAction => ({
  type: ADD_PRACTICE_UPDATE_DETAILS,
  data: {
    [type]: value,
  },
})

export const resetForm = (): BaseAction => ({ type: ADD_PRACTICE_RESET })

export const addPractice = (): BaseAction => ({
  type: ADD_PRACTICE_SUBMIT,
})

export const validateAddPracticeFormFail = (errors: Record<string, boolean>): AddPracticeFormErrorsAction => ({
  type: ADD_PRACTICE_FORM_VALIDATION_FAIL,
  data: { errors },
})

export const addPracticeSubmitFail = (): BaseAction => ({
  type: ADD_PRACTICE_SUBMIT_FAIL,
})

export const addPracticeSubmitSuccess = (): BaseAction => ({
  type: ADD_PRACTICE_SUBMIT_SUCCESS,
})
