import { Study } from '../../domain/models/commons/Study'
import RequestError from '../../errors/RequestError'
import { Search } from '../../reducers/commons/types'
import { DataAction, ErrorAction } from '../commons'

export const PATIENT_STUDIES_GET_STUDIES = 'PATIENT_STUDIES/GET_STUDIES'
export const PATIENT_STUDIES_GET_ALL_STUDIES = 'PATIENT_STUDIES/GET_ALL_STUDIES'
export const PATIENT_STUDIES_GET_STUDIES_SUCCESS = 'PATIENT_STUDIES/GET_STUDIES_SUCCESS'
export const PATIENT_STUDIES_GET_STUDIES_FAIL = 'PATIENT_STUDIES/GET_STUDIES_FAIL'

export type GetPatientStudiesData = { patientId: string; search: Partial<Search> }

export type GetAllPatientStudiesData = { patientId: string }

export type GetPatientStudiesResponseData = {
  data: Study[]
  patientId: string
}

export type PatientImagesInfoAbortedData = {
  patientId: string
  imagesInfoAborted: boolean
}

export const getPatientStudies = (
  patientId: string,
  search: Partial<Search> = {},
): DataAction<GetPatientStudiesData> => ({
  type: PATIENT_STUDIES_GET_STUDIES,
  data: { patientId, search },
})

export const getAllPatientStudies = (patientId: string): DataAction<GetAllPatientStudiesData> => ({
  type: PATIENT_STUDIES_GET_ALL_STUDIES,
  data: { patientId },
})

export const getPatientStudiesSuccess = (
  studiesData: GetPatientStudiesResponseData,
): DataAction<GetPatientStudiesResponseData> => ({
  type: PATIENT_STUDIES_GET_STUDIES_SUCCESS,
  data: studiesData,
})

export const getPatientStudiesFail = (
  patientId: string,
): ErrorAction & { data: ErrorAction['data'] & { patientId: string } } => ({
  type: PATIENT_STUDIES_GET_STUDIES_FAIL,
  data: { error: new RequestError('Error getting patient studies'), show: true, patientId },
})
