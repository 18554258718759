import { UpdateFieldAction } from '../../actions/commons'
import { LOGOUT_SUCCESS } from '../../actions/loginAction'
import {
  UPDATE_CHANGE_PASSWORD_FORM,
  CLEAR_CHANGE_PASSWORD_FORM,
  CHANGE_PASSWORD_FAIL,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_COMPLEXITY_FAIL,
  CHANGE_PASSWORD_COMPLEXITY_SUCCESS,
  PasswordAction,
  ChangePasswordFormFieldTypes,
} from '../../actions/passwordActions'
import { ChangePasswordFormType } from './PasswordReducer.types'

type PasswordState = {
  changePasswordForm: ChangePasswordFormType
  changePasswordFail: boolean
  changePasswordSuccess: boolean
  passwordNotComplexEnough: boolean
}

export const initialPasswordState: PasswordState = {
  changePasswordForm: {
    currentPassword: '',
    newPassword: '',
  },
  changePasswordFail: false,
  changePasswordSuccess: false,
  passwordNotComplexEnough: false,
}

const passwordReducer = (state: PasswordState = initialPasswordState, action: PasswordAction): PasswordState => {
  switch (action.type) {
    case UPDATE_CHANGE_PASSWORD_FORM:
      const a2 = action as UpdateFieldAction<ChangePasswordFormFieldTypes>
      return {
        ...state,
        changePasswordForm: {
          ...state.changePasswordForm,
          ...a2.data,
        },
      }
    case CHANGE_PASSWORD_FAIL:
      return {
        ...state,
        changePasswordFail: true,
      }
    case CHANGE_PASSWORD_COMPLEXITY_FAIL:
      return {
        ...state,
        passwordNotComplexEnough: true,
      }
    case CHANGE_PASSWORD_COMPLEXITY_SUCCESS:
      return {
        ...state,
        passwordNotComplexEnough: false,
      }
    case CHANGE_PASSWORD_SUCCESS:
      return {
        ...state,
        changePasswordSuccess: true,
      }
    case CLEAR_CHANGE_PASSWORD_FORM:
    case LOGOUT_SUCCESS:
      return { ...initialPasswordState }
    default:
      return state
  }
}

export default passwordReducer
