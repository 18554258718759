import React from 'react'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Row from 'react-bootstrap/Row'
import { RegisterAccountPageType } from '../../../actions/registerAccountActions.types'
import { ReactComponent as Number1 } from './Number1.svg'
import { ReactComponent as Number2 } from './Number2.svg'
import { ReactComponent as Number3 } from './Number3.svg'
import { ReactComponent as Number4 } from './Number4.svg'
import './StepIndicator.scss'
import { ReferralSectionType } from '../../../actions/ReferralActions/referralActions.types'

type NumberIconProps = {
  i: number
}
const NumberIcon = ({ i }): React.ReactElement<NumberIconProps> => {
  const EnumerationIcons = [
    <Number1 width="24" height="24" key={`enumeration-${i}`} />,
    <Number2 width="24" height="24" key={`enumeration-${i}`} />,
    <Number3 width="24" height="24" key={`enumeration-${i}`} />,
    <Number4 width="24" height="24" key={`enumeration-${i}`} />,
  ]
  return EnumerationIcons[i]
}

type StepIndicatorProps = {
  sectionIds: Record<string, string>
  currentSection: RegisterAccountPageType | ReferralSectionType
  navigateToPage: (sectionId: RegisterAccountPageType | ReferralSectionType) => void
}
const StepIndicator: React.FC<StepIndicatorProps> = ({ sectionIds, currentSection, navigateToPage }) => {
  const mappedIds = Object.keys(sectionIds)
  return (
    <Card as="section" className="mb-3 mb-lg-4">
      <Card.Body className="p-3 p-lg-4 py-1 py-lg-2">
        {mappedIds.map((k, i) => {
          const canAccessPage = mappedIds.indexOf(currentSection) < i
          return (
            <Row
              data-testid={k}
              key={k}
              className={canAccessPage ? 'opacity-50 my-3' : 'my-3'}
              onClick={canAccessPage ? null : () => navigateToPage(k as RegisterAccountPageType)}
              role={canAccessPage ? null : 'button'}
            >
              <Col xs={2}>
                <NumberIcon i={i} />
              </Col>
              <Col className="p-0">
                <h3 className="d-inline pb-0">{sectionIds[k]}</h3>
              </Col>
            </Row>
          )
        })}
      </Card.Body>
    </Card>
  )
}

export default StepIndicator
