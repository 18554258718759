import {
  PutEffect,
  SelectEffect,
  select,
  put,
  ForkEffect,
  fork,
  takeLatest,
  CancelEffect,
  cancel,
  take,
  TakeEffect,
} from 'redux-saga/effects'
import {
  APPOINTMENT_SEARCH_SEARCH,
  appointmentSearchSuccess,
  appointmentSearchFail,
} from '../../actions/AppointmentSearchActions/appointmentSearchActions'
import { ResponseStatus, ResponseWithStatus } from '../../fetch'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { Search } from '../../reducers/commons/types'
import mapSearchParams from '../../mappers/mapSearchParams'
import urls from '../../_constants/urls'
import runWithRefresh from '../RefreshAuthTokenSaga/runWithRefresh'
import { Study } from '../../domain/models/commons/Study'
import { ROUTER_ON_LOCATION_CHANGED, ReduxRouterState } from '@lagunovsky/redux-react-router'
import { ErrorAction, SearchStudiesSuccessAction } from '../../actions/commons'
import fetchSaga, { FetchGenerator } from '../fetch'
import { Task } from 'redux-saga'
import routes from '../../router/routes'

type SearchAppointmentsGenerator = Generator<
  SelectEffect | ResponseWithStatus<Study[]> | FetchGenerator<Study[]>,
  ResponseWithStatus<Study[]>,
  ResponseWithStatus<Study[]> | AccessToken | Search
>

export function* searchAppointments(): SearchAppointmentsGenerator {
  const searchState = (yield select((state) => state.appointmentSearch.currentSearch)) as Search
  const query = mapSearchParams(searchState)

  const res = (yield fetchSaga<Study[]>(
    `${process.env.REACT_APP_API_URL}${urls.SEARCH_ORDERS_API}`,
    query,
  )) as ResponseWithStatus<Study[]>

  return res
}

type AppointmentSearchSagaGenerator = Generator<
  | ForkEffect<
      Generator<
        | Generator<unknown, ResponseWithStatus<unknown>, unknown>
        | PutEffect<SearchStudiesSuccessAction>
        | PutEffect<ErrorAction>,
        void,
        unknown
      >
    >
  | TakeEffect
  | SelectEffect
  | CancelEffect
>

export function* appointmentSearchSaga(): AppointmentSearchSagaGenerator {
  const bgTask = yield fork(function* () {
    const submitResponse = yield runWithRefresh(searchAppointments)
    if (!submitResponse) return
    if (submitResponse.status === ResponseStatus.SUCCESS) {
      yield put(appointmentSearchSuccess(submitResponse.data))
    } else {
      yield put(appointmentSearchFail())
    }
  })

  // Abort the HTTP request (`searchAppointments())`) if the user navigates to another page before it has been completed.
  yield take(ROUTER_ON_LOCATION_CHANGED)

  const routerState = (yield select((state) => state.router)) as ReduxRouterState
  const { location } = routerState

  if (location.pathname === routes.appointments) {
    // Don't cancel the current HTTP request if the user forces the appointments page to reload
    return
  }

  yield cancel(bgTask as Task)
}

function* watchAppointmentSearchSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(APPOINTMENT_SEARCH_SEARCH, appointmentSearchSaga)
}

const appointmentSearchSagas = [fork(watchAppointmentSearchSaga)]

export default appointmentSearchSagas
