import React, { PropsWithChildren } from 'react'
import InputLabel from '../../../atoms/InputLabel'
import { Col, InputGroup, Row, Form } from 'react-bootstrap'

type Props = {
  label?: string
  inputName: string
  required?: boolean
  errorMessage?: string
  error?: boolean
  className?: string
  inline?: boolean
  onChangeCb: (name: string, value: string) => void
  value: number | string | undefined
  options: string[]
  disabled?: boolean
}

const RadioButtonGroup = ({
  inputName,
  errorMessage,
  error,
  onChangeCb,
  value,
  options,
  inline = false,
  required = false,
  disabled = false,
  label = '',
  className = '',
}: PropsWithChildren<Props>): JSX.Element => (
  <div data-testid="RadioButtonGroup" className={`p-0 radio-button-group ${className}`}>
    {label && (
      <Row className="m-0">
        <InputLabel label={`${label} ${required ? '*' : ''}`} />
      </Row>
    )}
    <Row className={`m-0 input ${error ? 'validation-error' : ''}`}>
      <Form.Group as={Col} className="p-0 justify-content-center">
        <InputGroup className={`d-flex flex-${inline ? 'row' : 'column'}`}>
          {options.map((option) => (
            <Form.Check
              className={`ms-0 d-flex align-items-center${error ? ' error' : ''}`}
              inline={inline}
              name={`${inputName}_${option}`}
              value={option}
              onChange={() => onChangeCb(inputName, option)}
              type="radio"
              label={option}
              disabled={disabled}
              key={`${inputName}_${option}`}
              data-testid={option}
              id={`${inputName}_${option}`}
              checked={value === option}
            />
          ))}
        </InputGroup>
      </Form.Group>
    </Row>
    {error && errorMessage && (
      <Row className="m-0 pl-3 pt-2">
        <p className="error">{errorMessage}</p>
      </Row>
    )}
  </div>
)

export default RadioButtonGroup
