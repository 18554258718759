import {
  WebcomradFormDataModel,
  WebcomradFormErrorModel,
  webcomradRequiredFields,
} from '../../../../reducers/WebcomradReducer/WebcomradReducer'
import { FieldValue } from '../../../../actions/registerAccountActions.types'
import genericValidators, { genericFieldNames } from '../GenericValidators'

export enum webcomradFieldNames {
  termsAndConditions = 'termsAndConditions',
  fullName = 'fullName',
  providerNumber = 'providerNumber',
  ahpranumber = 'ahpranumber',
  referrerEmail = 'referrerEmail',
  practiceName = 'practiceName',
  practiceAddress = 'practiceAddress',
  practiceSuburb = 'practiceSuburb',
  practiceState = 'practiceState',
  practicePostcode = 'practicePostcode',
  practicePhone = 'practicePhone',
  practiceFax = 'practiceFax',
  department = 'department',
  mobileNumber = 'mobileNumber',
  speciality = 'speciality',
  otherSpeciality = 'otherSpeciality',
  operatingSystemMac = 'operatingSystemMac',
  operatingSystemPC = 'operatingSystemPC',
}

export const validateWebcomradField = (field: string, value: FieldValue): boolean => {
  switch (field) {
    case webcomradFieldNames.termsAndConditions:
    case webcomradFieldNames.operatingSystemMac:
    case webcomradFieldNames.operatingSystemPC:
    case webcomradFieldNames.ahpranumber:
    case webcomradFieldNames.providerNumber:
      return !value
    case webcomradFieldNames.fullName:
    case webcomradFieldNames.practiceName:
      return !genericValidators(genericFieldNames.fullName, value)
    case webcomradFieldNames.referrerEmail:
      return !genericValidators(genericFieldNames.emailAddress, value)
    case webcomradFieldNames.practicePhone:
    case webcomradFieldNames.practiceFax:
    case webcomradFieldNames.mobileNumber:
      return !genericValidators(genericFieldNames.contactNumber, value)
    case webcomradFieldNames.practiceAddress:
      return !/[a-zA-Z]+/.test(value.toString())
    case webcomradFieldNames.practiceSuburb:
      return !/[a-zA-Z]+/.test(value.toString())
    case webcomradFieldNames.practiceState:
      return !/^(QLD|NSW|VIC|ACT|NT|WA|SA|TAS)$/.test(value.toString())
    case webcomradFieldNames.practicePostcode:
      return !/^[0-9]{4}$/.test(value.toString())
    case webcomradFieldNames.speciality:
      return !/^(General Practitioner|General Practitioner Registrar|General Practitioner Intern|Nurse Practitioner|Specialist|Allied Health|Hospital Registrar\/Intern|Dentist|Other)$/.test(
        value.toString(),
      )
    default:
      return false
  }
}

function validateFormData(formData: WebcomradFormDataModel): { errors: WebcomradFormErrorModel; isValid: boolean } {
  const errors = validateWebcomradFormValues(formData) as WebcomradFormErrorModel
  const isValid = Object.keys(errors).find((value) => {
    const isOperatingSystemPair = value === 'operatingSystemMac' || value === 'operatingSystemPC'
    if (isOperatingSystemPair) {
      const isAtLeastOneValid = errors.operatingSystemMac || errors.operatingSystemPC
      const noError = false
      if (isAtLeastOneValid) {
        return noError
      }
    }
    return !!errors[value]
  })
  return { errors, isValid: !isValid }
}

const validateWebcomradFormValues = (formData: WebcomradFormDataModel): WebcomradFormErrorModel => {
  const errors = {} as WebcomradFormErrorModel
  for (const field in formData) {
    const value = formData[field]
    const notRequiredAndHasNoValue = !webcomradRequiredFields[field] && !value
    errors[field] = validateWebcomradField(field, value)
    if (notRequiredAndHasNoValue) {
      errors[field] = false
    }
  }
  return errors
}

export default validateFormData
