import ErrorBase from '../../errors/ErrorBase'
import { BaseAction, ErrorAction } from '../commons'
import RequestError from '../../errors/RequestError'

export const DISPLAY_FALLBACK_UI = 'DISPLAY_FALLBACK_UI'
export const RESET_ERROR_STATE = 'RESET_ERROR_STATE'
export const BREAK_GLASS_CONSENT_FAIL = 'BREAK_GLASS/CONSENT_FAIL'

export const breakGlassConsentFail = (): ErrorAction => ({
  type: BREAK_GLASS_CONSENT_FAIL,
  data: { error: new RequestError('Error connecting to indexed database'), show: true },
})

export const displayFallbackUi = (error: ErrorBase): ErrorAction => ({
  type: DISPLAY_FALLBACK_UI,
  data: { error, show: true },
})
export const resetErrorState = (): BaseAction => ({ type: RESET_ERROR_STATE })
