import { SectionTypes } from '../../../domain/constants'
import { TextSection as ITextSection } from '../../../domain/models/sections/TextSection'
import TextSection from '../../molecules/TextSection'
import { VideoSection as IVideoSection } from '../../../domain/models/sections/VideoSection'
import VideoSection from '../../molecules/VideoSection'
import { ISearchSectionContent } from '../../../domain/models/pages/AccessPatientImagesPage/ISearchSectionContent'
import React, { FunctionComponent } from 'react'

type Props = {
  props: ITextSection | IVideoSection | ISearchSectionContent
}

const getSection = (type, props): React.ReactElement => {
  switch (type) {
    case SectionTypes.TEXT:
      const textProps = props as ITextSection
      return <TextSection key={1} {...textProps} />
    case SectionTypes.VIDEO:
      const videoProps = props as IVideoSection
      return <VideoSection {...videoProps} />

    default:
      return <div />
  }
}

const Section: FunctionComponent<Props> = ({ props }) => {
  return getSection(props.type, props)
}

export default Section
