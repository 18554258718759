import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

const location = {
  x: 0,
  y: 0,
}

export default function scrollToTop(): void {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(location.x, location.y)
  }, [pathname])

  return null
}
