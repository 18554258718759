import React, { MouseEvent, useCallback, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import IndicatorInternalLink from '../../../components/atoms/IndicatorInternalLink'
import { consentIsValid } from '../../../services/breakGlassConsentValidityWatcher'
import text from '../../../_constants/text'
import BreakGlassTimeoutModal from '../BreakGlassTimeoutModal'
import { StudyRow } from '../StudyTable/index.types'
import { useNavigate } from 'react-router'
import ReportCell from '../ReportCell'
import Icon from '../../../components/atoms/Icon'
import { Status } from '../../../domain/models/commons/Report'
import { patientImagesInfoAbort } from '../../../actions/PatientImagesActions/patientImagesActions'
import { Study } from '../../../domain/models/commons/Study'
import { Patient } from '../../../domain/models/commons/Patient'

const HIGHLIGHT_UNLOCKED_ROW_TIMEOUT = 4000

type StudyRowProps = {
  study: StudyRow
  activeSearchType: string
  patient: Patient
  displayName: string
  shouldHighlightUnlockedRow: boolean
  abortPatientImagesInfo: () => void
}

function isImagesAvailable(study: StudyRow): boolean {
  if (study.status === Status.PENDING || study.status === Status.APPOINTMENT_PENDING) {
    return false
  }

  return study.dicom?.data && study.dicom.data.length > 0
}

function getMissingImagesText(study: StudyRow): string {
  if (Study.isStudyInFuture(study)) {
    return text.PENDING
  } else if (!study.dicom || study.dicom.state.inProgress) {
    return text.LOADING
  }
  return text.NOT_AVAILABLE
}

const StudyTableRow: React.FC<StudyRowProps> = ({
  study,
  patient,
  displayName,
  shouldHighlightUnlockedRow,
  abortPatientImagesInfo,
}) => {
  const navigate = useNavigate()
  const [showBreakGlassExpiryModal, setShowBreakGlassExpiryModal] = useState(false)
  const [highlightUnlockedRow, setHighlightUnlockedRow] = useState(false)

  useEffect(() => {
    setHighlightUnlockedRow(shouldHighlightUnlockedRow)

    let timeout
    if (shouldHighlightUnlockedRow) {
      timeout = setTimeout(() => {
        setHighlightUnlockedRow(false)
      }, HIGHLIGHT_UNLOCKED_ROW_TIMEOUT)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [])

  const validateConsent = useCallback(
    (url: string) => {
      consentIsValid(displayName, patient.id).then((consent) => {
        if (consent) {
          abortPatientImagesInfo()
          navigate(url, { state: { patientDetails: patient } })
        } else {
          setShowBreakGlassExpiryModal(true)
        }
      })
    },
    [consentIsValid],
  )

  const handleShowImages = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      if (study.locked) {
        validateConsent(study.images)
      } else {
        navigate(study.images)
      }
    },
    [validateConsent, study.locked, study.images],
  )

  const handleShowReport = useCallback(
    (e: MouseEvent) => {
      e.preventDefault()
      if (study.locked) {
        validateConsent(study.report)
      } else {
        // Image info requests will still run after navigating to the report page,
        // causing slow loading times on the report page.
        // This will cancel out in-progress XHR requests for images to make subsequent requests on the report page run faster.
        abortPatientImagesInfo()
        navigate(study.report, { state: { patientDetails: patient } })
      }
    },
    [validateConsent, study.locked, study.report],
  )

  let className = 'mx-0 my-1 px-0 py-2 study-table-row'

  if (highlightUnlockedRow) {
    className = `${className} study-table-row--unlocked`
  }

  return (
    <>
      <BreakGlassTimeoutModal
        show={showBreakGlassExpiryModal}
        handleShow={() => setShowBreakGlassExpiryModal(!showBreakGlassExpiryModal)}
        patientName={patient.name}
        navigate
      />
      <Row data-testid="studyRow" className={className}>
        <Col className="study-table-col date-col" xs={6}>
          {moment(study.studyDate, text.DATE_SEARCH_FORMAT).format(text.DATE_FORMAT).toString()}
        </Col>
        <Col className="study-table-col procedures-col" xs={6}>
          {study.modality}
        </Col>
        <Col className="study-table-col description-col" xs={6} title={study.description}>
          {study.description}
        </Col>
        <Col className="study-table-col doctor-col" xs={6}>
          {study.referrer}
        </Col>
        <Col className="study-table-col accession-col" xs={6}>
          {study.accessionNo}
        </Col>
        <Col className="study-table-col link-col" xs={6}>
          {isImagesAvailable(study) ? (
            <IndicatorInternalLink
              placeholder={text.STARTED}
              type="success"
              href={study.images}
              onClick={handleShowImages}
            >
              {text.STUDY_TABLE_SHOW_IMAGES}
            </IndicatorInternalLink>
          ) : (
            <div className="images-missing">{getMissingImagesText(study)}</div>
          )}
        </Col>
        <ReportCell reportUrl={study.report} handleShowReport={handleShowReport} status={study.status} />
        {study.locked && (
          <Col xs={1} className="study-table-col lock-col" data-testid="unlockedIcon">
            <Icon className="lock-icon" name="lock-open" />
          </Col>
        )}
      </Row>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  const { activeSearchType } = state.patientSearch
  const { displayName } = state.account
  const showResults = ownProps.showResults && ownProps.study.locked

  return { activeSearchType, displayName, showResults }
}

const mapDispatchToProps = (dispatch) => {
  return {
    abortPatientImagesInfo: () => dispatch(patientImagesInfoAbort()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StudyTableRow)
