import { BaseAction } from '../commons'
import { FieldValue } from '../registerAccountActions.types'
import {
  WebcomradDataAction,
  WebcomradErrorAction,
  WebcomradFieldName,
  WebcomradFieldValidateAction,
} from './webcomradActions.types'
import { WebcomradFormDataModel } from '../../reducers/WebcomradReducer/WebcomradReducer'
import validateFormData from '../../components/forms/validations/Webcomrad'

export const WEBCOMRAD_REGISTRATION_SUBMIT = 'WEBCOMRAD/SUBMIT'
export const WEBCOMRAD_FIELD_UPDATE = 'WEBCOMRAD/FIELD_VALUE_UPDATE'
export const WEBCOMRAD_FIELD_VALIDATE = 'WEBCOMRAD/FIELD_VALUE_VALIDATE'
export const WEBCOMRAD_CLEAR = 'WEBCOMRAD/CLEAR'
export const WEBCOMRAD_ERROR = 'WEBCOMRAD/ERROR'
export const WEBCOMRAD_SUBMIT_SUCCESS = 'WEBCOMRAD/SUBMIT_SUCCESS'
export const WEBCOMRAD_SUBMIT_FAIL = 'WEBCOMRAD/SUBMIT_FAIL'
export const WEBCOMRAD_SUBMIT_LOADING = 'WEBCOMRAD/SUBMIT_LOADING'

export const updateWebcomradFieldValue = (type: WebcomradFieldName, value: FieldValue): WebcomradDataAction => ({
  type: WEBCOMRAD_FIELD_UPDATE,
  data: {
    [type]: value,
  },
})

export const submitWebcomradRegistration = (formData: WebcomradFormDataModel): BaseAction | WebcomradErrorAction => {
  const { errors, isValid } = validateFormData(formData)
  if (!isValid) {
    return {
      type: WEBCOMRAD_ERROR,
      errors,
    } as WebcomradErrorAction
  }
  return {
    type: WEBCOMRAD_REGISTRATION_SUBMIT,
  }
}

export const validateWebcomradFormField = (value: boolean, type: WebcomradFieldName): WebcomradFieldValidateAction => ({
  type: WEBCOMRAD_FIELD_VALIDATE,
  data: {
    fieldName: type,
    value,
  },
})

export const clearWebcomradForm = (): BaseAction => ({
  type: WEBCOMRAD_CLEAR,
})

export const submitWebcomradSubmitSuccess = (): BaseAction => ({
  type: WEBCOMRAD_SUBMIT_SUCCESS,
})

export const submitWebcomradSubmitFail = (): BaseAction => ({
  type: WEBCOMRAD_SUBMIT_FAIL,
})

export const submitWebcomradLoading = (): BaseAction => ({
  type: WEBCOMRAD_SUBMIT_LOADING,
})
