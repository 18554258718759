import React, { memo } from 'react'
import { Col, Row } from 'react-bootstrap'
import text from '../../../_constants/text'

import AppointmentTableColumnLabel from '../AppointmentTableColumnLabel'

type PatientTableHeaderRowProps = {
  onSort: (sortValue: string) => void
}

const AppointmentTableHeaderRow: React.FC<PatientTableHeaderRowProps> = memo(function AppointmentTableHeaderRow({
  onSort,
}) {
  return (
    <div className="search-table-header-row-wrapper">
      <Row className="search-table-row" data-testid="AppointmentTableHeaderRow">
        <Col xs={4} className="search-table-col">
          <AppointmentTableColumnLabel onSortCb={() => onSort('name')}>
            {text.PATIENT_TABLE_NAME}
          </AppointmentTableColumnLabel>
        </Col>
        <Col xs={5} className="search-table-col">
          <Row>
            <Col xs={3} className="search-table-col">
              <AppointmentTableColumnLabel onSortCb={() => onSort('dob')}>{text.DOB}</AppointmentTableColumnLabel>
            </Col>
            <Col xs={4} className="search-table-col">
              <AppointmentTableColumnLabel>{text.PATIENT_ID}</AppointmentTableColumnLabel>
            </Col>
            <Col xs={5} className="search-table-col">
              <AppointmentTableColumnLabel onSortCb={() => onSort('referrerName')}>
                {text.STUDY_TABLE_REFERRER}
              </AppointmentTableColumnLabel>
            </Col>
          </Row>
        </Col>
        <Col xs={3} className="search-table-col">
          <AppointmentTableColumnLabel onSortCb={() => onSort('nextAppointmentDate')}>
            {text.APPOINTMENT_TABLE_NEXT_APPOINTMENT}
          </AppointmentTableColumnLabel>
        </Col>
      </Row>
    </div>
  )
})

export default AppointmentTableHeaderRow
