import React from 'react'
import { Col, Row } from 'react-bootstrap'
import text from '../../../_constants/text'

import PatientTableColumnLabel from '../PatientTableColumnLabel'
import { PatientRowKeys } from '../../types'

type PatientTableHeaderRowProps = {
  onSort: (sortValue: PatientRowKeys) => void
}
const PatientTableHeaderRow: React.FC<PatientTableHeaderRowProps> = ({ onSort }) => (
  <div className="search-table-header-row-wrapper">
    <Row className="search-table-row" data-testid="PatientTableHeaderRow">
      <Col xs={4} className="search-table-col">
        <PatientTableColumnLabel onSortCb={() => onSort('name')}>{text.PATIENT_TABLE_NAME}</PatientTableColumnLabel>
      </Col>
      <Col xs={4} className="search-table-col">
        <Row>
          <Col xs={5} className="search-table-col">
            <PatientTableColumnLabel onSortCb={() => onSort('dob')}>{text.DOB}</PatientTableColumnLabel>
          </Col>
          <Col xs={7} className="search-table-col">
            <PatientTableColumnLabel>{text.PATIENT_ID}</PatientTableColumnLabel>
          </Col>
        </Row>
      </Col>
      <Col xs={4} className="search-table-col">
        <Row>
          <Col xs={5} className="search-table-col">
            <PatientTableColumnLabel onSortCb={() => onSort('latestStudyDate')}>
              {text.PATIENT_TABLE_LATEST_STUDY}
            </PatientTableColumnLabel>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
)

export default PatientTableHeaderRow
