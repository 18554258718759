import { useCallback, useState } from 'react'
import { SortOrder } from '../../pages/types'
import { sortBy } from 'lodash'

type SortKey = string
type Sort<SortKey> = {
  order: SortOrder
  value: SortKey
}

export const sortRows = <T extends Record<string, unknown>, K extends SortKey>(rows: T[], sort: Sort<K>): T[] => {
  const sorted: T[] = sortBy(rows, [(i: T) => i[sort.value]])
  return sort.order === SortOrder.DESC ? sorted.reverse() : sorted
}

type UseSortReturnType<K extends SortKey> = {
  sort: Sort<K>
  handleSort: (sortValue: K) => void
}

const useSort = <K extends SortKey>(defaultSortKey: K, defaultOrder = SortOrder.ASC): UseSortReturnType<K> => {
  const [sort, setSort] = useState({ order: defaultOrder, value: defaultSortKey })

  const handleSort = useCallback((sortValue: K) => {
    setSort(({ order }) => ({
      order: order === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC,
      value: sortValue,
    }))
  }, [])

  return { sort, handleSort }
}

export default useSort
