import React, { useEffect, Dispatch, useState } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import text from '../../../_constants/text'
import isEqual from 'lodash/isEqual'
import { connect } from 'react-redux'
import { BaseAction, SearchFormFieldType } from '../../../actions/commons'
import SmallButton from '../../../components/atoms/SmallButton'
import SearchBar from '../../../components/organisms/SearchBar'
import OverlayTooltip from '../../../components/molecules/OverlayTooltip'
import { ReactComponent as CheckIcon } from '../../../components/atoms/Icon/check_circle.svg'
import { RootState } from '../../../reducers/store'
import { Search } from '../../../reducers/commons/types'
import { Practice } from '../../../domain/models/commons/Practice'
import {
  clearAllSearch,
  patientSearch,
  saveDefaultSearchFilters,
  updateField,
  updateModalityFilter,
  updatePracticeFilter,
  updateSelectedPracticeList,
} from '../../../actions/PatientSearchActions/patientSearchActions'
import { getAccountDetails } from '../../../actions/accountAction'

interface PatientSearchBarProps {
  reset: () => void
  saveDefaultSearchFilters: () => void
  submitInProgress: boolean
  currentSearch: Search
  activeSearch: Search
  defaultSearchFiltersSaved: boolean
  updateField: (name: SearchFormFieldType, value: string) => void
  updateModalityFilter: (modality: string) => void
  updatePracticeFilter: (practice: string) => void
  updateSelectedPracticeList: (practices: Practice[]) => void
  patientSearch: (isInitialSearch?: boolean) => void
  getAccountDetails: () => void
  searchErrors: Partial<Record<SearchFormFieldType, boolean>>
  activeSearchType: string
  practices: Practice[]
  selectedPractices: string[]
  firstLoad: boolean
}

enum SaveSearchFiltersState {
  STALE,
  SUBMITTED,
  SAVED,
}

const PatientSearchBar: React.FC<PatientSearchBarProps> = ({
  activeSearchType,
  updateField,
  updateModalityFilter,
  updatePracticeFilter,
  currentSearch,
  activeSearch,
  saveDefaultSearchFilters,
  searchErrors,
  patientSearch,
  practices,
  submitInProgress,
  selectedPractices,
  defaultSearchFiltersSaved,
  getAccountDetails,
  reset,
  firstLoad,
}) => {
  const [saveSearchFiltersState, setSaveSearchFiltersState] = useState(
    defaultSearchFiltersSaved ? SaveSearchFiltersState.SAVED : SaveSearchFiltersState.STALE,
  )

  const handleSaveSearchFilters = () => {
    saveDefaultSearchFilters()
    setSaveSearchFiltersState(SaveSearchFiltersState.SUBMITTED)
  }

  useEffect(() => {
    getAccountDetails()

    const isWithinMyNetworkOrReferredByMe =
      activeSearchType === text.SEARCH_REFERRED_WITHIN_MY_NETWORK || activeSearchType === text.SEARCH_REFERRED_BY_ME

    const shouldTriggerInitialSearch = firstLoad && isWithinMyNetworkOrReferredByMe

    if (shouldTriggerInitialSearch) {
      patientSearch(true)
    }
  }, [])

  const RESET_SAVE_DEFAULT_SEARCH_BUTTON_TIMEOUT = 5000
  useEffect(() => {
    const isSaveSearchFiltersSubmitted = saveSearchFiltersState === SaveSearchFiltersState.SUBMITTED
    if (!isSaveSearchFiltersSubmitted) {
      return
    }

    const id = setTimeout(() => {
      setSaveSearchFiltersState(SaveSearchFiltersState.SAVED)
    }, RESET_SAVE_DEFAULT_SEARCH_BUTTON_TIMEOUT)

    return () => clearTimeout(id)
  }, [saveSearchFiltersState])

  const isCurrentSearchEqualToActiveSearch = isEqual(currentSearch, activeSearch)
  useEffect(() => {
    if (!isCurrentSearchEqualToActiveSearch) {
      setSaveSearchFiltersState(SaveSearchFiltersState.STALE)
    }
  }, [currentSearch, activeSearch])

  useEffect(() => {
    setSaveSearchFiltersState(defaultSearchFiltersSaved ? SaveSearchFiltersState.SAVED : SaveSearchFiltersState.STALE)
  }, [defaultSearchFiltersSaved])

  const isSaveSearchFiltersSubmitted = saveSearchFiltersState === SaveSearchFiltersState.SUBMITTED
  const areSearchFiltersSaved = saveSearchFiltersState === SaveSearchFiltersState.SAVED

  const isSaveSearchFiltersButtonDisabled = submitInProgress || areSearchFiltersSaved

  const saveSearchFiltersButton = isSaveSearchFiltersSubmitted ? (
    <p id="save-search-filters-updated-label" data-testid="SaveSearchFiltersUpdatedLabel">
      <span className="mx-1">{text.DEFAULT_FILTERS_UPDATED}</span>
      <CheckIcon width={12} height={12} />
    </p>
  ) : (
    <OverlayTooltip
      id="save-search-filters-tooltip"
      overlaidContent={
        <SmallButton
          className="my-1 mx-1"
          data-testid="SaveSearchFiltersBtn"
          disabled={isSaveSearchFiltersButtonDisabled}
          onClick={handleSaveSearchFilters}
        >
          {text.SAVE_FILTERS_AS_DEFAULT}
        </SmallButton>
      }
      tooltipContent={<span>{text.PATIENT_SEARCH_SAVE_FILTERS_AS_DEFAULT_TOOLTIP}</span>}
      placement="bottom"
    />
  )

  const handleSearch = () => {
    patientSearch()
  }

  const isSearchButtonDisabled = submitInProgress || isCurrentSearchEqualToActiveSearch
  const paramsInvalidError = searchErrors?.nameOrPatientId || searchErrors?.dob
  return (
    <>
      <SearchBar
        dateRangeFilters={text.SEARCH_DATE_RANGE_FILTERS}
        modalitiesFilters={text.SEARCH_MODALITY_FILTERS}
        practicesFilters={practices}
        searchReferredTypeFilters={[
          text.SEARCH_REFERRED_BY_ANYONE,
          text.SEARCH_REFERRED_WITHIN_MY_NETWORK,
          text.SEARCH_REFERRED_BY_ME,
        ]}
        updateField={updateField}
        updateModalityFilter={updateModalityFilter}
        updatePracticeFilter={updatePracticeFilter}
        updateSelectedPracticeList={updateSelectedPracticeList}
        handleSearch={handleSearch}
        paramsInvalidError={paramsInvalidError}
        isSearchButtonDisabled={isSearchButtonDisabled}
        currentSearch={currentSearch}
        submitInProgress={submitInProgress}
        selectedPractices={selectedPractices}
      />
      <Row>
        <Col xs={12} lg={4} className="pb-3">
          <SmallButton className="my-1 mx-1" disabled={submitInProgress} onClick={reset}>
            {text.RESET}
          </SmallButton>
          {saveSearchFiltersButton}
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const {
    currentSearch,
    activeSearch,
    searchErrors,
    activeSearchType,
    submit,
    selectedPractices,
    firstLoad,
    defaultSearchFiltersSaved,
  } = state.patientSearch
  const { practices } = state.account
  const { inProgress: submitInProgress } = submit
  return {
    currentSearch,
    activeSearch,
    activeSearchType,
    searchErrors,
    practices,
    selectedPractices,
    submitInProgress,
    firstLoad,
    defaultSearchFiltersSaved,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  reset: () => dispatch(clearAllSearch()),
  patientSearch: (isInitialSearch = false) => dispatch(patientSearch({ isInitialSearch })),
  getAccountDetails: () => dispatch(getAccountDetails()),
  saveDefaultSearchFilters: () => dispatch(saveDefaultSearchFilters()),
  updateField: (type, value) => dispatch(updateField(type, value)),
  updateModalityFilter: (filter) => dispatch(updateModalityFilter(filter)),
  updatePracticeFilter: (filter) => dispatch(updatePracticeFilter(filter)),
  updateSelectedPracticeList: (practices) => dispatch(updateSelectedPracticeList(practices)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientSearchBar)
