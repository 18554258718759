import { takeEvery, fork, select, put, ForkEffect, SelectEffect, PutEffect } from 'redux-saga/effects'
import { acceptTCSuccess, acceptTCFail, ACCEPT_TC } from '../../actions/AcceptTC/acceptTCActions'

import { get, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { runWithRefresh } from '../RefreshAuthTokenSaga/refreshAuthTokenSaga'

const acceptTCUrl = '/imedvisage/accepttandc'

type AcceptTCGenerator = Generator<
  SelectEffect | Promise<ResponseWithStatus<void>>,
  ResponseWithStatus<void>,
  ResponseWithStatus<void> | AccessToken
>
export function* acceptTC(): AcceptTCGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken
  const ret = (yield get(
    `${process.env.REACT_APP_API_URL}${acceptTCUrl}`,
    {
      Authorization: `${jwt.type} ${jwt.token}`,
    },
    {},
  )) as ResponseWithStatus<void>
  return ret
}

type AcceptTCSagaGenerator = Generator<PutEffect | unknown, void, ResponseWithStatus<void>>

export function* acceptTCSaga(): AcceptTCSagaGenerator {
  const submitResponse = (yield runWithRefresh(acceptTC) as AcceptTCGenerator) as ResponseWithStatus<void>
  if (!submitResponse) return
  if (submitResponse.status === ResponseStatus.SUCCESS) {
    yield put(acceptTCSuccess())
    return
  } else {
    yield put(acceptTCFail())
  }
}

function* watchAcceptTCSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(ACCEPT_TC, acceptTCSaga)
}

const acceptTCSagas = [fork(watchAcceptTCSaga)]

export default acceptTCSagas
