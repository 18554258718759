import axios from 'axios'
import { sendLog } from './services/monitoring/cloudwatch'

function interceptRequestAndLogErrors(): void {
  // Add a request interceptor
  axios.interceptors.request.use(
    function (config) {
      // Do something before request is sent
      return config
    },
    function (error) {
      sendLog(JSON.stringify(error.toJSON()))
      // Do something with request error
      return Promise.reject(error)
    },
  )

  // Add a response interceptor
  axios.interceptors.response.use(
    function (response) {
      // Any status code that lies within the range of 2xx causes this function to trigger
      // Do something with response data
      return response
    },
    function (error) {
      if (axios.isCancel(error) || error?.code === 'ECONNABORTED') {
        // Don't ingest logs for cancelled or aborted requests (i.e. redux saga would cancel HTTP requests for optimisation purposes)
        return
      }
      const errorToJSON = error.toJSON()
      // Strip auth headers & creds
      delete errorToJSON.request?._header
      delete errorToJSON.config?.headers?.Authorization
      delete errorToJSON.config?.data
      sendLog(JSON.stringify(errorToJSON))
      // Any status code that falls outside the range of 2xx causes this function to trigger
      // Do something with response error
      return Promise.reject(error)
    },
  )
}

export { interceptRequestAndLogErrors }
