import { RecoverUsernameFormFieldType } from '../../../actions/recoverUsernameAction'
import { Card, Button, Col, Container, Row } from 'react-bootstrap'
import text from '../../../_constants/text'
import InternalLink from '../../../components/atoms/InternalLink'
import Separator from '../../../components/atoms/Separator'
import { connect } from 'react-redux'
import routes from '../../../router/routes'
import { resetRecover } from '../../../actions/recoverUsernameAction'

interface ForgotUsernameFormSuccessCardProps {
  form: Record<RecoverUsernameFormFieldType, string>
  resetRecover: () => void
}

const ForgotUsernameSuccessCard: React.FC<ForgotUsernameFormSuccessCardProps> = ({ form, resetRecover }) => {
  return (
    <Card as="section" className="my-3 my-lg-4" data-testid="recover-username-success">
      <Card.Body className="p-3 p-lg-4">
        <Card.Title as="h2">{text.CHECK_EMAIL}</Card.Title>
        <Row className="mt-4 mb-4">
          <p>
            {text.EMAIL_SENT} <b>{form.email}</b> {text.WITH_USERNAME}
          </p>
        </Row>
        <Row className="mt-4 mb-1">
          <p>
            <b>{text.DIDNT_RECEIVE}</b>
          </p>
        </Row>
        <Row>
          <p>{text.RECOVER_USERNAME_PROMPT}</p>
        </Row>
        <Container className="d-flex flex-column px-0 mt-4">
          <Row className="mb-4">
            <Col xs={12} md={6}>
              <Button className="m-auto w-100" onClick={resetRecover}>
                {text.RE_ENTER_DETAILS}
              </Button>
            </Col>
            <Col className="d-flex flex-row m-auto m-md-0 mt-3 mt-md-0 px-0 w-auto" xs={12} md={6}>
              <p className="anchor-text m-auto">{text.OR}</p>
              <InternalLink href={routes.home} className="m-auto px-1">
                {text.RETURN_TO_SIGNIN}
              </InternalLink>
            </Col>
          </Row>
          <Row>
            <Separator />
            <p className="pt-4">{text.NEED_SUPPORT}</p>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}

const mapStateToProps = (state) => {
  const { form } = state.recoverUsername
  return { form }
}

const mapDispatchToProps = (dispatch) => ({
  resetRecover: () => dispatch(resetRecover()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotUsernameSuccessCard)
