import moment from 'moment'
import {
  call,
  CallEffect,
  cancel,
  CancelEffect,
  fork,
  ForkEffect,
  put,
  PutEffect,
  select,
  SelectEffect,
  take,
  TakeEffect,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects'
import { ReduxRouterState, ROUTER_ON_LOCATION_CHANGED } from '@lagunovsky/redux-react-router'
import { LOCAL_DEFAULT_PATIENT_SEARCH_KEY_PREFIX } from '../../_constants/variables'
import { acceptTCNotAccepted } from '../../actions/AcceptTC/acceptTCActions'
import {
  PATIENT_SEARCH_SAVE_DEFAULT_SEARCH_FILTERS,
  PATIENT_SEARCH_SEARCH_ALL,
  PATIENT_SEARCH_SEARCH,
  patientSearch,
  PatientSearchAllActionData,
  patientSearchFail,
  patientSearchSuccess,
  patientSearchValidationErrors,
  setCurrentSearch,
  updateField,
  updateModalityFilter,
  updatePracticeFilter,
  patientSearchSetFirstLoad,
  PATIENT_SEARCH_CLEAR_ALL,
  defaultSearchFiltersSaved,
  abortSearch,
} from '../../actions/PatientSearchActions/patientSearchActions'
import { validateString } from '../../components/forms/validations/PatientSearch'

import { ResponseStatus, ResponseWithStatus } from '../../fetch'
import mapSearchParams from '../../mappers/mapSearchParams/mapSearchParams'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { initialPatientSearchState } from '../../reducers/PatientSearchReducer/PatientSearchReducer'
import text from '../../_constants/text'
import { runWithRefresh } from '../RefreshAuthTokenSaga/refreshAuthTokenSaga'
import { resetSearchRowState } from '../../actions/PatientRowSearchStateActions/PatientRowSearchStateActions'
import { BaseAction, DataAction, InitialSearch, SearchStudiesActions } from '../../actions/commons'
import { addLocalStorageValue, deleteLocalStorageValue, getLocalStorageValue } from '../../services/localStorageService'
import { Study } from '../../domain/models/commons/Study'
import { Search } from '../../reducers/commons/types'
import fetchSaga, { FetchGenerator } from '../fetch'
import urls from '../../_constants/urls'
import { Task } from 'redux-saga'
import routes from '../../router/routes'

const validateFields = ['dob', 'nameOrPatientId']

type GetPatientSearchGenerator = Generator<
  SelectEffect | Promise<ResponseWithStatus<Study[]>> | PutEffect | FetchGenerator<Study[]>,
  ResponseWithStatus<Study[]>,
  ResponseWithStatus<Study[]> | AccessToken | Search | string
>
export function* getPatientSearch(): GetPatientSearchGenerator {
  const searchState = (yield select((state) => state.patientSearch.currentSearch)) as Search
  if (searchState.searchType === text.SEARCH_REFERRED_BY_ANYONE) {
    const errors = {}
    validateFields.forEach((id: string) => {
      if (!validateString(id, true, searchState[id])) {
        errors[id] = true
      }
    })
    if (Object.keys(errors).length) {
      yield put(patientSearchValidationErrors(errors))
      return
    }
  }
  const query = mapSearchParams(searchState)

  return (yield fetchSaga<Study[]>(
    `${process.env.REACT_APP_API_URL}${urls.SEARCH_ORDERS_API}`,
    query,
  )) as ResponseWithStatus<Study[]>
}

type PatientSearchSagaGenerator = Generator<
  | CancelEffect
  | SelectEffect
  | TakeEffect
  | PutEffect<BaseAction>
  | CallEffect<void>
  | ForkEffect<
      Generator<
        Generator<unknown, ResponseWithStatus<unknown>, unknown> | PutEffect<BaseAction>,
        void,
        ResponseWithStatus<Study[]>
      >
    >,
  void,
  unknown
>
export function* patientSearchSaga(action: SearchStudiesActions): PatientSearchSagaGenerator {
  yield put(resetSearchRowState())

  // PD-1957: retrieves sticky search filters from the localStorage when patient search is run for the first time (i.e. first page render)
  const isInitialSearch = action?.data.isInitialSearch
  if (isInitialSearch) {
    yield call(loadDefaultPatientSearchFiltersFromLocalStorage)
    yield put(patientSearchSetFirstLoad(false))
  }

  const { searchType } = (yield select((state) => state.patientSearch.currentSearch)) as Search
  // If search filter is set to "Referred by anyone" and it's the first search, abort the search to avoid unnecessary API calls and render input error messages
  // (i.e. patient name and DOB are required for this search type)
  const shouldAbortSearch = searchType === text.SEARCH_REFERRED_BY_ANYONE && isInitialSearch
  if (shouldAbortSearch) {
    yield put(abortSearch())
    return
  }

  const bgTask = yield fork(function* () {
    const submitResponse = (yield runWithRefresh(getPatientSearch)) as ResponseWithStatus<Study[]>
    if (!submitResponse) return
    if (submitResponse.status === ResponseStatus.SUCCESS) {
      yield put(patientSearchSuccess(submitResponse.data))
    } else if (submitResponse.status === ResponseStatus.API_ERROR) {
      yield put(acceptTCNotAccepted())
    } else {
      yield put(patientSearchFail())
    }
  })

  // Abort the HTTP request (`getPatientSearch())`) if the user navigates to another page before it has been completed.
  yield take(ROUTER_ON_LOCATION_CHANGED)

  yield cancel(bgTask as Task)
}

/**
 * Triggers a broad search for a given patient. That is, this will return all the studies for a given patient.
 * See https://dev.azure.com/au-palo-it/I-MED%20Online/_workitems/edit/975
 */
export function* patientSearchAllSaga(
  action: DataAction<PatientSearchAllActionData>,
): Generator<PutEffect<BaseAction>, void, unknown> {
  const { dob, patientId } = action.data

  yield put(updateField('nameOrPatientId', patientId))
  yield put(updateField('dateRange', text.SEARCH_DATE_RANGE_ALL_TIME))
  yield put(updateField('dob', moment(dob, text.DATE_SEARCH_FORMAT).format(text.DATE_FORMAT)))
  yield put(updateField('searchType', text.SEARCH_REFERRED_BY_ANYONE))

  yield put(updateModalityFilter(text.ALL_MODALITIES))
  yield put(updatePracticeFilter(text.ALL_PRACTICES))

  yield put(patientSearch({ isInitialSearch: false }))
}
// PD-1957 - Sticky Search Filters: persist search filters in the localStorage
export function* saveDefaultPatientSearchFiltersSaga(): Generator<
  SelectEffect | CallEffect<void>,
  void,
  string & Search
> {
  const username = (yield select((state) => state.login.username)) as string

  // Safe-guard: this should not occur as patient search-related actions should be triggered from authenticated pages.
  if (!username) {
    console.error('[PatientSearch] Cannot save the current patient search filters - user does not exist')
    return
  }

  const { modalities, dateRange, practices, searchType } = (yield select(
    (state) => state.patientSearch.currentSearch,
  )) as Search

  const key = `${LOCAL_DEFAULT_PATIENT_SEARCH_KEY_PREFIX}${username}`
  // PD-1957: only search filters are to be saved, not search terms. As such, no PHI need to be stored.
  const defaultSearchFilters = {
    dateRange,
    modalities,
    practices,
    searchType,
  }
  yield call(addLocalStorageValue, key, JSON.stringify(defaultSearchFilters))
}

export function* loadDefaultPatientSearchFiltersFromLocalStorage(): Generator<
  SelectEffect | CallEffect<string> | PutEffect<DataAction<Search>> | PutEffect<BaseAction>,
  void,
  string & Search
> {
  const username = (yield select((state) => state.login.username)) as string

  if (!username) {
    console.error('[PatientSearch] Cannot retrieve the default patient search filters - user does not exist')
    return
  }

  const key = `${LOCAL_DEFAULT_PATIENT_SEARCH_KEY_PREFIX}${username}`
  const defaultSearchFiltersValue = yield call(getLocalStorageValue, key)

  const defaultSearchFilters = JSON.parse(defaultSearchFiltersValue) as Search
  if (Boolean(defaultSearchFilters)) {
    yield put(defaultSearchFiltersSaved(true))
  }

  const defaultSearch = {
    ...initialPatientSearchState.currentSearch,
    ...defaultSearchFilters,
  }
  yield put(setCurrentSearch(defaultSearch))
}

let prevPath = ''
function* maybeLoadDefaultSearchSaga(): Generator<
  SelectEffect | CallEffect<void> | PutEffect<DataAction<InitialSearch>>,
  void,
  boolean & ReduxRouterState
> {
  const firstLoad = (yield select((state) => state.patientSearch.firstLoad)) as boolean

  if (firstLoad) {
    return
  }

  const routerState = (yield select((state) => state.router)) as ReduxRouterState
  const { location } = routerState

  const didUserNavigateBack = routerState?.action === 'POP'
  const isResultsPage = location.pathname === routes.results
  const isPrevPathAppointmentsPage = prevPath === routes.appointments

  prevPath = location.pathname

  // If the user is navigating back to the patient search page from the appts page, we need to re-fetch the patient studies to reset the state (i.e. re-load
  // patient-related data models)
  const shouldLoadDefaultPatientSearch = (isPrevPathAppointmentsPage || !didUserNavigateBack) && isResultsPage

  if (!shouldLoadDefaultPatientSearch) {
    return
  }

  // PD-1957 - Sticky Search Filters: load search filters from the localStorage and re-run the patient search when the user navigates to the patient search page
  yield call(loadDefaultPatientSearchFiltersFromLocalStorage)
  yield put(patientSearch({ isInitialSearch: false }))
}

function* patientSearchClearAllSaga() {
  const username = (yield select((state) => state.login.username)) as string

  if (!username) {
    console.error('[PatientSearch] Cannot clear the default patient search filters - user does not exist')
    return
  }

  const key = `${LOCAL_DEFAULT_PATIENT_SEARCH_KEY_PREFIX}${username}`
  yield call(deleteLocalStorageValue, key)
}

/* PD-1957 - Sticky Search Filters: remove the persistence of search filters into query parameters in favor of localStorage
function* saveSearchParamsIntoQueryParams(): Generator<
  SelectEffect | PutEffect<UpdateLocationActions | DataAction<boolean>>,
  void,
  Search | ReduxRouterState | boolean
> {
  const searchState = (yield select((state) => state.patientSearch.currentSearch)) as Search
  const routerState = (yield select((state) => state.router)) as ReduxRouterState
  const firstLoad = (yield select((state) => state.patientSearch.firstLoad)) as boolean

  const { location } = routerState

  // Ensure current page is /results to avoid triggering a unnecessary redirect with `push`
  if (location.pathname !== resultsPageUrl) {
    return
  }

  const query = stringifyQs(searchState)

  // Don't overwrite URL if user navigates back. It avoids duplicates in the navigation history.
  if (routerState?.action === 'POP' && !firstLoad) {
    return
  }

  if (firstLoad) {
    yield put(patientSearchSetFirstLoad(false))
  }

  yield put(push(`/results?${encodeURIComponent(query)}`))
}
*/

function* watchPatientSearchSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(PATIENT_SEARCH_SEARCH, patientSearchSaga)
}

export function* watchSaveDefaultPatientSearchFiltersSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(PATIENT_SEARCH_SAVE_DEFAULT_SEARCH_FILTERS, saveDefaultPatientSearchFiltersSaga)
}

export function* watchPatientSearchAllSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(PATIENT_SEARCH_SEARCH_ALL, patientSearchAllSaga)
}

export function* watchPatientSearchClearAllSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(PATIENT_SEARCH_CLEAR_ALL, patientSearchClearAllSaga)
}

export function* watchLocationSearch(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(ROUTER_ON_LOCATION_CHANGED, maybeLoadDefaultSearchSaga)
}

/* PD-1957 - Sticky Search Filters: remove the persistence of search filters into query parameters in favor of localStorage
export function* watchSearchSuccess(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(PATIENT_SEARCH_SEARCH_SUCCESS, saveSearchParamsIntoQueryParams)
}
*/

const patientSearchSagas = [
  fork(watchPatientSearchSaga),
  fork(watchPatientSearchAllSaga),
  fork(watchSaveDefaultPatientSearchFiltersSaga),
  fork(watchPatientSearchClearAllSaga),
  fork(watchLocationSearch),
]

export default patientSearchSagas
