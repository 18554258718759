import React, { useCallback, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import SingleLineTextInputField from '../../../components/forms/fields/SingleLineTextInputField'
import DropdownSelect from '../../../components/forms/fields/DropdownSelect'
import MultiselectDropdown from '../../../components/forms/fields/MultiselectDropdown'
import { Practice } from '../../../reducers/AccountReducer/AccountReducer'
import { Form } from 'react-bootstrap'
import text from '../../../_constants/text'
import SmallButton from '../../../components/atoms/SmallButton'
import DOBInputField from '../../../components/forms/fields/DOBInputField'
import { Search } from '../../../reducers/commons/types'
import { isEmpty, isEqual } from 'lodash'
import { SearchFormFieldType } from '../../../actions/commons'

interface SearchBarProps {
  updateField: (name: SearchFormFieldType, value: string) => void
  updateModalityFilter: (modality: string) => void
  updatePracticeFilter: (practice: string) => void
  updateSelectedPracticeList: (practices: Practice[]) => void
  handleSearch: () => void
  clearAll?: () => void
  dateRangeFilters: string[]
  modalitiesFilters: string[]
  practicesFilters: Practice[]
  searchReferredTypeFilters: string[]
  paramsInvalidError: boolean
  isSearchButtonDisabled: boolean
  currentSearch: Search
  selectedPractices: string[]
  submitInProgress: boolean
}

const ENTER = 'Enter'

const SearchBar: React.FC<SearchBarProps> = ({
  updateField,
  updateModalityFilter,
  updatePracticeFilter,
  updateSelectedPracticeList,
  handleSearch,
  clearAll,
  dateRangeFilters,
  modalitiesFilters,
  searchReferredTypeFilters,
  paramsInvalidError,
  isSearchButtonDisabled,
  currentSearch,
  practicesFilters,
  selectedPractices,
  submitInProgress,
}) => {
  const showClearAllButton = Boolean(clearAll)

  const downHandler = useCallback(
    (e) => {
      if (e.key === ENTER) {
        handleSearch()
      }
    },
    [handleSearch],
  )

  useEffect(() => {
    window.addEventListener('keydown', downHandler)
    return () => {
      window.removeEventListener('keydown', downHandler)
    }
  }, [])

  useEffect(() => {
    if (!isEmpty(practicesFilters) && !isEmpty(currentSearch.practices)) {
      updateSelectedPracticeList(practicesFilters)
    }
  }, [currentSearch.practices])

  useEffect(() => {
    if (isEqual(currentSearch.practices.length, practicesFilters.length) && practicesFilters.length !== 1) {
      updatePracticeFilter(text.ALL_PRACTICES)
    }
  }, [currentSearch.practices])

  useEffect(() => {
    if (isEqual(currentSearch.modalities.length, text.SEARCH_MODALITY_FILTERS.length - 1)) {
      updateModalityFilter(text.ALL_MODALITIES)
    }
  }, [currentSearch.modalities])

  return (
    <>
      <Row data-testid="SearchBarRow" className="d-flex justify-content-start">
        <Col xs={12} md={8} lg={6} className="d-flex pe-md-2">
          <SingleLineTextInputField
            type="text"
            placeholder={text.PLACEHOLDER_FULL_NAME_OR_ID}
            inputName="nameOrPatientId"
            onChangeCb={updateField}
            value={currentSearch.nameOrPatientId}
            large
            iconLeft="person"
            disabled={submitInProgress}
            error={paramsInvalidError}
          />
        </Col>
        <Col xs={12} md={4} lg={3} className="d-flex ps-md-2 pe-lg-2">
          <DOBInputField
            placeholder={text.PLACEHOLDER_DOB}
            onChangeCb={updateField}
            value={currentSearch.dob}
            large
            iconLeft="today"
            disabled={submitInProgress}
            error={paramsInvalidError}
            errorMessage={`${text.PATIENT_SEARCH_ERROR_MESSAGE} ${text.DATE_FORMAT}`}
          />
        </Col>
        <Col xs={12} lg={3} className="ps-3 d-none d-lg-flex">
          <Button
            data-testid="mobile-search"
            className="w-100"
            onClick={handleSearch}
            disabled={isSearchButtonDisabled}
          >
            {text.SEARCH}
          </Button>
        </Col>
      </Row>
      <Row data-testid="FiltersRow" className="px-0 w-100 d-flex justify-content-start">
        <Col xs={12} lg={2} className="pe-lg-2">
          <DropdownSelect
            inputName="dateRange"
            errorMessage="This field is required"
            value={currentSearch.dateRange}
            onChangeCb={updateField}
            required={false}
            error={false}
            disabled={submitInProgress}
            options={dateRangeFilters}
          />
        </Col>
        <Col xs={12} lg={2} className="pe-lg-2 ps-lg-2">
          <MultiselectDropdown label="" value={currentSearch.modalities} disabled={submitInProgress}>
            {modalitiesFilters.map((modality) => (
              <Form.Check
                className="form-check-dropdown"
                name="modalities"
                label={modality}
                key={modality}
                checked={
                  currentSearch.modalities.includes(modality) || currentSearch.modalities.includes(text.ALL_MODALITIES)
                }
                id={modality}
                onChange={() => updateModalityFilter(modality)}
                tabIndex={0}
              />
            ))}
          </MultiselectDropdown>
        </Col>
        <Col xs={12} lg={3} className="ps-lg-2 pe-lg-2">
          <DropdownSelect
            label=""
            inputName="searchType"
            errorMessage="This field is required"
            value={currentSearch.searchType}
            onChangeCb={updateField}
            required={false}
            error={false}
            disabled={submitInProgress}
            options={searchReferredTypeFilters}
          />
        </Col>
        <Col xs={12} lg={3} className="ps-lg-2 pe-lg-2">
          <MultiselectDropdown label="" value={selectedPractices} disabled={submitInProgress}>
            <>
              <Form.Check
                className="form-check-dropdown"
                label={text.ALL_PRACTICES}
                checked={currentSearch.practices.includes(text.ALL_PRACTICES)}
                onChange={() => updatePracticeFilter(text.ALL_PRACTICES)}
                id={text.ALL_PRACTICES}
              />
              {practicesFilters.map(
                (practice) =>
                  practice.practiceName && (
                    <Form.Check
                      className="form-check-dropdown"
                      label={practice.practiceName}
                      id={practice.uri}
                      key={practice.uri}
                      checked={
                        currentSearch.practices.includes(practice.uri) ||
                        currentSearch.practices.includes(text.ALL_PRACTICES)
                      }
                      onChange={() => updatePracticeFilter(practice.uri)}
                    />
                  ),
              )}
            </>
          </MultiselectDropdown>
        </Col>
        {showClearAllButton && (
          <Col xs={12} lg={2} className="px-lg-2 mb-4">
            <SmallButton className="my-1" disabled={submitInProgress} onClick={clearAll}>
              {text.CLEAR_ALL}
            </SmallButton>
          </Col>
        )}
      </Row>
      <Row className="d-lg-none">
        <Col xs={12}>
          <Button data-testid="search" className="w-100" onClick={handleSearch} disabled={isSearchButtonDisabled}>
            {text.SEARCH}
          </Button>
        </Col>
      </Row>
    </>
  )
}

export default SearchBar
