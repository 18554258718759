import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { RegistrationFormFieldType } from '../../../../actions/registerAccountActions.types'
import PasswordIndicator from '../../../../components/forms/validations/PasswordIndicator'
import SingleLineTextInputField from '../../../../components/forms/fields/SingleLineTextInputField/SingleLineTextInputField'
import text from '../../../../_constants/text'
import Icon from '../../../../components/atoms/Icon'
import icons from '../../../../_constants/icons'
import { RegistrationFormType } from '../../../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import RadioButtonGroup from '../../../../components/forms/fields/RadioButtonGroup/RadioButtonGroup'

interface GeneralInfoProps {
  onValueChange: (name: string, value: string) => void
  registrationForm: RegistrationFormType
  registrationFormErrors: Partial<Record<RegistrationFormFieldType, boolean>>
}

const GeneralInfo: React.FC<GeneralInfoProps> = ({ onValueChange, registrationForm, registrationFormErrors }) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const onPasswordToggle = () => {
    setPasswordVisible(!passwordVisible)
  }

  const userHasAccount = registrationForm.haveCurrentAccount === 'Yes, I have an existing account'

  return (
    <>
      <Row>
        <Col xs={12}>
          <RadioButtonGroup
            label="Do you have an I-MED Online account?"
            inputName="haveCurrentAccount"
            errorMessage={text.FIELD_REQUIRED}
            value={registrationForm.haveCurrentAccount}
            onChangeCb={onValueChange}
            required={false}
            error={registrationFormErrors.haveCurrentAccount}
            options={['Yes, I have an existing account', "No, I don't have an account"]}
          />
        </Col>
      </Row>
      {registrationForm.haveCurrentAccount !== '' && (
        <>
          <Row>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.FIRST_NAME}
                inputName="firstName"
                required={false}
                value={registrationForm.firstName}
                errorMessage={text.FIELD_REQUIRED}
                instructionMessage="As per AHPRA/Medicare registration"
                onChangeCb={onValueChange}
                error={registrationFormErrors.firstName}
                placeholder="e.g. Katie"
              />
            </Col>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.LAST_NAME}
                inputName="lastName"
                required={false}
                value={registrationForm.lastName}
                errorMessage={text.FIELD_REQUIRED}
                instructionMessage="As per AHPRA/Medicare registration"
                onChangeCb={onValueChange}
                error={registrationFormErrors.lastName}
                placeholder="e.g. Bishop"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.AHPRA}
                inputName="ahpraNumber"
                required={false}
                value={registrationForm.ahpraNumber}
                errorMessage={text.REGISTER_ERROR_AHPRA}
                instructionMessage="Don't include spaces"
                onChangeCb={onValueChange}
                error={registrationFormErrors.ahpraNumber}
                placeholder="e.g. MED0000123456"
              />
            </Col>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.EMAIL}
                inputName="email"
                required={false}
                value={registrationForm.email}
                instructionMessage="Cannot be generic or shared"
                onChangeCb={onValueChange}
                error={registrationFormErrors.email}
                errorMessage={text.REGISTER_ERROR_EMAIL}
                placeholder="e.g. k.bishop@i-med.com"
              />
            </Col>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.MOBILE_NUMBER}
                inputName="mobile"
                required={false}
                value={registrationForm.mobile}
                instructionMessage="For contact and two-factor password reset"
                onChangeCb={onValueChange}
                errorMessage="Must be a valid mobile number"
                error={registrationFormErrors.mobile}
                placeholder="e.g. 0400112233"
              />
            </Col>
          </Row>
          <Row>
            {userHasAccount && (
              <Col xs={12} lg={6}>
                <SingleLineTextInputField
                  label={text.REGISTER_LABEL_USERNAME}
                  inputName="userid"
                  required={userHasAccount}
                  value={registrationForm.userid}
                  onChangeCb={onValueChange}
                  errorMessage={text.REGISTER_ERROR_USERNAME}
                  error={registrationFormErrors.userid}
                />
              </Col>
            )}
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.PASSWORD}
                type={passwordVisible ? 'text' : 'password'}
                inputName="password"
                required={false}
                value={registrationForm.password}
                onChangeCb={onValueChange}
                error={registrationFormErrors.password}
                errorMessage={text.PASSWORD_INVALID_MSG}
              >
                <Icon
                  name={passwordVisible ? icons.HIDE : icons.SHOW}
                  className="password-toggle"
                  onClick={onPasswordToggle}
                />
              </SingleLineTextInputField>
              <PasswordIndicator password={registrationForm.password} />
            </Col>
            {!userHasAccount && <Col></Col>}
          </Row>
        </>
      )}
    </>
  )
}

export default GeneralInfo
