import { BaseAction, DataAction } from '../../actions/commons'
import {
  PATIENT_REPORT_CONSENT_EXPIRED,
  PATIENT_REPORT_GET_REPORT,
  PATIENT_REPORT_GET_REPORT_FAIL,
  PATIENT_REPORT_GET_REPORT_SUCCESS,
  PATIENT_REPORT_RESET,
  ReportData,
} from '../../actions/PatientReportActions/patientReportActions'
import { SubmitControls, submitStates } from '../commons/submitControls'
import { LOGOUT_SUCCESS } from '../../actions/loginAction'
import { Status } from '../../domain/models/commons/Report'
import { Attachment } from '../../domain/models/commons/Attachment'
import { Dicom } from '../../domain/models/commons/Dicom'

type PatientReportState = {
  numImageSets: number
  report: string
  reportUri: string
  reportGetControls: SubmitControls
  reportStatus: Status
  images: Dicom[]
  attachments: Attachment[]
  imagesDataGetControls: SubmitControls
  expired: boolean
}

export const initialPatientReportState: PatientReportState = {
  numImageSets: null,
  report: '',
  reportUri: null,
  reportStatus: null,
  images: [],
  attachments: [],
  reportGetControls: { ...submitStates.default },
  imagesDataGetControls: { ...submitStates.default },
  expired: false,
}

const patientReportReducer = (state = initialPatientReportState, action: BaseAction): PatientReportState => {
  switch (action.type) {
    case PATIENT_REPORT_GET_REPORT: {
      return { ...state, reportGetControls: { ...submitStates.inProgress } }
    }
    case PATIENT_REPORT_GET_REPORT_SUCCESS: {
      const a = action as DataAction<ReportData>
      return {
        ...state,
        reportGetControls: { ...submitStates.success },
        report: a.data.report,
        reportUri: a.data.reportUri,
        reportStatus: a.data.status,
        images: a.data.dicom,
        attachments: a.data.attachments,
        numImageSets: a.data.procedures.length,
      }
    }
    case PATIENT_REPORT_GET_REPORT_FAIL:
      return {
        ...state,
        reportGetControls: { ...submitStates.fail },
      }
    case PATIENT_REPORT_CONSENT_EXPIRED:
      return {
        ...state,
        expired: true,
      }
    case PATIENT_REPORT_RESET:
    case LOGOUT_SUCCESS:
      return {
        ...initialPatientReportState,
      }
    default:
      return state
  }
}

export default patientReportReducer
