import React, { PropsWithChildren } from 'react'
import { ContentLinkModel } from '../../../domain/models/commons/Link'
import { LinkTypes } from '../../../domain/constants'
import InternalLink from '../InternalLink'
import ExternalLink from '../ExternalLink'

export interface ContentLinkProps extends ContentLinkModel {
  className?: string
}
const ContentLink: React.FC<PropsWithChildren<ContentLinkProps>> = (props) => {
  if (props.type === LinkTypes.EXTERNAL_LINK) {
    return <ExternalLink {...props} href={props.value.url} />
  }
  return <InternalLink {...props} href={props.value.url} />
}

export default ContentLink
