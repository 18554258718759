import text from '../../_constants/text'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import React, { FC } from 'react'
import routes, { pageTitles } from '../../router/routes'
import InternalLink from '../../components/atoms/InternalLink'
import Layout from '../../components/wrappers/Layout'
import { Helmet } from 'react-helmet'

const NotFoundPage: FC = () => {
  return (
    <Layout hideFooter>
      <Helmet>
        <title>{pageTitles.notFound}</title>
      </Helmet>
      <div className="d-flex align-items-center content-background content-unauthenticated-background-min-height py-5">
        <Container className="max-grid-width p-lg-0 " fluid>
          <Row className="p-0 justify-content-center">
            <Col xs={12} lg={6}>
              <Card as="section" className="my-3 my-lg-4">
                <Card.Body className="p-3 p-lg-4">
                  <Card.Title className="mb-3" data-testid="title" as="h2">
                    {text.NOTFOUND_TITLE}
                  </Card.Title>
                  <Container className="d-flex flex-column p-0">
                    <Row className="mb-3">
                      <Col xs={12}>{text.NOTFOUND_MESSAGE}</Col>
                    </Row>
                    <Row className="mb-0">
                      <Col xs={12}>
                        <InternalLink href={routes.home}>
                          {/* @TODO this path should be changed once the refresh user session is implemented */}
                          <Button data-testid="backButton" className="mb-0">
                            {text.BACK_TO_PATIENT_SEARCH}
                          </Button>
                        </InternalLink>
                      </Col>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

export default NotFoundPage
