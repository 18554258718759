import React, { FunctionComponent, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Col, Dropdown as BootstrapDropdown, Row } from 'react-bootstrap'
import icons from '../../../../_constants/icons'
import Icon from '../../../atoms/Icon'
import InputLabel from '../../../atoms/InputLabel'

type MultiSelectProps = {
  label?: string
  error?: boolean
  errorMessage?: string
  required?: boolean
  value: string[]
  disabled?: boolean
} & PropsWithChildren

const MultiSelect: FunctionComponent<MultiSelectProps> = ({
  required = false,
  value,
  error,
  errorMessage,
  children,
  label = null,
  disabled = false,
}) => {
  const [show, setShow] = useState(false)
  const ref = useRef(null)

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  })

  function getPlaceholder(values) {
    if (!values || values.length === 1) {
      return values[0]
    }
    return `${values[0]} +${values.length - 1}`
  }

  return (
    <Col className="p-0 mt-0 dropdown-select" data-testid="DropdownSelect">
      {label && (
        <Row className="m-0">
          <InputLabel label={`${label} ${required ? '*' : ''}`} />
        </Row>
      )}
      <Row className={`m-0 input ${error ? 'validation-error' : ''}`}>
        <Col className="p-0 justify-content-center">
          <BootstrapDropdown show={show} ref={ref} tabIndex={0}>
            <BootstrapDropdown.Toggle
              disabled={disabled}
              className="multiselect-dropdown-toggle"
              onClick={() => setShow(!show)}
              data-testid="MultiselectDropdown-Toggle"
            >
              <div className="multiselect-dropdown-wrapper">{getPlaceholder(value)}</div>

              <Icon name={`${icons.ARROW_DOWN}${disabled ? '-disabled' : ''}`} className="keyboard-down-arrow" />
            </BootstrapDropdown.Toggle>
            <BootstrapDropdown.Menu className="multiselect-dropdown-menu" data-testid="MultiselectDropdown-Menu">
              {children}
            </BootstrapDropdown.Menu>
          </BootstrapDropdown>
        </Col>
      </Row>
      {error && errorMessage && (
        <Row className="m-0 pl-3 pt-2">
          <p className="error">{errorMessage}</p>
        </Row>
      )}
    </Col>
  )
}

export default MultiSelect
