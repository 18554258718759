import React from 'react'
import Icon from '../Icon'

interface props {
  onClick?: () => void
  iconLabel?: string
  icon?: string
  useSecondaryTheme: boolean
}
const MenuIcon: React.FC<props> = ({ useSecondaryTheme, onClick = () => null, iconLabel, icon }) => {
  if (!icon) {
    return <div className="align-self-center d-lg-none py-2 placeholder-icon" />
  }
  return (
    <div
      className="align-self-center mb-0 d-flex flex-column d-lg-none py-2 position-relative"
      onClick={() => onClick()}
    >
      <Icon name={icon} className="menu-icon" />
      <span className={`menu-icon-label ${useSecondaryTheme ? 'active' : ''}`}>{iconLabel}</span>
    </div>
  )
}

export default MenuIcon
