import { BaseAction } from '../../actions/commons'
import {
  REFERRAL_CLEAR,
  REFERRAL_NAVIGATE_BACK,
  REFERRAL_PREFILL,
  REFERRAL_SUBMIT_FAIL,
  REFERRAL_SUBMIT_SUCCESS,
  REFERRAL_UPDATE_FIELD,
  REFERRAL_VALIDATE_SECTION_FAIL,
  REFERRAL_VALIDATE_SECTION_SUCCESS,
  REFERRAL_ADD_PRACTICE,
  REFERRAL_REMOVE_PRACTICE,
  REFERRAL_UPDATE_PRACTICE_FIELD,
  REFERRAL_VALIDATE_PRACTICES_FAIL,
} from '../../actions/ReferralActions/referralActions'
import {
  ReferralDataAction,
  ReferralField,
  ReferralPracticeDataAction,
  ReferralPracticesErrorsAction,
  ReferralSectionAction,
  ReferralSectionErrorsAction,
  ReferralSectionType,
  RemovePracticeAction,
} from '../../actions/ReferralActions/referralActions.types'
import { submitStates } from '../commons/submitControls'
import { SubmitControls } from '../RegisterAccountReducer/RegisterAccountReducer.types'
import { PracticeDetailsErrorsType, PracticeDetailsType, Referral } from './ReferralReducer.types'

export type ReferralState = {
  referral: Referral
  errors: Partial<Record<ReferralField, boolean>>
  practicesDetails: PracticeDetailsType[]
  practicesDetailsErrors: PracticeDetailsErrorsType[]
  currentSection: ReferralSectionType
  submit: SubmitControls
}

export enum EReferralStates {
  PATIENT_DETAILS = 'patientDetails',
  EXAMINATION_AND_CLINICAL_DETAILS = 'examinationAndClinicalDetails',
  REFERRING_PRACTITIONER = 'referringPractitioner',
  CONFIRM_AND_SEND = 'confirmAndSend',
}

export const initialReferralState: ReferralState = {
  referral: {
    patientName: '',
    patientDob: '',
    patientGender: 'Select',
    patientPhone: '',
    patientEmail: '',
    patientStreet: '',
    patientSuburb: '',
    patientPostcode: '',
    patientState: 'Select',
    examDetails: '',
    clinicalDetails: '',
    ivContrastDetails: '',
    doctorName: '',
    doctorEmail: '',
    doctorStreet: '',
    doctorPracticeName: '',
    doctorPracticeEmail: '',
    doctorPhone: '',
    doctorPostcode: '',
    doctorState: '',
    doctorSuburb: '',
    doctorProviderNumber: '',
    ccDoctor: 'No',
    ccDoctorName: '',
    signatureName: '',
    signatureDate: '',
    signatureEntitled: false,
    copyToMe: false,
    copyToPractice: false,
    urgentResult: false,
    notifyPatient: true,
  },
  errors: {
    patientName: false,
    patientDob: false,
    patientGender: false,
    patientPhone: false,
    patientEmail: false,
    patientStreet: false,
    patientSuburb: false,
    patientPostcode: false,
    patientState: false,
    examDetails: false,
    clinicalDetails: false,
    ivContrastDetails: false,
    doctorName: false,
    doctorEmail: false,
    doctorStreet: false,
    doctorPracticeName: false,
    doctorPracticeEmail: false,
    doctorPhone: false,
    doctorPostcode: false,
    doctorState: false,
    doctorSuburb: false,
    referrerPracticeType: false,
    referrerPracticeFax: false,
    doctorProviderNumber: false,
    ccDoctor: false,
    signatureName: false,
    signatureDate: false,
    signatureEntitled: false,
    copyToMe: false,
    copyToPractice: false,
    urgentResult: false,
    notifyPatient: false,
  },
  practicesDetails: [{ doctorName: '', practiceName: '' } as PracticeDetailsType],
  practicesDetailsErrors: [{ doctorName: false, practiceName: false } as PracticeDetailsErrorsType],
  currentSection: EReferralStates.PATIENT_DETAILS,
  submit: {
    inProgress: false,
    success: false,
    fail: false,
  },
}

export const referralSectionsOrdered: ReferralSectionType[] = [
  EReferralStates.PATIENT_DETAILS,
  EReferralStates.EXAMINATION_AND_CLINICAL_DETAILS,
  EReferralStates.REFERRING_PRACTITIONER,
  EReferralStates.CONFIRM_AND_SEND,
]

export const requiredReferralFormFields = {
  patientDetails: (): string[] => [
    'patientName',
    'patientDob',
    'patientPhone',
    'patientStreet',
    'patientSuburb',
    'patientPostcode',
    'patientState',
  ],
  examinationAndClinicalDetails: (): string[] => ['examDetails', 'clinicalDetails'],
  referringPractitioner: (isUserSoloPractitioner: boolean): string[] => [
    'doctorName',
    ...(isUserSoloPractitioner ? [] : ['doctorPracticeName']),
    'doctorProviderNumber',
    'doctorPhone',
    'doctorStreet',
    'doctorPostcode',
    'doctorSuburb',
    'doctorState',
    'ccDoctor',
  ],
  practiceDetails: (): string[] => ['doctorName', 'practiceName'],
  confirmAndSend: (): string[] => ['signatureName', 'signatureDate', 'signatureEntitled'],
}

const referralReducer = (state: ReferralState = initialReferralState, action: BaseAction): ReferralState => {
  switch (action.type) {
    case REFERRAL_PREFILL:
      const a = action as ReferralDataAction
      return {
        ...state,
        referral: {
          ...state.referral,
          ...a.data,
        },
      }
    case REFERRAL_UPDATE_FIELD: {
      const a = action as ReferralDataAction
      const updateKey = Object.keys(a.data)
      const errors = { ...state.errors }
      errors[updateKey[0]] = false
      return {
        ...state,
        referral: {
          ...state.referral,
          ...a.data,
        },
        errors: errors,
        submit: { ...submitStates.default },
      }
    }
    case REFERRAL_UPDATE_PRACTICE_FIELD: {
      const a = action as ReferralPracticeDataAction
      const practiceToUpdate = state.practicesDetails[a.data.index]
      const updateKey = Object.keys(a.data.practiceDetails)
      const updatedPractice = { ...(practiceToUpdate as PracticeDetailsType), ...a.data.practiceDetails }
      const updatedPractices = [...state.practicesDetails]
      const practicesErrors = [...state.practicesDetailsErrors] as PracticeDetailsErrorsType[]
      delete practicesErrors[a.data.index][updateKey[0]]
      updatedPractices[a.data.index] = updatedPractice
      return {
        ...state,
        practicesDetails: updatedPractices,
        practicesDetailsErrors: practicesErrors,
        submit: { ...submitStates.default },
      }
    }
    case REFERRAL_ADD_PRACTICE:
      return {
        ...state,
        practicesDetails: [...state.practicesDetails, {} as PracticeDetailsType],
        practicesDetailsErrors: [...state.practicesDetailsErrors, {} as PracticeDetailsErrorsType],
        submit: { ...submitStates.default },
      }
    case REFERRAL_REMOVE_PRACTICE: {
      const a = action as RemovePracticeAction
      const updatedPractices = [...(state.practicesDetails as PracticeDetailsType[])]
      updatedPractices.splice(a.data.index, 1)
      return {
        ...state,
        practicesDetails: [...updatedPractices],
        submit: { ...submitStates.default },
      }
    }
    case REFERRAL_NAVIGATE_BACK: {
      const navigateAction = action as ReferralSectionAction
      return {
        ...state,
        currentSection: navigateAction.data.section,
      }
    }
    case REFERRAL_VALIDATE_SECTION_SUCCESS: {
      const navigateAction = action as ReferralSectionAction
      return {
        ...state,
        currentSection: navigateAction.data.section,
      }
    }
    case REFERRAL_VALIDATE_SECTION_FAIL: {
      const errorsAction = action as ReferralSectionErrorsAction
      return {
        ...state,
        errors: { ...errorsAction.data.errors },
      }
    }
    case REFERRAL_VALIDATE_PRACTICES_FAIL: {
      const errorsAction = action as ReferralPracticesErrorsAction
      return {
        ...state,
        practicesDetailsErrors: [...(errorsAction.data.practicesDetailErrors as PracticeDetailsErrorsType[])],
      }
    }
    case REFERRAL_SUBMIT_SUCCESS:
      return { ...state, submit: { inProgress: false, success: true, fail: false } }
    case REFERRAL_SUBMIT_FAIL:
      return { ...state, submit: { inProgress: false, fail: true, success: false } }
    case REFERRAL_CLEAR:
      return { ...initialReferralState }
    default:
      return state
  }
}

export default referralReducer
