import text from '../../../_constants/text'
import AlertBox from '../../../components/molecules/AlertBox'
import SingleLineTextInputField from '../../../components/forms/fields/SingleLineTextInputField/SingleLineTextInputField'
import { Button, Col, Row } from 'react-bootstrap'
import { FC, useEffect, useState } from 'react'

import { connect } from 'react-redux'
import RecoverFormsWrapper from '../../../components/molecules/RecoverFormsWrapper'
import Icon from '../../../components/atoms/Icon'
import icons from '../../../_constants/icons'
import PasswordIndicator from '../../../components/forms/validations/PasswordIndicator'
import { RootState } from '../../../reducers/store'
import {
  ResetPasswordFieldType,
  resetPasswordSubmit,
  updateField,
} from '../../../actions/ResetPasswordActions/resetPasswordActions'
import { useLocation } from 'react-router'

type ResetPasswordFormProps = {
  form: Record<ResetPasswordFieldType, string>
  formErrors: Partial<Record<ResetPasswordFieldType, boolean>>
  updateField: (type: ResetPasswordFieldType, value: string) => void
  submitForm: () => void
  submitError: boolean
}

const ResetPasswordForm: FC<ResetPasswordFormProps> = ({ form, formErrors, updateField, submitForm, submitError }) => {
  const location = useLocation()
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search)
    updateField('secret', searchParams.get('secret'))
  }, [location])
  const [passwordVisible, setPasswordVisible] = useState(false)
  return (
    <RecoverFormsWrapper title={text.CODE_REQUIRED}>
      <p className="mb-4">{text.CODE_REQUIRED_PROMPT}</p>
      {submitError && <AlertBox type="error" message={text.RECOVER_USER_PASSWORD_FAILURE} />}
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submitForm()
        }}
        className="mt-4"
      >
        <Row className="pb-4">
          <Col>
            <SingleLineTextInputField
              label={text.VERIFICATION_CODE}
              inputName="code"
              required={false}
              value={form.code}
              onChangeCb={updateField}
              error={formErrors.code}
              errorMessage={text.CODE_INVALID_MSG}
            />
            <SingleLineTextInputField
              label={text.PASSWORD}
              type={passwordVisible ? 'text' : 'password'}
              inputName="password"
              required={false}
              value={form.password}
              onChangeCb={updateField}
              error={formErrors.password}
              errorMessage={text.PASSWORD_INVALID_MSG}
            >
              <Icon
                name={passwordVisible ? icons.HIDE : icons.SHOW}
                className="password-toggle"
                onClick={() => setPasswordVisible(!passwordVisible)}
              />
            </SingleLineTextInputField>
            <PasswordIndicator password={form.password} />
          </Col>
        </Row>
        <Row className="pb-4">
          <Col xs={12} lg={6}>
            <Button data-testid="SubmitButton" className="w-100 my-auto" variant="primary" type="submit">
              {text.RECOVER_USER_PASSWORD_BUTTON}
            </Button>
          </Col>
        </Row>
      </form>
    </RecoverFormsWrapper>
  )
}

const mapStateToProps = (state: RootState) => {
  const { form, formErrors, submit } = state.resetPassword
  return { form, formErrors, submitError: submit.fail }
}

const mapDispatchToProps = (dispatch) => ({
  updateField: (type, value) => dispatch(updateField(type, value)),
  submitForm: () => dispatch(resetPasswordSubmit()),
})
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
