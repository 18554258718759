import React, { FC } from 'react'
import { Card } from 'react-bootstrap'
import CallToAction from '../../../components/molecules/CallToAction'
import { CtaTypes } from '../../../domain/constants'
import routes from '../../../router/routes'
import icons from '../../../_constants/icons'
import text from '../../../_constants/text'

const PatientReferralCard: FC = () => {
  return (
    <Card data-testid="PatientReferralCard" as="section" className="mb-4">
      <Card.Body>
        <Card.Title as="h2" className="mb-4">
          {text.SEND_REFERRAL}
        </Card.Title>
        <p>{text.REFERRAL_DESCRIPTION}</p>
        <CallToAction
          action={{
            id: 'e-referral-cta',
            value: {
              link: routes.referral,
              icon: icons.MAIL,
              buttonText: text.SEND_REFERRAL,
            },
            type: CtaTypes.INTERNAL_LINK,
          }}
          isDownloadable={true}
        />
      </Card.Body>
    </Card>
  )
}

export default PatientReferralCard
