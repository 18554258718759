import { BaseAction, UpdateFieldAction } from '../commons'

export const FORGOT_PASSWORD_SUBMIT = 'FORGOT_PASSWORD/SUBMIT'
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD/SUCCESS'
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD/FAIL'
export const FORGOT_PASSWORD_UPDATE_DETAILS = 'FORGOT_PASSWORD/UPDATE_DETAILS'
export const FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD/RESET'
export const FORGOT_PASSWORD_VALIDATION_FAIL = 'FORGOT_PASSWORD/VALIDATION_FAIL'

export type ForgotPasswordFormFieldType = 'email'

export interface RecoverUsernameSuccessAction extends BaseAction {
  result: string
}

export const resetRecover = (): BaseAction => ({ type: FORGOT_PASSWORD_RESET })

export const updateField = (
  type: ForgotPasswordFormFieldType,
  value: string,
): UpdateFieldAction<ForgotPasswordFormFieldType> => ({
  type: FORGOT_PASSWORD_UPDATE_DETAILS,
  data: {
    [type]: value,
  },
})

export const forgotPasswordSubmit = (): BaseAction => ({ type: FORGOT_PASSWORD_SUBMIT })
export const forgotPasswordSuccess = (): BaseAction => ({
  type: FORGOT_PASSWORD_SUCCESS,
})
export const forgotPasswordFail = (): BaseAction => ({ type: FORGOT_PASSWORD_FAIL })
export const forgotPasswordValidationFail = (): BaseAction => ({ type: FORGOT_PASSWORD_VALIDATION_FAIL })
