import moment from 'moment'
import React, { FormEvent, KeyboardEvent, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import text from '../../../../_constants/text'
import formatDateInputString, { KeyPressValues, noValueString } from '../../../../utils/formatDateInputString'
import Icon from '../../../atoms/Icon'
import InputLabel from '../../../atoms/InputLabel'

type OnChangeCb = (name: string, value: string | undefined) => void

type CaretPosition = {
  currentPosition: number
  selectionStart: number
  selectionEnd: number
  direction: number
}

type Props = {
  className?: string
  label?: string
  required?: boolean
  errorMessage?: string
  instructionMessage?: string
  placeholder?: string
  error?: boolean
  onChangeCb: OnChangeCb
  value: string | undefined
  large?: boolean
  iconLeft?: string
  disabled?: boolean
  inputName?: string
}

const DOBInputField = ({
  errorMessage,
  instructionMessage,
  placeholder,
  error,
  onChangeCb,
  value = '',
  label = '',
  className = '',
  large = false,
  required = false,
  iconLeft = '',
  disabled = false,
  inputName = 'dob',
}: PropsWithChildren<Props>): JSX.Element => {
  const [caretPosition, _setCaretPosition] = useState<CaretPosition>({
    currentPosition: 0,
    selectionStart: 0,
    selectionEnd: 0,
    direction: 1,
  })
  const inputRef = useRef(null)

  const setCaretPosition = (caretPosition: CaretPosition, newValue: string) => {
    const { currentPosition, direction, selectionStart, selectionEnd } = caretPosition
    let { length } = newValue

    if (value.charAt(currentPosition) === '/') {
      length++
      if (selectionStart == currentPosition) {
        caretPosition.selectionStart += direction
      }
      if (selectionEnd == currentPosition) {
        caretPosition.selectionEnd += direction
      }
      caretPosition.currentPosition += direction
    }

    const withinEndBound = currentPosition <= length + 2
    if (currentPosition >= 0 && withinEndBound) {
      _setCaretPosition(caretPosition)
    }
  }

  useEffect(() => {
    let timeout
    if (!!value) {
      const { selectionStart, selectionEnd } = caretPosition
      // Input value update might be triggered after input caret position is updated, causing the caret to be placed at the end of the input again
      // fix/hack: run `setSelectionRange` asynchronously so caret position is set after input value is updated
      timeout = window.setTimeout(() => {
        inputRef.current?.setSelectionRange(selectionStart, selectionEnd)
      }, 0)
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout)
      }
    }
  }, [caretPosition, value])

  // TODO(TASK-944): Write tests to ensure cursor gets positioned correctly.
  /**
   * For compatibility with mobile, text entry is handled by onInput.
   * onKeyDown handles:
   *  - backspace (left of cursor, deleting selection)
   *  - arrow left
   *  - paste (into cursor, replacing selection)
   *
   * @param e
   */
  const onKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    let vx
    let isDeleting
    let { selectionStart, selectionEnd } = inputRef.current
    let { currentPosition } = caretPosition
    if (currentPosition !== selectionStart && currentPosition !== selectionEnd) {
      currentPosition = selectionStart
    }

    switch (e.key) {
      case KeyPressValues.Backspace:
        isDeleting = true
        const hasSelection = selectionStart !== selectionEnd
        if (currentPosition == 0 && !hasSelection) {
          return
        }
        if (hasSelection) {
          vx = selectionStart - currentPosition
        } else if (value[selectionStart - 1] === '/') {
          vx = hasSelection ? 0 : -2
        } else {
          vx = -1
        }
        break
      case KeyPressValues.ArrowLeft:
        vx = -1
        break
      case KeyPressValues.ArrowRight:
        vx = 1
        break
      case KeyPressValues.Pasted:
        break
      default:
        vx = 0
        // handle 0-9, 'Unidentified' (mobile input) and other keys to be ignored
        if (e.key !== KeyPressValues.Unidentified) {
          const isKeyHandleable = Boolean(KeyPressValues[e.key])
          if (selectionStart === selectionEnd || !isKeyHandleable) {
            return
          }
        }
        if (selectionStart != selectionEnd) {
          vx = 1
          // replace selection
          isDeleting = true
        }
        e.preventDefault()
    }

    if (isDeleting) {
      currentPosition += vx
    } else if (!e.shiftKey) {
      currentPosition += vx
      selectionStart = selectionEnd = currentPosition
    } else {
      if (currentPosition === selectionStart) {
        if (currentPosition === selectionEnd && vx > 0) {
          selectionEnd++
        } else {
          selectionStart += vx
        }
      } else if (currentPosition === selectionEnd) {
        selectionEnd += vx
      }
      currentPosition += vx
    }

    if (e.key === KeyPressValues.ArrowLeft || e.key === KeyPressValues.ArrowRight) {
      e.preventDefault()
    } else {
      // numeric, backspace or paste -> edit input string
      const formattedDate = formatDateInputString(value, e.key, selectionStart, selectionEnd)
      onChangeCb(inputName, formattedDate)
      if (isDeleting) {
        selectionEnd = selectionStart = currentPosition
      }
    }

    setCaretPosition(
      {
        currentPosition,
        selectionStart,
        selectionEnd,
        direction: vx,
      },
      value,
    )
  }

  const onInput = (event: FormEvent<HTMLInputElement>): void => {
    const key = (event.nativeEvent as InputEvent).data
    // key can have zero length if type == `deleteContentBackward`
    // e.nativeEvent.data is null when pasting in but not in Firefox.
    // Ensure key.length is 1 to ensure we deal with single input keys only.
    if (key !== null && key.length === 1) {
      let { selectionStart, selectionEnd } = caretPosition || event.currentTarget

      const formattedDate = formatDateInputString(value, key, selectionStart, selectionEnd)

      selectionStart++
      if (formattedDate[selectionStart] === '/') {
        selectionStart++
      }
      selectionEnd = selectionStart

      setCaretPosition(
        {
          currentPosition: selectionStart,
          selectionStart,
          selectionEnd,
          direction: 1,
        },
        formattedDate,
      )

      onChangeCb(inputName, formattedDate)
    }
  }

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    let pastedText = event.clipboardData.getData('text')

    pastedText = pastedText.trim()

    // BUG-1342: copy-pasting doesn’t keep the formatting - dd/mm/yyyy
    if (/^\d?\d\/\d?\d\/\d{4}/.test(pastedText)) {
      pastedText = moment(pastedText, text.DATE_FORMAT).format(text.DATE_FORMAT)
    }

    const formattedPastedText = pastedText.replace(/\//g, noValueString)
    const pastedTextToNumber = Number.parseInt(formattedPastedText)

    const isPastedTextInvalidNumber = Number.isNaN(pastedTextToNumber)

    if (isPastedTextInvalidNumber) {
      return
    }

    const { selectionStart, selectionEnd } = inputRef.current

    const formattedDate = formatDateInputString(
      value,
      KeyPressValues.Pasted,
      selectionStart,
      selectionEnd,
      formattedPastedText,
    )

    const currentPosition = formattedDate.length

    setCaretPosition(
      {
        currentPosition,
        // Place the cursor at the end of the input
        selectionStart: currentPosition,
        selectionEnd: currentPosition,
        direction: 1,
      },
      formattedDate,
    )

    onChangeCb(inputName, formattedDate)
  }

  const onCopy = async () => {
    await navigator.clipboard.writeText(value)
  }

  return (
    <Col className={`p-0 single-line-text-input-field ${className}`} data-testid="DOB-input-field">
      {label && (
        <Row className="m-0" data-testid="DOB-input-field-label">
          <InputLabel label={`${label} ${required ? '*' : ''}`} />
          {instructionMessage && (
            <p className="input instruction" data-testid="DOB-input-field-instructional-message">
              {instructionMessage}
            </p>
          )}
        </Row>
      )}
      <Row className={`m-0 input ${error ? 'validation-error' : ''}`}>
        <Col className="p-0 justify-content-center">
          <InputGroup>
            {iconLeft ? (
              <Icon name={`${iconLeft}${disabled ? '-disabled' : ''}`} className="icon" />
            ) : (
              <div className="pl-3" />
            )}
            <FormControl
              data-testid="DOB-input-field-form-control"
              onKeyDown={onKeyDown}
              onInput={onInput}
              onPaste={onPaste}
              onCopy={onCopy}
              className={`${error ? 'error' : ''} ${large ? 'large' : ''} ${
                iconLeft ? 'with-icon' : ''
              } single-line-input p-0`}
              type="text"
              maxLength={10}
              name="dob"
              value={value}
              placeholder={placeholder}
              disabled={disabled}
              ref={inputRef}
            />
          </InputGroup>
        </Col>
      </Row>
      {error && errorMessage && (
        <Row className="m-0 pl-3 pt-2" data-testid="DOB-input-field-error-message">
          <p className="error">{errorMessage}</p>
        </Row>
      )}
    </Col>
  )
}

export default DOBInputField
