export type ServiceNotificationType = 'error' | 'warning'

export type ServiceNotification = {
  startTime: Date
  endTime: Date
  message: string
  type: ServiceNotificationType
  show: boolean
}

export const getServiceNotification = (): ServiceNotification => {
  try {
    const startTime = process.env.REACT_APP_SERVICE_NOTIFICATION_START
    const endTime = process.env.REACT_APP_SERVICE_NOTIFICATION_END
    const message = process.env.REACT_APP_SERVICE_NOTIFICATION_MESSAGE
    const type = (process.env.REACT_APP_SERVICE_NOTIFICATION_TYPE || 'error') as ServiceNotificationType
    if (!startTime || !endTime || !message) {
      return null
    }
    const now = new Date()
    const show = new Date(startTime) < now && new Date(endTime) > now
    const serviceNotification: ServiceNotification = {
      startTime: new Date(startTime),
      endTime: new Date(endTime),
      message,
      type,
      show,
    }
    return serviceNotification
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return null
  }
}
