import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap'
import { connect } from 'react-redux'
import { clearChangePasswordField } from '../../../actions/passwordActions'
import Label from '../../../components/atoms/Label'
import Separator from '../../../components/atoms/Separator'
import AlertBox from '../../../components/molecules/AlertBox'
import text from '../../../_constants/text'
import ChangeContactInfoModal from './ChangeContactInfoModal'
import ChangePasswordModal from './ChangePasswordModal'

interface ProfileCardProps {
  email: string
  mobile: string
  displayName: string
  clearChangePasswordField: () => void
  changePasswordSuccess: boolean
  updateContactInfoFail: boolean
  updateContactInfoSuccess: boolean
  emailInvalid: boolean
}

const ProfileCard: React.FC<ProfileCardProps> = ({
  email,
  mobile,
  displayName,
  clearChangePasswordField,
  changePasswordSuccess,
  updateContactInfoSuccess,
  updateContactInfoFail,
  emailInvalid,
}) => {
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false)
  const [showContactInfo, setShowContactInfo] = useState(false)
  const handleShowChangePasswordModal = () => {
    setShowChangePasswordModal(!showChangePasswordModal)
    clearChangePasswordField()
  }
  useEffect(() => {
    if (changePasswordSuccess) {
      setShowChangePasswordModal(!showChangePasswordModal)
    }
  }, [changePasswordSuccess])

  return (
    <Card as="section" className="mb-3">
      <Card.Body>
        <Card.Title as="h2" className="mb-4">
          {text.PROFILE}
        </Card.Title>
        <h3>{text.LOGIN_DETAILS}</h3>
        {changePasswordSuccess && <AlertBox type="success" message={text.CHANGE_PASSWORD_SUCCESS} className="mb-4" />}
        <Label>{text.USER_ID}</Label>
        <p>{displayName}</p>
        <Label>{text.PASSWORD}</Label>
        <p>{text.HIDDEN_PASSWORD}</p>
        <ChangePasswordModal show={showChangePasswordModal} handleShow={handleShowChangePasswordModal} />
        <Button className="mt-4" onClick={handleShowChangePasswordModal}>
          {text.CHANGE_PASSWORD}
        </Button>
        <Separator />
        {updateContactInfoSuccess && <AlertBox type="success" message={text.UPDATE_CONTACT_INFO_SUCCESS} />}
        {updateContactInfoFail && <AlertBox type="error" message={text.UPDATE_CONTACT_INFO_FAIL} />}
        {emailInvalid && <AlertBox type="error" message={text.UPDATE_CONTACT_INFO_EMAIL_FAIL} />}
        <h3 className="mt-4">{text.CONTACT_INFO}</h3>
        <Label>{text.EMAIL}</Label>
        <p>{email}</p>
        <Label>{text.MOBILE_NUMBER}</Label>
        <p>{mobile}</p>
        <Button className="mt-4" onClick={() => setShowContactInfo(true)}>
          {text.UPDATE_CONTACT}
        </Button>
        <ChangeContactInfoModal show={showContactInfo} handleShow={() => setShowContactInfo(false)} />
      </Card.Body>
    </Card>
  )
}

const mapStateToProps = (state) => {
  const { changePasswordSuccess } = state.password
  return { changePasswordSuccess }
}

const mapDispatchToProps = (dispatch) => {
  return {
    clearChangePasswordField: () => dispatch(clearChangePasswordField()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard)
