import { BaseAction, DataAction } from '../commons'

export const PATIENT_ROW_SEARCH_STATE_OPEN = 'PATIENT_ROW_SEARCH/OPEN'
export const PATIENT_ROW_SEARCH_STATE_CLOSE = 'PATIENT_ROW_SEARCH/CLOSE'
export const PATIENT_ROW_SEARCH_STATE_RESET = 'PATIENT_ROW_SEARCH/RESET'

export type SearchResultsData = {
  showResults: boolean
  patientId: string
}

export const resetSearchRowState = (): BaseAction => {
  return {
    type: PATIENT_ROW_SEARCH_STATE_RESET,
  }
}

export const storeSearchState = (data: SearchResultsData): DataAction<SearchResultsData> => {
  if (data.showResults) {
    return {
      type: PATIENT_ROW_SEARCH_STATE_OPEN,
      data: data,
    }
  } else {
    return {
      type: PATIENT_ROW_SEARCH_STATE_CLOSE,
      data: data,
    }
  }
}
