import { BaseAction } from '../../actions/commons'
import {
  WEBCOMRAD_CLEAR,
  WEBCOMRAD_ERROR,
  WEBCOMRAD_FIELD_UPDATE,
  WEBCOMRAD_FIELD_VALIDATE,
  WEBCOMRAD_SUBMIT_FAIL,
  WEBCOMRAD_SUBMIT_LOADING,
  WEBCOMRAD_SUBMIT_SUCCESS,
} from '../../actions/WebcomradActions/webcomradActions'
import {
  WebcomradDataAction,
  WebcomradErrorAction,
  WebcomradFieldValidateAction,
} from '../../actions/WebcomradActions/webcomradActions.types'
import { SubmitControls } from '../RegisterAccountReducer/RegisterAccountReducer.types'

export const webcomradRequiredFields = {
  termsAndConditions: true,
  fullName: true,
  providerNumber: true,
  ahpranumber: true,
  referrerEmail: true,
  practiceName: true,
  practiceAddress: true,
  practiceSuburb: true,
  practiceState: true,
  practicePostcode: true,
  practicePhone: true,
  practiceFax: false,
  department: false,
  mobileNumber: true,
  speciality: true,
  otherSpeciality: false,
  operatingSystemMac: true,
  operatingSystemPC: true,
}

export interface WebcomradFormDataModel {
  termsAndConditions: boolean
  fullName: string
  providerNumber: string
  ahpranumber: string
  referrerEmail: string
  practiceName: string
  practiceAddress: string
  practiceSuburb: string
  practiceState: string
  practicePostcode: string
  practicePhone: string
  practiceFax: string
  department: string
  mobileNumber: string
  speciality: string
  otherSpeciality: string
  operatingSystemMac: boolean
  operatingSystemPC: boolean
}

export interface WebcomradFormErrorModel {
  termsAndConditions: boolean
  fullName: boolean
  providerNumber: boolean
  ahpranumber: boolean
  referrerEmail: boolean
  practiceName: boolean
  practiceAddress: boolean
  practiceSuburb: boolean
  practiceState: boolean
  practicePostcode: boolean
  practicePhone: boolean
  practiceFax: boolean
  department: boolean
  mobileNumber: boolean
  speciality: boolean
  otherSpeciality: boolean
  operatingSystemMac: boolean
  operatingSystemPC: boolean
}

type WebcomradState = {
  formData: WebcomradFormDataModel
  errors: WebcomradFormErrorModel
  submit: SubmitControls
}

export const initialWebcomradState: WebcomradState = {
  formData: {
    termsAndConditions: false,
    fullName: '',
    providerNumber: '',
    ahpranumber: '',
    referrerEmail: '',
    practiceName: '',
    practiceAddress: '',
    practiceSuburb: '',
    practiceState: '',
    practicePostcode: '',
    practicePhone: '',
    practiceFax: '',
    department: '',
    mobileNumber: '',
    speciality: '',
    otherSpeciality: '',
    operatingSystemMac: false,
    operatingSystemPC: false,
  } as WebcomradFormDataModel,
  errors: {
    termsAndConditions: false,
    fullName: false,
    providerNumber: false,
    ahpranumber: false,
    referrerEmail: false,
    practiceName: false,
    practiceAddress: false,
    practiceSuburb: false,
    practiceState: false,
    practicePostcode: false,
    practicePhone: false,
    practiceFax: false,
    department: false,
    mobileNumber: false,
    speciality: false,
    otherSpeciality: false,
    operatingSystemMac: false,
    operatingSystemPC: false,
  } as WebcomradFormErrorModel,
  submit: {
    inProgress: false,
    success: false,
    fail: false,
  } as SubmitControls,
}

const WebcomradReducer = (state: WebcomradState = initialWebcomradState, action: BaseAction): WebcomradState => {
  switch (action.type) {
    case WEBCOMRAD_FIELD_UPDATE:
      const a = action as WebcomradDataAction
      return {
        ...state,
        formData: {
          ...state.formData,
          ...a.data,
        },
      }
    case WEBCOMRAD_ERROR:
      const errorsAction = action as WebcomradErrorAction
      return {
        ...state,
        errors: {
          ...errorsAction.errors,
        },
      }
    case WEBCOMRAD_FIELD_VALIDATE:
      const validateFieldAction = action as WebcomradFieldValidateAction
      const fieldData = validateFieldAction.data
      return {
        ...state,
        errors: {
          ...state.errors,
          [fieldData.fieldName]: fieldData.value,
        },
      }
    case WEBCOMRAD_SUBMIT_LOADING:
      return { ...state, submit: { inProgress: true, success: false, fail: false } }
    case WEBCOMRAD_SUBMIT_SUCCESS:
      return { ...state, submit: { inProgress: false, success: true, fail: false } }
    case WEBCOMRAD_SUBMIT_FAIL:
      return { ...state, submit: { inProgress: false, fail: true, success: false } }
    case WEBCOMRAD_CLEAR:
      return { ...initialWebcomradState }
    default:
      return state
  }
}

export default WebcomradReducer
