export enum PageTypes {
  I_MED_APPOINTMENT = 'imed_appointments',
  PROCEDURE_DETAIL = 'procedurepage',
  ARTICLE_DETAIL = 'basiccontentpage',
  FIND_A_CLINIC_PAGE = 'find_a_clinic_page',
  LIST_PAGE = 'list_page',
  NEWS_DETAIL = 'article_page',
  RESOURCE_PAGE = 'resource_page',
  HOME_PAGE = 'home_page',
  CLINIC_PAGE = 'clinic_page',
  FORM_IFRAME_PAGE = 'form_iframe_page',
  BIOGRAPHY_PAGE = 'biography_page',
  BIOGRAPHY_LIST_PAGE = 'biographies_list_page',
  ONLINE_FORM_PAGE = 'online_form_page',
  SUPPORT_PAGE = 'support_page',
}

export const pageTypesArray: PageTypes[] = [
  PageTypes.ARTICLE_DETAIL,
  PageTypes.BIOGRAPHY_LIST_PAGE,
  PageTypes.BIOGRAPHY_PAGE,
  PageTypes.CLINIC_PAGE,
  PageTypes.FIND_A_CLINIC_PAGE,
  PageTypes.FORM_IFRAME_PAGE,
  PageTypes.I_MED_APPOINTMENT,
  PageTypes.LIST_PAGE,
  PageTypes.NEWS_DETAIL,
  PageTypes.ONLINE_FORM_PAGE,
  PageTypes.PROCEDURE_DETAIL,
  PageTypes.RESOURCE_PAGE,
]

export enum SectionTypes {
  TEXT = 'textsection',
  VIDEO = 'videosection',
  SEARCH = 'searchsection',
  RELATED_ITEMS = 'relatedItemsBlock',
  CRM = 'crm',
}

export const pageItemIds = {
  FOOTER: 'footer_0e37af3',
}

export enum CtaTypes {
  EXTERNAL_LINK = 'call_to_action___link',
  INTERNAL_LINK = 'call_to_action___internal_link',
  LINK_WITH_PREVALIDATION = 'call_to_action___link_with_prevalidation',
}

export enum CtaLabels {
  INTELECONNECT = 'Login to InteleConnect/EV',
  REGIONAL_ACCESS = 'Apply for Regional access',
}

export enum LinkTypes {
  EXTERNAL_LINK = 'external_link',
}
