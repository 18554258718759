import axios, { AxiosResponse } from 'axios'
import qs from 'qs'
import { HttpStatusCodes } from '../_constants/variables'

export enum ResponseStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  FORBIDDEN = 'forbidden',
  UNAUTHORIZED = 'unauthorized',
  NOT_FOUND = 'not_found',
  API_ERROR = 'apiError',
}

const SUCCESS_CODES = [
  HttpStatusCodes.OK,
  HttpStatusCodes.ACCEPTED,
  HttpStatusCodes.CREATED,
  HttpStatusCodes.NO_CONTENT,
  HttpStatusCodes.NOT_FOUND,
]

const isRequestSuccess = <T>(res: AxiosResponse<T>) => {
  return SUCCESS_CODES.includes(res.status)
}

export const badResponse = {
  status: ResponseStatus.FAILURE,
  data: null,
}
export const forbiddenResponse = {
  status: ResponseStatus.FORBIDDEN,
  data: null,
}
export const unauthorizedResponse = {
  status: ResponseStatus.UNAUTHORIZED,
  data: null,
}
export const notFoundResponse = {
  status: ResponseStatus.NOT_FOUND,
  data: null,
}
export const apiErrorResponse = {
  status: ResponseStatus.API_ERROR,
  data: null,
}

export interface ResponseWithStatus<T> {
  status: ResponseStatus
  data: T
}
export const successResponse = <T>(data: T): ResponseWithStatus<T> => ({
  status: ResponseStatus.SUCCESS,
  data,
})

export const getResponse = <T>(axiosResponse: AxiosResponse<T>, path: string): ResponseWithStatus<T> => {
  if (!axiosResponse || !path) {
    return badResponse
  }
  if (axiosResponse.status === HttpStatusCodes.UNAUTHORIZED) {
    return unauthorizedResponse
  }
  if (axiosResponse.status === HttpStatusCodes.FORBIDDEN) {
    return forbiddenResponse
  }
  if (axiosResponse.status === HttpStatusCodes.API_ERROR) {
    return apiErrorResponse
  }
  if (isRequestSuccess(axiosResponse)) {
    return successResponse<T>(axiosResponse.data)
  }
  return badResponse
}

const contentTypeHeader = { 'Content-Type': 'application/json' }
export const get = async <T>(
  path: string,
  additionalHeaders?: Record<string, string>,
  params?: {},
  signal?: AbortSignal,
): Promise<ResponseWithStatus<T>> => {
  const headers = { ...contentTypeHeader, ...additionalHeaders }
  try {
    const res = await axios.request<T, AxiosResponse<T>>({
      method: 'get',
      url: path,
      headers,
      params,
      paramsSerializer: (a) => qs.stringify(a, { arrayFormat: 'repeat' }),
      signal,
    })
    return getResponse(res, path)
  } catch (e) {
    return getResponse(e.response, path)
  }
}

export const maybeGetWithCancellation = async <T>(
  path: string,
  additionalHeaders?: Record<string, string>,
  signal?: AbortSignal,
  params?: {},
): Promise<AxiosResponse<T>> => {
  const headers = { ...contentTypeHeader, ...additionalHeaders }
  return axios.request<T, AxiosResponse<T>>({
    method: 'get',
    url: path,
    headers,
    params,
    paramsSerializer: (a) => qs.stringify(a, { arrayFormat: 'repeat' }),
    signal,
  })
}

export const post = async <T>(
  path: string,
  additionalHeaders?: Record<string, string>,
  params?: {},
): Promise<ResponseWithStatus<T>> => {
  const headers = { ...contentTypeHeader, ...additionalHeaders }
  try {
    const res = await axios.request<unknown, AxiosResponse<T>>({
      method: 'post',
      data: params,
      url: path,
      headers,
    })
    return getResponse(res, path)
  } catch (e) {
    return getResponse(e.response, path)
  }
}
