import React from 'react'
import InternalLink from '../../atoms/InternalLink'
import Button from 'react-bootstrap/Button'
import Icon from '../../atoms/Icon'

interface props {
  linkUrl: string
  text: string
  icon: string
  useSecondaryTheme: boolean
  onLinkClick?: () => void
}

const MobileButtonBar: React.FC<props> = ({ useSecondaryTheme, linkUrl, text, icon, onLinkClick }) => {
  return (
    <div className="d-block d-lg-none mobile-bar-button w-100">
      <InternalLink href={linkUrl} className="mb-0">
        <Button onClick={onLinkClick} variant={useSecondaryTheme ? 'light' : 'primary'} className="w-100 m-0">
          <Icon name={icon} className="mr-2" />
          {text}
        </Button>
      </InternalLink>
    </div>
  )
}

MobileButtonBar.defaultProps = {
  onLinkClick: undefined,
}
export default MobileButtonBar
