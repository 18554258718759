import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { ReactComponent as Check } from './check_circle.svg'

type PageHeadingRowProps = {
  isSuccess?: boolean
  title: string
  lead?: string
}
const PageHeadingRow: React.FC<PageHeadingRowProps> = ({ title, isSuccess = false, lead = null }) => {
  return (
    <div className="py-4" data-testid="pageHeadingRow">
      <Row className="px-0">
        <Col xs={12} lg={8} className="d-flex flex-direction-row">
          <h1 className="mt-3">{title}</h1>
          {isSuccess && (
            <div className="ps-3 mt-3">
              <Check />
            </div>
          )}
        </Col>
      </Row>
      {lead && (
        <Row className="px-0">
          <Col lg={8}>
            <p>{lead}</p>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default PageHeadingRow
