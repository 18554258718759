import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'
import {
  WEBCOMRAD_PASSWORD_RESET_SUBMIT,
  webcomradPasswordResetSubmitFail,
  webcomradPasswordResetSubmitLoading,
  webcomradPasswordResetSubmitSuccess,
} from '../../actions/WebcomradPasswordResetActions/webcomradPasswordResetActions'
import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import runWithRefresh from '../RefreshAuthTokenSaga/runWithRefresh'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'

const WEBCOMRAD_PASSWORD_RESET_PATH = '/portal/webcomrad-password-reset'

type SubmitWebcomradPasswordResetSagaGenerator = Generator<
  PutEffect | SelectEffect | unknown,
  void,
  ResponseWithStatus<void>
>

type SubmitWebcomradPasswordResetGenerator = Generator<
  SelectEffect | Promise<ResponseWithStatus<void>>,
  ResponseWithStatus<void>,
  ResponseWithStatus<void> | AccessToken
>

export function* submitForm(): SubmitWebcomradPasswordResetGenerator {
  const formData = yield select((state) => state.webcomradPasswordReset.formData)

  return (yield post(
    `${process.env.REACT_APP_API_URL}${WEBCOMRAD_PASSWORD_RESET_PATH}`,
    {},
    formData,
  )) as ResponseWithStatus<void>
}

export function* submitWebcomradPasswordResetSaga(): SubmitWebcomradPasswordResetSagaGenerator {
  yield put(webcomradPasswordResetSubmitLoading())
  const response = (yield runWithRefresh(
    submitForm,
  ) as SubmitWebcomradPasswordResetGenerator) as ResponseWithStatus<void>
  if (response.status === ResponseStatus.SUCCESS) {
    yield put(webcomradPasswordResetSubmitSuccess())
    return
  }
  yield put(webcomradPasswordResetSubmitFail())
}

function* watchSubmitWebcomradPasswordReset(): Generator<ForkEffect<never>, void> {
  yield takeEvery([WEBCOMRAD_PASSWORD_RESET_SUBMIT], submitWebcomradPasswordResetSaga)
}

const webcomradPasswordResetSagas = [fork(watchSubmitWebcomradPasswordReset)]

export default webcomradPasswordResetSagas
