import { ForkEffect, put, select, takeEvery } from '@redux-saga/core/effects'
import { CallEffect, fork, PutEffect, SelectEffect } from 'redux-saga/effects'
import { DataAction } from '../../actions/commons'
import { patientImagesStoreParams } from '../../actions/PatientImagesActions/patientImagesActions'
import {
  OrderResponse,
  PATIENT_REPORT_GET_REPORT,
  patientReportConsentExpired,
  patientReportGetReportFail,
  patientReportGetReportSuccess,
} from '../../actions/PatientReportActions/patientReportActions'

import { badResponse, forbiddenResponse, get, ResponseStatus, ResponseWithStatus, successResponse } from '../../fetch'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { consentIsValid } from '../../services/breakGlassConsentValidityWatcher'
import { runWithRefresh } from '../RefreshAuthTokenSaga/refreshAuthTokenSaga'

const ORDER_PATH = '/imedvisage/order'
const REPORT_PATH = '/imedvisage/report'

type GetReportResponse = ResponseWithStatus<{ report: string; order: OrderResponse }>
type GetReportGenerator = Generator<
  SelectEffect | Promise<ResponseWithStatus<OrderResponse | string>> | PutEffect | CallEffect | Promise<boolean>,
  GetReportResponse,
  AccessToken | ResponseWithStatus<OrderResponse | string>
>
type GetReportData = {
  referrer: string
  patientId: string
  orderId: string
  accessible: boolean
}

export function* getReport(action: DataAction<GetReportData>): GetReportGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken

  if (!action.data.accessible) {
    const consentValid = yield consentIsValid(action.data.referrer, action.data.patientId)

    if (!consentValid) {
      yield put(patientReportConsentExpired())
      return
    }
  }

  const order = (yield get(
    `${process.env.REACT_APP_API_URL}${ORDER_PATH}`,
    { Authorization: `${jwt.type} ${jwt.token}` },
    {
      orderUri: `/order/${action.data.orderId}`,
      ...(!action.data.accessible && { breakGlass: 'true' }),
    },
  )) as ResponseWithStatus<OrderResponse>

  if (order.status === ResponseStatus.FORBIDDEN) {
    return forbiddenResponse
  }

  if (order.status === ResponseStatus.FAILURE) {
    yield put(patientReportGetReportFail())
    return
  }

  const report = (yield get(
    `${process.env.REACT_APP_API_URL}${REPORT_PATH}`,
    { Authorization: `${jwt.type} ${jwt.token}` },
    {
      orderUri: `/order/${action.data.orderId}`,
      reportUri: order.data.reportUri,
      ...(!action.data.accessible && { breakGlass: 'true' }),
    },
  )) as ResponseWithStatus<string>

  if (report.status === ResponseStatus.FORBIDDEN) {
    return forbiddenResponse
  }
  if (report.status === ResponseStatus.FAILURE) {
    return badResponse
  }
  return successResponse({ report: report.data, order: order.data })
}

export function* getReportSaga(
  action: DataAction<{ orderId: string; breakGlass: boolean }>,
): Generator<unknown | PutEffect, void, GetReportResponse> {
  const response = (yield runWithRefresh(getReport, action) as GetReportGenerator) as GetReportResponse

  if (!response) return

  if (response.status !== ResponseStatus.SUCCESS) {
    yield put(patientReportGetReportFail())
    return
  }

  yield put(
    patientImagesStoreParams({
      accessionNumber: response.data.order.accessionNumber,
    }),
  )
  yield put(
    patientReportGetReportSuccess({
      report: response.data.report,
      reportUri: response.data.order.reportUri,
      procedures: response.data.order.procedures,
      accessionNumber: response.data.order.accessionNumber,
      attachments: response.data.order.attachments,
      dicom: response.data.order.dicom,
      status: response.data.order.status,
    }),
  )
}

function* watchGetReport(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(PATIENT_REPORT_GET_REPORT, getReportSaga)
}

const reportSaga = [fork(watchGetReport)]

export default reportSaga
