import { IMedJwtPayload, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../../actions/loginAction'
import { NavigationItem } from '../../domain/models/commons/NavigationItem'
import { BaseAction, DataAction } from '../../actions/commons'
import intersection from 'lodash/intersection'
import { adminNav, referrerNav } from './NavReducer.config'

type NavState = {
  navLinks: {
    id: string
    type: string
    value: {
      icon: string
      name: string
      children: NavigationItem[]
      external?: boolean
    }
  }
}

export const initialNavState = {
  navLinks: {
    id: '',
    type: '',
    value: {
      icon: '',
      name: '',
      children: [],
      navigation: [],
    },
  },
}

const adminRoles: string[] = ['ROLE_ADMIN', 'ROLE_EDITOR', 'ROLE_CLEANUP', 'ROLE_CRM_ADMIN']
const navReducer = (state = initialNavState, action: BaseAction): NavState => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const a = action as DataAction<IMedJwtPayload>
      if (intersection(adminRoles, a.data.groups).length) {
        return {
          ...state,
          navLinks: { ...adminNav },
        }
      } else {
        return {
          ...state,
          navLinks: { ...referrerNav },
        }
      }
    }
    case LOGOUT_SUCCESS: {
      return { ...initialNavState }
    }
    default:
      return state
  }
}

export default navReducer
