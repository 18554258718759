import React from 'react'
import { Col, Row } from 'react-bootstrap'
import text from '../../../_constants/text'
import { StudyRowKeys } from '../StudyTable/index.types'
import StudyTableColumnLabel from '../StudyTableColumnLabel'

type StudyHeaderRowProps = {
  onSort: (sortValue: StudyRowKeys) => void
}
const StudyHeaderRow: React.FC<StudyHeaderRowProps> = ({ onSort }) => (
  <Row data-testid="studyHeaderRow" className="mx-0 px-0 mb-2 study-table-row study-header-row">
    <Col className="study-table-col date-col" xs={6}>
      <StudyTableColumnLabel onSortCb={() => onSort('studyDate')}>{text.STUDY_TABLE_STUDY_DATE}</StudyTableColumnLabel>
    </Col>
    <Col className="study-table-col procedures-col" xs={6}>
      <StudyTableColumnLabel onSortCb={() => onSort('modality')}>{text.STUDY_TABLE_MODALITY}</StudyTableColumnLabel>
    </Col>
    <Col className="study-table-col description-col" xs={6}>
      <StudyTableColumnLabel>{text.STUDY_TABLE_DESCRIPTION}</StudyTableColumnLabel>
    </Col>
    <Col className="study-table-col doctor-col" xs={6}>
      <StudyTableColumnLabel onSortCb={() => onSort('referrer')}>{text.STUDY_TABLE_REFERRER}</StudyTableColumnLabel>
    </Col>
    <Col className="study-table-col accession-col" xs={6}>
      <StudyTableColumnLabel onSortCb={() => onSort('accessionNo')}>
        {text.STUDY_TABLE_ACCESSION_NO}
      </StudyTableColumnLabel>
    </Col>
    <Col className="study-table-col link-col" xs={6}>
      <StudyTableColumnLabel onSortCb={() => onSort('images')}>{text.STUDY_TABLE_IMAGES}</StudyTableColumnLabel>
    </Col>
    <Col className="study-table-col link-col" xs={6}>
      <StudyTableColumnLabel onSortCb={() => onSort('report')}>{text.STUDY_TABLE_REPORT}</StudyTableColumnLabel>
    </Col>
    <Col className="study-table-col lock-col" xs={6}></Col>
  </Row>
)

export default StudyHeaderRow
