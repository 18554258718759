import React, { FC } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import DropdownSelect from '../../../../../components/forms/fields/DropdownSelect'
import RadioButtonGroup from '../../../../../components/forms/fields/RadioButtonGroup'
import SingleLineTextInputField from '../../../../../components/forms/fields/SingleLineTextInputField'
import { Practice } from '../../../../../domain/models/commons/Practice'
import {
  PracticeDetailsErrorsType,
  PracticeDetailsType,
  Referral,
  ReferralErrors,
} from '../../../../../reducers/ReferralReducer/ReferralReducer.types'
import { ReactComponent as AddIcon } from '../../../../../images/add_circle.svg'
import { ReactComponent as RemoveIcon } from '../../../../../images/remove_circle.svg'
import text from '../../../../../_constants/text'

const MIN_CC_DOCTORS = 1
const MAX_CC_DOCTORS = 5

interface ReferringPractitionerProps {
  onValueChange: (name: string, value: string | boolean) => void
  onPracticeValueChange: (index: number, type: string, value: string) => void
  referral: Referral
  errors: ReferralErrors
  practices: Practice[]
  practicesDetails: PracticeDetailsType[]
  practicesDetailsErrors: PracticeDetailsErrorsType[]
  addPractice: () => void
  removePractice: (index: number) => void
}

const referringPractitioner: FC<ReferringPractitionerProps> = ({
  onValueChange,
  onPracticeValueChange,
  referral,
  errors,
  practices,
  practicesDetails,
  practicesDetailsErrors,
  addPractice,
  removePractice,
}) => {
  const practicesDropdownOptions = practices
    .filter((practice) => practice.practiceName !== '')
    .map((practice) => practice.practiceName)

  const isUserSoloPractitioner = practicesDropdownOptions.length === 0

  const showDoctorFields = referral.doctorPracticeName !== '' || isUserSoloPractitioner

  return (
    <>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.FULL_NAME}
            inputName="doctorName"
            required={false}
            value={referral.doctorName}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.doctorName}
            placeholder={text.PLACEHOLDER_NAME}
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.EMAIL_OPTIONAL}
            inputName="doctorEmail"
            required={false}
            value={referral.doctorEmail}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.doctorEmail}
            placeholder={text.PLACEHOLDER_EMAIL}
          />
        </Col>
      </Row>
      {practicesDropdownOptions.length > 0 && (
        <Row>
          <Col xs={12} lg={6}>
            <DropdownSelect
              label={text.PRACTICE_NAME}
              inputName="doctorPracticeName"
              required={false}
              value={referral.doctorPracticeName}
              onChangeCb={onValueChange}
              error={errors.doctorPracticeName}
              errorMessage={text.FIELD_REQUIRED}
              options={practicesDropdownOptions}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PRACTICE_EMAIL_OPTIONAL}
            inputName="doctorPracticeEmail"
            required={false}
            value={referral.doctorPracticeEmail}
            onChangeCb={onValueChange}
            error={errors.doctorPracticeEmail}
            errorMessage={text.FIELD_REQUIRED}
            placeholder={text.PLACEHOLDER_EMAIL}
          />
        </Col>
      </Row>
      {showDoctorFields && (
        <>
          <Row>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.PROVIDER_NUMBER}
                type="text"
                inputName="doctorProviderNumber"
                required={false}
                value={referral.doctorProviderNumber}
                onChangeCb={onValueChange}
                error={errors.doctorProviderNumber}
                errorMessage={text.FIELD_REQUIRED}
                placeholder={text.PRACTICE_NUMBER_PLACEHOLDER}
              />
            </Col>
            <Col>
              <SingleLineTextInputField
                label={text.PRACTICE_PHONE}
                inputName="doctorPhone"
                required={false}
                error={errors.doctorPhone}
                value={referral.doctorPhone}
                onChangeCb={onValueChange}
                errorMessage={text.FIELD_REQUIRED}
                placeholder="e.g. 0200112233"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleLineTextInputField
                label={text.ADDRESS}
                inputName="doctorStreet"
                required={false}
                value={referral.doctorStreet}
                onChangeCb={onValueChange}
                error={errors.doctorStreet}
                errorMessage={text.FIELD_REQUIRED}
                placeholder=""
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={5}>
              <SingleLineTextInputField
                label={text.SUBURB}
                inputName="doctorSuburb"
                required={false}
                value={referral.doctorSuburb}
                onChangeCb={onValueChange}
                error={errors.doctorSuburb}
                errorMessage={text.FIELD_REQUIRED}
                placeholder=""
              />
            </Col>
            <Col xs={12} lg={3}>
              <SingleLineTextInputField
                label={text.POSTCODE}
                inputName="doctorPostcode"
                required={false}
                value={referral.doctorPostcode}
                onChangeCb={onValueChange}
                error={errors.doctorPostcode}
                errorMessage="This field is required"
                placeholder=""
              />
            </Col>
            <Col xs={12} lg={4}>
              <DropdownSelect
                label={text.STATE}
                inputName="doctorState"
                required={false}
                value={referral.doctorState}
                onChangeCb={onValueChange}
                error={errors.doctorState}
                errorMessage="This field is required"
                options={text.STATES}
              />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col xs={12}>
          <RadioButtonGroup
            label={text.REFERRAL_CC_DOCTOR}
            inputName="ccDoctor"
            errorMessage={text.FIELD_REQUIRED}
            value={referral.ccDoctor}
            onChangeCb={onValueChange}
            required={false}
            error={errors.ccDoctor}
            options={['Yes', 'No']}
          />
        </Col>
      </Row>
      {referral.ccDoctor === 'Yes' && (
        <>
          <Row className="mb-2">
            <b>{text.DOCTORS_DETAILS}</b>
          </Row>
          <div>
            {practicesDetails.map((practice: PracticeDetailsType, i: number) => (
              <span key={`practice_${i}`}>
                <Row>
                  <Col xs={12} lg={6}>
                    <SingleLineTextInputField
                      label={text.FULL_NAME}
                      inputName="doctorName"
                      required={false}
                      value={practice.doctorName}
                      onChangeCb={(name, value) => onPracticeValueChange(i, name, value)}
                      error={practicesDetailsErrors[i].doctorName}
                      errorMessage={text.FIELD_REQUIRED}
                      placeholder={text.PLACEHOLDER_NAME}
                      className="mb-2"
                    />
                  </Col>
                  <Col xs={12} lg={6}>
                    <SingleLineTextInputField
                      label={text.PRACTICE_NAME}
                      inputName="practiceName"
                      required={false}
                      value={practice.practiceName}
                      onChangeCb={(name, value) => onPracticeValueChange(i, name, value)}
                      error={practicesDetailsErrors[i].practiceName}
                      errorMessage={text.FIELD_REQUIRED}
                      placeholder={text.PRACTICE_NAME_PLACEHOLDER}
                      className="mb-2"
                    />
                  </Col>
                </Row>
                {i >= MIN_CC_DOCTORS && (
                  <Row className="d-flex">
                    <Col xs={12} lg={6} className="d-flex">
                      <Button
                        data-testid="RemovePracticeButton"
                        variant="secondary"
                        className="px-0"
                        onClick={() => removePractice(i)}
                      >
                        <span className="px-2 pl-0">
                          <RemoveIcon />
                        </span>
                        <span>{text.REMOVE_DOCTOR}</span>
                      </Button>
                    </Col>
                  </Row>
                )}
                {i < MAX_CC_DOCTORS && (
                  <Row>
                    <Col className="t-separator" />
                  </Row>
                )}
              </span>
            ))}

            {practicesDetails.length < MAX_CC_DOCTORS && (
              <Row>
                <Col xs={12} lg={6}>
                  <Button data-testid="AddPracticeButton" variant="light" onClick={addPractice}>
                    <span className="px-2">
                      <AddIcon />
                    </span>
                    <span>{text.ADD_ANOTHER_DOCTOR}</span>
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default referringPractitioner
