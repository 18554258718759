import { FC } from 'react'
import { Button, Modal } from 'react-bootstrap'
import { connect } from 'react-redux'

import WarningAlert from '../../../components/atoms/WarningAlert'
import text from '../../../_constants/text'
import { logout } from '../../../actions/loginAction'
import routes from '../../../router/routes'

interface SessionTimedOutModalProps {
  show: boolean
  handleLoginRedirect: () => void
}

const SessionTimedOutModal: FC<SessionTimedOutModalProps> = ({ show, handleLoginRedirect }) => {
  return (
    <Modal show={show} onHide={() => null} centered data-testid="session-timed-out-modal" size="lg">
      <Modal.Body>
        <div className="d-flex flex-column justify-content-center">
          <div className="d-flex flex-row align-items-center mb-3">
            <WarningAlert className="mt-4 ml-4" />
          </div>

          <h2 className="mt-3">{text.SESSION_TIMEOUT}</h2>

          <p className="mt-4">{text.LOGOUT_SESSION_TIMEOUT}</p>

          <div className="d-flex flex-row justify-content-end">
            <Button className="mt-4" data-testid="session-timed-out-continue" onClick={handleLoginRedirect}>
              {text.SIGN_IN}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { hasSessionTimedOut } = state.login
  const { location } = state.router

  const isCurrentPageLogin = location.pathname === routes.home || location.pathname === routes.login

  const show = hasSessionTimedOut && !isCurrentPageLogin

  return {
    show,
  }
}

const mapDispatchToProps = (dispatch) => ({
  handleLoginRedirect: () => {
    dispatch(logout(true))
  },
})

export { SessionTimedOutModal as SessionTimedOutModalForTest }

export default connect(mapStateToProps, mapDispatchToProps)(SessionTimedOutModal)
