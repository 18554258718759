import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'

import { BaseAction } from '../../actions/commons'
import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import {
  resetPasswordFail,
  ResetPasswordFieldType,
  resetPasswordSuccess,
  RESET_PASSWORD_SUBMIT,
  validateFail,
} from '../../actions/ResetPasswordActions/resetPasswordActions'
import { validateString } from '../../components/forms/validations/ResetPassword'

const RESET_PASSWORD_PATH = '/portal/reset-confirm'

const fieldsToValidate: Partial<ResetPasswordFieldType[]> = ['code', 'password']

export function* resetPassword(): Generator<
  SelectEffect | Promise<ResponseWithStatus<void>> | PutEffect<BaseAction>,
  void,
  Record<ResetPasswordFieldType, string> | ResponseWithStatus<void>
> {
  const resetPasswordDetails = (yield select((state) => state.resetPassword.form)) as Record<
    ResetPasswordFieldType,
    string
  >
  const errors: Partial<Record<ResetPasswordFieldType, boolean>> = {}
  fieldsToValidate.forEach((x: ResetPasswordFieldType) => {
    if (!validateString(x, true, resetPasswordDetails[x])) {
      errors[x] = true
    }
  })
  if (Object.keys(errors).length > 0) {
    yield put(validateFail(errors))
    return
  }
  const resetPasswordResponse = (yield post(
    `${process.env.REACT_APP_API_URL}${RESET_PASSWORD_PATH}`,
    {},
    {
      secret: resetPasswordDetails.secret,
      password: resetPasswordDetails.password,
      passcode: resetPasswordDetails.code,
    },
  )) as ResponseWithStatus<void>
  if (resetPasswordResponse.status === ResponseStatus.FAILURE) {
    yield put(resetPasswordFail())
  } else {
    yield put(resetPasswordSuccess())
  }
}

function* watchResetPassword(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(RESET_PASSWORD_SUBMIT, resetPassword)
}

const resetPasswordSagas = [fork(watchResetPassword)]

export default resetPasswordSagas
