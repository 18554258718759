import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'
import { DataAction } from '../../actions/commons'

import { get, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import {
  GetPatientStudiesData,
  getPatientStudiesFail,
  GetPatientStudiesResponseData,
  getPatientStudiesSuccess,
  PATIENT_STUDIES_GET_STUDIES,
} from '../../actions/PatientStudiesActions/patientStudiesActions'
import { runWithRefresh } from '../RefreshAuthTokenSaga/refreshAuthTokenSaga'
import mapSearchParams from '../../mappers/mapSearchParams'
import { Search } from '../../reducers/commons/types'
import { Study } from '../../domain/models/commons/Study'

const PATIENT_STUDIES_PATH = '/imedvisage/orders'

type GetStudiesResponse = ResponseWithStatus<GetPatientStudiesResponseData>

export function* getStudies(
  action: DataAction<GetPatientStudiesData>,
): Generator<
  SelectEffect | Promise<ResponseWithStatus<Study[]>> | PutEffect,
  ResponseWithStatus<Study[]>,
  ResponseWithStatus<Study[]> | AccessToken | Search | string
> {
  const jwt = (yield select((state) => state.login.token)) as AccessToken

  const searchState = (yield select((state) => state.patientSearch.currentSearch)) as Search

  const { patientId, search } = action.data

  const res = (yield get(
    `${process.env.REACT_APP_API_URL}${PATIENT_STUDIES_PATH}`,
    {
      Authorization: `${jwt.type} ${jwt.token}`,
    },
    mapSearchParams({
      // Apply search filters to fetch studies according to current user search rather than fetching all studies.
      ...searchState,
      ...search,
      nameOrPatientId: patientId,
    } as Search),
  )) as ResponseWithStatus<Study[]>

  if (res.status !== ResponseStatus.SUCCESS) {
    yield put(getPatientStudiesFail(patientId))
    return
  }

  yield put(getPatientStudiesSuccess({ data: res.data, patientId: action.data.patientId }))
}

export function* getStudiesSaga(
  action: DataAction<GetPatientStudiesData>,
): Generator<unknown | PutEffect, void, GetStudiesResponse> {
  yield runWithRefresh(getStudies, action)
}

function* watchGetStudies(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(PATIENT_STUDIES_GET_STUDIES, getStudiesSaga)
}

const studiesSaga = [fork(watchGetStudies)]

export default studiesSaga
