const PUBLIC_KEY = '6LesqmYaAAAAAE5qyFdAa5mqTVFIR5YKiReTMNSX'

type Actions = {
  action: string
}
declare global {
  interface Window {
    grecaptcha: {
      ready: (func: () => void) => void
      execute: (key: string, actions: Actions) => Promise<void>
    }
    captchaOnLoad: (successCallback: Function) => void
  }
}

export const setRecaptcha = () => {
  const script = document.createElement('script')
  script.src = 'https://www.google.com/recaptcha/api.js?render=' + PUBLIC_KEY
  document.body.appendChild(script)
}

export const getRecaptcha = (successCallback: Function) => {
  window.grecaptcha.ready(function () {
    window.grecaptcha.execute(PUBLIC_KEY, { action: 'submit' }).then(function (token: any) {
      successCallback(token)
    })
  })
}
