/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { call } from 'redux-saga/effects'
import { ResponseWithStatus, ResponseStatus } from '../../fetch'
import refreshAuth from './refreshAuthTokenSaga'
import { Saga } from 'redux-saga'

// using any and unknown because TS / saga issues https://github.com/redux-saga/redux-saga/issues/1177
export function* runWithRefresh(saga: Saga, ...params): Generator<unknown, ResponseWithStatus<unknown>, unknown> {
  let resp = (yield call(saga, ...params)) as ResponseWithStatus<unknown>
  if (!resp) return
  if (resp.status === ResponseStatus.FORBIDDEN) {
    const refreshSuccess = yield call(refreshAuth)
    if (!refreshSuccess) return
    resp = (yield call(saga, ...params)) as ResponseWithStatus<unknown>
  }
  return resp
}

export default runWithRefresh
