import React, { useEffect, useState } from 'react'
import { Button, Card, Row, Col } from 'react-bootstrap'
import text from '../../../_constants/text'
import { Practice } from '../../../reducers/AccountReducer/AccountReducer'
import AddPracticeModal from './AddPracticeModal'
import { resetForm } from '../../../actions/AddPractice/addPracticeActions'
import { connect } from 'react-redux'
import AlertBox from '../../../components/molecules/AlertBox'

interface PracticeCardProps {
  practices: Practice[]
  addPracticeSuccess: boolean
  resetForm: () => void
}

const PracticesCard: React.FC<PracticeCardProps> = ({ practices, addPracticeSuccess, resetForm }) => {
  const [showAddPracticeModal, setShowAddPracticeModal] = useState(false)
  const handleShowAddPracticeModal = () => {
    setShowAddPracticeModal(!showAddPracticeModal)
    resetForm()
  }

  useEffect(() => {
    if (addPracticeSuccess) {
      setShowAddPracticeModal(!showAddPracticeModal)
    }
  }, [addPracticeSuccess])

  return (
    <Card as="section" className="mb-3">
      <Card.Body>
        <Card.Title as="h2" className="mb-4">
          {text.PRACTICES}
        </Card.Title>
        {addPracticeSuccess && <AlertBox type="success" message={text.ADD_PRACTICE_SUCCESS} />}
        {practices.map((practice) => (
          <div key={practice.uri}>
            <Row className="my-3 d-flex flex-row px-2 px-lg-0">
              <Col md={9}>
                <p className="mb-0">{practice.practiceName}</p>
              </Col>
            </Row>
          </div>
        ))}
        <AddPracticeModal show={showAddPracticeModal} handleShow={handleShowAddPracticeModal} />
        <Button onClick={handleShowAddPracticeModal}>{text.ADD_ANOTHER_PRACTICE}</Button>
      </Card.Body>
    </Card>
  )
}

const mapStateToProps = (state) => {
  const { success: addPracticeSuccess } = state.addPractice.submit
  return { addPracticeSuccess }
}

const mapDispatchToProps = (dispatch) => {
  return {
    resetForm: () => dispatch(resetForm()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PracticesCard)
