import text from '../_constants/text'
import { Search, SearchState } from '../reducers/commons/types'

const isActiveSearchAllStudies = (state: SearchState): boolean => {
  const activeSearch = state.activeSearch as Search

  if (!activeSearch) {
    return false
  }

  return (
    activeSearch.practices?.[0] === text.ALL_PRACTICES &&
    activeSearch.modalities?.[0] === text.ALL_MODALITIES &&
    activeSearch.dateRange === text.SEARCH_DATE_RANGE_ALL_TIME &&
    activeSearch.searchType === text.SEARCH_REFERRED_BY_ANYONE &&
    activeSearch.dob !== '' &&
    activeSearch.nameOrPatientId !== ''
  )
}

const isActiveSearchWithinMyNetworkAndAllStudies = (state: SearchState): boolean => {
  const activeSearch = state.activeSearch as Search

  if (!activeSearch) {
    return false
  }

  return (
    activeSearch.practices?.[0] === text.ALL_PRACTICES &&
    activeSearch.modalities?.[0] === text.ALL_MODALITIES &&
    activeSearch.dateRange === text.SEARCH_DATE_RANGE_ALL_TIME &&
    activeSearch.searchType === text.SEARCH_REFERRED_WITHIN_MY_NETWORK
  )
}

export { isActiveSearchAllStudies, isActiveSearchWithinMyNetworkAndAllStudies }
