let db = null

export interface ObjectStore {
  name: string
  key: Array<string> | string
}

export type ObjectStores = Array<ObjectStore>

export type IndexedDbRecord = Array<string> | string

export const indexedDbResponses = {
  OPEN_DB_SUCCESS: 'Indexed database opened',
  CREATE_DB_SUCCESS: 'Indexed database created and opened',
  OPEN_CREATE_DB_FAIL: 'Error opening/creating database',
  INSERT_SUCCESS: 'Object added/updated successfully',
  INSERT_FAIL: 'Error adding object',
  DELETE_SUCCESS: 'Object deleted successfully',
  DELETE_FAIL: 'Error deleting object',
  GET_OBJECT_FAIL: 'Error getting object',
  DELETE_DB_SUCCESS: 'Database deleted successfully',
  DELETE_DB_FAIL: 'Error while deleting database',
}

const getStore = (name: string, mode: string): IDBObjectStore => {
  if (db) {
    const tx = db.transaction(name, mode)
    return tx.objectStore(name)
  }
  return null
}

export const initDatabase = async (objectStores: ObjectStores, dbName: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    const dbRequirement = indexedDB.open(dbName)
    dbRequirement.onsuccess = (e: Event) => {
      db = (e.target as IDBOpenDBRequest).result
      resolve(indexedDbResponses.OPEN_DB_SUCCESS)
    }
    dbRequirement.onupgradeneeded = (e: Event) => {
      db = (e.target as IDBOpenDBRequest).result
      if (db) {
        objectStores.forEach(({ name, key }) => {
          if (!db.objectStoreNames.contains(name)) {
            db.createObjectStore(name, {
              keyPath: key,
            })
          }
        })
        resolve(indexedDbResponses.CREATE_DB_SUCCESS)
      }
    }
    dbRequirement.onerror = () => {
      reject(indexedDbResponses.OPEN_CREATE_DB_FAIL)
    }
  })
}

export const upsertRecord = async (objectStoreName: string, item: {}): Promise<string> => {
  return new Promise((resolve, reject) => {
    const store = getStore(objectStoreName, 'readwrite')
    if (store) {
      const request = store.put(item)
      request.onsuccess = () => {
        resolve(indexedDbResponses.INSERT_SUCCESS)
      }
      request.onerror = () => {
        reject(indexedDbResponses.INSERT_FAIL)
      }
    } else {
      reject(indexedDbResponses.INSERT_FAIL)
    }
  })
}

export const deleteRecord = async (objectStoreName: string, itemKey: IndexedDbRecord): Promise<string> => {
  return new Promise((resolve, reject) => {
    const store = getStore(objectStoreName, 'readwrite')
    if (store) {
      const request = store.delete(itemKey)
      request.onsuccess = () => {
        resolve(indexedDbResponses.DELETE_SUCCESS)
      }
      request.onerror = () => {
        reject(indexedDbResponses.DELETE_FAIL)
      }
    }
  })
}

export const getItem = async <T>(objectStoreName: string, itemKey: IndexedDbRecord): Promise<T | string> => {
  return new Promise((resolve, reject) => {
    const store = getStore(objectStoreName, 'readonly')
    if (store) {
      const request = store.get(itemKey)
      request.onsuccess = () => {
        return resolve(request.result)
      }
      request.onerror = () => {
        return reject(indexedDbResponses.GET_OBJECT_FAIL)
      }
    }
  })
}

export const getAllItems = async <T>(objectStoreName: string, itemKey: IndexedDbRecord): Promise<Array<T> | string> => {
  return new Promise((resolve, reject) => {
    const store = getStore(objectStoreName, 'readonly')
    if (store) {
      const request = store.getAll()
      request.onsuccess = () => {
        const res = request.result.filter((item) => {
          return item.referrerUsername === itemKey
        })
        return resolve(res)
      }
      request.onerror = () => {
        return reject(indexedDbResponses.GET_OBJECT_FAIL)
      }
    }
  })
}

export const deleteDatabase = (dbName: string): Promise<string> => {
  return new Promise((resolve, reject) => {
    closeConnection()
    const request = indexedDB.deleteDatabase(dbName)
    request.onsuccess = () => {
      resolve(indexedDbResponses.DELETE_DB_SUCCESS)
    }
    request.onerror = () => {
      reject(indexedDbResponses.DELETE_DB_FAIL)
    }
    request.onblocked = () => {
      resolve(indexedDbResponses.DELETE_DB_FAIL)
    }
  })
}

export const closeConnection = (): void => {
  if (db) {
    db.close()
  }
}
