const lengthToFirstSlash = 2
const lengthToSecondSlash = 5
const dateFullLength = 10
const indexOfFirstSlash = lengthToFirstSlash - 1
const indexOfSecondSlash = lengthToSecondSlash - 1

export const noValueString = ''
export const slash = '/'
export const KeyPressValues = {
  '0': '0',
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  Backspace: 'Backspace',
  Unidentified: 'Unidentified',
  ArrowLeft: 'ArrowLeft',
  ArrowRight: 'ArrowRight',
  Pasted: 'Pasted',
}

const spliceString = (value: string, startingIndex: number, numOfCharsToRemove: number, valueToAdd: string): string => {
  return `${value.slice(0, startingIndex)}${valueToAdd}${value.slice(startingIndex + numOfCharsToRemove, value.length)}`
}

const formatSlashes = (value: string): string => {
  const valueAtFirstSlashPosition = value[lengthToFirstSlash]
  const valueAtSecondSlashPosition = value[lengthToSecondSlash]
  let newValue = value
  if (value.length > indexOfFirstSlash && valueAtFirstSlashPosition !== slash) {
    newValue = spliceString(value, lengthToFirstSlash, 0, slash)
  }
  if (value.length >= indexOfSecondSlash && valueAtSecondSlashPosition !== slash) {
    newValue = spliceString(newValue, lengthToSecondSlash, 0, slash)
  }
  return newValue
}

const formatDateInputString = (
  value: string,
  key: string,
  selectionStart: number,
  selectionEnd: number,
  valueToAdd?: string, // if pasted
): string => {
  let newValue = value || noValueString
  const hasSelectedSection = selectionStart !== selectionEnd
  switch (key) {
    case KeyPressValues.Unidentified:
    case KeyPressValues.Backspace: {
      let startingIndex = selectionStart
      if (!hasSelectedSection) {
        if (key === KeyPressValues.Unidentified) {
          return value
        }
        startingIndex--

        // Remove the slash and previous digit when deleting one character only - slashes are used as a mask and should be directly edited by users
        if (value[startingIndex] === '/') {
          startingIndex--
        }
      }

      const numberOfCharsToRemove = Math.max(selectionEnd - startingIndex, 0)
      const valueWithNewCharacter = spliceString(value, startingIndex, numberOfCharsToRemove, noValueString)
      const valueWithoutSlashes = valueWithNewCharacter.replace(/\//g, noValueString)
      newValue = formatSlashes(valueWithoutSlashes)
      return newValue
    }
    case KeyPressValues['0']:
    case KeyPressValues['1']:
    case KeyPressValues['2']:
    case KeyPressValues['3']:
    case KeyPressValues['4']:
    case KeyPressValues['5']:
    case KeyPressValues['6']:
    case KeyPressValues['7']:
    case KeyPressValues['8']:
    case KeyPressValues['9']:
      if (hasSelectedSection) {
        const numberOfCharsToRemove = selectionEnd - selectionStart
        const valueWithNewCharacter = spliceString(value, selectionStart, numberOfCharsToRemove, key)
        const valueWithoutSlashes = valueWithNewCharacter.replace(/\//g, noValueString)
        newValue = formatSlashes(valueWithoutSlashes)
        return newValue
      }

      const valueWithNewCharacter = spliceString(value, selectionStart, 0, key)
      const valueWithoutSlashes = valueWithNewCharacter.replace(/\//g, noValueString)

      newValue = formatSlashes(valueWithoutSlashes)
      newValue = newValue.substring(0, dateFullLength)
      return newValue
    case KeyPressValues.Pasted: {
      const numberOfCharsToRemove = selectionEnd - selectionStart
      const valueWithNewCharacter = spliceString(value, selectionStart, numberOfCharsToRemove, valueToAdd)
      const valueWithoutSlashes = valueWithNewCharacter.replace(/\//g, noValueString)
      newValue = formatSlashes(valueWithoutSlashes)
      newValue = newValue.substring(0, dateFullLength)
      return newValue
    }
    default:
      return newValue
  }
}

export default formatDateInputString
