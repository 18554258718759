import RequestError from '../../errors/RequestError'
import { BaseAction, DataAction, ErrorAction } from '../commons'
import { PatientImagesInfoAbortedData } from '../PatientStudiesActions/patientStudiesActions'
import { Dicom } from '../../domain/models/commons/Dicom'
import { PatientWithStudies } from '../../selectors/studies'

export const PATIENT_IMAGES_INFO = 'PATIENT_IMAGES/IMAGES_INFO'
export const PATIENT_IMAGES_INFO_ABORT = 'PATIENT_IMAGES/IMAGES_INFO_ABORT'
export const PATIENT_IMAGES_INFO_ABORTED = 'PATIENT_IMAGES/IMAGES_INFO_ABORTED'
export const PATIENT_IMAGES_INFO_SUCCESS = 'PATIENT_IMAGES/IMAGES_INFO_SUCCESS'
export const PATIENT_IMAGES_GET_IMAGES_DATA = 'PATIENT_IMAGES/GET_IMAGES_DATA'
export const PATIENT_IMAGES_GET_IMAGES_DATA_SUCCESS = 'PATIENT_IMAGES/GET_IMAGES_DATA_SUCCESS'
export const PATIENT_IMAGES_GET_IMAGES_DATA_FAIL = 'PATIENT_IMAGES/GET_IMAGES_DATA_FAIL'
export const PATIENT_IMAGES_CONSENT_EXPIRED = 'PATIENT_IMAGES/CONSENT_EXPIRED'
export const PATIENT_IMAGES_STORE_IMAGES_PARAMS = 'PATIENT_IMAGES/STORE_PARAMS'
export const PATIENT_IMAGES_RESET = 'PATIENT_IMAGES/RESET'

export type ImagesSection = {
  inteleviewerURL: string
}

export type ImagesInfoData = {
  patient: PatientWithStudies
}

export type ImagesData = {
  orderId: string
  accessionNumber: string
  referrer: string
  patientId: string
  accessible: boolean
}

export type ImagesStoreParams = {
  accessionNumber: string
}

export type ImagesInfoResponse = {
  data: Map<string, Array<Dicom>>
  patientId: string
}
export type ImageUrlsResp = Array<string>

export type ImagesUrls = {
  urls: ImageUrlsResp
}

export const imagesInfo = (data: ImagesInfoData): DataAction<ImagesInfoData> => ({
  type: PATIENT_IMAGES_INFO,
  data: data,
})

export const patientImagesGetImagesData = (imagesData: ImagesData): DataAction<ImagesData> => ({
  type: PATIENT_IMAGES_GET_IMAGES_DATA,
  data: imagesData,
})

export const patientImagesGetImagesInfoSuccess = (data: ImagesInfoResponse): DataAction<ImagesInfoResponse> => ({
  type: PATIENT_IMAGES_INFO_SUCCESS,
  data: data,
})
export const patientImagesGetImagesDataSuccess = (data: ImagesUrls): DataAction<ImagesUrls> => ({
  type: PATIENT_IMAGES_GET_IMAGES_DATA_SUCCESS,
  data: data,
})
export const patientImagesGetImagesDataFail = (): ErrorAction => ({
  type: PATIENT_IMAGES_GET_IMAGES_DATA_FAIL,
  data: {
    error: new RequestError('Error fetching images'),
    show: true,
  },
})

export const patientImagesGetPatientConsentExpired = (): BaseAction => ({
  type: PATIENT_IMAGES_CONSENT_EXPIRED,
})

export const patientImagesStoreParams = (imagesParams: ImagesStoreParams): DataAction<ImagesStoreParams> => ({
  type: PATIENT_IMAGES_STORE_IMAGES_PARAMS,
  data: imagesParams,
})

export const patientImagesReset = (): BaseAction => ({ type: PATIENT_IMAGES_RESET })

export const patientImagesInfoAbort = (): BaseAction => ({
  type: PATIENT_IMAGES_INFO_ABORT,
})

export const patientImagesInfoAborted = (
  patientId: string,
  imagesInfoAborted: boolean,
): DataAction<PatientImagesInfoAbortedData> => ({
  type: PATIENT_IMAGES_INFO_ABORTED,
  data: { patientId, imagesInfoAborted },
})
