const BOOK_IFRAME_DOMAIN = process.env['NEXT_PUBLIC_BOOK_TARGET'] || 'https://booki-med.com.au'

const urls = {
  BOOK: '/book-appointment',
  SEARCH_ORDERS_API: '/imedvisage/orders',
  ACCESS_IMAGES: '/resources/access-patient-images',
  BOOK_IFRAME: `${BOOK_IFRAME_DOMAIN}/iframe/home/select-exam`,
  RETURN_TO_IMED: 'https://i-med.com.au/',
  SUPPORT: 'https://i-med.com.au/support',
  FEEDBACK: 'https://i-med.com.au/referrer-enquiry',
  ORDER_REFERRAL_PADS: 'https://i-med.com.au/order-referral-pads',
  BOOK_AN_APPOINTMENT: 'https://i-med.com.au/book-appointment',
}

export default urls
