import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'
import {
  forgotPasswordFail,
  ForgotPasswordFormFieldType,
  forgotPasswordSuccess,
  forgotPasswordValidationFail,
  FORGOT_PASSWORD_SUBMIT,
} from '../../actions/ForgotPasswordActions/forgotPasswordActions'
import { BaseAction } from '../../actions/commons'
import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { validateString } from '../../components/forms/validations/ForgotPassword'

const FORGOT_PASSWORD_PATH = '/portal/reset-password'

export function* forgotPassword(): Generator<
  SelectEffect | Promise<ResponseWithStatus<void>> | PutEffect<BaseAction>,
  void,
  Record<ForgotPasswordFormFieldType, string> | ResponseWithStatus<void>
> {
  const forgotPasswordDetails = (yield select((state) => state.forgotPassword.form)) as Record<
    ForgotPasswordFormFieldType,
    string
  >
  const isValid = validateString('email', true, forgotPasswordDetails.email)
  if (!isValid) {
    yield put(forgotPasswordValidationFail())
    return
  }
  const recoverUsernameResponse = (yield post(
    `${process.env.REACT_APP_API_URL}${FORGOT_PASSWORD_PATH}`,
    {},
    {
      username: forgotPasswordDetails.email,
    },
  )) as ResponseWithStatus<void>
  if (recoverUsernameResponse.status === ResponseStatus.FAILURE) {
    yield put(forgotPasswordFail())
  } else {
    yield put(forgotPasswordSuccess())
  }
}

function* watchForgotPassword(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(FORGOT_PASSWORD_SUBMIT, forgotPassword)
}

const forgotPasswordSagas = [fork(watchForgotPassword)]

export default forgotPasswordSagas
