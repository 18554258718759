import { ForkEffect, put, select, takeEvery, cancel, takeLatest } from '@redux-saga/core/effects'
import { fork, join, PutEffect, SelectEffect } from 'redux-saga/effects'
import { isMobile } from 'react-device-detect'
import { AxiosResponse } from 'axios'
import { DataAction } from '../../actions/commons'
import {
  ImagesData,
  ImagesInfoData,
  ImageUrlsResp,
  PATIENT_IMAGES_GET_IMAGES_DATA,
  PATIENT_IMAGES_INFO,
  patientImagesGetImagesDataFail,
  patientImagesGetImagesDataSuccess,
  patientImagesGetImagesInfoSuccess,
  patientImagesGetPatientConsentExpired,
  PATIENT_IMAGES_INFO_ABORT,
  patientImagesInfoAborted,
} from '../../actions/PatientImagesActions/patientImagesActions'

import { get, getResponse, maybeGetWithCancellation, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { consentIsValid } from '../../services/breakGlassConsentValidityWatcher'
import { runWithRefresh } from '../RefreshAuthTokenSaga/refreshAuthTokenSaga'
import { OrderResponse } from '../../actions/PatientReportActions/patientReportActions'
import { Study } from '../../domain/models/commons/Study'
import { Dicom } from '../../domain/models/commons/Dicom'

const IMAGES_PATH = '/imedvisage/viewIvEv'
const IMAGES_INFO_PATH = '/imedvisage/orders/images'

function* splitArray(array: string[], n: number): Generator<string[]> {
  for (let i = 0; i < array.length; i += n) {
    yield array.slice(i, i + n)
  }
}

const MAX_ACCESSION_NUMBERS_TO_FETCH = 3

type GetImagesDataGenerator = Generator<
  SelectEffect | PutEffect | Promise<ResponseWithStatus<ImageUrlsResp | string | OrderResponse>> | Promise<boolean>,
  ResponseWithStatus<ImageUrlsResp>,
  AccessToken | ResponseWithStatus<ImageUrlsResp | string | OrderResponse>
>

function mapAccessionNumbers(accessionNumbers: string[]): string {
  let result = '?'
  for (let i = 0; i < accessionNumbers.length; i++) {
    if (i !== 0) {
      result += '&'
    }
    result += 'accessionNumbers=' + accessionNumbers[i]
  }
  return result
}

export function* getImagesInfo(accessionNumbers: string[], signal?: AbortSignal): Generator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken

  const path = `${process.env.REACT_APP_API_URL}${IMAGES_INFO_PATH}${mapAccessionNumbers(accessionNumbers)}`

  const getImagesInfoTask = yield fork(() =>
    maybeGetWithCancellation(
      path,
      {
        Authorization: `${jwt.type} ${jwt.token}`,
      },
      signal,
    ),
  )

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // type for task is not inferred properly somehow
  const result = (yield join(getImagesInfoTask)) as AxiosResponse<unknown>

  return getResponse(result, path)
}

function getModeParam(): { mode: string } {
  return { mode: isMobile ? 'mobile' : '' }
}

export function* getImagesData(action: DataAction<ImagesData>): GetImagesDataGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken

  if (!action.data.accessible && action.data.patientId) {
    const consentValid = yield consentIsValid(action.data.referrer, action.data.patientId)

    if (!consentValid) {
      yield put(patientImagesGetPatientConsentExpired())
      return
    }
  }

  function getAccessionNumberParam() {
    return action.data.accessionNumber && { accessionNumber: action.data.accessionNumber }
  }

  const images = (yield get(
    `${process.env.REACT_APP_API_URL}${IMAGES_PATH}`,
    { Authorization: `${jwt.type} ${jwt.token}` },
    {
      orderUri: `/order/${action.data.orderId}`,
      ...getAccessionNumberParam(),
      ...getModeParam(),
      ...(!action.data.accessible && { breakGlass: 'true' }),
    },
  )) as ResponseWithStatus<ImageUrlsResp>

  if (images.status === ResponseStatus.UNAUTHORIZED) {
    yield put(patientImagesGetImagesDataFail())
    return
  }

  return images
}

export function* getImagesDataSaga(
  action: DataAction<ImagesData>,
): Generator<unknown | PutEffect, void, AccessToken | ResponseWithStatus<ImageUrlsResp | string>> {
  const imagesURLs = (yield runWithRefresh(
    getImagesData,
    action,
  ) as GetImagesDataGenerator) as ResponseWithStatus<ImageUrlsResp>
  if (!imagesURLs) return
  if (imagesURLs.status === ResponseStatus.FAILURE) {
    yield put(patientImagesGetImagesDataFail())
    return
  }
  yield put(patientImagesGetImagesDataSuccess({ urls: imagesURLs.data }))
}

function isImagesAvailable(study: Study): boolean {
  const hasAborted = study.dicom?.data?.length === 0 && study.dicom?.state?.inProgress
  return (study.dicom === undefined || hasAborted) && !Study.isStudyInFuture(study)
}

function getAccessionNumbersArray(action: DataAction<ImagesInfoData>) {
  const accessionNumbers = action.data.patient.studies.filter(isImagesAvailable).flatMap(Study.getAccessionNumbers)
  return splitArray(accessionNumbers, MAX_ACCESSION_NUMBERS_TO_FETCH)
}

export function* getImagesInfoSaga(
  action: DataAction<ImagesInfoData>,
): Generator<unknown | PutEffect, void, AccessToken | ResponseWithStatus<Map<string, Array<Dicom>> | string>> {
  const abortController = new AbortController()
  const signal = abortController.signal
  const patientId = action.data.patient.id
  const tasks = []
  for (const accessionNumbers of getAccessionNumbersArray(action)) {
    const imagesInfoTask = yield fork(function* () {
      const imagesInfo = yield runWithRefresh(getImagesInfo, accessionNumbers, signal)

      if (!imagesInfo) return
      if (imagesInfo.status === ResponseStatus.FAILURE) {
        yield put(patientImagesGetImagesDataFail())
        return
      }
      yield put(patientImagesGetImagesInfoSuccess({ data: imagesInfo.data, patientId }))
    })

    tasks.push(imagesInfoTask)
  }

  // Image info requests will still run after navigating to the report page,
  // causing slow loading times on the report page.
  // This cancels out in-progress XHR requests for images to make subsequent requests run faster.
  yield takeLatest(PATIENT_IMAGES_INFO_ABORT, function* t() {
    yield put(patientImagesInfoAborted(patientId, true))

    for (const task of tasks) {
      yield cancel(task)
    }

    // Cancel XHR requests
    abortController.abort()
  })
}

function* watchGetImagesData(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(PATIENT_IMAGES_GET_IMAGES_DATA, getImagesDataSaga)
}

function* watchGetImagesInfo(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(PATIENT_IMAGES_INFO, getImagesInfoSaga)
}

const patientImagesSaga = [fork(watchGetImagesData), fork(watchGetImagesInfo)]

export default patientImagesSaga
