import text from '../_constants/text'
import { BaseAction, UpdateFieldAction } from './commons'

export const RECOVER_USERNAME = 'RECOVER_USERNAME/RECOVER_USERNAME'
export const RECOVER_USERNAME_SUCCESS = 'RECOVER_USERNAME/RECOVER_USERNAME_SUCCESS'
export const RECOVER_USERNAME_FAIL = 'RECOVER_USERNAME/RECOVER_USERNAME_FAIL'
export const RECOVER_USERNAME_UPDATE_DETAILS = 'RECOVER_USERNAME/RECOVER_UPDATE_DETAILS'
export const RECOVER_USERNAME_RESET = 'RECOVER_USERNAME/RECOVER_UPDATE_RESET'
export const RECOVER_USERNAME_VALID_EMAIL = 'RECOVER_USERNAME/VALID_EMAIL'
export const RECOVER_USERNAME_INVALID_EMAIL = 'RECOVER_USERNAME/INVALID_EMAIL'
export const RECOVER_USERNAME_INVALID_AHPRA = 'RECOVER_USERNAME/INVALID_AHPRA '
export const RECOVER_USERNAME_VALID_AHPRA = 'RECOVER_USERNAME/VALID_AHPRA '

export type RecoverUsernameFormFieldType = 'email' | 'ahpra'

export interface RecoverUsernameSuccessAction extends BaseAction {
  result: string
}

export const resetRecover = (): BaseAction => ({ type: RECOVER_USERNAME_RESET })
export const updateField = (
  type: RecoverUsernameFormFieldType,
  value: string,
): UpdateFieldAction<RecoverUsernameFormFieldType> => ({
  type: RECOVER_USERNAME_UPDATE_DETAILS,
  data: {
    [type]: value,
  },
})

export const recoverUsernameInvalidEmail = (): BaseAction => ({ type: RECOVER_USERNAME_INVALID_EMAIL })
export const recoverUsernameValidEmail = (): BaseAction => ({ type: RECOVER_USERNAME_VALID_EMAIL })
export const invalidAhpra = (): BaseAction => ({ type: RECOVER_USERNAME_INVALID_AHPRA })
export const validAhpra = (): BaseAction => ({ type: RECOVER_USERNAME_VALID_AHPRA })

export const recoverUsername = (): BaseAction => ({ type: RECOVER_USERNAME })

export const recoverUsernameSuccess = (): RecoverUsernameSuccessAction => ({
  type: RECOVER_USERNAME_SUCCESS,
  result: text.SUCCESS,
})
export const recoverUsernameFail = (): BaseAction => ({ type: RECOVER_USERNAME_FAIL })
