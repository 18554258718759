import React, { useCallback, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import Layout from '../../components/wrappers/Layout'
import text from '../../_constants/text'
import { Helmet } from 'react-helmet'
import { patientReportGetReport, patientReportReset } from '../../actions/PatientReportActions/patientReportActions'
import { PatientDetails, getPatientDetails } from '../../actions/PatientDetailsActions/patientDetailsActions'
import { useParams } from 'react-router'
import { RootState } from '../../reducers/store'
import PatientDetailsCard from './PatientDetailsCard'
import PatientReportCard from './PatientReportCard'
import Loader from '../../components/wrappers/Loader'
import PatientImagesCard from './PatientImagesCard'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import BreakGlassTimeoutModal from '../PatientSearchPage/BreakGlassTimeoutModal'
import { deleteConsent, getConsent } from '../../services/indexedDB/breakGlassConsentsStorage/consentsDB'
import { useNavigate } from 'react-router'
import { isAccessible } from '../../hooks/urlParams'
import PatientReferralCard from './PatientReferralCard'
import { Status } from '../../domain/models/commons/Report'
import { pageTitles } from '../../router/routes'
import { contactInfoReset } from '../../actions/contactInfoActions'
import { getAccountDetails } from '../../actions/accountAction'
import PageHeadingRow from '../../components/molecules/PageHeadingRow'
import PatientStudyDocumentsCard from './PatientStudyDocumentsCard'
import { analyticsTracker } from '../../services/analytics/analyticsTrackerService'
import { CustomDimensions, GAEventNames } from '../../domain/models/commons/GoogleAnalytics'
import { clearReferral } from '../../actions/ReferralActions/referralActions'
import { Attachment } from '../../domain/models/commons/Attachment'
import { Dicom } from '../../domain/models/commons/Dicom'
import { Study } from '../../domain/models/commons/Study'

interface PatientReportPageProps {
  getPatientData: (patientId: string) => void
  getAccountDetails: () => void
  getReport: (referrerUsername: string, patientId: string, orderId: string, accessible: boolean) => void
  resetPatientData: () => void
  clearReferral: () => void
  patientData: PatientDetails
  report: string
  reportUri: string
  reportStatus: Status
  breakGlass: boolean
  expired: boolean
  isLoading: boolean
  numImageSets: number
  referrerEmail: string
  referrerUsername: string
  attachments: Attachment[]
  images: Dicom[]
  token: AccessToken
}

interface PatientReportPageState {
  patientData: PatientDetails
  report: string
  reportUri: string
  reportStatus: Status
  breakGlass: boolean
  expired: boolean
  numImageSets: number
  isLoading: boolean
  referrerEmail: string
  images: Dicom[]
  token: AccessToken
  attachments: Attachment[]
  referrerUsername: string
}

const PatientReportPage: React.FC<PatientReportPageProps> = ({
  getPatientData,
  getAccountDetails,
  resetPatientData,
  getReport,
  patientData,
  breakGlass,
  expired,
  report,
  reportUri,
  reportStatus,
  isLoading,
  numImageSets,
  referrerEmail,
  token,
  referrerUsername,
  attachments,
  images,
}) => {
  const { patientId, orderId } = useParams<{ patientId: string; orderId: string; accessionNo: string }>()
  const accessible = isAccessible()
  const [showBreakGlassTimeoutModal, setShowBreakGlassTimeoutModal] = useState(false)
  const navigate = useNavigate()

  const handleConsentValidity = useCallback(
    async (link: string, target: string) => {
      if (target === '_blank') {
        window.open(link, target)
      } else {
        navigate(link)
      }
    },
    [setShowBreakGlassTimeoutModal, deleteConsent, getConsent, history, patientData, referrerUsername],
  )

  useEffect(() => {
    if (referrerUsername === '' || referrerEmail === '') {
      getAccountDetails()
    }
  }, [])

  useEffect(() => {
    // Fetching & preloading extra patient details for pre-filling e-referral form
    if (patientId) {
      getPatientData(patientId)
    }
    return resetPatientData
  }, [patientId])

  useEffect(() => {
    if (orderId && patientData.id) {
      getReport(referrerUsername, patientData.id, orderId, accessible)
    }
  }, [orderId, breakGlass, patientData, accessible])

  useEffect(() => {
    if (!!patientData && !!images.length && !!patientData.id) {
      analyticsTracker().track(GAEventNames.REPORT_PAGE, {
        [CustomDimensions.BREAK_GLASS]: breakGlass || false,
      })
    }
  }, [patientData, images])

  // Report can be either in a complete state or in a state where images are available and need to be analysed
  const isReportReady = reportStatus === Status.COMPLETE

  return (
    <Layout>
      <Helmet>
        <title>{pageTitles.patientReport}</title>
      </Helmet>
      <BreakGlassTimeoutModal show={expired} handleShow={null} patientName={patientData.name} navigate />
      <Loader isLoading={isLoading}>
        <div className="content-background content-background-min-height">
          <BreakGlassTimeoutModal
            show={showBreakGlassTimeoutModal}
            handleShow={() => setShowBreakGlassTimeoutModal(!showBreakGlassTimeoutModal)}
            patientName={patientData.name}
            navigate
          />
          <Container className="max-grid-width pb-5 content-padding" fluid>
            <PageHeadingRow title={`${text.STUDY_FOR} ${patientData.name}`} />
            <Row className="px-lg-0 justify-content-center">
              <Col xs={12} lg={8}>
                <PatientReportCard
                  report={report}
                  reportUri={reportUri}
                  referralPdfUri={Study.getReferralPdfUri(attachments)}
                  orderId={orderId}
                  token={token}
                  handleConsentValidityCb={handleConsentValidity}
                  breakGlass={accessible}
                  isReportReady={isReportReady}
                  isLoading={isLoading}
                />
              </Col>
              <Col xs={12} lg={4}>
                <PatientDetailsCard patientData={patientData} />
                <PatientReferralCard />
                <PatientStudyDocumentsCard documents={attachments} accessible={accessible} token={token} />
                {images.length > 0 && (
                  <PatientImagesCard
                    numImageSets={numImageSets}
                    patientId={patientId}
                    orderId={orderId}
                    accessionNo={images[0].accessionNumber}
                    accessible={accessible}
                    handleConsentValidityCb={handleConsentValidity}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </Loader>
    </Layout>
  )
}

const mapStateToProps = (state: RootState): PatientReportPageState => {
  const patientDetails = state?.router?.location?.state?.patientDetails
  const patientData = patientDetails || state?.patientDetails?.patientData
  const { token } = state.login
  const {
    report,
    numImageSets,
    patientGetControls,
    reportGetControls,
    reportUri,
    reportStatus,
    images,
    attachments,
    breakGlass,
    expired,
  } = state.patientReport
  const { displayName: referrerUsername, email: referrerEmail, submit: accountSubmit } = state.account
  return {
    referrerEmail,
    referrerUsername,
    images,
    patientData,
    report,
    reportUri,
    reportStatus,
    numImageSets,
    breakGlass,
    expired,
    token,
    attachments,
    isLoading: patientGetControls?.inProgress || reportGetControls?.inProgress || accountSubmit?.inProgress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAccountDetails: () => dispatch(getAccountDetails()),
  getPatientData: (patientId: string) => dispatch(getPatientDetails(patientId)),
  contactInfoReset: () => dispatch(contactInfoReset()),
  getReport: (displayName: string, patientId: string, orderId: string, accessible: boolean) =>
    dispatch(patientReportGetReport(displayName, patientId, orderId, accessible)),
  resetPatientData: () => dispatch(patientReportReset()),
  clearReferral: () => dispatch(clearReferral()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientReportPage)
