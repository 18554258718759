import React, { PropsWithChildren } from 'react'
import { Col, FormCheck, Row } from 'react-bootstrap'

type Props = {
  label: string
  inputName: string
  required?: boolean
  errorMessage?: string
  error?: boolean
  hideLabel?: boolean
  value: boolean
  className?: string
  onChangeCb: (name: string, value: boolean) => void
  testId?: string
}

const CheckboxField = ({
  label,
  inputName,
  required = false,
  hideLabel = false,
  errorMessage,
  error,
  value,
  className = '',
  onChangeCb,
  testId = 'CheckboxInput-FormCheck',
}: PropsWithChildren<Props>): JSX.Element => {
  const onClick = (name: string) => {
    const newValue = !value
    onChangeCb(name, newValue)
  }
  return (
    <div className={`p-0 checkbox-field ${className}`}>
      <Row className={`m-0 input ${error ? 'validation-error' : ''}`}>
        <Col className="p-0 justify-content-center">
          <FormCheck
            aria-label={label}
            data-testid={testId}
            onChange={() => onClick(inputName)}
            label={hideLabel ? '' : `${label} ${required ? '*' : ''}`}
            className={`${error ? 'error' : ''} checkbox-input p-0`}
            type="checkbox"
            checked={value}
            name={inputName}
          />
        </Col>
      </Row>
      {error && errorMessage && (
        <Row as="div" className="m-0 pl-3 pt-2">
          <p className="error">{errorMessage}</p>
        </Row>
      )}
    </div>
  )
}

export default CheckboxField
