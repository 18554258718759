export type ApiPractice = {
  practiceName: string
  practiceType:
    | 'General Practitioner'
    | 'Medical Specialist'
    | 'Dentist'
    | 'Physiotherapist'
    | 'Chiropractor'
    | 'Allied Health - Other'
    | 'Multi-Disciplinary'
  practicePhone: string
  practiceFax?: string
  providerNumber: string
  practiceAddress: string
  practiceStreet: string
  practiceSuburb: string
  practiceState: string
  practicePostcode: string
}

export type RegisterAccountApiRequest = {
  practices: ApiPractice[]
  password: string
  confirmPassword: string
  email: string
  firstName: string
  lastName: string
  ahpraNumber: string
  preferredPhone: string
  mobile: string
  accountType: AccountTypes
  userid?: string
}

export enum AccountTypes {
  NEW = 'NEW',
  PACS = 'PACS',
}

export enum CheckboxMap {
  CHECKED = 'YES',
  UNCHECKED = 'NO',
}
