import React, { FC, useEffect } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Layout from '../../components/wrappers/Layout'
import text from '../../_constants/text'
import ReferralForm from './ReferralForm'
import ReferralWrapper from './ReferralWrapper'
import StepIndicator from '../../components/molecules/StepIndicator'
import { ReferralSectionType } from '../../actions/ReferralActions/referralActions.types'
import { connect } from 'react-redux'
import {
  clearReferral,
  navigateBack,
  navigateToSection,
  submitReferral,
} from '../../actions/ReferralActions/referralActions'
import Success from './Success'
import { Referral } from '../../reducers/ReferralReducer/ReferralReducer.types'
import PageHeadingRow from '../../components/molecules/PageHeadingRow'
import { analyticsTracker } from '../../services/analytics/analyticsTrackerService'
import { CustomDimensions, EReferralIntervals, GAEventNames } from '../../domain/models/commons/GoogleAnalytics'

export const referralTitles: Record<ReferralSectionType, string> = {
  patientDetails: text.PATIENT_DETAILS,
  examinationAndClinicalDetails: text.EXAMINATION_AND_CLINICAL_DETAILS,
  referringPractitioner: text.REFERRING_PRACTITIONER,
  confirmAndSend: text.CONFIRM_AND_SEND,
}

interface ReferralPageProps {
  currentSection: ReferralSectionType
  referral: Referral
  navigateToSection: (sectionId: ReferralSectionType) => void
  navigateBack: (sectionId: ReferralSectionType) => void
  submitReferral: () => void
  clearReferral: () => void
  submissionSuccess: boolean
  submissionFail: boolean
}

const ReferralPage: FC<ReferralPageProps> = ({
  currentSection,
  referral,
  navigateToSection,
  navigateBack,
  submitReferral,
  clearReferral,
  submissionSuccess,
  submissionFail,
}) => {
  const heading = submissionSuccess ? text.REFERRAL_SENT : text.REFERRAL_TITLE
  const title = referralTitles[currentSection]

  useEffect(() => {
    analyticsTracker().track(GAEventNames.E_REFERRALS_PAGE, {
      [CustomDimensions.INTERVALS]: EReferralIntervals.STARTED,
    })
  }, [])

  useEffect(() => {
    return () => {
      clearReferral()
    }
  }, [])

  return (
    <Layout>
      <div className="d-flex content-background content-background-min-height pb-5">
        <Container className="max-grid-width pb-2 content-padding" fluid>
          <PageHeadingRow
            title={heading}
            isSuccess={submissionSuccess}
            lead={!submissionSuccess && text.REFERRAL_SUBTITLE}
          />
          <Row className="px-0 flex-column-reverse flex-lg-row">
            <Col xs={12} lg={8}>
              {submissionSuccess ? (
                <Success
                  clearForm={clearReferral}
                  patientName={referral.patientName}
                  patientMobile={referral.patientPhone}
                  hasSentPatientSMSNotification={referral.notifyPatient}
                />
              ) : (
                <ReferralWrapper
                  title={title}
                  currentSection={currentSection}
                  navigateToSection={navigateToSection}
                  navigateBack={navigateBack}
                  submitReferral={submitReferral}
                  submissionFail={submissionFail}
                >
                  <ReferralForm />
                </ReferralWrapper>
              )}
            </Col>
            <Col xs={12} lg={4}>
              {!submissionSuccess && (
                <StepIndicator
                  sectionIds={referralTitles}
                  navigateToPage={navigateBack}
                  currentSection={currentSection}
                />
              )}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { currentSection, referral, submit } = state.referral
  return { currentSection, referral, submissionSuccess: submit.success, submissionFail: submit.fail }
}

const mapDispatchToProps = (dispatch) => ({
  navigateToSection: (sectionId: ReferralSectionType) => dispatch(navigateToSection(sectionId)),
  navigateBack: (pageId: ReferralSectionType) => dispatch(navigateBack(pageId)),
  submitReferral: () => dispatch(submitReferral()),
  clearReferral: () => dispatch(clearReferral()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReferralPage)
