import { fieldsToValidate } from '../../../../reducers/RegisterAccountReducer/RegisterAccountReducer'
import { PracticeDetailsType } from '../../../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { validateString } from '../RegisterAccount'

export const validatePracticeForm = (practiceDetails: PracticeDetailsType): Record<string, boolean> => {
  const pageFieldsToValidate = fieldsToValidate['practiceDetails']
  const errors = {}
  pageFieldsToValidate.forEach((formEntry: string) => {
    if (!validateString(formEntry, true, practiceDetails[formEntry] as string)) {
      errors[formEntry] = true
    }
  })
  return errors
}
