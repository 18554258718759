import React, { PropsWithChildren } from 'react'
import Icon from '../../../components/atoms/Icon'

type AppointmentTableColumnLabelProps = PropsWithChildren & {
  onSortCb?: () => void
}

const AppointmentTableColumnLabel: React.FC<AppointmentTableColumnLabelProps> = ({ children, onSortCb }) => {
  return (
    <div className="d-flex flex-row search-table-col-label" data-testid="AppointmentTableColumnLabel">
      <span className="mr-2 text">{children}</span>
      {onSortCb && (
        <Icon className="icon" name="sort" onClick={onSortCb} data-testid="AppointmentTableColumnLabel-Icon" />
      )}
    </div>
  )
}

export default AppointmentTableColumnLabel
