import React from 'react'
import Row from 'react-bootstrap/Row'
import { RootState } from '../../../reducers/store'
import text from '../../../_constants/text'
import { connect } from 'react-redux'

interface EmptyStateProps {
  activeSearchType: string
  submitFail: boolean
  submitSuccess: boolean
  data: []
}

const breakGlassNoResultsMessage = (): JSX.Element => {
  return (
    <div className="col-lg-6 col-6 empty-state-no-results">
      <Row className="title justify-content-center">{text.SEARCH_NO_RESULTS_TITLE}</Row>
      <Row className="sub-title">{text.SEARCH_NO_RESULTS_SUBTITLE}</Row>
      <li>{text.SEARCH_NO_RESULTS_CHECK_NAME_DOB}</li>
      <li>{`${text.SEARCH_NO_RESULTS_CHECK_DATE} ${text.DATE_FORMAT}`}</li>
      <li>{text.SEARCH_NO_RESULTS_CHECK_NET}</li>
    </div>
  )
}

const myPracticesNoResultsMessage = (): JSX.Element => {
  return (
    <div className="col-lg-6 col-6 empty-state-no-results">
      <Row className="title justify-content-center">{text.SEARCH_NO_RESULTS_TITLE}</Row>
      <Row className="sub-title">{text.SEARCH_NO_RESULTS_SUBTITLE}</Row>
      <li>{text.SEARCH_NO_RESULTS_ADJUST_FILTERS}</li>
      <li>{`${text.SEARCH_NO_RESULTS_CHECK_DATE} ${text.DATE_FORMAT}`}</li>
      <li>{text.SEARCH_NO_RESULTS_CHECK_NET}</li>
      <li>{text.SEARCH_NO_RESULTS_CHECK_PROVIDER_NUMBER}</li>
    </div>
  )
}

export const getEmptyStateMessage = (
  activeSearchType: string,
  submitFail: boolean,
  submitSuccess: boolean,
  data: [],
): JSX.Element => {
  if (activeSearchType === text.SEARCH_REFERRED_WITHIN_MY_NETWORK || activeSearchType === text.SEARCH_REFERRED_BY_ME) {
    return myPracticesNoResultsMessage()
  } else if (activeSearchType === text.SEARCH_REFERRED_BY_ANYONE) {
    if (submitFail || (submitSuccess && data.length === 0)) {
      return breakGlassNoResultsMessage()
    } else {
      return <>{text.PATIENT_SEARCH_EMPTY_STATE_BREAK_GLASS}</>
    }
  }
}

const EmptyState: React.FC<EmptyStateProps> = ({ activeSearchType, submitFail, submitSuccess, data }) => (
  <Row data-testid="EmptyState" className="mx-0 px-0 py-5 w-100 d-flex justify-content-center">
    {getEmptyStateMessage(activeSearchType, submitFail, submitSuccess, data)}
  </Row>
)

const mapStateToProps = (state: RootState) => {
  const { activeSearchType, submit } = state.patientSearch
  const { byPatientId: studies } = state.patientStudies
  const { fail: submitFail, success: submitSuccess } = submit
  return { activeSearchType, submitFail, submitSuccess, data: Object.keys(studies) }
}

export default connect(mapStateToProps, null)(EmptyState)
