import React, { FC, PropsWithChildren } from 'react'
import { Card, Button } from 'react-bootstrap'
import { ReferralSectionType } from '../../../actions/ReferralActions/referralActions.types'
import { referralSectionsOrdered } from '../../../reducers/ReferralReducer/ReferralReducer'
import text from '../../../_constants/text'
import AlertBox from '../../../components/molecules/AlertBox'

interface ReferralWrapperProps extends PropsWithChildren {
  title: string
  currentSection: string
  navigateToSection: (sectionId: ReferralSectionType) => void
  navigateBack: (sectionId: ReferralSectionType) => void
  submitReferral: () => void
  submissionFail: boolean
}

const ReferralWrapper: FC<ReferralWrapperProps> = ({
  title,
  currentSection,
  navigateToSection,
  navigateBack,
  submitReferral,
  children,
  submissionFail,
}) => {
  const currentSectionIndex = referralSectionsOrdered.findIndex((page) => page === currentSection)
  const nextSection = referralSectionsOrdered[currentSectionIndex + 1]
  const prevSection = referralSectionsOrdered[currentSectionIndex - 1]
  const isLastSection = currentSectionIndex === referralSectionsOrdered.length - 1
  const isFirstSection = currentSectionIndex === 0
  return (
    <Card as="section" className="mb-3 mb-lg-4">
      <Card.Body className="p-3 p-lg-4">
        <Card.Title data-testid="title" as="h2">
          {title}
        </Card.Title>
        <Card.Subtitle data-testid="subtitle" as="p" className="mt-3 mb-4">
          {isLastSection ? text.REFERRAL_FORM_CONFIRM_AND_SEND : text.REFERRAL_FORM_SUBTITLE}
        </Card.Subtitle>
        {submissionFail && <AlertBox type="error" message={text.REFERRAL_SUBMIT_ERROR} className="mb-4" />}
        <form
          onSubmit={(e) => {
            e.preventDefault()
            submitReferral()
          }}
        >
          {children}
          <Button
            data-testid="backButton"
            className={`btn-light ${isFirstSection ? 'd-none' : ''} my-4 mx-2`}
            onClick={() => navigateBack(prevSection)}
          >
            {text.BACK}
          </Button>
          <Button
            data-testid="continueButton"
            className={`${isLastSection ? 'd-none' : ''} my-4`}
            onClick={() => navigateToSection(nextSection)}
          >
            {text.CONTINUE}
          </Button>
          <Button data-testid="sendReferralButton" className={isLastSection ? 'my-4' : 'd-none'} type="submit">
            {text.SEND_REFERRAL}
          </Button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default ReferralWrapper
