import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'
import {
  addPracticeSubmitFail,
  addPracticeSubmitSuccess,
  ADD_PRACTICE_SUBMIT,
  validateAddPracticeFormFail,
} from '../../actions/AddPractice/addPracticeActions'
import { validatePracticeForm } from '../../components/forms/validations/AddPractice'
import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import mapAddPracticeStateToApi from '../../mappers/mapAddPracticeStateToApi'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { PracticeDetailsType } from '../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { runWithRefresh } from '../RefreshAuthTokenSaga/refreshAuthTokenSaga'

const ADD_PRACTICE_PATH = '/practice/add'

type AddPracticeGenerator = Generator<
  SelectEffect | PutEffect | Promise<ResponseWithStatus<void>>,
  ResponseWithStatus<void>,
  ResponseWithStatus<void> | PracticeDetailsType | AccessToken | string
>

export function* addPracticeCall(): AddPracticeGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken
  const practiceDetails = (yield select((state) => state.addPractice.practiceForm)) as PracticeDetailsType
  const practitionerEmail = (yield select((state) => state.account.email)) as string
  const formErrors = validatePracticeForm(practiceDetails)
  if (Object.keys(formErrors).length !== 0) {
    yield put(validateAddPracticeFormFail(formErrors))
    return
  }
  const addPracticeResponse = (yield post(
    `${process.env.REACT_APP_API_URL}${ADD_PRACTICE_PATH}`,
    {
      Authorization: `${jwt.type} ${jwt.token}`,
    },
    mapAddPracticeStateToApi(practiceDetails, practitionerEmail),
  )) as ResponseWithStatus<void>
  return addPracticeResponse
}
export function* addPractice(): Generator<
  unknown | PutEffect,
  void,
  ResponseWithStatus<void> | PracticeDetailsType | AccessToken
> {
  const addPracticeResponse = (yield runWithRefresh(
    addPracticeCall,
  ) as AddPracticeGenerator) as ResponseWithStatus<void>
  if (!addPracticeResponse) return
  if (addPracticeResponse.status === ResponseStatus.SUCCESS) {
    yield put(addPracticeSubmitSuccess())
  } else {
    yield put(addPracticeSubmitFail())
  }
}

function* watchAddPractice(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(ADD_PRACTICE_SUBMIT, addPractice)
}

const addPracticeSaga = [fork(watchAddPractice)]

export default addPracticeSaga
