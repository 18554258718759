import React, { FC } from 'react'
import { Button } from 'react-bootstrap'
import Modal, { ModalHeaderIconTypes } from '../../../../components/molecules/Modal/Modal'
import text from '../../../../_constants/text'
interface TCAccessGrantedModalProps {
  show: boolean
  handleShow: () => void
}

const TCAccessGrantedModal: FC<TCAccessGrantedModalProps> = ({ show, handleShow }) => {
  return (
    <Modal
      onHide={handleShow}
      heading={text.TC_ACCESS_GRANTED_MODAL_TITLE}
      show={show}
      headerIcon={ModalHeaderIconTypes.CHECK}
    >
      <p className="mt-2">{text.TC_ACCESS_GRANTED_MODAL_DESC}</p>
      <div className="d-flex flex-row justify-content-end">
        <Button className="mb-0" data-testid="tc-access-granted-btn" onClick={handleShow}>
          {text.TC_ACCESS_GRANTED_MODAL_BTN}
        </Button>
      </div>
    </Modal>
  )
}

export default TCAccessGrantedModal
