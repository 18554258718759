import moment, { Moment } from 'moment'

export enum FieldValueType {
  // patient details
  patientName = 'patientName',
  patientDob = 'patientDob',
  patientGender = 'patientGender',
  patientPhone = 'patientPhone',
  patientEmail = 'patientEmail',
  patientStreet = 'patientStreet',
  patientSuburb = 'patientSuburb',
  patientPostcode = 'patientPostcode',
  patientState = 'patientState',
  patientCompensation = 'patientCompensation',
  // examination and clinical details
  examDetails = 'examDetails',
  clinicalDetails = 'clinicalDetails',
  patientPregnant = 'patientPregnant',
  ivContrastAllergy = 'ivContrastAllergy',
  ivRenal = 'ivRenal',
  ivDiabetes = 'ivDiabetes',
  // referring practitioner details
  doctorName = 'doctorName',
  doctorEmail = 'doctorEmail',
  doctorPracticeName = 'doctorPracticeName',
  doctorPracticeEmail = 'doctorPracticeEmail',
  doctorProviderNumber = 'doctorProviderNumber',
  doctorPhone = 'doctorPhone',
  doctorStreet = 'doctorStreet',
  doctorPostcode = 'doctorPostcode',
  doctorSuburb = 'doctorSuburb',
  doctorState = 'doctorState',
  ccDoctor = 'ccDoctor',
  // confirm and send details
  signatureDate = 'signatureDate',
}

export const validateString = (valueType: string, required: boolean, value: string): boolean => {
  if (!required && !value) {
    return true
  }
  if (required && !value) {
    return false
  }
  switch (valueType as FieldValueType) {
    case FieldValueType.patientName:
    case FieldValueType.doctorName:
      return /^[a-zA-Z\ .'-]*?\ [a-zA-Z\ .'-]*?$/.test(value)
    case FieldValueType.patientDob:
    case FieldValueType.signatureDate:
      return /[\d\d\/\d\d\/\d\d\d\d]/.test(value) && parseInt(value.slice(6)) >= 1900
    case FieldValueType.patientGender:
      return /^(Male|Female)$/.test(value)
    case FieldValueType.patientPhone:
    case FieldValueType.doctorPhone: {
      // Remove spaces
      const phone = value.replace(/ /g, '')
      return /^(0[23478][0-9]{2}|1[38]00)[0-9]{6}$/.test(phone)
    }
    case FieldValueType.doctorEmail:
    case FieldValueType.patientEmail:
    case FieldValueType.doctorPracticeEmail:
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value)
    case FieldValueType.patientStreet:
      return /[a-zA-Z]+/.test(value)
    case FieldValueType.patientSuburb:
    case FieldValueType.doctorSuburb:
      return /[a-zA-Z]+/.test(value)
    case FieldValueType.patientPostcode:
    case FieldValueType.doctorPostcode:
      return /^[0-9]{4}$/.test(value)
    case FieldValueType.patientState:
    case FieldValueType.doctorState:
      return /^(QLD|NSW|VIC|ACT|NT|WA|SA|TAS)$/.test(value)
    case FieldValueType.examDetails:
    case FieldValueType.clinicalDetails:
      return /[a-zA-Z]+/.test(value)
    case FieldValueType.patientCompensation:
    case FieldValueType.patientPregnant:
    case FieldValueType.ivContrastAllergy:
    case FieldValueType.ivRenal:
    case FieldValueType.ivDiabetes:
    case FieldValueType.ccDoctor:
      return /^(Yes|No)$/.test(value)
    default:
      return true
  }
}

export const validateDob = (dob: Moment, now: Moment = moment()): boolean => {
  // DOB must be in the past
  return dob < now
}
