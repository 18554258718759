import React, { FC } from 'react'
import { Button } from 'react-bootstrap'

import text from '../../../../_constants/text'
import pageContent from '../../../TermsOfUsePage/pageContent'
import Modal, { ModalHeaderSizes, ModalSizes } from '../../../../components/molecules/Modal/Modal'
import { TextSection as ITextSection } from '../../../../domain/models/sections/TextSection'
import TextSection from '../../../../components/molecules/TextSection'

interface TCAcceptanceModalProps {
  show: boolean
  onDeny: () => void
  tcAcceptanceCB: () => void
}

const TCAcceptanceModal: FC<TCAcceptanceModalProps> = ({ show, onDeny, tcAcceptanceCB }) => {
  const content: ITextSection[] = pageContent.CONTENT

  return (
    <Modal
      size={ModalSizes.LG}
      onHide={onDeny}
      heading={text.TC_ACCEPTANCE_TERMS_OF_USE}
      show={show}
      headingSize={ModalHeaderSizes.H1}
    >
      <div className="scrollable">
        {content && content.map((e) => <TextSection key={e.id} className="ps-0" {...e} />)}
      </div>
      <div className="d-flex flex-row justify-content-end ">
        <Button variant="light" className="mt-4 mx-3" data-testid="tc-acceptance-cancel" onClick={onDeny}>
          {text.CANCEL}
        </Button>
        <Button className="mt-4" data-testid="tc-acceptance-confirm" onClick={tcAcceptanceCB}>
          {text.TC_ACCEPTANCE_ACCEPT_BTN}
        </Button>
      </div>
    </Modal>
  )
}

export default TCAcceptanceModal
