import { Card, Col, Container, Row } from 'react-bootstrap'
import text from '../../../_constants/text'
import Separator from '../../atoms/Separator'
import React, { FunctionComponent, PropsWithChildren } from 'react'

type Props = {
  title?: string
  errorMessage?: string
  supportMessage?: string
} & PropsWithChildren

const ErrorCard: FunctionComponent<Props> = ({
  title = text.ERROR_TITLE,
  errorMessage = text.ERROR_MESSAGE,
  supportMessage = text.NEED_SUPPORT,
  children,
}) => {
  return (
    <Card as="section" className="my-3 my-lg-4">
      <Card.Body className="p-3 p-lg-4">
        <Card.Title className="mb-3" data-testid="title" as="h2">
          {title}
        </Card.Title>
        <Container className="d-flex flex-column p-0">
          <Row className="mb-3">
            <Col xs={12}>{errorMessage}</Col>
          </Row>
          <Row className="mb-3">
            <Col xs={12}>{children}</Col>
          </Row>
          <Row>
            <Separator />
            <p className="pt-4">{supportMessage}</p>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}

export default ErrorCard
