import { BaseAction, DataAction } from '../../actions/commons'
import { IMedJwtPayload, LOGIN_SUCCESS } from '../../actions/loginAction'

type FooterState = {
  isAdminLinks: boolean
}

export const initialFooterState = {
  isAdminLinks: false,
}

const footerReducer = (state = initialFooterState, action: BaseAction): FooterState => {
  if (action.type === LOGIN_SUCCESS) {
    const a = action as DataAction<IMedJwtPayload>
    switch (a.data.groups?.[0] as string) {
      case 'ROLE_ADMIN':
        return {
          ...state,
          isAdminLinks: true,
        }
      case 'ROLE_EDITOR':
        return {
          ...state,
          isAdminLinks: true,
        }
      case 'ROLE_CLEANUP':
        return {
          ...state,
          isAdminLinks: true,
        }
      case 'ROLE_CRM_ADMIN':
        return {
          ...state,
          isAdminLinks: true,
        }
      default:
        return {
          ...state,
          isAdminLinks: false,
        }
    }
  }
  return {
    ...state,
    isAdminLinks: state.isAdminLinks,
  }
}

export default footerReducer
