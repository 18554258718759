import React from 'react'
import InfoContent from '../../components/organisms/InfoContent'
import Layout from '../../components/wrappers/Layout'
import HeroSection from '../../components/molecules/HeroSection'
import pageContent from '../PrivacyPolicyPage/pageContent'
import useScrollToTop from '../../utils/scrollToTop'
import { Helmet } from 'react-helmet'
import { pageTitles } from '../../router/routes'

const PrivacyPolicyPage: React.FC = () => {
  useScrollToTop()
  return (
    <Layout>
      <Helmet>
        <title>{pageTitles.privacyPolicy}</title>
      </Helmet>
      <HeroSection
        image={pageContent.HERO_SECTION.heroImage}
        title={pageContent.HERO_SECTION.pageHeading}
        text={pageContent.HERO_SECTION.pageSummary}
      />
      <InfoContent content={pageContent.CONTENT} />
    </Layout>
  )
}

export default PrivacyPolicyPage
