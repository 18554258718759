import React, { ChangeEvent, PropsWithChildren } from 'react'
import InputLabel from '../../../atoms/InputLabel'
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap'

type OnChangeCb = (name: string, value: string | undefined) => void

type Props = {
  label: string
  inputName: string
  instructionMessage?: string
  required: boolean
  errorMessage?: string
  error?: boolean
  onChangeEvent: OnChangeCb
  value: number | string | undefined
  maxLength?: number
}

const TextareaField = ({
  label,
  inputName,
  instructionMessage,
  required,
  errorMessage,
  error,
  onChangeEvent,
  value,
  maxLength = 1000,
}: PropsWithChildren<Props>): JSX.Element => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const value = e.currentTarget.value
    const name = e.currentTarget.name
    onChangeEvent(name, value)
  }
  return (
    <Col className="p-0 mb-4 text-area-field">
      <Row className="m-0">
        <InputLabel label={`${label} ${required ? '*' : ''}`} />
        {instructionMessage && <p className="input instruction">{instructionMessage}</p>}
      </Row>
      <Row className={`m-0 input ${error ? 'validation-error' : ''}`}>
        <Col className="p-0 justify-content-center">
          <InputGroup>
            <FormControl
              data-testid="TextareaField-FormControl"
              onChange={onChange}
              className={`${error ? 'error' : ''} single-line-input p-0`}
              as="textarea"
              maxLength={maxLength}
              rows={7}
              name={inputName}
              value={value}
            />
          </InputGroup>
        </Col>
      </Row>
      {error && errorMessage && (
        <Row className="m-0 pl-3 pt-2">
          <p className="error">{errorMessage}</p>
        </Row>
      )}
    </Col>
  )
}

export default TextareaField
