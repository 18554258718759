import { UrlParams } from '../_constants/variables'
import { isMobile } from 'react-device-detect'

const routes: Record<string, string> = {
  home: '/',
  login: '/login',
  apply: '/apply',
  admin: '/admin',
  account: '/account',
  appointments: '/appointments',
  results: '/results',
  referral: '/referral',
  privacyPolicy: '/privacy-policy',
  termsOfUse: '/terms-of-use',
  forgotUsername: '/forgot-username',
  forgotPassword: '/forgot-password',
  patientReport: '/results/:patientId/:orderId',
  resetPassword: '/resetconfirm',
  patientImageSets: '/results/:patientId/:orderId/images',
  webcomrad: '/webcomrad',
  webcomradPasswordReset: '/webcomrad-password-reset',
  alfredMaterImaging: '/alfred-mater-imaging',
}

export const pageTitles: Record<string, string> = {
  home: 'Home',
  apply: 'Register Account',
  account: 'My Account',
  appointments: 'Upcoming Appointments',
  results: 'Search',
  privacyPolicy: 'Privacy Policy',
  termsOfUse: 'Terms of Use',
  forgotUsername: 'Forgot Username',
  forgotPassword: 'Forgot Password',
  patientImageSets: 'Patient Images',
  patientReport: 'Patient Report',
  resetPassword: 'Reset Password',
  notFound: 'Not Found',
  login: 'I-MED Online | For medical practitioners | Log in',
}
export const noFooterRoutes = [
  routes.apply,
  routes.termsOfUse,
  routes.privacyPolicy,
  routes.forgotUsername,
  routes.forgotPassword,
  routes.resetPassword,
]
export const noAuthFooterRoutes = [routes.home]

function includeAccessionNumber(accessible: boolean, accessionNo: string) {
  return (!accessible || isMobile) && accessionNo
}

export const getReportRoute = (
  patientId: string,
  orderId: string,
  accessible: boolean,
  accessionNo?: string,
): string => {
  if (includeAccessionNumber(accessible, accessionNo)) {
    return `/results/${patientId}/${orderId}?${UrlParams.BREAK_GLASS}=${!accessible}&${
      UrlParams.ACCESSION_NUMBER
    }=${accessionNo}`
  }
  return `/results/${patientId}/${orderId}?${UrlParams.BREAK_GLASS}=${!accessible}`
}

export const getImageSetsRoute = (
  patientId: string,
  orderId: string,
  accessible: boolean,
  accessionNo?: string,
): string => {
  if (includeAccessionNumber(accessible, accessionNo)) {
    return `/results/${patientId}/${orderId}/images?${UrlParams.BREAK_GLASS}=${!accessible}&${
      UrlParams.ACCESSION_NUMBER
    }=${accessionNo}`
  }
  return `/results/${patientId}/${orderId}/images?${UrlParams.BREAK_GLASS}=${!accessible}`
}

export default routes
