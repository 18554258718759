import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Image from 'react-bootstrap/Image'
import getSrcSet from '../../../utils/getImageSrcSet'
import { Image as IImage } from '../../../domain/models/commons/Image'
import formatDate from '../../../utils/formatDate'

interface ISection {
  title: string
  text?: string
  className?: string
  image: IImage
  date?: Date | string
}
const HeroSection: React.FC<ISection> = ({ title, text, image, className, date }) => {
  const imageSrcSet = getSrcSet(image)
  return (
    <>
      <Container fluid className={`d-lg-none m-0 ${className}`} data-testid="HeroSection-mobile">
        {image ? (
          <Row className="justify-content-lg-center">
            <Col className="pr-0 overflow-hidden">
              <Image
                className="hero-image mb-4"
                src={image.url}
                srcSet={imageSrcSet}
                sizes="100vw"
                alt={image.description}
              />
            </Col>
          </Row>
        ) : (
          <div className="mb-4" />
        )}
        <Row>
          <Col>
            {date && <p className="hero-date">{formatDate(date)}</p>}
            <h1>{title}</h1>
            <p className="lead">{text}</p>
          </Col>
        </Row>
      </Container>
      <Container
        as="section"
        fluid
        className={`my-0 hero-container-position-lg d-none d-lg-block ${className}`}
        data-testid="HeroSection-desktop"
      >
        <Row className="ml-0">
          <Col className="lead-padding max-lead-width">
            {date && <p className="hero-date">{formatDate(date)}</p>}
            <h1>{title}</h1>
            <p className="lead">{text}</p>
          </Col>
          {image && (
            <Col className="overflow-hidden">
              <Image
                height="320"
                className="hero-image-lg"
                src={image.url}
                srcSet={imageSrcSet}
                sizes="50vw"
                alt={image.description}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}
HeroSection.defaultProps = {
  className: '',
  text: '',
  date: '',
}

export default HeroSection
