import React, { FC } from 'react'
import { Button, Modal } from 'react-bootstrap'
import InternalLink from '../../../components/atoms/InternalLink'
import WarningAlert from '../../../components/atoms/WarningAlert'
import routes from '../../../router/routes'
import text from '../../../_constants/text'

interface BreakGlassTimeoutModalProps {
  patientName: string
  show: boolean
  handleShow?: () => void
  navigate?: boolean
}

const BreakGlassTimeoutModal: FC<BreakGlassTimeoutModalProps> = ({
  patientName,
  show,
  handleShow,
  navigate = false,
}) => {
  return (
    <Modal show={show} onHide={handleShow} backdrop="static" centered data-testid="break-glass-timeout-modal">
      <Modal.Body>
        <div className="d-flex flex-row justify-content-between mb-3">
          <WarningAlert className="mt-4 ml-4" />
        </div>
        <h2 className="mt-3">{text.ACCESS_TIMEOUT}</h2>
        <p className="mt-3">
          {text.TIMEOUT_LINE1}
          <b>{patientName}</b>
          {text.TIMEOUT_LINE2}
        </p>
        <p className="mt-3">{text.TIMEOUT_SUMMARY}</p>
        <div className="d-flex flex-row justify-content-end ">
          {navigate ? (
            <InternalLink href={routes.home} className="mb-0" data-testid="break-glass-timout-internallink">
              <Button className="mt-4 mb-0" data-testid="break-glass-timeout-back-button">
                {text.BACK_TO_PATIENT_SEARCH}
              </Button>
            </InternalLink>
          ) : (
            <Button className="mt-4 mb-0" data-testid="break-glass-timeout-back-button" onClick={handleShow}>
              {text.BACK_TO_PATIENT_SEARCH}
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BreakGlassTimeoutModal
