import moment from 'moment'
import { Search } from '../../reducers/commons/types'
import { formatSearchDate } from '../../utils/formatDate'
import text from '../../_constants/text'
import isEmpty from 'lodash/isEmpty'
import includes from 'lodash/includes'

type SearchGetParams = {
  search: string
  patientId: string
  patientUrNumber: string
  dob: string
  searchType: string
  orderStatus: string[]
  startDate: string
  endDate: string
  practices: string
}

export const formatDate = (date: Date): string => {
  return formatSearchDate(date, '-')
}

export const getPastDate = (numDaysAgo: number): Date => {
  const pastDate = new Date()
  pastDate.setDate(pastDate.getDate() - numDaysAgo)
  return pastDate
}

export const getFutureDate = (numDays: number): Date => {
  const futureDate = new Date()
  futureDate.setDate(futureDate.getDate() + numDays)
  return futureDate
}

const getDateRangeParams = (searchState: Search): Partial<SearchGetParams> => {
  const ret: Partial<SearchGetParams> = {}
  switch (searchState.dateRange) {
    case text.SEARCH_DATE_RANGE_TODAY:
      const dateToday = formatDate(new Date())
      ret.startDate = dateToday
      ret.endDate = dateToday
      break
    case text.SEARCH_DATE_RANGE_TOMORROW:
      const tomorrow = formatDate(getFutureDate(1))
      ret.startDate = tomorrow
      ret.endDate = tomorrow
      break
    case text.SEARCH_DATE_RANGE_YESTERDAY:
      const yesterday = formatDate(getPastDate(1))
      ret.startDate = yesterday
      ret.endDate = yesterday
      break
    case text.SEARCH_DATE_RANGE_PAST_WEEK:
      ret.startDate = formatDate(getPastDate(7))
      ret.endDate = formatDate(new Date())
      break
    case text.SEARCH_DATE_RANGE_NEXT_WEEK:
      ret.startDate = formatDate(new Date())
      ret.endDate = formatDate(getFutureDate(7))
      break
    case text.SEARCH_DATE_RANGE_PAST_2_WEEKS:
      ret.startDate = formatDate(getPastDate(14))
      ret.endDate = formatDate(new Date())
      break
    case text.SEARCH_DATE_RANGE_NEXT_2_WEEKS:
      ret.startDate = formatDate(new Date())
      ret.endDate = formatDate(getFutureDate(14))
      break
    case text.SEARCH_DATE_RANGE_PAST_MONTH:
      ret.startDate = formatDate(getPastDate(30))
      ret.endDate = formatDate(new Date())
      break
    case text.SEARCH_DATE_RANGE_NEXT_MONTH:
      ret.startDate = formatDate(new Date())
      ret.endDate = formatDate(getFutureDate(30))
      break
    case text.SEARCH_DATE_RANGE_PAST_6_MONTHS:
      ret.startDate = formatDate(getPastDate(180))
      ret.endDate = formatDate(new Date())
      break
    case text.SEARCH_DATE_RANGE_NEXT_6_MONTHS:
      ret.startDate = formatDate(new Date())
      ret.endDate = formatDate(getFutureDate(180))
      break
    case text.SEARCH_DATE_RANGE_PAST_YEAR:
      ret.startDate = formatDate(getPastDate(365))
      ret.endDate = formatDate(new Date())
      break
    case text.SEARCH_DATE_RANGE_NEXT_YEAR:
      ret.startDate = formatDate(new Date())
      ret.endDate = formatDate(getFutureDate(365))
      break
  }
  return ret
}

const getCommonParams = (searchState: Search): Partial<SearchGetParams> => {
  let ret: Partial<SearchGetParams> = {}
  if (searchState.dob) {
    ret.dob = moment(searchState.dob, text.DATE_FORMAT).format(text.DATE_SEARCH_FORMAT).toString()
  }
  if (searchState.orderStatus) {
    ret.orderStatus = searchState.orderStatus
  }
  if (searchState.dateRange) {
    ret = { ...ret, ...getDateRangeParams(searchState) }
  }
  if (
    'practices' in searchState &&
    !isEmpty(searchState.practices) &&
    !includes(searchState.practices, text.ALL_PRACTICES)
  ) {
    ret.practices = searchState.practices.join(',')
  }
  return ret
}

const inferSearchTypeParam = (searchState: Search): Partial<SearchGetParams> => {
  const ret: Partial<SearchGetParams> = {}
  if (searchState.searchType) {
    switch (searchState.searchType) {
      case text.SEARCH_REFERRED_BY_ANYONE:
        ret.searchType = text.SEARCH_TYPE_ALL
        break
      case text.SEARCH_REFERRED_WITHIN_MY_NETWORK:
        ret.searchType = text.SEARCH_TYPE_LINKED
        break
      case text.SEARCH_REFERRED_BY_ME:
        ret.searchType = text.SEARCH_TYPE_REFERRER
        break
    }
  }
  return ret
}

const map = (searchState: Search): Partial<SearchGetParams> => {
  let ret: Partial<SearchGetParams> = inferSearchTypeParam(searchState)
  ret = { ...ret, ...getCommonParams(searchState) }

  if (searchState.nameOrPatientId) {
    // I-MED referrers can input UR numbers in different ways - the regex to parse UR numbers is more permissive to allow for multiple UR number formats
    // e.g. 000000-BBH, 1111111CAB, 222222/QMTISA
    const urNumberGroup = searchState.nameOrPatientId.trim().match(/^([0-9]{6,8})[:\-\/]?([a-zA-Z]+)$/)
    if (urNumberGroup) {
      // UR number in Visage is of the form [0-9]{6,8}:[a-zA-Z]+ e.g. 0123456:CAB
      ret.patientUrNumber = `${urNumberGroup[1]}:${urNumberGroup[2]}`
    } else if (/^[0-9A-Z]{2}[\\.|\-].+$/.test(searchState.nameOrPatientId.trim())) {
      ret.patientId = searchState.nameOrPatientId
    } else {
      ret.search = searchState.nameOrPatientId.replace(',', ' ')
    }
  }
  return ret
}

export default map
