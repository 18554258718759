import React, { PropsWithChildren } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import { RegisterAccountPageType } from '../../../actions/registerAccountActions.types'
import text from '../../../_constants/text'
import InternalLink from '../../../components/atoms/InternalLink'
import routes from '../../../router/routes'
import { registeredAccountPagesOrdered } from '../../../reducers/RegisterAccountReducer/RegisterAccountReducer'
import AlertBox from '../../../components/molecules/AlertBox'

type CardWrapperProps = {
  currentPage: RegisterAccountPageType
  submissionFail: boolean
  navigateToPage: (pageId: RegisterAccountPageType) => void
  submitForm: () => void
} & PropsWithChildren
const CardWrapper: React.FC<CardWrapperProps> = ({
  currentPage,
  navigateToPage,
  children,
  submitForm,
  submissionFail,
}) => {
  const currentPageIndex = registeredAccountPagesOrdered.findIndex((x) => x === currentPage)
  const nextPage = registeredAccountPagesOrdered[currentPageIndex + 1]
  const isLastPage = currentPageIndex === registeredAccountPagesOrdered.length - 1
  return (
    <Card as="section" className="mb-3 mb-lg-4">
      <Card.Body className="p-3 p-lg-4">
        <Card.Title data-testid="title" as="h2">
          {text.REGISTER}
        </Card.Title>
        <Card.Subtitle data-testid="subtitle" as="p" className="mt-3">
          {isLastPage && text.USER_AGREEMENT_NOTICE}
          {!isLastPage && text.REGISTER_IMPERATIVE}
        </Card.Subtitle>
        {submissionFail && (
          <AlertBox
            type="error"
            message="Your registration could not be submitted. Please check your internet connection and try again."
          />
        )}

        <form
          onSubmit={(e) => {
            e.preventDefault()
            submitForm()
          }}
          className="mt-4"
        >
          {children}
          <Button data-testid="registerButton" className={`${isLastPage ? 'd-block' : 'd-none'} mb-4`} type="submit">
            {text.REGISTER_FOR_ACCESS}
          </Button>
          <Button
            data-testid="continueButton"
            className={`${isLastPage ? 'd-none' : 'd-block'} my-4`}
            onClick={() => navigateToPage(nextPage)}
          >
            {text.CONTINUE}
          </Button>
        </form>
        <div data-testid="signInLink" className="t-separator mt-4">
          <span> {text.ALREADY_HAVE_ACCOUNT}</span>
          <InternalLink href={routes.home} className="pt-4 px-2">
            {text.SIGN_IN}
          </InternalLink>
        </div>
      </Card.Body>
    </Card>
  )
}

export default CardWrapper
