import { indexedDbResponses } from '../indexedDB'
import { ConsentKey, getConsent } from '../indexedDB/breakGlassConsentsStorage/consentsDB'

export const consentIsValid = async (referrerUsername: string, patientId: string): Promise<boolean> => {
  const consentKey: ConsentKey = { referrerUsername: referrerUsername, patientId: patientId }
  const consent = await getConsent(consentKey)
  if (consent === undefined) {
    return false
  }
  return consent !== indexedDbResponses.GET_OBJECT_FAIL
}
