import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { Col, Collapse, Row } from 'react-bootstrap'
import moment from 'moment'
import { connect } from 'react-redux'
import { breakGlassConsentFail } from '../../../actions/errorActions'
import Icon from '../../../components/atoms/Icon'
import {
  ConsentKey,
  ConsentRecord,
  getConsent,
  insertConsent,
} from '../../../services/indexedDB/breakGlassConsentsStorage/consentsDB'
import icons from '../../../_constants/icons'
import text from '../../../_constants/text'
import BreakGlassModal from '../BreakGlassModal'
import StudyTable from '../StudyTable'
import { imagesInfo } from '../../../actions/PatientImagesActions/patientImagesActions'
import { storeSearchState } from '../../../actions/PatientRowSearchStateActions/PatientRowSearchStateActions'
import { getPatientStudies } from '../../../actions/PatientStudiesActions/patientStudiesActions'
import { patientSearchAll } from '../../../actions/PatientSearchActions/patientSearchActions'
import { isActiveSearchAllStudies, isActiveSearchWithinMyNetworkAndAllStudies } from '../../../selectors/search'
import { analyticsTracker } from '../../../services/analytics/analyticsTrackerService'
import { CustomDimensions, GAEventNames } from '../../../domain/models/commons/GoogleAnalytics'
import { Study } from '../../../domain/models/commons/Study'
import { PatientWithStudies } from '../../../selectors/studies'

interface PatientTableRowProps {
  abortedImagesInfoStudies: Study[]
  activeSearchType: string
  areExtraStudiesLoading: boolean
  breakGlassConsentFail: () => void
  displayName: string
  fetchPatientHiddenStudies: (patientId: string) => void
  getImagesData: (patient: PatientWithStudies) => void
  modalities: string[]
  modalityFiltersApplied: boolean
  openPatients: Map<string, boolean>
  patient: PatientWithStudies
  searchAllStudies: (patientId: string, dob: string) => void
  isSearchAllStudies: boolean
  isSearchWithinMyNetworkAndAllStudies?: boolean
  storeSearchState: (showResults: boolean, patientId: string) => void
}

const PatientTableRow: React.FC<PatientTableRowProps> = ({
  abortedImagesInfoStudies,
  activeSearchType,
  areExtraStudiesLoading,
  breakGlassConsentFail,
  displayName,
  fetchPatientHiddenStudies,
  getImagesData,
  openPatients,
  patient,
  searchAllStudies,
  isSearchAllStudies,
  isSearchWithinMyNetworkAndAllStudies,
  storeSearchState,
}) => {
  const isOpen = useMemo(() => {
    return openPatients.get(patient.id)
  }, [openPatients[patient.id]])

  const [showResults, setShowResults] = useState(isOpen)
  const [unlocked, setUnlocked] = useState(false)
  const [showBreakGlassConsentForm, setShowBreakGlassConsentForm] = useState(false)
  const [hasBrokenGlass, setHasBrokenGlass] = useState(false)
  const consentRecordKey: ConsentKey = { referrerUsername: displayName, patientId: patient.id }

  const patientRowRef = useRef(null)

  const isActiveSearchWithinMyNetwork =
    activeSearchType === text.SEARCH_REFERRED_WITHIN_MY_NETWORK || activeSearchType === text.SEARCH_REFERRED_BY_ME

  useEffect(() => {
    if (showResults) {
      storeSearchState(showResults, patient.id)
    }

    if (showResults && patient.studies.some((study) => !study.dicom)) {
      getImagesData(patient)
    }
  }, [showResults, patient.studies.length])

  useEffect(() => {
    // ImagesInfo requests might have been cancelled (e.g. when navigating to a given report page)
    // This ensures images info get fetched again.
    if (abortedImagesInfoStudies.length > 0) {
      getImagesData({
        ...patient,
        studies: abortedImagesInfoStudies,
      })
    }
  }, [])

  useEffect(() => {
    const shouldFetchHiddenStudies = isActiveSearchWithinMyNetwork && unlocked
    if (shouldFetchHiddenStudies) {
      fetchPatientHiddenStudies(patient.id)
    }
  }, [unlocked])

  const checkConsentExpiry = () => {
    getConsent(consentRecordKey)
      .then((consent: ConsentRecord) => {
        if (consent) {
          setUnlocked(true)
          setShowBreakGlassConsentForm(false)
        }
      })
      .catch(() => {
        breakGlassConsentFail()
      })
  }

  useEffect(() => {
    checkConsentExpiry()
  })

  useEffect(() => {
    if (openPatients.size === 0) {
      return
    }

    const lastOpenedPatientId = Array.from(openPatients.keys()).pop()
    if (lastOpenedPatientId === patient.id && patientRowRef.current.scrollIntoView) {
      patientRowRef.current.scrollIntoView()
    }
  }, [])

  useEffect(() => {
    if (showResults && isActiveSearchWithinMyNetwork) {
      fetchPatientHiddenStudies(patient.id)
    }
  }, [showResults])

  const handleBreakGlassConsent = useCallback(() => {
    insertConsent({ referrerUsername: displayName, patientId: patient.id, startTime: new Date() })
      .then(() => {
        setShowBreakGlassConsentForm(!showBreakGlassConsentForm)
        setUnlocked(true)
        setHasBrokenGlass(true)

        analyticsTracker().track(GAEventNames.BREAK_GLASS, {
          [CustomDimensions.DATE]: new Date().toLocaleString(),
        })
      })
      .catch(() => {
        breakGlassConsentFail()
      })
  }, [showResults, showBreakGlassConsentForm])

  const handleBreakGlass = () => {
    if (!unlocked) {
      return setShowBreakGlassConsentForm(!showBreakGlassConsentForm)
    }
  }

  const showStudyResults = () => setShowResults(!showResults)

  // Don't show view all studies button if active search is already a broad search - that is all the studies are already loaded - and there is no hidden studies to fetch
  const hasHiddenStudies = !unlocked && isSearchWithinMyNetworkAndAllStudies && patient.hiddenStudies > 0

  let showSearchAllStudies

  if (isSearchWithinMyNetworkAndAllStudies) {
    showSearchAllStudies = hasHiddenStudies
  } else {
    showSearchAllStudies = !isSearchAllStudies
  }

  return (
    <div
      data-testid="PatientTableRow-Wrapper"
      className={`search-table-row-wrapper ${showResults ? 'active' : ''}`}
      id={patient.id}
      ref={patientRowRef}
    >
      <BreakGlassModal
        show={showBreakGlassConsentForm}
        handleShow={() => setShowBreakGlassConsentForm(!showBreakGlassConsentForm)}
        consentBreakGlass={() => handleBreakGlassConsent()}
      />
      <Row data-testid="PatientTableRow" onClick={showStudyResults} className="search-table-row">
        <Col xs={4} className="search-table-col d-flex">
          <div className="me-2">
            <Icon className="pe-2" name={showResults ? icons.ARROW_UP : icons.ARROW_DOWN} />
          </div>
          <div>
            <b>{patient.name}</b>
          </div>
        </Col>
        <Col xs={4} className="search-table-col">
          <Row>
            <Col xs={5} className="search-table-col">
              {moment(patient.dob, text.DATE_SEARCH_FORMAT).format(text.DATE_FORMAT).toString()}
            </Col>
            <Col xs={7} className="search-table-col">
              {patient.id}
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="search-table-col">
          <Row>
            <Col xs={5} className="search-table-col">
              {moment(patient.latestStudyDate, text.DATE_SEARCH_FORMAT).format(text.DATE_FORMAT).toString()}
            </Col>
          </Row>
        </Col>
      </Row>
      <Collapse in={showResults}>
        <Row className="pb-2 search-table-row-study-table-wrapper" data-testid="PatientTableRow-StudyTable">
          {showResults ? (
            <StudyTable
              areExtraStudiesLoading={areExtraStudiesLoading}
              onBreakGlass={handleBreakGlass}
              patient={patient}
              shouldShowExtraStudiesRow={!unlocked || areExtraStudiesLoading || showSearchAllStudies}
              hasBrokenGlass={hasBrokenGlass}
              unlocked={unlocked}
              onViewAllStudies={showSearchAllStudies && searchAllStudies}
            />
          ) : (
            <div />
          )}
        </Row>
      </Collapse>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  const patientId = ownProps.patient?.id
  const { displayName } = state.account
  const { activeSearchType } = state.patientSearch
  const areExtraStudiesLoading = state.patientStudies?.submitById?.[patientId]?.inProgress
  const isSearchAllStudies = isActiveSearchAllStudies(state.patientSearch)
  const isSearchWithinMyNetworkAndAllStudies = isActiveSearchWithinMyNetworkAndAllStudies(state.patientSearch)
  const abortedImagesInfoStudies = state.patientStudies?.submitById?.[patientId]?.imagesInfoAborted
    ? state.patientStudies?.byPatientId?.[patientId].filter((study) => study.dicom?.state?.inProgress)
    : []
  const { openPatients } = state.patientRowSearchState

  return {
    abortedImagesInfoStudies,
    activeSearchType,
    areExtraStudiesLoading,
    displayName,
    isSearchAllStudies,
    isSearchWithinMyNetworkAndAllStudies,
    openPatients,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    breakGlassConsentFail: () => dispatch(breakGlassConsentFail()),
    storeSearchState: (showResults: boolean, patientId: string) =>
      dispatch(storeSearchState({ showResults, patientId })),
    getImagesData: (patient: PatientWithStudies) => dispatch(imagesInfo({ patient })),
    fetchPatientHiddenStudies: (patientId: string) =>
      dispatch(getPatientStudies(patientId, { searchType: text.SEARCH_REFERRED_BY_ANYONE })),
    searchAllStudies: (patientId: string, dob: string) => dispatch(patientSearchAll({ patientId, dob })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PatientTableRow)
