import React, { FC, PropsWithChildren } from 'react'
import { Modal as BootstrapModal } from 'react-bootstrap'
import icons from '../../../_constants/icons'
import text from '../../../_constants/text'
import Icon from '../../atoms/Icon'
import WarningAlert from '../../atoms/WarningAlert'
import { ReactComponent as Check } from './check_circle_24px.svg'

export enum ModalHeaderIconTypes {
  CHECK = 'check',
  WARNING = 'warning',
}

export enum ModalSizes {
  SM = 'sm',
  LG = 'lg',
  XL = 'xl',
}

export enum ModalHeaderSizes {
  H1 = 'h1',
  H2 = 'h2',
}

interface ModalProps extends PropsWithChildren {
  show: boolean
  heading: string
  onHide: () => void
  headingSize?: ModalHeaderSizes
  size?: ModalSizes
  headerIcon?: ModalHeaderIconTypes
}

type ModalHeadingIconProps = {
  type: ModalHeaderIconTypes
}

const ModalHeading2: FC<PropsWithChildren> = ({ children }) => (
  <h2 data-testid="modal-title" className="mt-auto">
    {children}
  </h2>
)
const ModalHeading1: FC<PropsWithChildren> = ({ children }) => (
  <h1 data-testid="modal-title" className="mt-auto">
    {children}
  </h1>
)
type ModalHeadingProps = {
  size?: ModalHeaderSizes
} & PropsWithChildren

const ModalHeading: FC<ModalHeadingProps> = ({ children, size = ModalHeaderSizes.H2 }) => {
  switch (size) {
    case ModalHeaderSizes.H1:
      return <ModalHeading1>{children}</ModalHeading1>
    default:
      return <ModalHeading2>{children}</ModalHeading2>
  }
}

const ModalHeadingIcon: FC<ModalHeadingIconProps> = ({ type }) => {
  switch (type) {
    case ModalHeaderIconTypes.CHECK:
      return <Check />
    case ModalHeaderIconTypes.WARNING:
      return <WarningAlert />
    default:
      return null
  }
}

const Modal: FC<ModalProps> = ({
  heading,
  show,
  onHide,
  children,
  headingSize = null,
  headerIcon = null,
  size = null,
}) => {
  return (
    <BootstrapModal size={size} show={show} onHide={onHide} centered data-testid="modal">
      <BootstrapModal.Header className="pb-0">
        <div className="w-100 d-flex flex-column">
          <div className="d-flex flex-row justify-content-between">
            {headerIcon ? (
              <ModalHeadingIcon type={headerIcon} />
            ) : (
              <ModalHeading size={headingSize}>{heading}</ModalHeading>
            )}
            <div className="c-pointer" onClick={onHide} data-testid="modal-close">
              <Icon name={icons.CLOSE} />
              <p className="blue bold">{text.CLOSE}</p>
            </div>
          </div>
          {headerIcon && <ModalHeading size={headingSize}>{heading}</ModalHeading>}
        </div>
      </BootstrapModal.Header>
      <BootstrapModal.Body className="p-4 pt-0">{children}</BootstrapModal.Body>
    </BootstrapModal>
  )
}

export default Modal
