const icons = {
  MENU: 'menu',
  SEARCH: 'search',
  CLOSE: 'close',
  PORTRAIT: 'portrait',
  EVENT: 'event',
  LOCATION: 'location_on',
  PHONE: 'phone',
  PRINT: 'print',
  MAIL: 'mail',
  ARROW_RIGHT: 'keyboard_arrow_right',
  ARROW_DOWN: 'keyboard_arrow_down',
  ARROW_DOWN_DISABLED: 'keyboard_arrow_down-disabled',
  ARROW_UP: 'keyboard_arrow_up',
  TODAY: 'today',
  TODAY_DISABLED: 'today-disabled',
  PERSON: 'person',
  PERSON_DISABLED: 'person-disabled',
  ARTICLE: 'article',
  DOWNLOAD: 'download',
  INFO: 'info',
  INDICATOR: 'indicator',
  INDICATOR_LIGHT: 'indicator-invalid',
  ERROR_OUTLINE: 'error_outline',
  PLACE: 'place',
  BACK: 'back',
  BACK_BLUE: 'back_blue',
  HIDE: 'input-hide',
  SHOW: 'input-show',
  MOVE_FROM: 'move-from',
  SORT: 'sort',
  LOCK: 'lock',
  LOCK_OPEN: 'lock-open',
  IMAGE_SEARCH: 'image-search',
  SUCCESS: 'success',
  ERROR: 'error',
  PRIMARY: 'primary',
  WARNING: 'warning',
  PDF: 'pdf',
  CHECK: 'check_circle',
}
export default icons
