import { BaseAction, UpdateFieldAction, ValidationErrorsAction } from '../commons'
export const RESET_PASSWORD_UPDATE_DETAILS = 'RESET_PASSWORD/UPDATE_DETAILS'
export const RESET_PASSWORD_SUBMIT = 'RESET_PASSWORD/SUBMIT'
export const RESET_PASSWORD_SUBMIT_SUCCESS = 'RESET_PASSWORD/SUBMIT_SUCCESS'
export const RESET_PASSWORD_SUBMIT_FAIL = 'RESET_PASSWORD/SUBMIT_FAIL'

export const RESET_PASSWORD_VALIDATE_SUCCESS = 'RESET_PASSWORD/VALIDATE_SUCCESS'
export const RESET_PASSWORD_VALIDATE_FAIL = 'RESET_PASSWORD/VALIDATE_FAIL'
export const RESET_PASSWORD_VALIDATE = 'RESET_PASSWORD/VALIDATE'
export const RESET_PASSWORD_RESET = 'RESET_PASSWORD/RESET'

export type ResetPasswordFieldType = 'code' | 'password' | 'secret'

export const updateField = (
  type: ResetPasswordFieldType,
  value: string,
): UpdateFieldAction<ResetPasswordFieldType> => ({
  type: RESET_PASSWORD_UPDATE_DETAILS,
  data: {
    [type]: value,
  },
})

export const resetPasswordSubmit = (): BaseAction => ({ type: RESET_PASSWORD_SUBMIT })
export const resetPasswordSuccess = (): BaseAction => ({
  type: RESET_PASSWORD_SUBMIT_SUCCESS,
})
export const resetPasswordFail = (): BaseAction => ({ type: RESET_PASSWORD_SUBMIT_FAIL })

export const validate = (): BaseAction => ({
  type: RESET_PASSWORD_VALIDATE,
})
export const resetPasswordReset = (): BaseAction => ({
  type: RESET_PASSWORD_RESET,
})

export const validateSuccess = (): BaseAction => ({
  type: RESET_PASSWORD_VALIDATE_SUCCESS,
})
export const validateFail = (
  errors: Partial<Record<ResetPasswordFieldType, boolean>>,
): ValidationErrorsAction<ResetPasswordFieldType> => ({
  type: RESET_PASSWORD_VALIDATE_FAIL,
  data: { errors },
})
