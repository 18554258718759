import React, { FC } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PageHeadingRow from '../../components/molecules/PageHeadingRow'
import SubmitWebcomradSuccess from '../WebcomradPage/Success'
import WebcomradWrapper from '../WebcomradPage/WebcomradWrapper'
import text from '../../_constants/text'
import {
  WebcomradPasswordResetErrorModel,
  WebcomradPasswordResetFormDataModel,
} from '../../reducers/WebcomradPasswordResetReducer/WebcomradPasswordResetReducer'
import WebcomradResetPasswordForm from './WebcomradResetPasswordForm'
import routes from '../../router/routes'

type Props = {
  submissionSuccess: boolean
  submissionFailed: boolean
  formData: WebcomradPasswordResetFormDataModel
  errors: WebcomradPasswordResetErrorModel
  onValueChange: (fieldName: string, value: string | boolean) => void
  onSubmit: () => void
  clearForm: () => void
}

const WebcomradResetPasswordPage: FC<Props> = ({
  submissionSuccess,
  submissionFailed,
  formData,
  errors,
  onValueChange,
  onSubmit,
  clearForm,
}) => {
  const heading = submissionSuccess ? text.WEBCOMRAD_PASSWORD_RESET_SENT_TITLE : text.WEBCOMRAD_PASSWORD_RESET_TITLE
  return (
    <div
      data-testid="webcomrad-password-reset-page"
      className="d-flex content-background content-background-min-height pb-5"
    >
      <Container className="max-grid-width pb-2 content-padding" fluid>
        <PageHeadingRow title={heading} isSuccess={submissionSuccess} />
        <Row className="px-0 flex-column-reverse flex-lg-row">
          <Col xs={12} lg={8}>
            {submissionSuccess ? (
              <SubmitWebcomradSuccess
                clearForm={clearForm}
                messageOne={text.WEBCOMRAD_PASSWORD_RESET_SUCCESS_MESSAGE_1}
                messageTwo={text.WEBCOMRAD_SUCCESS_MESSAGE_2}
                buttonText={text.BACK_TO_HOME}
                linkRoute={routes.home}
              />
            ) : (
              <WebcomradWrapper
                submitRegistration={onSubmit}
                submissionFail={submissionFailed}
                buttonText={text.SUBMIT}
                errorMessage={text.WEBCOMRAD_PASSWORD_RESET_SUBMIT_ERROR}
              >
                <WebcomradResetPasswordForm formData={formData} errors={errors} onValueChange={onValueChange} />
              </WebcomradWrapper>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WebcomradResetPasswordPage
