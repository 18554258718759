import { Study } from '../domain/models/commons/Study'
import ErrorBase from '../errors/ErrorBase'

export const searchActionCreator =
  (searchType: string) =>
  (actionName: string): string => {
    return `${searchType}_SEARCH/${actionName}`
  }

export type SearchFormFieldType = 'nameOrPatientId' | 'dob' | 'dateRange' | 'searchType'

export interface BaseAction {
  type: string
}

export interface UpdateFieldAction<T extends string> extends BaseAction {
  data: Partial<Record<T, string>>
}

export interface UpdateFilterAction<T extends string> extends BaseAction {
  data: Partial<Record<T, string>>
}

export interface ValidationErrorsAction<T extends string> extends BaseAction {
  data: { errors: Partial<Record<T, boolean>> }
}

export interface DataAction<T> extends BaseAction {
  data: T
}

export interface ErrorAction extends BaseAction {
  data: {
    show?: boolean
    error: ErrorBase
  }
}

export type SearchStudiesSuccessAction = DataAction<Study[]>

export type InitialSearch = { isInitialSearch: boolean }

export type SearchStudiesActions = DataAction<InitialSearch>

export function createUpdateFieldAction<T extends string>(action: string) {
  return (type: T, value: string): UpdateFieldAction<T> => ({
    type: action,
    data: {
      [type]: value,
    } as Record<T, string>,
  })
}

export function createSearchValidationErrorsAction<T extends string>(action: string) {
  return (errors: Partial<Record<T, boolean>>): ValidationErrorsAction<T> => ({
    type: action,
    data: { errors },
  })
}

export function createDataAction<T>(action: string, defaultValue?: T) {
  return (data: T = defaultValue): DataAction<T> => ({
    type: action,
    data,
  })
}

export function createErrorAction<T>(action: string, error: T) {
  return (): DataAction<{ error: T; show: boolean }> => ({
    type: action,
    data: { error, show: true },
  })
}
