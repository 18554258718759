import React, { useCallback, useState } from 'react'
import { Container } from 'react-bootstrap'
import StudyHeaderRow from '../StudyTableHeaderRow'
import StudyTableRow from '../StudyTableRow'
import { Sort, StudyRowKeys, StudyRow } from './index.types'
import StudyTableExtraStudiesRow from '../StudyTableExtraStudiesRow'
import { getImageSetsRoute, getReportRoute } from '../../../router/routes'
import { Study } from '../../../domain/models/commons/Study'
import { Procedure } from '../../../domain/models/commons/Procedure'
import { SortOrder } from '../../types'
import { PatientWithStudies } from '../../../selectors/studies'

interface StudyTableProps {
  areExtraStudiesLoading: boolean
  onBreakGlass: () => void
  unlocked: boolean
  hasBrokenGlass: boolean
  patient: PatientWithStudies
  shouldShowExtraStudiesRow: boolean
  onViewAllStudies: (patientId: string, dob: string) => void
}

const modalityReducer = (acc: string, p: Procedure): string => {
  if (acc === '') {
    return p.modality
  }
  return `${acc}, ${p.modality}`
}

const mapStudies = (input: Study[]): StudyRow[] =>
  input.map((study: Study): StudyRow => {
    const accessionNo = study.accessionNumber
    return {
      key: `${study.patient.id}-${study.date}-${accessionNo}-${study.uri}`,
      studyDate: study.date,
      modality: study.procedures.reduce(modalityReducer, ''),
      description: study.description,
      referrer: study.referrer.name,
      accessionNo: accessionNo,
      dicom: study.dicom,
      images: getImageSetsRoute(
        study.patient.uri.split('/')[2],
        study.uri.split('/')[2],
        study.accessible,
        accessionNo,
      ),
      report: getReportRoute(study.patient.uri.split('/')[2], study.uri.split('/')[2], study.accessible, accessionNo),
      locked: !study.accessible,
      status: study.status,
    }
  })

const sortStudyRows = (input: StudyRow[], sort: Sort): StudyRow[] => {
  return input.sort((a, b) => {
    if (a[sort.value] < b[sort.value]) {
      return sort.order === SortOrder.DESC ? 1 : -1
    }
    return sort.order === SortOrder.DESC ? -1 : 1
  })
}

const shouldShowStudy = (study: StudyRow, unlocked: boolean) => {
  return !study.locked || (study.locked && unlocked)
}
const filterAccessibleStudies = (unlocked: boolean) => (study) => shouldShowStudy(study, unlocked)

const StudyTable: React.FC<StudyTableProps> = ({
  areExtraStudiesLoading,
  onBreakGlass,
  patient,
  shouldShowExtraStudiesRow,
  unlocked,
  hasBrokenGlass,
  onViewAllStudies,
}) => {
  const [sort, setSort] = useState<Sort>({ order: SortOrder.DESC, value: 'studyDate' })

  const onSort = useCallback(
    (sortValue: StudyRowKeys) => {
      if (sort.value !== sortValue) {
        setSort({ order: SortOrder.DESC, value: sortValue })
      } else if (sort.order === SortOrder.DESC) {
        setSort({ order: SortOrder.ASC, value: sortValue })
      } else {
        setSort({ order: SortOrder.DESC, value: sortValue })
      }
    },
    [sort],
  )

  const studyRows = mapStudies(patient.studies)

  const accessibleStudies = studyRows.filter(filterAccessibleStudies(unlocked))

  const nbOfHiddenStudies = patient.hiddenStudies || patient.studies.length - accessibleStudies.length

  const shouldHighlightUnlockedRow = (row: StudyRow) => {
    return row.locked && unlocked && hasBrokenGlass
  }

  const handleViewAllStudies = () => onViewAllStudies(patient.id, patient.dob)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { studies, ...patientProp } = patient
  return (
    <Container className="max-grid-width p-3 py-0 study-table" fluid>
      <StudyHeaderRow onSort={onSort} />
      {sortStudyRows(accessibleStudies, sort).map((row: StudyRow) => (
        <StudyTableRow
          key={row.key}
          study={row}
          patient={patientProp}
          shouldHighlightUnlockedRow={shouldHighlightUnlockedRow(row)}
        />
      ))}
      {/* Notify the user that there are hidden studies that do not appear in the current search results */}
      {shouldShowExtraStudiesRow && (
        <StudyTableExtraStudiesRow
          extraStudies={nbOfHiddenStudies}
          isLoading={areExtraStudiesLoading}
          onBreakGlassClick={onBreakGlass}
          onViewAllStudiesClick={onViewAllStudies && handleViewAllStudies}
          showBreakGlass={!unlocked}
        />
      )}
    </Container>
  )
}

export default StudyTable
