import { Attachment } from '../../domain/models/commons/Attachment'
import { Dicom } from '../../domain/models/commons/Dicom'
import { Status } from '../../domain/models/commons/Report'
import RequestError from '../../errors/RequestError'
import { BaseAction, DataAction, ErrorAction } from '../commons'

export const PATIENT_REPORT_GET_REPORT = 'PATIENT_REPORT/GET_REPORT'
export const PATIENT_REPORT_GET_REPORT_SUCCESS = 'PATIENT_REPORT/GET_REPORT_SUCCESS'
export const PATIENT_REPORT_GET_REPORT_FAIL = 'PATIENT_REPORT/GET_REPORT_FAIL'
export const PATIENT_REPORT_CONSENT_EXPIRED = 'PATIENT_REPORT/CONSENT_EXPIRED'
export const PATIENT_REPORT_RESET = 'PATIENT_REPORT/RESET'

export type ReportData = {
  report: string
  dicom: Dicom[]
  reportUri: string
  procedures: Array<JSON>
  accessionNumber: string
  attachments: Array<Attachment>
  status: Status
}

export type OrderResponse = {
  reportUri: string
  accessionNumber: string
  procedures: Array<JSON>
  attachments: Array<Attachment>
  dicom: Array<Dicom>
  status: Status
}

export const patientReportConsentExpired = (): BaseAction => ({
  type: PATIENT_REPORT_CONSENT_EXPIRED,
})

export const patientReportReset = (): BaseAction => ({ type: PATIENT_REPORT_RESET })

export const patientReportGetReport = (
  referrer: string,
  patientId: string,
  orderId: string,
  accessible: boolean,
): DataAction<{ referrer: string; patientId: string; orderId: string; accessible: boolean }> => ({
  type: PATIENT_REPORT_GET_REPORT,
  data: { referrer, patientId, orderId, accessible },
})

export const patientReportGetReportSuccess = (reportData: ReportData): DataAction<ReportData> => ({
  type: PATIENT_REPORT_GET_REPORT_SUCCESS,
  data: reportData,
})

export const patientReportGetReportFail = (): ErrorAction => ({
  type: PATIENT_REPORT_GET_REPORT_FAIL,
  data: { error: new RequestError('Error getting report'), show: true },
})
