import { ForkEffect, put, select, takeEvery } from '@redux-saga/core/effects'
import { fork, SelectEffect } from 'redux-saga/effects'
import {
  AccountDetailsResponse,
  GET_ACCOUNT_DETAILS,
  getAccountDetailsFail,
  getAccountDetailsSuccess,
} from '../../actions/accountAction'
import { get, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { runWithRefresh } from '../RefreshAuthTokenSaga/refreshAuthTokenSaga'

const ACCOUNT_DETAILS_PATH = '/account/details'

type GetAccountDetailsGenerator = Generator<
  SelectEffect | Promise<ResponseWithStatus<AccountDetailsResponse>>,
  ResponseWithStatus<AccountDetailsResponse>,
  AccessToken | ResponseWithStatus<AccountDetailsResponse>
>

export function* getAccountDetails(): GetAccountDetailsGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken
  const accountDetailsResponse = (yield get(
    `${process.env.REACT_APP_API_URL}${ACCOUNT_DETAILS_PATH}`,
    { Authorization: `${jwt.type} ${jwt.token}` },
    {},
  )) as ResponseWithStatus<AccountDetailsResponse>
  return accountDetailsResponse
}

export function* getAccountDetailsSaga(): Generator<unknown, void, unknown> {
  const accountDetailsResponse = (yield runWithRefresh(getAccountDetails)) as ResponseWithStatus<AccountDetailsResponse>
  if (!accountDetailsResponse) return
  if (accountDetailsResponse.status === ResponseStatus.SUCCESS) {
    yield put(getAccountDetailsSuccess(accountDetailsResponse.data as AccountDetailsResponse))
    return
  }
  yield put(getAccountDetailsFail())
}

function* watchGetAccountDetails(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(GET_ACCOUNT_DETAILS, getAccountDetailsSaga)
}

const accountSagas = [fork(watchGetAccountDetails)]

export default accountSagas
