import { FC, useEffect } from 'react'
import AlfredMaterImagingPage from '../AlfredMaterImagingPage'
import { AlfredMaterImagingFieldName } from '../../../actions/AlfredMaterImagingActions/alfredMaterImagingActions.types'
import { connect } from 'react-redux'
import {
  clearAlfredMaterImagingForm,
  submitAlfredMaterImagingRegistration,
  updateAlfredMaterImagingFieldValue,
  validateAlfredMaterImagingFormField,
} from '../../../actions/AlfredMaterImagingActions/alfredMaterImagingActions'
import { FieldValue } from '../../../actions/registerAccountActions.types'
import {
  AlfredMaterImagingFormDataModel,
  AlfredMaterImagingFormErrorModel,
} from '../../../reducers/AlfredMaterImagingReducer/AlfredMaterImagingReducer'
import { SubmitControls } from '../../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { validateAlfredMaterImagingField } from '../../../components/forms/validations/AlfredMaterImaging'
import Layout from '../../../components/wrappers/Layout'
import Loader from '../../../components/wrappers/Loader'
import text from '../../../_constants/text'

interface AlfredMaterImagingProps {
  formData: AlfredMaterImagingFormDataModel
  submitRegistration: (formData: AlfredMaterImagingFormDataModel) => void
  validateField: (value: boolean, type: AlfredMaterImagingFieldName) => void
  clearForm: () => void
  updateFieldValue: (type: AlfredMaterImagingFieldName, value: FieldValue) => void
  errors: AlfredMaterImagingFormErrorModel
  submit: SubmitControls
}

const AlfredMaterImagingContainer: FC<AlfredMaterImagingProps> = ({
  formData,
  submitRegistration,
  clearForm,
  updateFieldValue,
  errors,
  submit,
  validateField,
}) => {
  useEffect(() => {
    return () => {
      clearForm()
    }
  }, [])

  const onValueChange = (fieldName: AlfredMaterImagingFieldName, value: FieldValue) => {
    const isValid = validateAlfredMaterImagingField(fieldName, value)
    validateField(isValid, fieldName)
    updateFieldValue(fieldName, value)
  }

  const onSubmit = (): void => {
    submitRegistration(formData)
  }

  return (
    <Layout>
      <Loader isLoading={submit.inProgress} message={text.SUBMITTING_FORM_MESSAGE}>
        <AlfredMaterImagingPage
          submissionSuccess={submit.success}
          submissionFailed={submit.fail}
          formData={formData}
          errors={errors}
          onValueChange={onValueChange}
          onSubmit={onSubmit}
          clearForm={clearForm}
        />
      </Loader>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { formData, errors, submit } = state.alfredMaterImaging
  return { formData, errors, submit }
}

const mapDispatchToProps = (dispatch) => ({
  submitRegistration: (formData) => dispatch(submitAlfredMaterImagingRegistration(formData)),
  updateFieldValue: (type: AlfredMaterImagingFieldName, value: FieldValue) =>
    dispatch(updateAlfredMaterImagingFieldValue(type, value)),
  validateField: (value: boolean, type: AlfredMaterImagingFieldName) =>
    dispatch(validateAlfredMaterImagingFormField(value, type)),
  clearForm: () => dispatch(clearAlfredMaterImagingForm()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlfredMaterImagingContainer)
