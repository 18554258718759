import React from 'react'
import { Container } from 'react-bootstrap'
import { shallowEqual } from 'react-redux'
import AppointmentTableHeaderRow from '../AppointmentTableHeaderRow'
import { selectPatientsWithNextAppointmentInfo } from '../../../selectors/studies'
import { PatientRowKeys } from '../../types'
import AppointmentTableRow from '../AppointmentTableRow'
import { RootState } from '../../../reducers/store'
import AppointmentTableLoadingRow from '../AppointmentTableLoadingRow'
import useSort, { sortRows } from '../../../hooks/useSort'
import { useAppSelector } from '../../../hooks/redux'

const AppointmentTable: React.FC = () => {
  const { sort, handleSort } = useSort<PatientRowKeys>('nextAppointmentDate')

  const patientsWithNextAppointmentDates = useAppSelector(selectPatientsWithNextAppointmentInfo, shallowEqual)
  const isLoading = useAppSelector((state: RootState) => state.appointmentSearch.submit.inProgress)

  const showLoader = !patientsWithNextAppointmentDates.length || isLoading
  const sortedPatientRows = sortRows(patientsWithNextAppointmentDates, sort)
  return (
    <Container
      data-testid="AppointmentTable"
      className="max-grid-width px-0 search-table overflow-x-scroll-mobile"
      fluid
    >
      <AppointmentTableHeaderRow onSort={handleSort} />
      {showLoader
        ? [1, 2, 3, 4].map((i) => <AppointmentTableLoadingRow key={`search-table-loading-row-${i}`} index={i} />)
        : sortedPatientRows.map((patientRow) => (
            <AppointmentTableRow key={`search-table-row-${patientRow.id}`} {...patientRow} />
          ))}
    </Container>
  )
}

export default AppointmentTable
