import React from 'react'
import Card from 'react-bootstrap/Card'
import { TextSection } from '../../../domain/models/sections/TextSection'
import RichText from '../../atoms/RichText'
import Icon from '../../atoms/Icon'

interface ISection extends TextSection {
  className?: string
  children?: React.ReactElement
}
const Section: React.FC<ISection> = ({ value, className, children }) => {
  return (
    <Card as="section" className={`my-3 my-lg-4 ${className}`}>
      <Card.Body className="p-3 p-lg-4">
        <Card.Title as="h2">
          {value.icon && <Icon name={value.icon} className="icon mr-1" />}
          {value.title}
        </Card.Title>
        <RichText html={value.text} />
        {children}
      </Card.Body>
    </Card>
  )
}

Section.defaultProps = {
  className: '',
}

export default Section
