import React, { FC } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import InternalLink from '../../../components/atoms/InternalLink'
import routes from '../../../router/routes'
import text from '../../../_constants/text'

interface SubmitReferralSuccessProps {
  clearForm: () => void
  patientName: string
  patientMobile: string
  hasSentPatientSMSNotification?: boolean
}

const SubmitReferralSuccess: FC<SubmitReferralSuccessProps> = ({
  clearForm,
  patientName,
  patientMobile,
  hasSentPatientSMSNotification = true,
}) => {
  return (
    <Card as="section" className="my-3 my-lg-4">
      <Card.Body className="p-3 p-lg-4">
        {hasSentPatientSMSNotification && (
          <Card.Text as="div" data-testid="subtitle" className="mt-3 success-registration">
            <p>
              {text.REFERRAL_SUCCESS_MESSAGE_1}
              {patientName}
              {text.OWNERSHIP}
              {text.REFERRAL_SUCCESS_MESSAGE_2}
              {patientMobile}
              {text.REFERRAL_SUCCESS_MESSAGE_3}
            </p>
          </Card.Text>
        )}
        <InternalLink href={routes.home}>
          <Button data-testid="continueButton" className="mt-3" onClick={clearForm}>
            {text.BACK_TO_PATIENT_SEARCH}
          </Button>
        </InternalLink>
      </Card.Body>
    </Card>
  )
}

export default SubmitReferralSuccess
