import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import { detect } from 'detect-browser'
import React from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import UnsupportedBrowser from './components/molecules/UnsupportedBrowser'
import { sendLog } from './services/monitoring/cloudwatch'
import { interceptRequestAndLogErrors } from './xhrRequestInterceptor'

const browser = detect()
const unsupportedBrowser = 'ie'

const root = createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>{browser.name === unsupportedBrowser ? <UnsupportedBrowser /> : <App />}</React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

// BUG-1450: Referrer Portal issue
// Referral form submission sometimes fails and we don't have visibility
// on errors and exceptions that may be triggered in the browser.
// This intercepts http requests & responses errors to help catch potential FE errors that may occur when the user submits a referral.
interceptRequestAndLogErrors()

// BUG-1450: Referrer Portal issue
// Referral form submission sometimes fails and we don't have visibility
// on errors and exceptions that may be triggered in the browser.
// This aims to help catch potential FE errors that may occur when the user submits a referral.
window.addEventListener('error', (e) => {
  // Get the error properties from the error event object
  const { message, filename, lineno, colno } = e

  // Catch errors and report to cloudwatch
  sendLog(`${message} [${filename} - ${lineno}:${colno}]`)

  e.preventDefault()
})
