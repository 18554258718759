import React, { FC, useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import { getAccountDetails } from '../../../actions/accountAction'
import {
  addPractice,
  preFillReferral,
  removePractice,
  updatePracticeField,
  updateReferralField,
} from '../../../actions/ReferralActions/referralActions'
import { ReferralField, ReferralSectionType } from '../../../actions/ReferralActions/referralActions.types'
import { Practice } from '../../../domain/models/commons/Practice'
import {
  PracticeDetailsErrorsType,
  PracticeDetailsType,
  Referral,
  ReferralErrors,
} from '../../../reducers/ReferralReducer/ReferralReducer.types'
import ConfirmAndSend from './Sections/ConfirmAndSend'
import ExaminationAndClinicalDetails from './Sections/ExaminationAndClinicDetails'
import PatientDetails from './Sections/PatientDetails'
import ReferringPractitioner from './Sections/ReferreringPractitioner'
import { PatientDetails as PatientDetailsModel } from '../../../actions/PatientDetailsActions/patientDetailsActions'
import moment from 'moment'
import { formatDoctorFullname, formatFullname } from '../../../utils/formatFullname'

interface ReferralFormProps {
  updateReferralField: (type: ReferralField, value: string | boolean) => void
  updatePracticeField: (index: number, type: string, value: string) => void
  preFillReferral: (prefillData: Partial<Referral>) => void
  getAccountDetails: () => void
  referral: Referral
  errors: ReferralErrors
  currentSection: ReferralSectionType
  practices: Practice[]
  referrerEmail: string
  hasAccountDetails: boolean
  patientData: PatientDetailsModel
  practicesDetails: PracticeDetailsType[]
  practicesDetailsErrors: PracticeDetailsErrorsType[]
  addPractice: () => void
  removePractice: (index: number) => void
}

const formatDOB = (date: string): string => {
  return moment(date).format('DD/MM/YYYY')
}

const ReferralForm: FC<ReferralFormProps> = ({
  updateReferralField,
  updatePracticeField,
  preFillReferral,
  getAccountDetails,
  referral,
  errors,
  currentSection,
  practices,
  referrerEmail,
  hasAccountDetails,
  patientData,
  practicesDetails,
  practicesDetailsErrors,
  addPractice,
  removePractice,
}) => {
  const preFillPracticeInformation = (value: string | boolean) => {
    const selectedPractice = practices.find((practice) => practice.practiceName === value)
    const practiceInformation = {
      doctorPracticeName: selectedPractice.practiceName,
      doctorProviderNumber: selectedPractice.providerNumber,
      doctorPhone: selectedPractice.phone1,
      doctorStreet: selectedPractice.address.line1,
      doctorSuburb: selectedPractice.address.city,
      doctorPostcode: selectedPractice.address.postcode,
      doctorState: selectedPractice.address.state,
    }
    preFillReferral(practiceInformation)
  }

  const onUpdateReferralField = (fieldName: string, value: string | boolean) => {
    if (fieldName === 'doctorPracticeName') {
      preFillPracticeInformation(value)
    } else {
      updateReferralField(fieldName as ReferralField, value)
    }
  }

  useEffect(() => {
    if (!hasAccountDetails) {
      getAccountDetails()
    }
    if (!!patientData.id) {
      const { name, address, city, dob, email, mobile, postcode, state } = patientData
      preFillReferral({
        patientName: formatFullname(name),
        patientDob: formatDOB(dob),
        patientEmail: email,
        patientPhone: mobile,
        patientPostcode: postcode,
        patientState: state,
        patientStreet: address,
        patientSuburb: city,
      })
    }
    if (!referral.doctorName || !referral.doctorEmail) {
      const selectedPractice = practices[0] as Practice

      if (selectedPractice !== undefined) {
        preFillReferral({
          doctorName: formatDoctorFullname(selectedPractice.fullName),
          doctorEmail: referrerEmail,
          doctorPracticeName: selectedPractice.practiceName,
          doctorProviderNumber: selectedPractice.providerNumber,
          doctorPhone: selectedPractice.phone1,
          doctorStreet: selectedPractice.address.line1,
          doctorSuburb: selectedPractice.address.city,
          doctorPostcode: selectedPractice.address.postcode,
          doctorState: selectedPractice.address.state,
        })
      }
    }
  }, [practices])

  switch (currentSection) {
    case 'patientDetails':
      return (
        <Container className="p-0">
          <PatientDetails referral={referral} onValueChange={onUpdateReferralField} errors={errors} />
        </Container>
      )
    case 'examinationAndClinicalDetails':
      return (
        <Container className="p-0">
          <ExaminationAndClinicalDetails referral={referral} onValueChange={onUpdateReferralField} errors={errors} />
        </Container>
      )
    case 'referringPractitioner':
      return (
        <Container className="p-0">
          <ReferringPractitioner
            referral={referral}
            onValueChange={onUpdateReferralField}
            errors={errors}
            practices={practices}
            practicesDetails={practicesDetails}
            practicesDetailsErrors={practicesDetailsErrors}
            onPracticeValueChange={updatePracticeField}
            addPractice={addPractice}
            removePractice={removePractice}
            data-testid="ReferringPractitioner"
          />
        </Container>
      )
    case 'confirmAndSend':
      return (
        <Container className="p-0">
          <ConfirmAndSend referral={referral} onValueChange={onUpdateReferralField} errors={errors} />
        </Container>
      )
    default:
      return (
        <Container className="p-0">
          <PatientDetails referral={referral} onValueChange={onUpdateReferralField} errors={errors} />
        </Container>
      )
  }
}

const mapStateToProps = (state) => {
  const { referral, errors, currentSection, practicesDetails, practicesDetailsErrors } = state.referral
  const { practices, email: referrerEmail, submit } = state.account
  const { patientData } = state.patientDetails
  const { success } = submit
  return {
    referral,
    errors,
    currentSection,
    practices,
    referrerEmail,
    hasAccountDetails: success,
    patientData,
    practicesDetails,
    practicesDetailsErrors,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAccountDetails: () => dispatch(getAccountDetails()),
  updateReferralField: (type, value) => dispatch(updateReferralField(type, value)),
  updatePracticeField: (index, type, value) => dispatch(updatePracticeField(index, type, value)),
  preFillReferral: (prefillData: Partial<Referral>) => dispatch(preFillReferral(prefillData)),
  addPractice: () => dispatch(addPractice()),
  removePractice: (index: number) => dispatch(removePractice(index)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ReferralForm)
