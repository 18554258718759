import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import SingleLineTextInputField from '../../../components/forms/fields/SingleLineTextInputField'
import text from '../../../_constants/text'
import DropdownSelect from '../../../components/forms/fields/DropdownSelect'
import CheckboxField from '../../../components/forms/fields/CheckboxField'
import InputLabel from '../../../components/atoms/InputLabel'
import ExternalLink from '../../../components/atoms/ExternalLink'
import {
  alfredMaterImagingRequiredFields,
  AlfredMaterImagingFormDataModel,
  AlfredMaterImagingFormErrorModel,
} from '../../../reducers/AlfredMaterImagingReducer/AlfredMaterImagingReducer'
import { alfredMaterImagingFieldNames } from '../../../components/forms/validations/AlfredMaterImaging'

interface AlfredMaterImagingFormModel {
  formData: AlfredMaterImagingFormDataModel
  errors: AlfredMaterImagingFormErrorModel
  onValueChange: (fieldName: string, value: string | boolean) => void
}

const AlfredMaterImagingForm: FC<AlfredMaterImagingFormModel> = ({ formData, errors, onValueChange }) => {
  return (
    <div data-testid="alfred-mater-imaging-form">
      <Row>
        <Col xs={12} lg={10}>
          <p data-testid="t-and-cs-message">
            {text.T_AND_CS_MESSAGE}{' '}
            <ExternalLink href={text.ALFRED_MATER_IMAGING_USER_AGREEMENT_LINK}>
              {text.ALFRED_MATER_IMAGING_USER_AGREEMENT_LINK}
            </ExternalLink>
          </p>
          <CheckboxField
            label={`${text.YES}${text.I_ACCEPT}${text.USER_ACCESS_AGREEMENT}`}
            inputName={alfredMaterImagingFieldNames.termsAndConditions}
            value={formData.termsAndConditions}
            onChangeCb={onValueChange}
            testId="t-and-cs-checkbox"
            required={alfredMaterImagingRequiredFields.termsAndConditions}
            error={errors.termsAndConditions}
            errorMessage={text.FIELD_REQUIRED}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.FIRST_NAME}
            inputName={alfredMaterImagingFieldNames.firstName}
            instructionMessage={text.FULL_NAME_INSTRUCTION}
            required={alfredMaterImagingRequiredFields.firstName}
            value={formData.firstName}
            errorMessage={`${text.FIRSTNAME_ERROR_MESSAGE} ${text.PLACEHOLDER_PATIENT_NAME}`}
            onChangeCb={onValueChange}
            error={errors.firstName}
            placeholder={text.PLACEHOLDER_PATIENT_FIRST_NAME}
            labelTestId="firstname-label"
            inputTestId="firstname-input"
          />
          <SingleLineTextInputField
            label={text.LAST_NAME}
            inputName={alfredMaterImagingFieldNames.lastName}
            required={alfredMaterImagingRequiredFields.lastName}
            value={formData.lastName}
            errorMessage={`${text.FULL_NAME_ERROR_MESSAGE} ${text.PLACEHOLDER_PATIENT_NAME}`}
            onChangeCb={onValueChange}
            error={errors.familyName}
            placeholder={text.PLACEHOLDER_PATIENT_FAMILY_NAME}
            labelTestId="lastname-label"
            inputTestId="lastname-input"
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.EXISTING_GENERIC_USERNAME_IF_APPLICABLE}
            inputName={alfredMaterImagingFieldNames.username}
            required={alfredMaterImagingRequiredFields.username}
            value={formData.username}
            errorMessage={`${text.USERNAME_ERROR_MESSAGE} ${text.PLACEHOLDER_PATIENT_NAME}`}
            onChangeCb={onValueChange}
            error={errors.username}
            labelTestId="username-label"
            inputTestId="username-input"
          />
          <SingleLineTextInputField
            label={text.EMAIL}
            inputName={alfredMaterImagingFieldNames.referrerEmail}
            required={alfredMaterImagingRequiredFields.referrerEmail}
            value={formData.referrerEmail}
            instructionMessage={text.REFERRER_EMAIL_INSTRUCTION}
            onChangeCb={onValueChange}
            error={errors.referrerEmail}
            errorMessage={`${text.EMAIL_ERROR_MESSAGE} ${text.PLACEHOLDER_PATIENT_EMAIL}`}
            placeholder={text.PLACEHOLDER_PATIENT_EMAIL}
            labelTestId="referrer-email-label"
            inputTestId="referrer-email-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.MOBILE_NUMBER}
            inputName={alfredMaterImagingFieldNames.mobileNumber}
            required={alfredMaterImagingRequiredFields.mobileNumber}
            value={formData.mobileNumber}
            errorMessage={`${text.MOBILE_NUMBER_ERROR_MESSAGE} ${text.PLACEHOLDER_MOBILE}`}
            onChangeCb={onValueChange}
            error={errors.mobileNumber}
            placeholder={text.PLACEHOLDER_MOBILE}
            labelTestId="mobile-number-label"
            inputTestId="mobile-number-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PROVIDER_NUMBER}
            inputName={alfredMaterImagingFieldNames.providerNumber}
            required={alfredMaterImagingRequiredFields.providerNumber}
            value={formData.providerNumber}
            errorMessage={`${text.PROVIDER_NUMBER_ERROR_MESSAGE} ${text.PRACTICE_NUMBER_PLACEHOLDER}`}
            onChangeCb={onValueChange}
            error={errors.providerNumber}
            placeholder={text.PRACTICE_NUMBER_PLACEHOLDER}
            labelTestId="provider-number-label"
            inputTestId="provider-number-input"
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.AHPRA}
            inputName={alfredMaterImagingFieldNames.ahpranumber}
            required={alfredMaterImagingRequiredFields.ahpranumber}
            value={formData.ahpranumber}
            onChangeCb={onValueChange}
            error={errors.ahpranumber}
            errorMessage={`${text.AHPRA_ERROR_MESSAGE} ${text.AHPRA_NUMBER_PLACEHOLDER}`}
            placeholder={text.AHPRA_NUMBER_PLACEHOLDER}
            labelTestId="AHPRA-number-label"
            inputTestId="AHPRA-number-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PRACTICE_NAME_HOSPITAL_NAME}
            inputName={alfredMaterImagingFieldNames.practiceName}
            required={alfredMaterImagingRequiredFields.practiceName}
            value={formData.practiceName}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.practiceName}
            placeholder={text.PRACTICE_NAME_PLACEHOLDER}
            labelTestId="practice-name-label"
            inputTestId="practice-name-input"
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.DEPARTMENT_OR_WARD_LABEL}
            inputName={alfredMaterImagingFieldNames.department}
            required={alfredMaterImagingRequiredFields.department}
            value={formData.department}
            errorMessage={text.DEPARTMENT_ERROR_MESSAGE}
            onChangeCb={onValueChange}
            error={errors.department}
            labelTestId="department-label"
            inputTestId="department-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={12}>
          <SingleLineTextInputField
            label={text.PRACTICE_ADDRESS}
            inputName={alfredMaterImagingFieldNames.practiceAddress}
            required={alfredMaterImagingRequiredFields.practiceAddress}
            value={formData.practiceAddress}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.practiceAddress}
            placeholder={text.PRACTICE_ADDRESS_PLACEHOLDER}
            labelTestId="practice-address-label"
            inputTestId="practice-address-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PRACTICE_SUBURB}
            inputName={alfredMaterImagingFieldNames.practiceSuburb}
            required={alfredMaterImagingRequiredFields.practiceSuburb}
            value={formData.practiceSuburb}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.practiceSuburb}
            placeholder={text.PRACTICE_SUBURB_PLACEHOLDER}
            labelTestId="practice-suburb-label"
            inputTestId="practice-suburb-input"
          />
        </Col>
        <Col xs={12} lg={3}>
          <DropdownSelect
            label={text.STATE}
            inputName={alfredMaterImagingFieldNames.practiceState}
            required={alfredMaterImagingRequiredFields.practiceState}
            value={formData.practiceState}
            onChangeCb={onValueChange}
            error={errors.practiceState}
            errorMessage={text.FIELD_REQUIRED}
            options={text.STATES}
            testId="practice-state-dropdown"
          />
        </Col>
        <Col xs={12} lg={3}>
          <SingleLineTextInputField
            label={text.POSTCODE}
            inputName={alfredMaterImagingFieldNames.practicePostcode}
            required={alfredMaterImagingRequiredFields.practicePostcode}
            value={formData.practicePostcode}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.practicePostcode}
            placeholder={text.PRACTICE_POSTCODE_PLACEHOLDER}
            labelTestId="practice-postcode-label"
            inputTestId="practice-postcode-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PRACTICE_DEPARTMENT_PHONE_NUMBER}
            inputName={alfredMaterImagingFieldNames.practicePhone}
            required={alfredMaterImagingRequiredFields.practicePhone}
            value={formData.practicePhone}
            errorMessage={`${text.PHONE_NUMBER_ERROR_MESSAGE} ${text.PRACTICE_PHONE_PLACEHOLDER}`}
            onChangeCb={onValueChange}
            error={errors.practicePhone}
            placeholder={text.PRACTICE_PHONE_PLACEHOLDER}
            labelTestId="practice-phone-label"
            inputTestId="practice-phone-input"
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.PRACTICE_DEPARTMENT_FAX_NUMBER}
            inputName={alfredMaterImagingFieldNames.practiceFax}
            required={alfredMaterImagingRequiredFields.practiceFax}
            value={formData.practiceFax}
            errorMessage={`${text.FAX_ERROR_MESSAGE} ${text.PRACTICE_PHONE_PLACEHOLDER}`}
            onChangeCb={onValueChange}
            error={errors.practiceFax}
            placeholder={text.PRACTICE_PHONE_PLACEHOLDER}
            labelTestId="practice-fax-label"
            inputTestId="practice-fax-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <DropdownSelect
            label={text.SPECIALITY_LABEL}
            inputName={alfredMaterImagingFieldNames.speciality}
            required={alfredMaterImagingRequiredFields.speciality}
            value={formData.speciality}
            onChangeCb={onValueChange}
            error={errors.speciality}
            errorMessage={text.FIELD_REQUIRED}
            options={text.SPECIALITY_OPTIONS}
            testId="speciality-dropdown"
          />
        </Col>
      </Row>
      {formData.speciality === 'Other' && (
        <Row>
          <Col xs={12} lg={6}>
            <SingleLineTextInputField
              label={text.OTHER_SPECIALITY_LABEL}
              inputName={alfredMaterImagingFieldNames.otherSpeciality}
              required={alfredMaterImagingRequiredFields.otherSpeciality}
              value={formData.otherSpeciality}
              errorMessage={text.OTHER_SPECIALITY_ERROR_MESSAGE}
              onChangeCb={onValueChange}
              error={errors.otherSpeciality}
              labelTestId="speciality-other-label"
              inputTestId="speciality-other-input"
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.SUB_SPECIALITY_LABEL}
            inputName={alfredMaterImagingFieldNames.subSpeciality}
            required={alfredMaterImagingRequiredFields.subSpeciality}
            value={formData.subSpeciality}
            errorMessage={text.OTHER_SPECIALITY_ERROR_MESSAGE}
            onChangeCb={onValueChange}
            error={errors.subSpeciality}
            labelTestId="subspeciality-label"
            inputTestId="subspeciality-input"
          />
        </Col>
      </Row>
      <div className="d-flex flex-column">
        <InputLabel label={`${text.OPERATING_SYSTEM_LABEL} *`} />
        <CheckboxField
          className="mb-2"
          label={text.OPERATING_SYSTEM_MAC_LABEL}
          required={false}
          inputName={alfredMaterImagingFieldNames.operatingSystemMac}
          onChangeCb={onValueChange}
          value={formData.operatingSystemMac}
          error={errors.operatingSystemMac && errors.operatingSystemPC}
          testId="operating-system-mac"
        />
        <CheckboxField
          className="mb-2"
          label={text.OPERATING_SYSTEM_PC_LABEL}
          required={false}
          inputName={alfredMaterImagingFieldNames.operatingSystemPC}
          onChangeCb={onValueChange}
          value={formData.operatingSystemPC}
          error={errors.operatingSystemMac && errors.operatingSystemPC}
          testId="operating-system-pc"
        />
        {errors.operatingSystemMac && errors.operatingSystemPC && (
          <Row className="m-0 pl-3" data-testid="operating-system-error-message">
            <p className="error">{text.OPERATING_SYSTEM_ERROR_MESSAGE}</p>
          </Row>
        )}
      </div>
      <Row>
        <Col xs={12} lg={6}>
          <DropdownSelect
            label={text.PRACTICE_MANAGEMENT_SYSTEM_LABEL}
            inputName={alfredMaterImagingFieldNames.practiceManagementSystem}
            required={alfredMaterImagingRequiredFields.speciality}
            value={formData.practiceManagementSystem}
            onChangeCb={onValueChange}
            error={errors.speciality}
            errorMessage={text.FIELD_REQUIRED}
            options={text.PRACTICE_MANAGEMENT_SYSTEM_OPTIONS}
            testId="practice-management-system-dropdown"
          />
        </Col>
      </Row>
      {formData.practiceManagementSystem === 'Other' && (
        <Row>
          <Col xs={12} lg={6}>
            <SingleLineTextInputField
              label={text.OTHER_PRACTICE_MANAGEMENT_SYSTEM_LABEL}
              inputName={alfredMaterImagingFieldNames.otherPracticeManagementSystem}
              required={alfredMaterImagingRequiredFields.otherPracticeManagementSystem}
              value={formData.otherPracticeManagementSystem}
              errorMessage={text.OTHER_PRACTICE_MANAGEMENT_SYSTEM_ERROR_MESSAGE}
              onChangeCb={onValueChange}
              error={errors.otherPracticeManagementSystem}
              labelTestId="practice-management-system-other-label"
              inputTestId="practice-management-system-other-input"
            />
          </Col>
        </Row>
      )}
    </div>
  )
}

export default AlfredMaterImagingForm
