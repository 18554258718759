import { Patient } from '../domain/models/commons/Patient'

export enum SortOrder {
  DESC = 'DESC',
  ASC = 'ASC',
}

export type PatientRowKeys = keyof Patient

export type Sort = {
  order: SortOrder
  value: PatientRowKeys
}
