import React, { FC } from 'react'

type LoadingBoxProps = {
  isNarrow?: boolean
}
const LoadingBox: FC<LoadingBoxProps> = ({ isNarrow = false }) => {
  return <div className={`loading-box${isNarrow ? ' narrow' : ''}`}></div>
}

export default LoadingBox
