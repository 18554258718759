export type ErrorProps = {
  message: string
  code: string
  type?: string
}

class ErrorBase extends Error {
  constructor(props: ErrorProps) {
    super(props.message)
    this.code = props.code
    this.type = 'ErrorBase'
    if (props.type) {
      this.type = props.type
    }
  }
  public type: string
  public code: string
}

export default ErrorBase
