import React, { FC, PropsWithChildren } from 'react'
import Modal from 'react-bootstrap/Modal'
import LoadingMessage from '../../atoms/LoadingMessage'

import './Loader.scss'

export type LoaderProps = {
  isLoading: boolean
  message?: string
} & PropsWithChildren

const Loader: FC<LoaderProps> = ({ children, isLoading, message }) => {
  return (
    <>
      <Modal data-testid="LoaderModal" show={isLoading} centered animation={false}>
        <div
          style={{ height: '300px', width: '100%' }}
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <div data-testid="Loader" className="lds-ellipsis mb-4">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
          <div data-testid="LoaderMessage">{message || <LoadingMessage />}</div>
        </div>
      </Modal>
      {children}
    </>
  )
}

export default Loader
