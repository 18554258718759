const visageAPIFullnamePatternRe = /([A-Za-z,\-]*),\s[A-Za-z]*\s([A-Za-z,\-]*)/i

// Format a Visage Patient Fullname (format: LASTNAME, Title Firstname) into a valid Snapscan E-Referral "Firstname Lastname" input
export const formatFullname = (fullname: string): string => {
  const match = fullname.match(visageAPIFullnamePatternRe)

  if (!match) {
    return fullname
  }

  const firstname = match[2]
  const lastname = match[1]

  return `${firstname} ${lastname}`
}

// Format a Visage Doctor Fullname (format: LASTNAME, Title Firstname) into a valid Snapscan E-Referral "Title Firstname Lastname" input
export const formatDoctorFullname = (fullname: string): string => {
  const match = fullname.match(visageAPIFullnamePatternRe)

  if (!match) {
    return fullname
  }

  const title = match[0]
    .substring(match[0].indexOf(',') + 1)
    .replace(match[2], '')
    .trim()
  const firstname = match[2]
  const lastname = match[1]

  return `${title} ${firstname} ${lastname}`
}
