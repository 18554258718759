import React, { Dispatch, useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import Layout from '../../components/wrappers/Layout'
import text from '../../_constants/text'
import EmptyState from '../../components/organisms/EmptyState'
import PatientSearchBar from './PatientSearchBar'
import { RootState } from '../../reducers/store'
import PatientTable from './PatientTable'
import { BaseAction } from '../../actions/commons'
import { logout } from '../../actions/loginAction'
import AcceptTC from './AcceptTC/AcceptTC'
import { pageTitles } from '../../router/routes'
import { Helmet } from 'react-helmet'
import { getServiceNotification } from '../../services/serviceNotificationService'
import AlertBox from '../../components/molecules/AlertBox'
import PageHeadingRow from '../../components/molecules/PageHeadingRow'
import { Referral } from '../../reducers/ReferralReducer/ReferralReducer.types'
import { preFillReferral } from '../../actions/ReferralActions/referralActions'
import { clearPatientDetails, PatientDetails } from '../../actions/PatientDetailsActions/patientDetailsActions'
import { analyticsTracker } from '../../services/analytics/analyticsTrackerService'
import { GAEventNames } from '../../domain/models/commons/GoogleAnalytics'
import { PatientWithStudies, selectPatientsWithStudies } from '../../selectors/studies'

interface PatientSearchPageProps {
  studies: PatientWithStudies[]
  isLoading: boolean
  patientData: PatientDetails
  referrerName: string
  clearPatientDetails: () => string
  preFillReferral: (prefillData: Partial<Referral>) => void
}

const PatientSearchPage: React.FC<PatientSearchPageProps> = ({
  studies,
  isLoading,
  patientData,
  clearPatientDetails,
  preFillReferral,
}) => {
  const serviceNotification = getServiceNotification()
  const shouldShowEmptyState = Object.keys(studies).length === 0 && !isLoading

  useEffect(() => {
    if (!!patientData.id) {
      preFillReferral({
        patientName: '',
        patientDob: '',
        patientEmail: '',
        patientPhone: '',
        patientPostcode: '',
        patientState: '',
        patientStreet: '',
        patientSuburb: '',
      })
      clearPatientDetails()
    }
    analyticsTracker().track(GAEventNames.RESULTS_PAGE, {})
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>{pageTitles.results}</title>
      </Helmet>
      <div className="content-background content-background-min-height">
        <AcceptTC />
        <Container className="px-xl-0 max-grid-width pb-5" fluid>
          {serviceNotification?.show && (
            <Row className="pt-3">
              <Col xs={12}>
                <AlertBox type={serviceNotification.type} message={serviceNotification.message} className="my-3" />
              </Col>
            </Row>
          )}
          <PageHeadingRow title={text.PATIENT_SEARCH} />
          <PatientSearchBar />
          {shouldShowEmptyState ? <EmptyState /> : <PatientTable studies={studies} isLoading={isLoading} />}
        </Container>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  const { submit } = state.patientSearch
  const { submit: tcSubmit } = state.acceptTC
  const { patientData } = state.patientDetails
  const { displayName: referrerName } = state.account

  const studiesFilteredByModalities = selectPatientsWithStudies(state, 'patient')

  return {
    studies: studiesFilteredByModalities,
    isLoading: submit.inProgress || tcSubmit.inProgress,
    patientData,
    referrerName,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  logout: () => dispatch(logout()),
  preFillReferral: (prefillData: Partial<Referral>) => dispatch(preFillReferral(prefillData)),
  clearPatientDetails: () => dispatch(clearPatientDetails()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientSearchPage)
