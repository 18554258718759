import React from 'react'
import icons from '../../../_constants/icons'

type IconProps = {
  name: string
  className?: string
  onClick?: () => void
}

const Icon: React.FC<IconProps> = ({ name, className, onClick = () => null }) => {
  const adjustedClassName = className ? ` ${className}` : className
  if (Object.values(icons).includes(name)) {
    return <span data-testid="SvgIcon" className={`icon-${name}${adjustedClassName}`} onClick={onClick} />
  }
  return (
    <i data-testid="FontIcon" className={`material-icons-outlined${adjustedClassName}`}>
      {name}
    </i>
  )
}

Icon.defaultProps = {
  className: '',
}

export default Icon
