import { all, AllEffect, ForkEffect } from 'redux-saga/effects'

import accountSaga from './AccountSaga/accountSaga'
import appointmentsSearchSaga from './AppointmentSearchSaga'
import contactInfoSagas from './ContactInfoSaga'
import forgotPasswordSagas from './ForgotPasswordSaga/forgotPasswordSaga'
import loginSaga from './LoginSaga'
import passwordSagas from './PasswordSaga'
import patientSearchSaga from './PatientSearchSaga'
import recoverUsernameSaga from './RecoverUsernameSaga'
import registerAccountSagas from './RegisterAccountSaga'
import addPracticeSaga from './AddPracticeSaga'
import resetPasswordSaga from './ResetPasswordSaga'
import patientReportSaga from './PatientReportSaga'
import patientImagesSaga from './PatientImagesSaga/PatientImagesSaga'
import patientDetailsSaga from './PatientDetailsSaga/PatientDetailsSaga'
import patientStudiesSaga from './PatientStudiesSaga/PatientStudiesSaga'
import referralSaga from './ReferralSaga/referralSaga'
import acceptTCSaga from './AcceptTCSaga/AcceptTCSaga'
import webcomradSaga from './WebcomradSaga'
import webcomradPasswordResetSaga from './WebcomradPasswordResetSaga'
import alfredMaterImagingSagas from './AlfredMaterImagingSaga'

export default function* rootSaga(): Generator<AllEffect<ForkEffect<void>>, void, unknown> {
  yield all([
    ...loginSaga,
    ...accountSaga,
    ...registerAccountSagas,
    ...recoverUsernameSaga,
    ...contactInfoSagas,
    ...passwordSagas,
    ...addPracticeSaga,
    ...forgotPasswordSagas,
    ...resetPasswordSaga,
    ...patientSearchSaga,
    ...patientReportSaga,
    ...patientImagesSaga,
    ...patientDetailsSaga,
    ...patientStudiesSaga,
    ...appointmentsSearchSaga,
    ...referralSaga,
    ...acceptTCSaga,
    ...webcomradSaga,
    ...webcomradPasswordResetSaga,
    ...alfredMaterImagingSagas,
  ])
}
