import { BaseAction } from '../../actions/commons'
import { submitStates } from '../commons/submitControls'
import { SubmitControls } from '../RegisterAccountReducer/RegisterAccountReducer.types'
import {
  ACCEPT_TC_NOT_ACCEPTED,
  ACCEPT_TC,
  ACCEPT_TC_DENIED,
  ACCEPT_TC_SUCCESS,
  ACCEPT_TC_FAIL,
} from '../../actions/AcceptTC/acceptTCActions'
import { LOGOUT_SUCCESS } from '../../actions/loginAction'

export type AcceptTCState = {
  submit: SubmitControls
  tcRequired: boolean
  tcAccepted: boolean
  tcDenied: boolean
}

export const acceptTCInitialState: AcceptTCState = {
  tcRequired: false,
  tcAccepted: false,
  tcDenied: false,
  submit: { ...submitStates.default },
}

const acceptTCReducer = (state: AcceptTCState = acceptTCInitialState, action: BaseAction): AcceptTCState => {
  switch (action.type) {
    case ACCEPT_TC_NOT_ACCEPTED: {
      return { ...state, tcRequired: true, tcDenied: false }
    }
    case ACCEPT_TC: {
      return {
        ...state,
        tcDenied: false,
        submit: { ...submitStates.inProgress },
        tcRequired: false,
      }
    }
    case ACCEPT_TC_DENIED: {
      return {
        ...state,
        tcDenied: true,
        tcRequired: false,
      }
    }
    case ACCEPT_TC_SUCCESS: {
      return {
        ...state,
        tcDenied: false,
        tcAccepted: true,
        submit: { ...submitStates.success },
      }
    }
    case ACCEPT_TC_FAIL: {
      return {
        ...state,
        submit: { ...submitStates.fail },
      }
    }
    case LOGOUT_SUCCESS:
      return { ...acceptTCInitialState }
    default:
      return state
  }
}

export default acceptTCReducer
