import { select, put, SelectEffect, PutEffect, take, TakeEffect } from 'redux-saga/effects'
import { BaseAction } from '../../actions/commons'
import {
  loginFail,
  JwtTokenWithRefresh,
  REFRESH_TOKEN_FINISH,
  refreshTokenStart,
  refreshTokenFinish,
  refreshTokenSuccess,
  logout,
  loginObtainedToken,
} from '../../actions/loginAction'
import { get, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { addLocalStorageValue, deleteLocalStorageValue, getLocalStorageValue } from '../../services/localStorageService'
import { LOCAL_REFRESH_KEY, LOCAL_TOKEN_KEY } from '../../_constants/variables'
export { default as runWithRefresh } from './runWithRefresh'

const LOGIN_PATH = '/portal/refresh-token'

export type RefreshAuthGenerator = Generator<
  TakeEffect | SelectEffect | Promise<ResponseWithStatus<JwtTokenWithRefresh>> | PutEffect<BaseAction>,
  boolean,
  ResponseWithStatus<JwtTokenWithRefresh> | string | boolean
>
export function* refreshAuth(): RefreshAuthGenerator {
  const isRefreshInProgress = (yield select((state) => state.login.refreshTokenSubmit.inProgress)) as boolean
  if (isRefreshInProgress) {
    yield take(REFRESH_TOKEN_FINISH)
    const wasRefreshSuccess = (yield select((state) => state.login.refreshTokenSubmit.success)) as boolean
    return wasRefreshSuccess
  }
  yield put(refreshTokenStart())
  let refreshToken = (yield select((state) => state.login.refreshToken)) as string
  if (!refreshToken) {
    refreshToken = getLocalStorageValue(LOCAL_REFRESH_KEY) as string
  }
  const loginResponse = (yield get<JwtTokenWithRefresh>(
    `${process.env.REACT_APP_API_URL}${LOGIN_PATH}`,
    {},
    { refreshToken: refreshToken },
  )) as ResponseWithStatus<JwtTokenWithRefresh>

  if (loginResponse.status === ResponseStatus.FAILURE || loginResponse.status === ResponseStatus.UNAUTHORIZED) {
    deleteLocalStorageValue(LOCAL_TOKEN_KEY)
    deleteLocalStorageValue(LOCAL_REFRESH_KEY)
    yield put(loginFail())
    yield put(logout(true))
    yield put(refreshTokenFinish())
    return false
  }
  addLocalStorageValue(LOCAL_TOKEN_KEY, loginResponse.data.token)
  addLocalStorageValue(LOCAL_REFRESH_KEY, loginResponse.data.refreshToken)
  yield put(loginObtainedToken(loginResponse.data))
  yield put(refreshTokenSuccess())
  yield put(refreshTokenFinish())
  return true
}

export default refreshAuth
