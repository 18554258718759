import React, { PropsWithChildren } from 'react'
import Header from '../../organisms/Header'

const FooterlessLayout: React.FC<PropsWithChildren> = ({ children }) => (
  <>
    <Header />
    <main data-testid="main" className="min-vh-100">
      {children}
    </main>
  </>
)

export default FooterlessLayout
