export type SubmitControls = {
  inProgress: boolean
  success: boolean
  fail: boolean
}

export type SubmitStates = {
  inProgress: SubmitControls
  success: SubmitControls
  fail: SubmitControls
  default: SubmitControls
}

export const submitStates: SubmitStates = {
  inProgress: { success: false, fail: false, inProgress: true },
  success: { success: true, fail: false, inProgress: false },
  fail: { success: false, fail: true, inProgress: false },
  default: { success: false, fail: false, inProgress: false },
}
