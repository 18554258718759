import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import CheckboxField from '../../../../../components/forms/fields/CheckboxField'
import DOBInputField from '../../../../../components/forms/fields/DOBInputField'
import SingleLineTextInputField from '../../../../../components/forms/fields/SingleLineTextInputField'
import { Referral, ReferralErrors } from '../../../../../reducers/ReferralReducer/ReferralReducer.types'
import text from '../../../../../_constants/text'

interface PatientDetailsProps {
  onValueChange: (name: string, value: string | boolean) => void
  referral: Referral
  errors: ReferralErrors
}

const ExaminationAndClinicalDetails: FC<PatientDetailsProps> = ({ onValueChange, referral, errors }) => {
  return (
    <>
      <Row>
        <b className="mb-2">{text.SIGNATURE}</b>
        <Col xs={12} lg={7}>
          <SingleLineTextInputField
            label={text.FULL_NAME}
            inputName="signatureName"
            required={false}
            value={referral.signatureName}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.signatureName}
            placeholder={text.DR_PLACEHOLDER}
          />
        </Col>
        <Col xs={5} lg={4}>
          <DOBInputField
            label={text.DATE}
            inputName="signatureDate"
            required={false}
            value={referral.signatureDate}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.signatureDate}
            placeholder={text.DATE}
          />
        </Col>
      </Row>
      <Row className="mb-1">
        <Col xs={12}>
          <CheckboxField
            label={text.HEALTH_INSURANCE_ACT}
            required={false}
            inputName="signatureEntitled"
            onChangeCb={onValueChange}
            value={referral.signatureEntitled}
            error={errors.signatureEntitled}
            errorMessage={text.FIELD_REQUIRED}
          />
        </Col>
      </Row>
      <div className="d-flex flex-column">
        <b className="mb-2">{text.ADDITIONAL_OPTIONS}</b>
        <CheckboxField
          className="mb-2"
          label={text.SEND_EMAIL_COPY}
          required={false}
          inputName="copyToMe"
          onChangeCb={onValueChange}
          value={referral.copyToMe}
          error={errors.copyToMe}
        />
        <CheckboxField
          className="mb-2"
          label={text.SEND_PRACTICE_EMAIL_COPY}
          required={false}
          inputName="copyToPractice"
          onChangeCb={onValueChange}
          value={referral.copyToPractice}
          error={errors.copyToPractice}
        />
        <CheckboxField
          className="mb-2"
          label={text.PATIENT_NOTIFICATION}
          required={false}
          inputName="notifyPatient"
          onChangeCb={() => onValueChange('notifyPatient', !referral.notifyPatient)}
          value={!referral.notifyPatient}
          error={errors.notifyPatient}
        />
      </div>
    </>
  )
}

export default ExaminationAndClinicalDetails
