import React, { FC } from 'react'
import { Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Separator from '../../../../components/atoms/Separator'
import Modal, { ModalHeaderIconTypes } from '../../../../components/molecules/Modal/Modal'
import text from '../../../../_constants/text'

interface TCAccessDeniedModalProps {
  show: boolean
  handleShow: () => void
  tcAccessDeniedCB: () => void
}

const TCAccessDeniedModal: FC<TCAccessDeniedModalProps> = ({ show, handleShow, tcAccessDeniedCB }) => {
  return (
    <Modal
      onHide={handleShow}
      heading={text.TC_ACCESS_DENIED_MODAL_TITLE}
      show={show}
      headerIcon={ModalHeaderIconTypes.WARNING}
    >
      <Row>
        <p className="mb-3">{text.TC_ACCESS_DENIED_MODAL_DESC}</p>
        <Separator />
        <p className="pt-4">{text.NEED_SUPPORT}</p>
        <Link to="#" onClick={tcAccessDeniedCB} data-testid="accessDeniedLink" className="pt-2 mb-0">
          {text.TC_ACCESS_DENIED_MODAL_REVIEW_TERMS}
        </Link>
      </Row>
    </Modal>
  )
}

export default TCAccessDeniedModal
