import {
  REGISTER_ADD_PRACTICE,
  REGISTER_CLEAR,
  REGISTER_NAVIGATE_BACK,
  REGISTER_REMOVE_PRACTICE,
  REGISTER_SUBMIT,
  REGISTER_SUBMIT_FAIL,
  REGISTER_SUBMIT_SUCCESS,
  REGISTER_UPDATE_DETAILS,
  REGISTER_UPDATE_PRACTICE_DETAILS,
  REGISTER_VALIDATE_PAGE_FAIL,
  REGISTER_VALIDATE_PAGE_SUCCESS,
  REGISTER_VALIDATE_PRACTICES_FAIL,
} from '../../actions/registerAccountActions'
import {
  RegisterAccountPageType,
  RegisterDataAction,
  RegisterPageAction,
  RegisterPageErrorsAction,
  RegisterPracticeDataAction,
  RegisterPracticesErrorsAction,
  UnregisterPracticeAction,
} from '../../actions/registerAccountActions.types'
import { BaseAction } from '../../actions/commons'
import {
  PracticeDetailsErrorsType,
  PracticeDetailsType,
  RegistrationFormType,
  SubmitControls,
} from './RegisterAccountReducer.types'
import { RegistrationFormFieldType } from '../../actions/registerAccountActions.types'
import { submitStates } from '../commons/submitControls'

type RegisterAccountState = {
  registrationForm: RegistrationFormType
  registrationFormErrors: Partial<Record<RegistrationFormFieldType, boolean>>
  currentPage: RegisterAccountPageType
  practices: PracticeDetailsType[]
  practicesErrors: PracticeDetailsErrorsType[]
  submit: SubmitControls
}

export const initialRegisterAccountState: RegisterAccountState = {
  registrationForm: {
    userid: '',
    haveCurrentAccount: '',
    firstName: '',
    lastName: '',
    ahpraNumber: '',
    email: '',
    mobile: '',
    password: '',
    mandatoryUserAccessAgreement: false,
  },
  submit: {
    ...submitStates.default,
  },
  practices: [{} as PracticeDetailsType],
  practicesErrors: [{} as PracticeDetailsErrorsType],
  registrationFormErrors: {},
  currentPage: 'generalInfo',
}

export const fieldsToValidate = {
  generalInfo: ['haveCurrentAccount', 'firstName', 'lastName', 'mobile', 'email', 'ahpraNumber', 'password'],
  practiceDetails: [
    'practiceName',
    'practiceType',
    'practicePhone',
    'providerNumber',
    'practiceAddress',
    'practiceSuburb',
    'practicePostcode',
    'practiceState',
  ],
  finish: ['mandatoryUserAccessAgreement'],
}

export const registeredAccountPagesOrdered: RegisterAccountPageType[] = ['generalInfo', 'practiceDetails', 'finish']

const registerAccountReducer = (
  state: RegisterAccountState = initialRegisterAccountState,
  action: BaseAction,
): RegisterAccountState => {
  switch (action.type) {
    case REGISTER_VALIDATE_PAGE_SUCCESS: {
      const navigateAction = action as RegisterPageAction
      return {
        ...state,
        currentPage: navigateAction.data.page,
      }
    }
    case REGISTER_NAVIGATE_BACK: {
      const navigateAction = action as RegisterPageAction
      return {
        ...state,
        currentPage: navigateAction.data.page,
      }
    }
    case REGISTER_VALIDATE_PAGE_FAIL: {
      const errorsAction = action as RegisterPageErrorsAction
      return {
        ...state,
        registrationFormErrors: { ...errorsAction.data.errors },
      }
    }
    case REGISTER_VALIDATE_PRACTICES_FAIL: {
      const errorsAction = action as RegisterPracticesErrorsAction
      return {
        ...state,
        practicesErrors: [...(errorsAction.data.practicesErrors as PracticeDetailsErrorsType[])],
      }
    }
    case REGISTER_UPDATE_DETAILS:
      const a = action as RegisterDataAction
      const updateKey = Object.keys(a.data)
      const errors = { ...state.registrationFormErrors }
      delete errors[updateKey[0]]
      return {
        ...state,
        registrationForm: {
          ...state.registrationForm,
          ...a.data,
        },
        registrationFormErrors: errors,
        submit: { ...submitStates.default },
      }
    case REGISTER_ADD_PRACTICE:
      return {
        ...state,
        practices: [...state.practices, {} as PracticeDetailsType],
        practicesErrors: [...state.practicesErrors, {} as PracticeDetailsErrorsType],
        submit: { ...submitStates.default },
      }
    case REGISTER_REMOVE_PRACTICE: {
      const a = action as UnregisterPracticeAction
      const updatedPractices = [...(state.practices as PracticeDetailsType[])]
      updatedPractices.splice(a.data.index, 1)
      return {
        ...state,
        practices: [...updatedPractices],
        submit: { ...submitStates.default },
      }
    }

    case REGISTER_UPDATE_PRACTICE_DETAILS: {
      const a = action as RegisterPracticeDataAction
      const practiceToUpdate = state.practices[a.data.index]
      const updateKey = Object.keys(a.data.practiceDetails)
      const updatedPractice = { ...(practiceToUpdate as PracticeDetailsType), ...a.data.practiceDetails }
      const updatedPractices = [...state.practices]
      const practicesErrors = [...state.practicesErrors] as PracticeDetailsErrorsType[]
      delete practicesErrors[a.data.index][updateKey[0]]
      updatedPractices[a.data.index] = updatedPractice
      return {
        ...state,
        practices: updatedPractices,
        practicesErrors,
        submit: { inProgress: false, success: false, fail: false },
      }
    }
    case REGISTER_SUBMIT:
      return { ...state, submit: { ...submitStates.inProgress } }
    case REGISTER_SUBMIT_SUCCESS:
      return { ...state, submit: { ...submitStates.success } }
    case REGISTER_SUBMIT_FAIL:
      return { ...state, submit: { ...submitStates.fail } }
    case REGISTER_CLEAR:
      return { ...initialRegisterAccountState }
    default:
      return state
  }
}

export default registerAccountReducer
