import { put, select, takeEvery, ForkEffect } from '@redux-saga/core/effects'
import { fork, PutEffect, SelectEffect } from 'redux-saga/effects'
import {
  CHANGE_PASSWORD,
  changePasswordFail,
  changePasswordSuccess,
  changePasswordComplexityFail,
  changePasswordComplexitySuccess,
  ChangePasswordFormFieldTypes,
} from '../../actions/passwordActions'
import { validateString } from '../../components/forms/validations/RegisterAccount'
import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import mapAccountStateToApi from '../../mappers/mapAccountStateToApi'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { runWithRefresh } from '../RefreshAuthTokenSaga/refreshAuthTokenSaga'
import { analyticsTracker } from '../../services/analytics/analyticsTrackerService'
import { GAEventNames } from '../../domain/models/commons/GoogleAnalytics'

const ACCOUNT_CHANGE_PASSWORD_PATH = '/password/change'

type ChangePasswordGenerator = Generator<
  SelectEffect | Promise<ResponseWithStatus<void>> | PutEffect,
  ResponseWithStatus<void>,
  AccessToken | Record<ChangePasswordFormFieldTypes, string> | ResponseWithStatus<void>
>

export function* changePassword(): ChangePasswordGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken
  const passwordDetails = (yield select((state) => state.password.changePasswordForm)) as Record<
    ChangePasswordFormFieldTypes,
    string
  >

  if (!validateString('password', true, passwordDetails.newPassword)) {
    yield put(changePasswordComplexityFail())
    return
  } else {
    yield put(changePasswordComplexitySuccess())
  }

  const changePasswordResponse = (yield post(
    `${process.env.REACT_APP_API_URL}${ACCOUNT_CHANGE_PASSWORD_PATH}`,
    { Authorization: `${jwt.type} ${jwt.token}` },
    mapAccountStateToApi(passwordDetails),
  )) as ResponseWithStatus<void>
  return changePasswordResponse
}

export function* changePasswordSaga(): Generator<unknown | PutEffect, void, unknown> {
  const changePasswordResponse = (yield runWithRefresh(
    changePassword,
  ) as ChangePasswordGenerator) as ResponseWithStatus<void>
  if (!changePasswordResponse) return
  if (changePasswordResponse.status === ResponseStatus.SUCCESS) {
    analyticsTracker().track(GAEventNames.CHANGE_PASSWORD, {})
    yield put(changePasswordSuccess())
  } else {
    yield put(changePasswordFail())
  }
}

function* watchChangePassword(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga)
}

const passwordSagas = [fork(watchChangePassword)]

export default passwordSagas
