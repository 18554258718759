import { BaseAction, DataAction } from '../../actions/commons'
import { LOGOUT_SUCCESS } from '../../actions/loginAction'
import {
  PatientDetails,
  PATIENT_GET_PATIENT,
  PATIENT_GET_PATIENT_FAIL,
  PATIENT_GET_PATIENT_SUCCESS,
  PATIENT_DETAILS_CLEAR,
} from '../../actions/PatientDetailsActions/patientDetailsActions'
import { SubmitControls, submitStates } from '../commons/submitControls'

type PatientDetailsState = {
  patientData: PatientDetails
  patientGetControls: SubmitControls
}

export const initialPatientDetailsState = {
  patientData: {
    id: '',
    address: '',
    city: '',
    dob: '',
    email: '',
    mobile: '',
    name: '',
    postcode: '',
    state: '',
  },
  patientGetControls: { ...submitStates.default },
}

const patientDetailsReducer = (state = initialPatientDetailsState, action: BaseAction): PatientDetailsState => {
  switch (action.type) {
    case PATIENT_GET_PATIENT: {
      return { ...state, patientGetControls: { ...submitStates.inProgress } }
    }
    case PATIENT_GET_PATIENT_SUCCESS: {
      const a = action as DataAction<PatientDetails>
      return {
        ...state,
        patientGetControls: { ...submitStates.success },
        patientData: { ...state.patientData, ...a.data },
      }
    }
    case PATIENT_GET_PATIENT_FAIL: {
      return {
        ...state,
        patientGetControls: { ...submitStates.fail },
      }
    }
    case LOGOUT_SUCCESS:
      return { ...initialPatientDetailsState }
    case PATIENT_DETAILS_CLEAR: {
      return {
        ...state,
        ...initialPatientDetailsState,
      }
    }
    default:
      return state
  }
}

export default patientDetailsReducer
