import { BaseAction, UpdateFieldAction } from '../../actions/commons'
import {
  RecoverUsernameFormFieldType,
  RECOVER_USERNAME_SUCCESS,
  RECOVER_USERNAME_UPDATE_DETAILS,
  RECOVER_USERNAME_FAIL,
  RECOVER_USERNAME_RESET,
  RECOVER_USERNAME_VALID_EMAIL,
  RECOVER_USERNAME_INVALID_EMAIL,
  RECOVER_USERNAME_INVALID_AHPRA,
  RECOVER_USERNAME_VALID_AHPRA,
} from '../../actions/recoverUsernameAction'
import { SubmitControls } from '../RegisterAccountReducer/RegisterAccountReducer.types'

export type RecoverUsernameState = {
  form: Record<RecoverUsernameFormFieldType, string>
  submit: SubmitControls
  emailIsInvalid: boolean
  ahpraIsInvalid: boolean
}

export const initialRecoverUsernameState: RecoverUsernameState = {
  form: {
    email: '',
    ahpra: '',
  },
  submit: {
    inProgress: false,
    success: false,
    fail: false,
  },
  emailIsInvalid: false,
  ahpraIsInvalid: false,
}

const recoverUsernameReducer = (
  state: RecoverUsernameState = initialRecoverUsernameState,
  action: BaseAction,
): RecoverUsernameState => {
  switch (action.type) {
    case RECOVER_USERNAME_VALID_EMAIL:
      return {
        ...state,
        emailIsInvalid: false,
      }
    case RECOVER_USERNAME_INVALID_EMAIL:
      return {
        ...state,
        emailIsInvalid: true,
      }
    case RECOVER_USERNAME_VALID_AHPRA:
      return {
        ...state,
        ahpraIsInvalid: false,
      }
    case RECOVER_USERNAME_INVALID_AHPRA:
      return {
        ...state,
        ahpraIsInvalid: true,
      }
    case RECOVER_USERNAME_RESET:
      return {
        ...state,
        form: {
          ...initialRecoverUsernameState.form,
        },
        submit: { inProgress: false, success: false, fail: false },
      }
    case RECOVER_USERNAME_UPDATE_DETAILS:
      const a = action as UpdateFieldAction<RecoverUsernameFormFieldType>
      return {
        ...state,
        form: {
          ...state.form,
          ...a.data,
        },
        submit: { inProgress: false, success: false, fail: false },
      }
    case RECOVER_USERNAME_FAIL:
      return {
        ...state,
        submit: { inProgress: false, success: false, fail: true },
      }
    case RECOVER_USERNAME_SUCCESS: {
      const a = action as UpdateFieldAction<RecoverUsernameFormFieldType>
      return {
        ...state,
        form: {
          ...state.form,
          ...a.data,
        },
        submit: { inProgress: false, success: true, fail: false },
      }
    }
    default:
      return state
  }
}

export default recoverUsernameReducer
