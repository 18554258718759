import { State } from '../../domain/models/commons/Address'
import RequestError from '../../errors/RequestError'
import { BaseAction, DataAction, ErrorAction } from '../commons'

export const PATIENT_GET_PATIENT = 'PATIENT_DETAILS/GET_PATIENT'
export const PATIENT_GET_PATIENT_SUCCESS = 'PATIENT_DETAILS/GET_PATIENT_SUCCESS'
export const PATIENT_GET_PATIENT_FAIL = 'PATIENT_DETAILS/GET_PATIENT_FAIL'
export const PATIENT_DETAILS_CLEAR = 'PATIENT_DETAILS/CLEAR'

export type PatientDetails = {
  address: string
  city: string
  dob: string
  email: string
  mobile: string
  name: string
  postcode: string
  state: State | string
  id: string
}

export const getPatientDetails = (patientId: string): DataAction<{ patientId: string }> => ({
  type: PATIENT_GET_PATIENT,
  data: { patientId },
})

export const getPatientDetailsSuccess = (data: PatientDetails): DataAction<PatientDetails> => ({
  type: PATIENT_GET_PATIENT_SUCCESS,
  data,
})

export const getPatientDetailsFail = (): ErrorAction => ({
  type: PATIENT_GET_PATIENT_FAIL,
  data: { error: new RequestError('Error getting patient details'), show: true },
})

export const clearPatientDetails = (): BaseAction => ({
  type: PATIENT_DETAILS_CLEAR,
})
