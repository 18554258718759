import React, { FunctionComponent } from 'react'
import { Row, Button } from 'react-bootstrap'
import Icon from '../../../components/atoms/Icon'
import text from '../../../_constants/text'

type Props = {
  extraStudies: number
  isLoading: boolean
  showBreakGlass: boolean
  onBreakGlassClick: () => void
  onViewAllStudiesClick?: () => void
}

const StudyTableExtraStudiesRow: FunctionComponent<Props> = ({
  extraStudies,
  isLoading,
  onBreakGlassClick,
  onViewAllStudiesClick,
  showBreakGlass,
}) => {
  const hasExtraStudies = extraStudies > 0
  const extraStudiesCopy = hasExtraStudies
    ? [
        text.EXTRA_STUDIES_LABEL_1,
        // eslint-disable-next-line
        <strong key="extra-studies">
          {extraStudies} {extraStudies === 1 ? 'study' : 'studies'}
        </strong>,
        text.EXTRA_STUDIES_LABEL_2,
      ]
    : text.NO_EXTRA_STUDIES_FOUND_LABEL

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    onBreakGlassClick()
  }

  const shouldShowExtraStudiesLabel = (!showBreakGlass && !hasExtraStudies) || showBreakGlass

  return (
    <Row
      data-testid="studyTableExtraStudiesRow"
      className="extra-study-row flex-column mx-0 px-0 py-2 align-items-baseline justify-content-between"
    >
      {shouldShowExtraStudiesLabel && (
        <p className="extra-study-label w-auto p-2" data-testid="ExtraStudiesLabel">
          {isLoading ? text.LOADING_RESULTS : extraStudiesCopy}
        </p>
      )}
      {hasExtraStudies && showBreakGlass ? (
        <Button
          data-testid="studyTableExtraStudiesBreakGlass"
          className="w-auto p-2 px-4 my-1 btn-light btn-block btn btn-primary"
          onClick={handleClick}
        >
          <Icon className="px-2" name="lock" />
          {text.BREAK_GLASS_ACCESS_MESSAGE}
        </Button>
      ) : null}
      {onViewAllStudiesClick && (
        <Button
          data-testid="studyTableShowAllStudies"
          className="w-auto p-2 px-4 my-1 btn-light btn-block"
          onClick={() => onViewAllStudiesClick()}
        >
          <span className="px-2 icon-update-search align-middle"></span>
          <span className="align-middle">{text.UPDATE_SEARCH_VIEW_ALL_STUDIES}</span>
        </Button>
      )}
    </Row>
  )
}

export default StudyTableExtraStudiesRow
