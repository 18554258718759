import React, { FC, useEffect, useState } from 'react'
import { Button, Col, Modal, Row } from 'react-bootstrap'
import Icon from '../../../components/atoms/Icon'

import WarningAlert from '../../../components/atoms/WarningAlert'
import routes from '../../../router/routes'
import icons from '../../../_constants/icons'
import text from '../../../_constants/text'

interface BreakGlassModalProps {
  show: boolean
  handleShow: () => void
  consentBreakGlass: () => void
}

const BreakGlassModal: FC<BreakGlassModalProps> = ({ show, handleShow, consentBreakGlass }) => {
  const [consentAccepted, setConsentAccepted] = useState(false)

  useEffect(() => {
    setConsentAccepted(false)
  }, [show])

  return (
    <Modal show={show} onHide={handleShow} centered data-testid="break-glass-modal" size="lg">
      <Modal.Body>
        <div className="d-flex flex-row justify-content-between mb-3">
          <WarningAlert className="mt-4 ml-4" />
          <div className="c-pointer mt-3" onClick={handleShow} data-testid="break-glass-close">
            <Icon name={icons.CLOSE} />
            <p className="blue bold">{text.CLOSE}</p>
          </div>
        </div>
        <h2 className="mt-3">{text.RESTRICTED_ACCESS}</h2>
        <p>{text.RESTRICTED_EXPLAINATION}</p>
        <p className="mt-3">
          {text.RESTRICTED_ACKNOWLEDGEMENT}
          <b>{text.RESTRICTED_ACKNOWLEDGMENT_BOLD}</b>
        </p>
        <p className="mt-3">
          <em>{text.RESTRICTED_CONSENT}</em>
        </p>
        <form className="d-flex pt-4">
          <Row>
            <Col lg={1} className="d-flex align-items-center w-auto pe-2">
              <input
                type="checkbox"
                name="breakGlassConsent"
                value="breakGlassConsent"
                className="form-check-input align-middle"
                onChange={() => setConsentAccepted(!consentAccepted)}
                data-testid="break-glass-consent-checkbox"
              />
            </Col>
            <Col className="ps-1">
              <label htmlFor="breakGlassConsent">
                {text.RESTRICTED_ACCEPT}
                <a href={routes.termsOfUse}>{text.RESTRICTED_TERMS_OF_USE}</a>
              </label>
            </Col>
          </Row>
        </form>
        <div className="d-flex flex-row justify-content-end ">
          <Button variant="light" className="mt-4 mx-3" data-testid="break-glass-cancel" onClick={handleShow}>
            {text.CANCEL}
          </Button>
          <Button
            className="mt-4"
            disabled={!consentAccepted}
            data-testid="break-glass-confirm"
            onClick={consentBreakGlass}
          >
            {text.CONTINUE}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default BreakGlassModal
