import React from 'react'
import moment from 'moment'
import { Card } from 'react-bootstrap'
import { PatientDetails } from '../../../actions/PatientDetailsActions/patientDetailsActions'
import InputLabel from '../../../components/atoms/InputLabel'
import text from '../../../_constants/text'

interface PatientDetailsCardProps {
  patientData: PatientDetails
}

const PatientDetailsCard: React.FC<PatientDetailsCardProps> = ({ patientData }) => {
  return (
    <Card data-testid="PatientDetailsCard" as="section" className="mb-4">
      <Card.Body>
        <Card.Title as="h2" className="mb-4">
          {text.PATIENT_DETAILS}
        </Card.Title>
        <InputLabel label={text.FULL_NAME} />
        <p>{patientData.name}</p>
        <InputLabel label={text.DOB} />
        <p>{moment(patientData.dob, text.DATE_SEARCH_FORMAT).format(text.DATE_FORMAT).toString()}</p>
        <InputLabel label={text.PATIENT_ID} />
        <p>{patientData.id}</p>
      </Card.Body>
    </Card>
  )
}

export default PatientDetailsCard
