import { createBrowserHistory } from 'history'
import { applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createRouterMiddleware } from '@lagunovsky/redux-react-router'
import rootReducer from '.'
import rootSaga from '../sagas'

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    gtag: UniversalAnalytics.ga
  }
}

const middlewares = []
export const history = createBrowserHistory()

const sagaMiddleware = createSagaMiddleware()
middlewares.push(sagaMiddleware)
middlewares.push(createRouterMiddleware(history))

const composeEnhancers = true ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose : compose

export const store = createStore(rootReducer(history), composeEnhancers(applyMiddleware(...middlewares)))

sagaMiddleware.run(rootSaga)
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
