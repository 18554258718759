import { CancelledEffect, SelectEffect, cancelled, select } from 'redux-saga/effects'
import { ResponseWithStatus, get } from '../fetch'
import { AccessToken } from '../reducers/LoginReducer/LoginReducer'

type FetchGenerator<T> = Generator<
  SelectEffect | Promise<ResponseWithStatus<T>> | CancelledEffect,
  ResponseWithStatus<T>,
  ResponseWithStatus<T> | AccessToken
>

function* fetchSaga<T>(url: string, query: Record<string, unknown>): FetchGenerator<T> {
  const jwt = (yield select((state) => state.login.token)) as AccessToken

  const abortController = new AbortController()
  const signal = abortController.signal

  try {
    const res = (yield get(
      url,
      {
        Authorization: `${jwt?.type} ${jwt?.token}`,
      },
      query,
      signal,
    )) as ResponseWithStatus<T>
    return res
  } catch (e) {
  } finally {
    if (yield cancelled()) {
      abortController.abort()
    }
  }
}

export type { FetchGenerator }

export default fetchSaga
