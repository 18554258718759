import React from 'react'
import { RegistrationFormFieldType } from '../../../../actions/registerAccountActions.types'
import InternalLink from '../../../../components/atoms/InternalLink'
import CheckboxField from '../../../../components/forms/fields/CheckboxField/CheckboxField'
import { RegistrationFormType } from '../../../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import routes from '../../../../router/routes'
import text from '../../../../_constants/text'

interface FinishProps {
  onValueChange: (name: string, value: boolean) => void
  registrationForm: RegistrationFormType
  registrationFormErrors: Partial<Record<RegistrationFormFieldType, boolean>>
}

const Finish: React.FC<FinishProps> = ({ onValueChange, registrationForm, registrationFormErrors }) => {
  return (
    <>
      <div className="d-flex finish-checkbox-wrapper mb-4">
        <CheckboxField
          label="I accept the User Access Agreement of the I-MED Radiology Network"
          hideLabel
          required={false}
          inputName="mandatoryUserAccessAgreement"
          onChangeCb={onValueChange}
          value={registrationForm.mandatoryUserAccessAgreement}
          error={registrationFormErrors.mandatoryUserAccessAgreement}
        />
        <span>
          {text.I_ACCEPT}
          <InternalLink href={routes.termsOfUse}>{text.TERMS_OF_USE}</InternalLink>
          {text.OF_IMED}
        </span>
      </div>
      {registrationFormErrors.mandatoryUserAccessAgreement && <p className="error">{text.FIELD_REQUIRED}</p>}
    </>
  )
}

export default Finish
