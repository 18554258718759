import React, { Dispatch, useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Button from 'react-bootstrap/Button'
import SearchBar from '../../../components/organisms/SearchBar'
import text from '../../../_constants/text'
import { connect } from 'react-redux'
import { BaseAction, SearchFormFieldType } from '../../../actions/commons'
import { Search } from '../../../reducers/commons/types'
import { Practice } from '../../../reducers/AccountReducer/AccountReducer'
import isEqual from 'lodash/isEqual'
import {
  appointmentSearch,
  clearAllSearch,
  updateField,
  updateModalityFilter,
  updatePracticeFilter,
  updateSelectedPracticeList,
} from '../../../actions/AppointmentSearchActions/appointmentSearchActions'
import { getAccountDetails } from '../../../actions/accountAction'
import urls from '../../../_constants/urls'
import ExternalLink from '../../../components/atoms/ExternalLink'
import icons from '../../../_constants/icons'
import Icon from '../../../components/atoms/Icon'

interface SearchBarProps {
  updateField: (name: SearchFormFieldType, value: string) => void
  updateModalityFilter: (modality: string) => void
  updatePracticeFilter: (practice: string) => void
  updateSelectedPracticeList: (practices: Practice[]) => void
  appointmentSearch: (firstLoad?: boolean) => void
  getAccountDetails: () => void
  clearAll: () => void
  currentSearch: Search
  activeSearch: Search
  activeSearchType: string
  searchErrors: Partial<Record<SearchFormFieldType, boolean>>
  practices: Practice[]
  submitInProgress: boolean
  selectedPractices: string[]
  firstLoad: boolean
}

const AppointmentSearchBar: React.FC<SearchBarProps> = ({
  updateField,
  updateModalityFilter,
  updatePracticeFilter,
  updateSelectedPracticeList,
  appointmentSearch,
  clearAll,
  currentSearch,
  activeSearch,
  searchErrors,
  practices,
  submitInProgress,
  selectedPractices,
}) => {
  const handleSearch = () => {
    appointmentSearch()
  }

  useEffect(() => {
    getAccountDetails()
  }, [])

  const isCurrentSearchEqualToActiveSearch = isEqual(currentSearch, activeSearch)
  const isSearchButtonDisabled = submitInProgress || isCurrentSearchEqualToActiveSearch
  const paramsInvalidError = searchErrors?.nameOrPatientId || searchErrors?.dob
  return (
    <>
      <SearchBar
        updateField={updateField}
        updateModalityFilter={updateModalityFilter}
        updatePracticeFilter={updatePracticeFilter}
        updateSelectedPracticeList={updateSelectedPracticeList}
        handleSearch={handleSearch}
        clearAll={clearAll}
        dateRangeFilters={text.APPOINTMENT_SEARCH_DATE_RANGE_FILTERS}
        modalitiesFilters={text.SEARCH_MODALITY_FILTERS}
        practicesFilters={practices}
        searchReferredTypeFilters={[text.SEARCH_REFERRED_WITHIN_MY_NETWORK, text.SEARCH_REFERRED_BY_ME]}
        paramsInvalidError={paramsInvalidError}
        isSearchButtonDisabled={isSearchButtonDisabled}
        currentSearch={currentSearch}
        submitInProgress={submitInProgress}
        selectedPractices={selectedPractices}
      />
      <Row>
        <Col xs={12}>
          <ExternalLink href={urls.BOOK_AN_APPOINTMENT} newTab={true}>
            <Button className="mb-4" variant="light">
              <Icon name={icons.EVENT} className="mr-2" />
              {text.BOOK_AN_APPOINTMENT}
            </Button>
          </ExternalLink>
        </Col>
      </Row>
    </>
  )
}

const mapStateToProps = (state) => {
  const { currentSearch, activeSearch, searchErrors, activeSearchType, submit, selectedPractices, firstLoad } =
    state.appointmentSearch
  const { practices } = state.account
  const { inProgress: submitInProgress } = submit
  return {
    currentSearch,
    activeSearch,
    activeSearchType,
    searchErrors,
    practices,
    selectedPractices,
    submitInProgress,
    firstLoad,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  appointmentSearch: (firstLoad = false) => dispatch(appointmentSearch(firstLoad)),
  getAccountDetails: () => dispatch(getAccountDetails()),
  clearAll: () => dispatch(clearAllSearch()),
  updateField: (type, value) => dispatch(updateField(type, value)),
  updateModalityFilter: (filter) => dispatch(updateModalityFilter(filter)),
  updatePracticeFilter: (filter) => dispatch(updatePracticeFilter(filter)),
  updateSelectedPracticeList: (practices) => dispatch(updateSelectedPracticeList(practices)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AppointmentSearchBar)
