import React, { FC, useState } from 'react'
import Nav from 'react-bootstrap/Nav'
import { Link } from 'react-router-dom'
import { NavigationItem } from '../../../domain/models/commons/NavigationItem'
import { ContentLinkModel } from '../../../domain/models/commons/Link'
import Icon from '../../atoms/Icon'
import ExternalLink from '../../atoms/ExternalLink'
import { history } from '../../../reducers/store'

const LVL_1_PREFIX = 'navLvl1'
const LVL_2_PREFIX = 'navLvl2'

interface props extends NavigationItem {
  className?: string
  onLinkClick: () => void
}

interface IFirstLvlLink {
  item: NavigationItem
  onClick: () => void
}

const FirstLvlLink: FC<IFirstLvlLink> = ({ item, onClick }) => {
  if (item.value.navigation.length !== 1) {
    return (
      <Nav.Link className="dropdown-toggle" eventKey={`${LVL_1_PREFIX}_${item.id}`}>
        {item.value.name}
      </Nav.Link>
    )
  }

  const url = item.value.navigation[0].value.url
  const isExternalLink = item.value.navigation[0].value.external
  const linkId = item.id
  const pagePath = history.location?.pathname
  const navLink = (
    <Nav.Link as="div" eventKey={`${LVL_1_PREFIX}_${item.id}`} onClick={onClick}>
      <Icon name={item.value.icon} />
      {item.value.name}
    </Nav.Link>
  )

  return isExternalLink ? (
    <ExternalLink href={url} pagePath={pagePath} linkId={linkId}>
      {navLink}
    </ExternalLink>
  ) : (
    <Link to={url}>{navLink}</Link>
  )
}
const MobileNavigation: FC<props> = ({ id, value, className, onLinkClick }) => {
  const [openElements, setOpenElements] = useState<{ [key: string]: boolean }>({})
  const clickHandler = (id: string) => {
    setOpenElements({ ...openElements, [`${LVL_1_PREFIX}_${id}`]: !openElements[`${LVL_1_PREFIX}_${id}`] })
  }
  return (
    <Nav as="nav" id={id} variant="pills" className={`mobile-nav mx-3 ${className}`}>
      {value?.children.map((firstLvlItem: NavigationItem) => {
        // Ignore items without navigation targets
        if (!firstLvlItem.value.navigation.length) {
          return null
        }
        const elementHasChildren = firstLvlItem.value.navigation.length > 1
        let secondLvlNav = []
        // If there is more that 1 nav target create 2nd lvl pills in an array
        if (elementHasChildren) {
          secondLvlNav = firstLvlItem.value.navigation.map((secondLvlItem: ContentLinkModel) => (
            <Nav.Item
              onClick={onLinkClick}
              className={`nav-item-lvl-2 ${openElements[`${LVL_1_PREFIX}_${firstLvlItem.id}`] ? 'd-block' : 'd-none'}`}
              key={`${LVL_2_PREFIX}_${secondLvlItem.id}`}
            >
              <Link to={secondLvlItem.value.url}>
                <Nav.Link as="div" className="pl-5" eventKey={`${LVL_2_PREFIX}_${firstLvlItem.id}`}>
                  {secondLvlItem.value.name}
                </Nav.Link>
              </Link>
            </Nav.Item>
          ))
        }
        // Create element and inject created 2nd lvl pills
        return (
          <React.Fragment key={`fragment_${LVL_1_PREFIX}_${firstLvlItem.id}`}>
            <Nav.Item key={`${LVL_1_PREFIX}_${firstLvlItem.id}`} onClick={() => clickHandler(firstLvlItem.id)}>
              <FirstLvlLink item={firstLvlItem} onClick={onLinkClick} />
            </Nav.Item>
            {secondLvlNav}
          </React.Fragment>
        )
      })}
    </Nav>
  )
}

export default MobileNavigation
