import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Layout from '../../components/wrappers/Layout'
import { RootState } from '../../reducers/store'
import { useNavigate, useParams } from 'react-router'
import {
  patientImagesGetImagesData,
  patientImagesReset,
  ImagesData,
} from '../../actions/PatientImagesActions/patientImagesActions'
import BreakGlassTimeoutModal from '../PatientSearchPage/BreakGlassTimeoutModal'
import { patientReportReset } from '../../actions/PatientReportActions/patientReportActions'
import { PatientDetails, getPatientDetails } from '../../actions/PatientDetailsActions/patientDetailsActions'
import { getAccessionNumber, isAccessible } from '../../hooks/urlParams'
import { Helmet } from 'react-helmet'
import { pageTitles } from '../../router/routes'
import ErrorCard from '../../components/organisms/ErrorCard'
import { Button } from 'react-bootstrap'
import text from '../../_constants/text'

interface PatientImageSetsPageProps {
  getPatientData: (patientId: string) => void
  getImagesData: (imagesData: ImagesData) => void
  resetPatientData: () => void
  resetImagesData: () => void
  patientData: PatientDetails
  inteleviewerURL: string
  accessionNumber: string
  prevUrl: string
  displayName: string
  breakGlass: boolean
  expired: boolean
  inProgress: boolean
}

const PatientImageSetsPage: React.FC<PatientImageSetsPageProps> = ({
  getPatientData,
  getImagesData,
  resetPatientData,
  resetImagesData,
  patientData,
  inteleviewerURL,
  displayName,
  expired,
  inProgress,
}) => {
  const { patientId, orderId } = useParams<{ patientId: string; orderId: string; accessionNo: string }>()
  const accessible = isAccessible()
  const accessionNr = getAccessionNumber()
  const navigate = useNavigate()
  useEffect(() => {
    if (patientId) {
      getPatientData(patientId)
    }
    return resetPatientData
  }, [patientId, getPatientData, resetPatientData])

  useEffect(() => {
    if (orderId) {
      getImagesData({
        orderId: orderId,
        accessionNumber: accessionNr,
        referrer: displayName,
        patientId: patientData.id,
        accessible: accessible,
      })
    }
    return resetImagesData
  }, [orderId, patientData.id, getImagesData, resetImagesData])

  return (
    <Layout hideFooter>
      <Helmet>
        <title>{pageTitles.patientImageSets}</title>
      </Helmet>
      <>
        <BreakGlassTimeoutModal show={expired} patientName={patientData.name} navigate />
        <div className="content-background">
          {inteleviewerURL && (
            <div className="embed-iframe">
              <iframe
                data-testid="embeddediIframe"
                className="images-iframe"
                title="inteleviewer iframe"
                allowFullScreen
                key={inteleviewerURL}
                src={inteleviewerURL}
              />
            </div>
          )}
          {!inteleviewerURL && !inProgress && (
            <ErrorCard title="No images found">
              <Button onClick={() => navigate(-1)} data-testid="backButton">
                {text.BACK_TO_PATIENT_SEARCH}
              </Button>
            </ErrorCard>
          )}
        </div>
      </>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  const { imagesData, accessionNumber, prevUrl, breakGlass, expired, imagesDataGetControls } = state.patientImages
  const { inProgress } = imagesDataGetControls
  const { patientData } = state.patientDetails
  const { displayName } = state.account
  const { inteleviewerURL } = imagesData
  return { inteleviewerURL, accessionNumber, prevUrl, displayName, patientData, breakGlass, expired, inProgress }
}

const mapDispatchToProps = (dispatch) => ({
  getPatientData: (patientId: string) => dispatch(getPatientDetails(patientId)),
  getImagesData: (imagesData: ImagesData) => dispatch(patientImagesGetImagesData(imagesData)),
  resetImagesData: () => dispatch(patientImagesReset()),
  resetPatientData: () => dispatch(patientReportReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(PatientImageSetsPage)
