import React, { useEffect } from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import Layout from '../../components/wrappers/Layout'
import PageHeadingRow from '../../components/molecules/PageHeadingRow/PageHeadingRow'
import text from '../../_constants/text'
import { pageTitles } from '../../router/routes'
import { appointmentSearch } from '../../actions/AppointmentSearchActions/appointmentSearchActions'
import { selectPatientsWithNextAppointmentInfo } from '../../selectors/studies'
import AppointmentTable from './AppointmentTable'
import { useAppDispatch } from '../../hooks/redux'
import AppointmentSearchBar from './AppointmentSearchBar'
import { useAppSelector } from '../../hooks/redux'
import { shallowEqual } from 'react-redux'
import EmptyState from '../../components/organisms/EmptyState'
import { RootState } from '../../reducers/store'

const AppointmentSearchPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const patientsWithNextAppointmentDates = useAppSelector(selectPatientsWithNextAppointmentInfo, shallowEqual)
  const isLoading = useAppSelector((state: RootState) => state.appointmentSearch.submit.inProgress)
  const shouldShowEmptyState = patientsWithNextAppointmentDates.length == 0 && !isLoading

  useEffect(() => {
    dispatch(appointmentSearch(true))
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>{pageTitles.appointments}</title>
      </Helmet>
      <div className="content-background content-background-min-height">
        <Container data-testid="AppointmentSearchPageContainer" className="px-xl-0 max-grid-width pb-5" fluid>
          <PageHeadingRow title={text.APPOINTMENT_SEARCH} />
          <AppointmentSearchBar />
          {shouldShowEmptyState ? <EmptyState /> : <AppointmentTable />}
        </Container>
      </div>
    </Layout>
  )
}

export default AppointmentSearchPage
