import React, { useEffect } from 'react'
import Layout from '../../components/wrappers/Layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { SubmitControls } from '../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { connect } from 'react-redux'
import ForgotUsernameSuccessCard from './ForgotUsernameSuccessCard'
import ForgotUsernameFormInputCard from './ForgotUsernameFormInputCard'
import { resetRecover } from '../../actions/recoverUsernameAction'
import { pageTitles } from '../../router/routes'
import { Helmet } from 'react-helmet'

interface ForgotUsernamePageProps {
  submit: SubmitControls
  resetRecover: () => void
}

const ForgotUsernamePage: React.FC<ForgotUsernamePageProps> = ({ submit, resetRecover }) => {
  useEffect(() => {
    return resetRecover
  }, [])

  return (
    <Layout>
      <Helmet>
        <title>{pageTitles.forgotUsername}</title>
      </Helmet>
      <div className="d-flex align-items-center content-background content-unauthenticated-background-min-height py-5">
        <Container className="max-grid-width py-0 px-lg-0" fluid>
          <Row className="px-0 justify-content-center">
            <Col xs={12} lg={6}>
              {submit.success ? <ForgotUsernameSuccessCard /> : <ForgotUsernameFormInputCard />}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { form, submit } = state.recoverUsername
  return { form, submit }
}

const mapDispatchToProps = (dispatch) => ({
  resetRecover: () => dispatch(resetRecover()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotUsernamePage)
