import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import DropdownSelect from '../../../../../components/forms/fields/DropdownSelect'
import SingleLineTextInputField from '../../../../../components/forms/fields/SingleLineTextInputField'
import { Referral, ReferralErrors } from '../../../../../reducers/ReferralReducer/ReferralReducer.types'
import text from '../../../../../_constants/text'
import DOBInputField from '../../../../../components/forms/fields/DOBInputField'
import InputLabel from '../../../../../components/atoms/InputLabel'

interface PatientDetailsProps {
  onValueChange: (name: string, value: string | boolean) => void
  referral: Referral
  errors: ReferralErrors
}

const PatientDetails: FC<PatientDetailsProps> = ({ onValueChange, referral, errors }) => {
  return (
    <>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.FULL_NAME}
            inputName="patientName"
            required={false}
            value={referral.patientName}
            errorMessage={text.FIELD_REQUIRED}
            onChangeCb={onValueChange}
            error={errors.patientName}
            placeholder={text.PLACEHOLDER_PATIENT_NAME}
          />
        </Col>
        <Col xs={12} lg={3}>
          <InputLabel label={text.DOB} />
          <DOBInputField
            inputName="patientDob"
            placeholder={text.DATE_FORMAT}
            onChangeCb={onValueChange}
            value={referral.patientDob}
            errorMessage={text.DOB_ERROR_MESSAGE}
            error={errors.patientDob}
          />
        </Col>
        <Col xs={12} lg={3}>
          <DropdownSelect
            label={text.GENDER_OPTIONAL}
            inputName="patientGender"
            errorMessage={text.FIELD_REQUIRED}
            value={referral.patientGender}
            onChangeCb={onValueChange}
            required={false}
            error={errors.patientGender}
            options={text.GENDER_OPTIONS}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.MOBILE_NUMBER}
            inputName="patientPhone"
            required={false}
            value={referral.patientPhone}
            instructionMessage={text.REFERRAL_MOBILE_INSTRUCTION}
            onChangeCb={onValueChange}
            errorMessage={text.ERROR_MESSAGE_MOBILE}
            error={errors.patientPhone}
            placeholder={text.PLACEHOLDER_MOBILE}
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.EMAIL_OPTIONAL}
            inputName="patientEmail"
            required={false}
            value={referral.patientEmail}
            instructionMessage={text.REFERRAL_EMAIL_INSTRUCTION}
            onChangeCb={onValueChange}
            error={errors.patientEmail}
            errorMessage={text.EMAIL_ERROR_MESSAGE}
            placeholder={text.PLACEHOLDER_PATIENT_EMAIL}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <SingleLineTextInputField
            label={text.ADDRESS}
            inputName="patientStreet"
            required={false}
            value={referral.patientStreet}
            onChangeCb={onValueChange}
            error={errors.patientStreet}
            errorMessage={text.FIELD_REQUIRED}
            placeholder=""
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.SUBURB}
            inputName="patientSuburb"
            required={false}
            value={referral.patientSuburb}
            onChangeCb={onValueChange}
            error={errors.patientSuburb}
            errorMessage={text.FIELD_REQUIRED}
            placeholder=""
          />
        </Col>
        <Col xs={12} lg={3}>
          <SingleLineTextInputField
            label={text.POSTCODE}
            inputName="patientPostcode"
            required={false}
            value={referral.patientPostcode}
            onChangeCb={onValueChange}
            error={errors.patientPostcode}
            errorMessage={text.FIELD_REQUIRED}
            placeholder=""
          />
        </Col>
        <Col xs={12} lg={3}>
          <DropdownSelect
            label={text.STATE}
            inputName="patientState"
            required={false}
            value={referral.patientState}
            onChangeCb={onValueChange}
            error={errors.patientState}
            errorMessage={text.FIELD_REQUIRED}
            options={text.STATES}
          />
        </Col>
      </Row>
    </>
  )
}

export default PatientDetails
