import { BaseAction } from '../../actions/commons'
import { SubmitControls } from '../RegisterAccountReducer/RegisterAccountReducer.types'
import {
  WEBCOMRAD_PASSWORD_RESET_CLEAR,
  WEBCOMRAD_PASSWORD_RESET_ERROR,
  WEBCOMRAD_PASSWORD_RESET_FIELD_UPDATE,
  WEBCOMRAD_PASSWORD_RESET_FIELD_VALIDATE,
  WEBCOMRAD_PASSWORD_RESET_SUBMIT_FAIL,
  WEBCOMRAD_PASSWORD_RESET_SUBMIT_LOADING,
  WEBCOMRAD_PASSWORD_RESET_SUBMIT_SUCCESS,
} from '../../actions/WebcomradPasswordResetActions/webcomradPasswordResetActions'
import {
  WebcomradPasswordResetErrorAction,
  WebcomradPasswordResetDataAction,
  WebcomradPasswordResetFieldValidateAction,
} from '../../actions/WebcomradPasswordResetActions/webcomradPasswordResetActions.types'

export interface WebcomradPasswordResetFormDataModel {
  fullName: string
  username: string
  contactNumber: string
  emailAddress: string
}

export interface WebcomradPasswordResetErrorModel {
  fullName: boolean
  username: boolean
  contactNumber: boolean
  emailAddress: boolean
}

export interface WebcomradPasswordResetState {
  formData: WebcomradPasswordResetFormDataModel
  errors: WebcomradPasswordResetErrorModel
  submit: SubmitControls
}

export const initialWebcomradPasswordResetState: WebcomradPasswordResetState = {
  formData: {
    fullName: '',
    username: '',
    contactNumber: '',
    emailAddress: '',
  } as WebcomradPasswordResetFormDataModel,
  errors: {
    fullName: false,
    username: false,
    contactNumber: false,
    emailAddress: false,
  } as WebcomradPasswordResetErrorModel,
  submit: {
    inProgress: false,
    success: false,
    fail: false,
  } as SubmitControls,
}

const WebcomradPasswordResetReducer = (
  state: WebcomradPasswordResetState = initialWebcomradPasswordResetState,
  action: BaseAction,
): WebcomradPasswordResetState => {
  switch (action.type) {
    case WEBCOMRAD_PASSWORD_RESET_FIELD_UPDATE:
      const a = action as WebcomradPasswordResetDataAction
      return {
        ...state,
        formData: {
          ...state.formData,
          ...a.data,
        },
      }
    case WEBCOMRAD_PASSWORD_RESET_ERROR:
      const errorsAction = action as WebcomradPasswordResetErrorAction
      return {
        ...state,
        errors: {
          ...errorsAction.errors,
        },
      }
    case WEBCOMRAD_PASSWORD_RESET_FIELD_VALIDATE:
      const field = action as WebcomradPasswordResetFieldValidateAction
      const fieldData = field.data
      return {
        ...state,
        errors: {
          ...state.errors,
          [fieldData.fieldName]: fieldData.value,
        },
      }
    case WEBCOMRAD_PASSWORD_RESET_SUBMIT_SUCCESS:
      return { ...state, submit: { inProgress: false, success: true, fail: false } }
    case WEBCOMRAD_PASSWORD_RESET_SUBMIT_FAIL:
      return { ...state, submit: { inProgress: false, fail: true, success: false } }
    case WEBCOMRAD_PASSWORD_RESET_SUBMIT_LOADING:
      return { ...state, submit: { inProgress: true, success: false, fail: false } }
    case WEBCOMRAD_PASSWORD_RESET_CLEAR:
      return { ...initialWebcomradPasswordResetState }
    default:
      return state
  }
}

export default WebcomradPasswordResetReducer
