import React, { FC, useEffect } from 'react'
import Layout from '../../components/wrappers/Layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import ForgotPasswordForm from './ForgotPasswordForm'
import { connect } from 'react-redux'
import ForgotPasswordSuccess from './ForgotPasswordSuccess'
import { RootState } from '../../reducers/store'
import { resetRecover } from '../../actions/ForgotPasswordActions/forgotPasswordActions'
import { pageTitles } from '../../router/routes'
import { Helmet } from 'react-helmet'

type ForgotPasswordPageProps = {
  recoverUsernameSuccess: boolean
  resetRecover: () => void
}

const ForgotPasswordPage: FC<ForgotPasswordPageProps> = ({ recoverUsernameSuccess, resetRecover }) => {
  useEffect(() => resetRecover, [resetRecover])
  return (
    <Layout>
      <Helmet>
        <title>{pageTitles.forgotPassword}</title>
      </Helmet>
      <div className="d-flex align-items-center content-background content-unauthenticated-background-min-height py-5">
        <Container className="max-grid-width py-0 content-padding" fluid>
          <Row className="px-0 justify-content-center">
            <Col xs={12} lg={6}>
              {recoverUsernameSuccess ? <ForgotPasswordSuccess /> : <ForgotPasswordForm />}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  const { success } = state.forgotPassword.submit
  return { recoverUsernameSuccess: success }
}

const mapDispatchToProps = (dispatch) => ({
  resetRecover: () => dispatch(resetRecover()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage)
