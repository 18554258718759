import { BaseAction } from '../commons'
import { FieldValue } from '../registerAccountActions.types'
import {
  ReferralDataAction,
  ReferralField,
  ReferralSectionAction,
  ReferralSectionErrorsAction,
  ReferralSectionType,
  ReferralPracticeDataAction,
  RemovePracticeAction,
  ReferralPracticesErrorsAction,
} from './referralActions.types'

export const REFERRAL_PREFILL = 'REFERRAL/PREFILL'
export const REFERRAL_UPDATE_FIELD = 'REFERRAL/UPDATE_PATIENT_DETAILS'
export const REFERRAL_UPDATE_PRACTICE_FIELD = 'REFERRAL/UPDATE_PRACTICE_DETAILS'
export const REFERRAL_CLEAR = 'REFERRAL/CLEAR'
export const REFERRAL_NAVIGATE = 'REFERRAL/NAVIGATE'
export const REFERRAL_NAVIGATE_BACK = 'REFERRAL/NAVIGATE_BACK'
export const REFERRAL_ADD_PRACTICE = 'REFERRAL/ADD_PRACTICE'
export const REFERRAL_REMOVE_PRACTICE = 'REFERRAL/REMOVE_PRACTICE'
export const REFERRAL_VALIDATE_SECTION_SUCCESS = 'REFERRAL/VALIDATE_SECTION_SUCCESS'
export const REFERRAL_VALIDATE_SECTION_FAIL = 'REFERRAL/VALIDATE_SECTION_FAIL'
export const REFERRAL_VALIDATE_PRACTICES_FAIL = 'REFERRAL/VALIDATE_PRACTICES_SECTION_FAIL'
export const REFERRAL_SUBMIT = 'REFERRAL/SUBMIT'
export const REFERRAL_SUBMIT_SUCCESS = 'REFERRAL/SUBMIT_SUCCESS'
export const REFERRAL_SUBMIT_FAIL = 'REFERRAL/SUBMIT_FAIL'

export const preFillReferral = (prefillData: Record<string, string | boolean>): ReferralDataAction => ({
  type: REFERRAL_PREFILL,
  data: prefillData,
})

export const updateReferralField = (type: ReferralField, value: FieldValue): ReferralDataAction => ({
  type: REFERRAL_UPDATE_FIELD,
  data: {
    [type]: value,
  },
})

export const updatePracticeField = (index: number, type: string, value: string): ReferralPracticeDataAction => ({
  type: REFERRAL_UPDATE_PRACTICE_FIELD,
  data: {
    practiceDetails: {
      [type]: value,
    },
    index,
  },
})

export const clearReferral = (): BaseAction => ({
  type: REFERRAL_CLEAR,
})

export const navigateToSection = (sectionId: ReferralSectionType): ReferralSectionAction => ({
  type: REFERRAL_NAVIGATE,
  data: { section: sectionId },
})

export const navigateBack = (sectionId: ReferralSectionType): ReferralSectionAction => ({
  type: REFERRAL_NAVIGATE_BACK,
  data: { section: sectionId },
})

export const addPractice = (): BaseAction => ({
  type: REFERRAL_ADD_PRACTICE,
})

export const removePractice = (index: number): RemovePracticeAction => ({
  type: REFERRAL_REMOVE_PRACTICE,
  data: { index },
})

export const validateSectionSuccess = (sectionId: ReferralSectionType): ReferralSectionAction => ({
  type: REFERRAL_VALIDATE_SECTION_SUCCESS,
  data: { section: sectionId },
})

export const validateSectionFail = (errors: Record<string, boolean>): ReferralSectionErrorsAction => ({
  type: REFERRAL_VALIDATE_SECTION_FAIL,
  data: { errors },
})

export const validatePracticesFail = (
  practicesDetailErrors: Record<string, boolean>[],
): ReferralPracticesErrorsAction => ({
  type: REFERRAL_VALIDATE_PRACTICES_FAIL,
  data: { practicesDetailErrors },
})

export const submitReferral = (): BaseAction => ({
  type: REFERRAL_SUBMIT,
})

export const submitReferralSuccess = (): BaseAction => ({
  type: REFERRAL_SUBMIT_SUCCESS,
})

export const submitReferralFail = (): BaseAction => ({
  type: REFERRAL_SUBMIT_FAIL,
})
