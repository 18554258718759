import { submitStates } from '../commons/submitControls'
import { Status } from '../../domain/models/commons/Report'
import text from '../../_constants/text'
import { Search } from '../commons/types'
import createSearchReducer from '../commons/createSearchReducer'

export const defaultAppointmentSearchInputs: Search = {
  nameOrPatientId: '',
  dob: '',
  dateRange: text.SEARCH_DATE_RANGE_TODAY,
  modalities: [text.ALL_MODALITIES],
  practices: [text.ALL_PRACTICES],
  orderStatus: [Status.APPOINTMENT_PENDING, Status.PENDING],
  searchType: text.SEARCH_REFERRED_BY_ME,
}

export const initialAppointmentSearchState = {
  firstLoad: true,
  activeSearchType: text.SEARCH_REFERRED_BY_ME,
  searchErrors: {},
  currentSearch: defaultAppointmentSearchInputs,
  activeSearch: defaultAppointmentSearchInputs,
  submit: {
    ...submitStates.default,
  },
  selectedPractices: [text.ALL_PRACTICES],
}

const appointmentSearchReducer = createSearchReducer(
  'appointment',
  initialAppointmentSearchState,
  defaultAppointmentSearchInputs,
)

export default appointmentSearchReducer
