import {
  PracticeDetailsType,
  RegistrationFormType,
} from '../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { AccountTypes, ApiPractice, RegisterAccountApiRequest } from './mapRegisterAccountStateToApi.types'

const map = (practices: PracticeDetailsType[], answers: RegistrationFormType): RegisterAccountApiRequest => {
  const mapped: RegisterAccountApiRequest = {
    practices: practices.map((p: PracticeDetailsType): ApiPractice => ({ ...p, practiceStreet: p.practiceAddress })),
    password: answers.password,
    confirmPassword: answers.password,
    email: answers.email,
    firstName: answers.firstName,
    lastName: answers.lastName,
    ahpraNumber: answers.ahpraNumber.trim().toUpperCase(),
    preferredPhone: answers.mobile,
    mobile: answers.mobile,
    accountType: AccountTypes.NEW,
  }
  if (answers.userid) {
    mapped.accountType = AccountTypes.PACS
    mapped.userid = answers.userid
  }
  return mapped
}
export default map
