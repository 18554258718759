import React from 'react'
import { Card } from 'react-bootstrap'
import text from '../../../_constants/text'
import CallToAction from '../../../components/molecules/CallToAction'
import icons from '../../../_constants/icons'
import { CtaTypes } from '../../../domain/constants'
import { AccessToken } from '../../../reducers/LoginReducer/LoginReducer'
import { UrlParams } from '../../../_constants/variables'

interface PatientReportCardProps {
  report: string
  reportUri: string
  orderId: string
  referralPdfUri: string
  token: AccessToken
  handleConsentValidityCb: (link: string, target: string) => void
  breakGlass: boolean
  isReportReady: boolean
  isLoading: boolean
}

export const getAttachmentLink = (referralPdfUri: string, token: AccessToken, breakGlass: boolean): string =>
  `${process.env.REACT_APP_API_URL}/imedvisage/attachment?attachmentUri=${referralPdfUri}&token=${token.type} ${
    token.token
  }${!breakGlass ? `&${UrlParams.BREAK_GLASS}=true` : ''}`

export const getPdfLink = (reportUri: string, order: string, token: AccessToken, breakGlass: boolean): string =>
  `${process.env.REACT_APP_API_URL}/imedvisage/reportPdf?reportUri=${reportUri}&orderUri=%2Forder%2F${order}&token=${
    token.type
  } ${token.token}${!breakGlass ? `&${UrlParams.BREAK_GLASS}=true` : ''}`

const PatientReportCard: React.FC<PatientReportCardProps> = ({
  report,
  orderId,
  reportUri,
  isReportReady,
  isLoading,
  referralPdfUri,
  token,
  handleConsentValidityCb,
  breakGlass,
}) => {
  const reportContent = isReportReady ? report : text.REPORT_PENDING_MESSAGE
  const pdfLink = reportUri && getPdfLink(reportUri, orderId, token, breakGlass)

  const shouldShowDownloadReportBtn = isReportReady && pdfLink

  return (
    <Card data-testid="PatientReportCard" as="section" className="mb-4">
      <Card.Body>
        <Card.Title as="h2" className="mb-4">
          {text.REPORT}
        </Card.Title>
        <div className="d-flex flex-column flex-lg-row mb-4">
          {shouldShowDownloadReportBtn && (
            <CallToAction
              action={{
                id: pdfLink,
                value: {
                  link: pdfLink,
                  icon: icons.PDF,
                  buttonText: text.VIEW_REPORT,
                },
                type: CtaTypes.LINK_WITH_PREVALIDATION,
              }}
              isDownloadable={false}
              onClick={() => {
                handleConsentValidityCb(pdfLink, '_blank')
              }}
            />
          )}
          {referralPdfUri && (
            <CallToAction
              action={{
                id: referralPdfUri,
                value: {
                  link: getAttachmentLink(referralPdfUri, token, breakGlass),
                  icon: icons.ARTICLE,
                  buttonText: text.VIEW_REFERRAL,
                },
                type: CtaTypes.LINK_WITH_PREVALIDATION,
              }}
              isDownloadable={true}
              onClick={() => {
                handleConsentValidityCb(getAttachmentLink(referralPdfUri, token, breakGlass), '_blank')
              }}
            />
          )}
        </div>
        {!isLoading && (
          <div
            data-testid="PatientReportCardContent"
            className="report mb-2"
            dangerouslySetInnerHTML={{ __html: reportContent }}
          />
        )}
      </Card.Body>
    </Card>
  )
}

export default PatientReportCard
