import React, { FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react'
import { Link } from 'react-router-dom'

type InternalLinkProps = {
  className?: string
  href: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
}
const InternalLink: FunctionComponent<PropsWithChildren<InternalLinkProps>> = ({
  href,
  className,
  onClick,
  children,
}) => {
  if (!onClick) {
    return (
      <Link className={className} to={href} data-testid="internal-link">
        {children}
      </Link>
    )
  }
  return (
    <Link className={className} to={href} onClick={onClick}>
      {children}
    </Link>
  )
}

export default InternalLink
