export const pageContent = {
  HERO_SECTION: {
    pageHeading: 'Your privacy',
    pageSummary:
      'We are committed to protecting our referring health professionals’, patients’ and prospective patients’ privacy through explaining our practices, only collecting, using or sharing information that is necessary to provide our services, or with your consent, and resolving any privacy-related problems as quickly as possible.',
    heroImage: undefined,
  },
  CONTENT: [
    {
      id: 'Privacy-Policy-1',
      type: 'textsection',
      value: {
        title: 'Privacy statement',
        text:
          '<p>This Privacy Statement sets out how the I-MED Group, including I-MED Radiology Network Limited and its associated companies (together&nbsp;<strong>I-MED</strong>), collects, uses, shares and otherwise handles and holds personal information in accordance with the&nbsp;<em>Privacy Act 1988</em>&nbsp;(Cth) and other Australian privacy laws, where relevant.</p>\n' +
          '<p><a data-asset-id="21364a28-84b6-4f37-9b77-e68a4582f5b8" href="https://assets-us-01.kc-usercontent.com:443/0f1bf390-09c2-00ff-dbea-bab90e37fcfe/94019763-01c5-445f-b7d5-a21b26084f91/IMED_Your_Privacy.pdf"><strong>Download Privacy Statement as a printer friendly PDF</strong></a></p>\n' +
          '<p><a data-asset-id="1fa5959d-3269-4023-bc1d-dbab04edf2b7" href="https://assets-us-01.kc-usercontent.com:443/0f1bf390-09c2-00ff-dbea-bab90e37fcfe/3ff0fbd0-8a56-4017-a031-6061b1429873/I-MED_Privacy_Complaint_Form.pdf"><strong>Download Privacy Complaint Form</strong></a></p>',
      },
    },
    {
      id: 'Privacy-Policy-2',
      type: 'textsection',
      value: {
        title: 'Our approach to privacy',
        text:
          '<p>To protect your privacy, I-MED will:</p>\n' +
          '<ul>\n' +
          '  <li>only obtain health information necessary to provide our services to you;</li>\n' +
          '  <li>take all reasonable steps to keep your health information secure;</li>\n' +
          '  <li>give you access to the information we have about you in accordance with our obligations under privacy laws;</li>\n' +
          '  <li>not disclose your personal information outside I-MED except in accordance with this Privacy Statement or with your consent, unless we are legally authorised or required to do so;</li>\n' +
          '  <li>provide you with an accessible complaints mechanism;</li>\n' +
          '  <li>provide procedures and training to staff to ensure that privacy processes are followed;</li>\n' +
          '  <li>take steps to ensure I-MED’s external (including offshore) contractors comply with privacy laws; and</li>\n' +
          '  <li>continue to review and improve our privacy practices.</li>\n' +
          '</ul>',
      },
    },
    {
      id: 'Privacy-Policy-3',
      type: 'textsection',
      value: {
        title: 'Why and how we collect and hold your personal information',
        text:
          '<p><em><strong>Patients and prospective patients</strong></em></p>\n' +
          '<p>I-MED collects and retains personal information, including health information, about patients and prospective patients to provide:</p>\n' +
          '<ul>\n' +
          '  <li>medical imaging services, which assist in providing health care to patients and prospective patients; and</li>\n' +
          '  <li>patient-related services to Referring Health Professionals and/or other health service providers.&nbsp;</li>\n' +
          '</ul>\n' +
          '<p>We collect personal information directly from you wherever possible, and we will usually do this when you attend our clinics, offices or other sites, but we may also obtain it by phone or through our websites. &nbsp;</p>\n' +
          '<p>We may also collect information about you from third parties, including from your treating health professionals, medical specialist, and/or from pathology companies or other health services that hold your results.</p>\n' +
          '<p>If you are a patient or prospective patient, health&nbsp;information&nbsp;that may be collected from third parties and held by &nbsp;&nbsp;&nbsp;I-MED includes:</p>\n' +
          '<ul>\n' +
          '  <li>&nbsp;the results of any tests or procedures;</li>\n' +
          '  <li>your clinical history (e.g. medication taken, previous test results, pathology results) and other circumstances (including family, social, medical or employment history);</li>\n' +
          '  <li>information provided by your Referring Health Professional and/or medical specialist that is necessary for the services we provide;</li>\n' +
          '  <li>information provided by other third parties (such as from a family member, authorised representative, or allied health professional (such as a physiotherapist, chiropractor, osteopath, podiatrist, dentist or nurse)); and</li>\n' +
          '  <li>payment and administrative information (such as your Medicare number and other individual health identifiers, workers compensation insurance, transport accident compensation, private health insurance or billing details).</li>\n' +
          '</ul>\n' +
          '<p>You can choose to deal with us anonymously or by using another name or nickname (that is pseudonymously).&nbsp; However, if you choose not to provide your personal information to I-MED, we may be unable to provide you or your Referring Health Professional with the requested services.<br>\n' +
          '</p>\n' +
          '<p><em><strong>Health professionals</strong></em></p>\n' +
          '<p>We collect and hold personal information about health professionals who refer patients to us for testing (<strong>Referring Health Professionals</strong>), medical specialists and hospital and clinic staff, other health service providers, individuals who supply goods and services to I-MED and other individuals to facilitate our business activities and carry out our services.</p>\n' +
          '<p>Personal information is also collected from, and about, employment applicants.</p>',
      },
    },
    {
      id: 'Privacy-Policy-4',
      type: 'textsection',
      value: {
        title: 'How we use your personal information',
        text:
          '<p>If you are a patient or potential patient, I-MED will use your personal information to:</p>\n' +
          '<ul>\n' +
          '  <li>provide imaging and other health services;</li>\n' +
          '  <li>make an assessment of your health status;</li>\n' +
          '  <li>provide a specialist report to your Referring Health Professional and/or medical specialist or to third parties where required or authorised to do so (such as to a family member, authorised representative, insurer or allied health professional);</li>\n' +
          '  <li>verify your identity if you ask for a copy of a report of any imaging services provided by I-MED;</li>\n' +
          '  <li>carry out internal administrative services (such as billing and collecting any outstanding debts);</li>\n' +
          '  <li>send out appointment reminders;</li>\n' +
          '  <li>notify relevant organisations (such as medical insurers and/or legal advisors) of an incident/accident, including when a claim is made against I-MED;</li>\n' +
          '  <li>provide training and continuing education of professional personnel (all information is de-identified prior to this use);</li>\n' +
          '  <li>undertake quality assurance activities, practice accreditation, customer satisfaction surveys, statistical analysis and complaint handling; and</li>\n' +
          '  <li>respond to messages/enquiries you submit through our websites.</li>\n' +
          '</ul>\n' +
          '<p>If you are a candidate seeking employment with I-MED, we will use your personal information to process your application and assess your suitability for any role. We may retain your information for future reference.</p>\n' +
          '<p>If you are a health professional who refers patients to us for testing, a medical specialist, hospital and clinic staff member, other health service provider&nbsp;or&nbsp;an individual who supplies (or is a representative of a supplier of) goods and services to I-MED we will use your&nbsp;personal information&nbsp;for our legitimate business and service dealings with you.</p>',
      },
    },
    {
      id: 'Privacy-Policy-5',
      type: 'textsection',
      value: {
        title: 'Sharing your personal information (disclosure)',
        text:
          '<p>I-MED shares personal information&nbsp;about our patients&nbsp;with&nbsp;other organisations&nbsp;or&nbsp;persons in&nbsp;specified circumstances.&nbsp;</p>\n' +
          '<p>I-MED will sometimes need to share personal information with persons or organisations outside I-MED for medical, insurance, legal, management and/or procedural reasons, which include:</p>\n' +
          '<ul>\n' +
          "  <li>&nbsp;your Referring Health Professional, employees and other health professionals in your Referring Health Professional's clinic and any other health professional that your Referring Health Professional has asked us to copy our report to (such as your GP or another specialist);</li>\n" +
          '  <li>consultant medical specialists or other registered health professionals involved in your ongoing health care outside of I-MED and who have been requested to provide further advice on your medical condition;</li>\n' +
          '  <li>hospital staff;</li>\n' +
          '  <li>registered health professionals granted access to I-MED’s secure web-based password-protected Referrer Portal (see below);</li>\n' +
          '  <li>local and offshore contractors who we have partnered with to provide services to our business operations. &nbsp;When we engage those parties we require them to enter into an arrangement with us to comply with privacy and confidentiality requirements;</li>\n' +
          '  <li>your representative(s) (e.g. a guardian, carer, translator/intermediary and/or authorised representative) unless you have told us that you do not wish us to disclose information to them;</li>\n' +
          '  <li>health services, other health professionals or enforcement bodies in situations where I-MED is informed that there is a serious threat to life, health or safety;</li>\n' +
          '  <li>insurers for the purpose of benefits payable or other third parties for billing/accounting purposes (such as Medicare, workers compensation insurers, transport accident insurers or your private health fund);</li>\n' +
          '  <li>our professional advisors (such as auditors and legal advisors);</li>\n' +
          '  <li>government and regulatory authorities and other organisations, where required or authorised by or under an Australian law;</li>\n' +
          '  <li>I-MED’s associated entities (as that term is defined in the&nbsp;<em>Corporations Act 2001</em>&nbsp;(Cth)) within our corporate group structure; and</li>\n' +
          '  <li>research bodies.</li>\n' +
          '</ul>\n' +
          '<p>I-MED will only provide your health information to research bodies where:</p>\n' +
          '<ul>\n' +
          '  <li>it is reasonably necessary for research relevant to public health and the research cannot be undertaken by providing de-identified information;</li>\n' +
          '  <li>I-MED is satisfied all requirements for privacy and confidentiality by the research body have been met and I-MED reasonably believes that the research body will not further share or use the personal information;</li>\n' +
          '  <li>providing the information is in accordance with the National Health and Medical Research Council Guidelines approved under section 95A of the&nbsp;<em>Privacy Act 1988&nbsp;</em>(Cth) or other relevant State/Territory Privacy Commissioner’s guidelines about sharing health information for research (as applicable);&nbsp;and</li>\n' +
          '  <li>the research and the sharing of information has been approved by a recognised Human Research Ethics Committee.</li>\n' +
          '</ul>\n' +
          '<p>You can contact the I-MED Privacy Officer at the contact details below if you have any questions about the disclosure of your personal information, including to I-MED’s offshore contractors and the countries in which our contractors are located.</p>\n' +
          '<p>I-MED will de-identify personal information to carry out evaluations of our service quality and timeliness, including providing the de-identified information to other parties to assist us with these activities.</p>',
      },
    },
    {
      id: 'Privacy-Policy-6',
      type: 'textsection',
      value: {
        title: 'Access to and correction of your information',
        text:
          '<p>You may request access to personal information we hold about you, including reports of any imaging services provided by I-MED. If you ask for a copy of a report of any imaging services provided by I-MED, we may request personal information (such as your Medicare number and requesting doctor, date of birth, mobile number or email address) from you to verify your identity before providing the requested information. In some instances, charges may apply to provide copies of results or images.&nbsp; We will tell you about any costs before they are incurred. In some limited circumstances we may refuse your request.</p>\n' +
          '<p>The best way to obtain your results is in consultation with your Referring Health Professional, so that they can interpret the results and explain them to you.&nbsp; Your imaging findings are only one aspect of your health assessment and your Referring Health Professional is in the best position to discuss the results and explain it along with your other findings and health test results.</p>\n' +
          '<p>I-MED endeavours to ensure that the personal information we collect, use and disclose is accurate, up-to-date and complete. The accuracy and completeness of that information depends on the information you provide to us.&nbsp; Please let us know:</p>\n' +
          '<ul>\n' +
          '  <li>if there are any errors in the information we hold; and</li>\n' +
          '  <li>of any changes to your information (such as your name, address, phone number or Medicare number).</li>\n' +
          '</ul>\n' +
          '<p>You may request access to, and/or correction of, your personal information by contacting the&nbsp;<a href="https://i-med.com.au/find-a-clinic" data-new-window="true" title="Find a clinic" target="_blank" rel="noopener noreferrer">local I-MED clinic</a>&nbsp;where your information is held.&nbsp;</p>\n' +
          '<p>If we refuse your request for access to or correction of your personal information, we will tell you why (unless it is unreasonable to do so). You may complain about our refusal (see Complaints section below). &nbsp;</p>\n' +
          '<p>If you intend to have another person collect your images on your behalf, you must provide written consent and tell us the name of the person collecting your images.</p>',
      },
    },
    {
      id: 'Privacy-Policy-7',
      type: 'textsection',
      value: {
        title: 'Data security',
        text:
          '<p>We take all reasonable steps to protect your personal information from misuse, interference and loss, and from unauthorised access, modification or disclosure. Records are held securely for future retrieval in accordance with applicable laws and good business practice.&nbsp; If I-MED no longer needs, or is no longer required, to retain information, I-MED will take reasonable steps to destroy the information or will ensure that the information is de-identified.</p>\n' +
          '<p><strong>Online access by registered Health Professionals to your medical images and reports</strong></p>\n' +
          '<p>I-MED provides a secure web-based password-protected portal for health professionals and hospital medical staff to access patient images and reports (the&nbsp;<strong>Referrer Portal</strong>).&nbsp; Your health information (such as images and reports) may be made available to health professionals through the Referrer Portal. &nbsp;</p>\n' +
          '<p>Health professionals must apply to I-MED for a username and password in order to access the Referrer Portal.&nbsp; Before being granted online access they must accept the terms set out in a User Access Agreement stating that the information is required in order to provide a medical service to patients and that it will not be used or shared for any other purpose. In addition, users of the Referrer Portal are bound by privacy laws, codes of practice, employer policies and the legal and ethical obligations of professional confidentiality relevant to their profession.&nbsp;Our systems trace, record and store indefinitely all access activity on every patient file.&nbsp;</p>\n' +
          '<p>Your health information will be accessible online via the Referrer Portal to your Referring Health Professional (e.g. to your family doctor or specialist), any other health professional who the Referring Health Professional has asked to be copied in the referral and other persons who I-MED records show are currently involved in your care (such as hospital staff).&nbsp;</p>\n' +
          '<p>Additionally, I-MED may provide electronic access to your health information to other health professionals, specialists, allied health professional or hospital medical staff who have signed a User Access Agreement with I-MED if they become involved in your care.&nbsp; You or another person acting for you (such as a parent acting for a child) can indicate on our Privacy Collection Statement when you attend your local clinic for testing or contact us at any time using the contact details provided, if you do not want your records to be made available to another health professional.</p>',
      },
    },
    {
      id: 'Privacy-Policy-8',
      type: 'textsection',
      value: {
        title: 'Complaints',
        text:
          '<p>If you wish to complain about how we have handled your personal information, please send your complaint to the&nbsp;I-MED Privacy Officer at the address below.&nbsp;&nbsp;Alternatively, you can complete a Privacy Complaint Form&nbsp;<a href="https://www.i-med.com.au/wps/wcm/connect/i-med/242a18aa-8a77-4187-ae26-847677fa625d/I-MED_Privacy_Complaint_Form.pdf?MOD=AJPERES&amp;CVID=lCbqnF3&amp;CVID=lCbqnF3&amp;CVID=lCbqnF3&amp;CVID=lCbqnF3&amp;CVID=lCbqnF3&amp;CVID=lCbqnF3&amp;CVID=lypRcDK&amp;CVID=lypRcDK">here.</a>&nbsp;The complaint will be investigated and a response will be sent to you as quickly as possible (generally within 30 days of receipt of the complaint). If you are not satisfied with the response, you can contact the Office of the Australian Information Commissioner.</p>\n' +
          '<p><strong>Your privacy is important to I-MED.</strong></p>\n' +
          '<p><strong>Contact Details:</strong><br>\n' +
          'I-MED Radiology Network Limited<br>\n' +
          'Attention: Privacy Officer<br>\n' +
          'GPO Box 514, Sydney NSW 2001<br>\n' +
          'Phone: (02) 8274 1000<br>\n' +
          'Fax: (02) 8274 1077</p>',
      },
    },
    {
      id: 'Privacy-Policy-9',
      type: 'textsection',
      value: {
        title: 'Cookies',
        text: '<p>This site uses cookies to store information on your computer. Some of these cookies are essential to make our site work and others help us to improve the user experience. To view a list of the cookies on this website and related I-MED sites, <a data-asset-id="31d53b7b-2f32-421b-8f55-5227edff85a5" href="https://assets-us-01.kc-usercontent.com:443/0f1bf390-09c2-00ff-dbea-bab90e37fcfe/1c926745-c002-424e-8ed8-38f67d3b3d17/Cookie%20Notice%20Document_Nov_2020_6.11.2020.pdf">click here</a>. You can change the settings for your cookies within your browser.</p>',
      },
    },
  ],
}

export default pageContent
