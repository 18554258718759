import { PageTypes } from '../../domain/constants'
import routes from '../../router/routes'
import urls from '../../_constants/urls'

export const adminNav = {
  id: 'root_4ce41710-27d7-4a6b-b1fc-c6dcbee4bad3',
  type: 'navigation_item',
  value: {
    icon: '',
    name: '',
    children: [
      {
        id: 'admin',
        type: 'navigation_item',
        value: {
          icon: '',
          name: '',
          children: [
            {
              id: 'admin-dropdown',
              type: 'navigation_item',
              value: {
                icon: '',
                name: 'Admin',
                children: [],
                navigation: [
                  {
                    id: 'approve-accounts',
                    type: PageTypes.ARTICLE_DETAIL,
                    value: {
                      name: 'Approve Accounts',
                      url: '/approve',
                    },
                  },
                  {
                    id: 'manage-accounts',
                    type: PageTypes.ARTICLE_DETAIL,
                    value: {
                      name: 'Manage Accounts',
                      url: '/manage',
                    },
                  },
                  {
                    id: 'deactive-accounts',
                    type: PageTypes.ARTICLE_DETAIL,
                    value: {
                      name: 'Deactivate accounts and emails',
                      url: '/deactive',
                    },
                  },
                  {
                    id: 'manage-db',
                    type: PageTypes.ARTICLE_DETAIL,
                    value: {
                      name: 'Manage DB and CRM',
                      url: '/db',
                    },
                  },
                ],
              },
            },
            {
              id: 'find_a_clinic_d5491a82-a204-44e7-a42b-3a02c84ff08b',
              type: 'navigation_item',
              value: {
                icon: '',
                name: 'Account details',
                children: [],
                navigation: [
                  {
                    id: 'untitled_content_item_c6c5ea2_c6c5ea2b-74ee-4d51-ad09-3b1981f4268b',
                    type: PageTypes.ARTICLE_DETAIL,
                    value: {
                      name: 'Account details',
                      url: '/account',
                    },
                  },
                ],
              },
            },
          ],
          navigation: [],
        },
      },
    ],
  },
}

export const referrerNav = {
  id: 'root_4ce41710-27d7-4a6b-b1fc-c6dcbee4bad3',
  type: 'navigation_item',
  value: {
    icon: '',
    name: '',
    children: [
      {
        id: 'default',
        type: 'navigation_item',
        value: {
          icon: '',
          name: '',
          children: [
            {
              id: 'find_a_clinic_d5491a82-a204-44e7-a42b-3a02c84ff07b',
              type: 'navigation_item',
              value: {
                icon: '',
                name: 'Patient Search',
                children: [],
                navigation: [
                  {
                    id: 'untitled_content_item_c6c5ea2_c6c5ea2b-74ee-4d51-ad09-3b1981f4267b',
                    type: PageTypes.ARTICLE_DETAIL,
                    value: {
                      name: 'Patient search',
                      url: '/results',
                    },
                  },
                ],
              },
            },
            {
              id: 'ereferral',
              type: 'navigation_item',
              value: {
                icon: '',
                name: 'Send e-Referral',
                children: [],
                navigation: [
                  {
                    id: 'untitled_content_item_c6c5ea2_c6c5ea2b-74ee-4d51-ad09-3b1981f4267b',
                    type: PageTypes.ARTICLE_DETAIL,
                    value: {
                      name: 'Send e-referral',
                      url: routes.referral,
                    },
                  },
                ],
              },
            },
            {
              id: 'appointments',
              type: 'navigation_item',
              value: {
                icon: '',
                name: 'Patient Appointments',
                children: [],
                navigation: [
                  {
                    id: 'untitled_content_item_c6c5ea2_c6c5ea2b-74ee-4d51-ad09-3b1981f4267b',
                    type: PageTypes.I_MED_APPOINTMENT,
                    value: {
                      name: 'Patient Appointments',
                      url: routes.appointments,
                    },
                  },
                ],
              },
            },
            {
              id: 'support',
              type: 'navigation_item',
              value: {
                icon: '',
                name: 'Support',
                children: [],
                navigation: [
                  {
                    id: 'untitled_content_item_c6c5ea2_c6c5ea2b-74ee-4d51-ad09-3b1981f4267b',
                    type: PageTypes.SUPPORT_PAGE,
                    value: {
                      name: 'Support',
                      url: urls.SUPPORT,
                      external: true,
                    },
                  },
                ],
              },
            },
            {
              id: 'find_a_clinic_d5491a82-a204-44e7-a42b-3a02c84ff08b',
              type: 'navigation_item',
              value: {
                icon: '',
                name: 'My Account',
                children: [],
                navigation: [
                  {
                    id: 'untitled_content_item_c6c5ea2_c6c5ea2b-74ee-4d51-ad09-3b1981f4268b',
                    type: PageTypes.ARTICLE_DETAIL,
                    value: {
                      name: 'My Account',
                      url: '/account',
                    },
                  },
                ],
              },
            },
          ],
          navigation: [],
        },
      },
    ],
  },
}
