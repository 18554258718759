import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import SingleLineTextInputField from '../../../../components/forms/fields/SingleLineTextInputField/SingleLineTextInputField'
import DropdownSelect from '../../../../components/forms/fields/DropdownSelect/DropdownSelect'
import {
  PracticeDetailsErrorsType,
  PracticeDetailsType,
} from '../../../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { ReactComponent as AddIcon } from './add_circle.svg'
import { ReactComponent as RemoveIcon } from './remove_circle.svg'
import text from '../../../../_constants/text'

interface PracticeDetailsProps {
  onPracticeValueChange: (index: number, type: string, value: string) => void
  practices: PracticeDetailsType[]
  practicesErrors: PracticeDetailsErrorsType[]
  addPractice: () => void
  removePractice: (index: number) => void
}

const PracticeDetails: React.FC<PracticeDetailsProps> = ({
  onPracticeValueChange,
  practices,
  practicesErrors,
  addPractice,
  removePractice,
}) => {
  const onValueChange = (name: string, value: string, i: number) => {
    onPracticeValueChange(i, name, value)
  }

  return (
    <>
      {practices.map((practice: PracticeDetailsType, i: number) => (
        <React.Fragment key={`practice_${i}`}>
          <Row>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.PRACTICE_NAME}
                inputName="practiceName"
                required={false}
                value={practice.practiceName}
                onChangeCb={(name, value) => onValueChange(name, value, i)}
                error={practicesErrors[i].practiceName}
                errorMessage={text.FIELD_REQUIRED}
                placeholder={text.PRACTICE_NAME_PLACEHOLDER}
              />
            </Col>
            <Col xs={12} lg={6}>
              <DropdownSelect
                label={text.PRACTICE_TYPE}
                inputName="practiceType"
                value={practice.practiceType}
                onChangeCb={(name, value) => onValueChange(name, value, i)}
                required={false}
                errorMessage={text.FIELD_REQUIRED}
                error={practicesErrors[i].practiceType}
                options={[
                  'General Practitioner',
                  'Medical Specialist',
                  'Dentist',
                  'Physiotherapist',
                  'Chiropractor',
                  'Allied Health - Other',
                  'Multi-Disciplinary',
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.PRACTICE_PHONE}
                inputName="practicePhone"
                required={false}
                error={practicesErrors[i].practicePhone}
                value={practice.practicePhone}
                onChangeCb={(name, value) => onValueChange(name, value, i)}
                errorMessage={text.FIELD_REQUIRED}
                placeholder="e.g. 0200112233"
              />
            </Col>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.PRACTICE_FAX}
                inputName="practiceFax"
                required={false}
                value={practice.practiceFax}
                onChangeCb={(name, value) => onValueChange(name, value, i)}
                error={practicesErrors[i].practiceFax}
                errorMessage={text.FIELD_REQUIRED}
                placeholder="e.g. 0200112233"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label="Provider number"
                inputName="providerNumber"
                required={false}
                value={practice.providerNumber}
                onChangeCb={(name, value) => onValueChange(name, value, i)}
                error={practicesErrors[i].providerNumber}
                errorMessage={text.PROVIDER_NUMBER_ERROR_MESSAGE}
                placeholder={text.PRACTICE_NUMBER_PLACEHOLDER}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <SingleLineTextInputField
                label={text.ADDRESS}
                inputName="practiceAddress"
                required={false}
                value={practice.practiceAddress}
                onChangeCb={(name, value) => onValueChange(name, value, i)}
                error={practicesErrors[i].practiceAddress}
                errorMessage={text.FIELD_REQUIRED}
                placeholder=""
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={5}>
              <SingleLineTextInputField
                label={text.SUBURB}
                inputName="practiceSuburb"
                required={false}
                value={practice.practiceSuburb}
                onChangeCb={(name, value) => onValueChange(name, value, i)}
                error={practicesErrors[i].practiceSuburb}
                errorMessage={text.FIELD_REQUIRED}
                placeholder=""
              />
            </Col>
            <Col xs={12} lg={3}>
              <SingleLineTextInputField
                label={text.POSTCODE}
                inputName="practicePostcode"
                required={false}
                value={practice.practicePostcode}
                onChangeCb={(name, value) => onValueChange(name, value, i)}
                error={practicesErrors[i].practicePostcode}
                errorMessage={text.FIELD_REQUIRED}
                placeholder=""
              />
            </Col>
            <Col xs={12} lg={4}>
              <DropdownSelect
                label={text.STATE}
                inputName="practiceState"
                required={false}
                value={practice.practiceState}
                onChangeCb={(name, value) => onValueChange(name, value, i)}
                error={practicesErrors[i].practiceState}
                errorMessage={text.FIELD_REQUIRED}
                options={[
                  'Queensland',
                  'New South Wales',
                  'Victoria',
                  'Australian Capital Territory',
                  'Northern Territory',
                  'Western Australia',
                  'South Australia',
                  'Tasmania',
                ]}
              />
            </Col>
          </Row>
          <Row className="d-flex">
            <Col xs={12} lg={6}>
              {practices.length - 1 === i && (
                <Button data-testid="AddPracticeButton" variant="light" onClick={addPractice}>
                  <span className="px-2">
                    <AddIcon />
                  </span>
                  <span>{text.ADD_ANOTHER_PRACTICE}</span>
                </Button>
              )}
            </Col>
            <Col xs={12} lg={6} className="d-flex justify-content-end">
              {practices.length > 1 && (
                <Button
                  data-testid="RemovePracticeButton"
                  variant="secondary"
                  className="px-0"
                  onClick={() => removePractice(i)}
                >
                  <span className="px-2 pl-0">
                    <RemoveIcon />
                  </span>
                  <span>{text.REMOVE_PRACTICE}</span>
                </Button>
              )}
            </Col>
          </Row>
          {practices.length - 1 !== i && (
            <Row className="p-4">
              <Col className="t-separator" />
            </Row>
          )}
        </React.Fragment>
      ))}
    </>
  )
}

export default PracticeDetails
