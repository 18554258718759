import { validatePassword } from '../../../../utils/passwordValidation'

export enum FieldValueType {
  code = 'code',
  password = 'password',
}

export const validateString = (valueType: string, required: boolean, value: string): boolean => {
  if (!required) {
    return true
  }
  if (required && !value) {
    return false
  }
  switch (valueType as FieldValueType) {
    case FieldValueType.password:
      return validatePassword(value)
    case FieldValueType.code:
      return value.length === 8
    default:
      return true
  }
}
