export const pageContent = {
  HERO_SECTION: {
    pageHeading: 'Terms of Use',
    pageSummary: undefined,
    heroImage: undefined,
  },
  CONTENT: [
    {
      id: 'Terms-of-Use',
      type: 'textsection',
      value: {
        title: 'I-MED User Access Agreement',
        text:
          '<p>In this User Access Agreement (&ldquo;<strong>Agreement</strong>&rdquo;), the terms "we", "us" and "our" means I-MED Radiology Network Limited (ACN 093 625 346) and each of our associated entities, and "you" and &ldquo;your&rdquo; means the individual or user agreeing to this Agreement.</p>\n' +
          '\n' +
          '<h3>Background</h3>\n' +
          '<p>A. Privacy law requires us to take reasonable steps to ensure that any personal information (including health and sensitive information) that we hold is protected from misuse, interference and loss, from unauthorised access, modification or disclosure, and is restricted to authorised persons for the provision of health services only (being the purpose for which it was collected).</p>\n' +
          '<p>B. We also need to ensure our electronic and information systems, including our RIS and PACS, servers, equipment, software and networks (whether accessed via I-MED Online, I-MED Online 2.0, WebComrad, InteleConnect, InteleBrowser or otherwise) (collectively, "<strong>I-MED Online</strong>") are protected from viruses, other malware and unauthorised access &ndash; and are used for their proper purpose.</p>\n' +
          '<p>C. We therefore require every individual and ED User (as those terms are defined below) who wish to use I-MED Online to agree to this Agreement, comply with the restrictions contained in it and to act in a way that maintains the privacy of our patients, referrers and staff and the security of I-MED Online.</p>\n' +
          '\n' +
          '<h3>Agreement</h3>\n' +
          '<p>1. <strong>By registering for and continuing to use an I-MED Online account, you agree to comply with the terms set out in this Agreement, and agree to comply with our <a href="https://i-med.com.au/website-terms-of-use" target="_blank">Website Terms of Use</a> that are found on our website www.i-med.com.au, as updated from time to time.</strong></p>\n' +
          '<p>2. <strong>This Agreement contains important information, including disclaimers and limitations of liability that apply to your access to I-MED Online</strong></p>\n' +
          '<p>3. This Agreement:</p>\n' +
          '<p>a) becomes binding on you on your acceptance of it when registering for your I-MED Online account; and</p>\n' +
          '<p>b) governs your ongoing access to and use of I-MED Online at all times.</p>\n' +
          '<p>4. All of the terms of, and obligations under, this Agreement apply to you as an individual.</p>\n' +
          '<p>5. To be eligible to register for and continue to hold an I-MED Online account, you represent and warrant that you are at time of registration, and will continue to be at all relevant times, a registered health practitioner.</p>\n' +
          '<p>6. In consideration of, amongst other things, our providing you with access to I-MED Online, you acknowledge and agree as follows:</p>\n' +
          "<p>a) <strong>(purpose)</strong> to access, copy, print and/or hold a patient's medical imaging records or other personal information available on or from I-MED Online, for the sole purpose of providing diagnostic or treatment services to that patient at that time and to not browse through or access, print, copy or hold any person&rsquo;s records for any other purpose;</p>\n" +
          "<p>b) <strong>(privacy)</strong> that you will respect patients' privacy and protect the confidentiality of all images and information (as health information) which you access I-MED Online and will comply with all privacy obligations under the Privacy Act 1988 (Cth) and all other relevant privacy laws and codes, including professional codes relating to patients&rsquo; privacy and confidentiality (&ldquo;<strong>Privacy Laws</strong>&rdquo;) in accessing, using, disclosing, printing or copying and/or holding information made available to you via I-MED Online, which information will form part of your patients&rsquo; confidential health records that you hold and you must protect them accordingly;</p>\n" +
          '<p>c) that you have read, agree to and will comply with this Agreement and our <a href="https://i-med.com.au/your-privacy" target="_blank">Privacy Statement (&ldquo;Your Privacy&rdquo;)</a> and you will comply with any other policies related to the security or privacy of electronic records that we notify you of from time to time;</p>\n' +
          '<p>d) <strong>(access details)</strong> to keep your username and password securely stored and absolutely confidential at all times and, except as specifically permitted in clause 7 below, take steps to ensure that no other person is able to ascertain your user name and password (including not providing them to any other person or leaving them in a place that can be accessed by other persons) and to change your password regularly;</p>\n' +
          '<p>e) <strong>(details up-to-date)</strong> to promptly notify us if any of your personal information held by us in connection with your access to I-MED Online changes or is inaccurate or incomplete;</p>\n' +
          '<p>f) <strong>(username and passwords)</strong> to immediately notify us if your username/s and/or password/s have been lost, misplaced or stolen;</p>\n' +
          '<p>g) <strong>(security)</strong> to take all reasonable steps to maintain the security and integrity of I-MED Online and the information systems, including by installing and maintaining on your information systems appropriate firewalls and malware prevention and detection program and ensuring that no unauthorised person can use your information systems to obtain access to I-MED Online and/or any patient personal information or confidential information you obtained via I-MED Online;</p>\n' +
          '<p>h) <strong>(appropriate use)</strong> to not submit, post, upload, email or otherwise send or transmit to I-MED Online anything that contains software viruses or any other computer code, files or programs designed to interrupt, harm, damage, destroy or limit the functionality of any part of I-MED Online or, otherwise, to not interfere with any part of I-MED Online;</p>\n' +
          '<p>i) <strong>(data breaches)</strong> to notify us immediately if you become aware of any actual or threatened breach of security or a data breach relating to I-MED Online or any personal information on or obtained via I-MED Online and to take all steps we reasonably require to assist us to manage or mitigate any risks, including to patients, and to investigate and/or report, as required, any threatened or actual security or data breach; and</p>\n' +
          '<p>j) <strong>(monitoring and compliance)</strong> to us conducting regular audits of your access to and use of I-MED Online for the purpose of monitoring compliance with this Agreement and to co-operate with any such audit undertaken by us or our agents.</p>\n' +
          '\n' +
          '<h3>Emergency and hospital department accounts</h3>\n' +
          '<p>7. If you are registering an account as an emergency department (or other hospital department or similar approved in writing by us) then, notwithstanding any other terms of this Agreement:</p>\n' +
          '<p>a) you may have and use a shared username and password for use by emergency department staff whom are registered health practitioners who you have authorised to do so (&ldquo;<strong>ED Users</strong>&rdquo;);</p>\n' +
          '<p>b) you must not provide the username and password to any person other than ED Users;</p>\n' +
          '<p>c) you must keep the emergency department username and password securely stored; and</p>\n' +
          '<p>d) you must procure that each of your ED Users accept and agree to this Agreement as they apply to them (see clause 8 below) before they access I-MED Online for the first time and you must ensure that they comply with it,</p>\n' +
          '<p>but, otherwise, the terms of and obligations under this Agreement apply to you.</p>\n' +
          '<p>8. If you are an ED User, you agree to and will comply with all provisions in clause 6 and you must immediately cease using the username and password to access I-MED Online once you leave the employ of or cease acting on behalf of the entity under which authority you were provided such username and/or password.</p>\n' +
          '<p>9. <strong>You indemnify and hold us harmless in relation to all losses, damages, actions, claims, costs or expenses which may be brought against, suffered or incurred by us as a direct or indirect result of your (or ED Users, as applicable) failing to comply with any relevant the terms and conditions of this Agreement including, without limitation, your failure to implement appropriate safeguards to maintain the security and integrity of I-MED Online and your (or your ED User&rsquo;s) failure to comply with any Privacy Laws.</strong></p>\n' +
          '<p>10. We may revoke your access to I-MED Online in our absolute discretion in the event of any breach, suspected or likely breach by you (or ED Users, as applicable) of this Agreement.</p>\n' +
          '<p>11. You agree that you are solely responsible for all costs associated with your use of I-MED Online including, without limitation, all telecommunication costs, data usage and systems to view information provided by I-MED Online.</p>\n' +
          '<p>12. We may revise this Agreement and the incorporated policies listed above from time to time and in such case, the revised version will be effective on and from the date we specify. In such case we will notify you in advance by posting a notice on our website and you agree to receive notices electronically in this manner.</p>\n' +
          '<p>13. By continuing to use I-MED Online after any changes to this Agreement become effective, you agree to comply with those changes. If you do not agree with the changes, or any provisions of this Agreement after you have signed up for an I-MED Online account, you may deactivate your account by <a href="mailto:referrer@i-med.com.au">contacting us</a>.</p>\n' +
          '<p>14. You may also deactivate your account for any reason by <a href="mailto:referrer@i-med.com.au">contacting us</a></p>\n' +
          '\n' +
          '<p><a href="https://i-medonline.com.au/referrer/files/public/user-access-agreement.pdf" rel="noreferrer" target="_blank" download>Download this I-MED Online User Access Agreement as a printable PDF</a></p>',
      },
    },
  ],
}

export default pageContent
