import { ContactInfo } from '../reducers/ContactInfoReducer/ContactInfoReducer'
import { BaseAction, UpdateFieldAction } from './commons'

export const CONTACT_INFO_UPDATE_DETAILS = 'CONTACT_INFO/UPDATE_DETAILS'
export const CONTACT_INFO_SUBMIT = 'CONTACT_INFO/SUBMIT'
export const CONTACT_INFO_EMAIL_INVALID = 'CONTACT_INFO/EMAIL_INVALID'
export const CONTACT_INFO_SUBMIT_SUCCESS = 'CONTACT_INFO/SUBMIT_SUCCESS'
export const CONTACT_INFO_SUBMIT_FAIL = 'CONTACT_INFO/SUBMIT_FAIL'
export const CONTACT_INFO_RESET = 'CONTACT_INFO/RESET'

export type ContactInfoFormFieldType = 'mobile' | 'email'

export const updateContactInfoField = (
  type: ContactInfoFormFieldType,
  value: string,
): UpdateFieldAction<ContactInfoFormFieldType> => ({
  type: CONTACT_INFO_UPDATE_DETAILS,
  data: {
    [type]: value,
  },
})

export const contactInfoSubmit = (): BaseAction => ({
  type: CONTACT_INFO_SUBMIT,
})

export const contactInfoEmailInvalid = (): BaseAction => ({
  type: CONTACT_INFO_EMAIL_INVALID,
})

export const contactInfoSubmitSuccess = (data: Partial<ContactInfo>): UpdateFieldAction<ContactInfoFormFieldType> => ({
  type: CONTACT_INFO_SUBMIT_SUCCESS,
  data,
})
export const contactInfoSubmitFail = (): BaseAction => ({
  type: CONTACT_INFO_SUBMIT_FAIL,
})
export const contactInfoReset = (): BaseAction => ({
  type: CONTACT_INFO_RESET,
})
