import { Button, Card } from 'react-bootstrap'
import text from '../../../_constants/text'
import urls from '../../../_constants/urls'
import ExternalLink from '../../../components/atoms/ExternalLink'

const OrderReferralPadsCard: React.FC = () => {
  return (
    <Card as="section">
      <Card.Body>
        <Card.Title as="h2" className="mb-4">
          {text.ORDER_REFERRAL_PADS}
        </Card.Title>
        <ExternalLink href={urls.ORDER_REFERRAL_PADS} newTab={true}>
          <Button className="mt-4">{text.CLICK_TO_ORDER}</Button>
        </ExternalLink>
      </Card.Body>
    </Card>
  )
}

export default OrderReferralPadsCard
