import { BaseAction, DataAction } from '../../actions/commons'
import { PATIENT_SEARCH_SEARCH_SUCCESS } from '../../actions/PatientSearchActions/patientSearchActions'
import {
  GetPatientStudiesData,
  GetPatientStudiesResponseData,
  PatientImagesInfoAbortedData,
  PATIENT_STUDIES_GET_STUDIES,
  PATIENT_STUDIES_GET_STUDIES_FAIL,
  PATIENT_STUDIES_GET_STUDIES_SUCCESS,
} from '../../actions/PatientStudiesActions/patientStudiesActions'
import { SubmitControls, submitStates } from '../commons/submitControls'
import groupBy from 'lodash/groupBy'
import merge from 'lodash/merge'
import unionBy from 'lodash/unionBy'
import {
  ImagesInfoData,
  ImagesInfoResponse,
  PATIENT_IMAGES_INFO,
  PATIENT_IMAGES_INFO_ABORTED,
  PATIENT_IMAGES_INFO_SUCCESS,
} from '../../actions/PatientImagesActions/patientImagesActions'
import { APPOINTMENT_SEARCH_SEARCH_SUCCESS } from '../../actions/AppointmentSearchActions/appointmentSearchActions'
import { Study } from '../../domain/models/commons/Study'

export type PatientStudiesState = {
  byPatientId: {
    [patientId: string]: Study[]
  }
  submitById: {
    [patientId: string]: SubmitControls & { imagesInfoAborted?: boolean }
  }
}

export const initialPatientStudiesState: PatientStudiesState = {
  byPatientId: {},
  submitById: {},
}

function mapDicomLoadingToSearchResult(studies: Study[]): Study[] {
  return studies.map((study) => {
    if (study.dicom && study.dicom.state.success) {
      return study
    } else {
      return {
        ...study,
        dicom: {
          data: [],
          state: submitStates.inProgress,
        },
      }
    }
  })
}

function mapDicomToSearchResult(studies: Study[], response: ImagesInfoResponse): Study[] {
  return studies.map((study) => {
    const studyAccessionNumbers = Study.getAccessionNumbers(study)
    if (!Object.keys(response.data).some((id) => studyAccessionNumbers.includes(id))) {
      return study
    }
    const dicomResult = studyAccessionNumbers
      .filter(
        (accessionNumber: string) => !!response.data[accessionNumber] && response.data[accessionNumber].length > 0,
      )
      .flatMap((accessionNumber) => response.data[accessionNumber])
    if (dicomResult && Array.isArray(dicomResult)) {
      return {
        ...study,
        dicom: { data: dicomResult, state: submitStates.success },
      }
    }

    return study
  })
}

function mapHiddenStudiesToStudies(studies: Study[]): Study[] {
  return studies.map((study) => {
    return merge(study, {
      patient: { ...study.patient, hiddenStudies: studies.filter((s) => s.accessible === false).length },
    })
  })
}

const PatientStudiesReducer = (state = initialPatientStudiesState, action: BaseAction): PatientStudiesState => {
  switch (action.type) {
    case PATIENT_STUDIES_GET_STUDIES: {
      const { patientId } = (action as DataAction<GetPatientStudiesData>).data
      return {
        ...state,
        submitById: { ...state.submitById, [patientId]: { ...submitStates.inProgress } },
      }
    }
    case PATIENT_SEARCH_SEARCH_SUCCESS:
    case APPOINTMENT_SEARCH_SEARCH_SUCCESS: {
      const { data } = action as DataAction<Study[]>

      const studiesGroupedByPatient = groupBy(data, (study: Study) => study.patient.id)

      return {
        ...state,
        byPatientId: {
          ...studiesGroupedByPatient,
        },
        submitById: {},
      }
    }
    case PATIENT_IMAGES_INFO: {
      const a = action as DataAction<ImagesInfoData>
      const { patient } = a.data
      return {
        ...state,
        byPatientId: {
          ...state.byPatientId,
          [patient.id]: mapDicomLoadingToSearchResult(state.byPatientId[patient.id] || []),
        },
      }
    }
    case PATIENT_IMAGES_INFO_SUCCESS: {
      const a = action as DataAction<ImagesInfoResponse>

      const { patientId } = a.data
      return {
        ...state,
        byPatientId: {
          ...state.byPatientId,
          [patientId]: mapDicomToSearchResult(state.byPatientId[patientId] || [], a.data),
        },
      }
    }
    case PATIENT_STUDIES_GET_STUDIES_SUCCESS: {
      const { data, patientId } = (action as DataAction<GetPatientStudiesResponseData>).data

      const studiesGroupedByPatient = groupBy(data, (study: Study) => study.patient.id)

      const patientStudies = studiesGroupedByPatient[patientId]

      // Merge array of studies without duplicates
      const mergedStudies = unionBy(state.byPatientId[patientId], patientStudies, 'uri')

      const studiesWithHiddenPatientStudies = mapHiddenStudiesToStudies(mergedStudies)

      return {
        ...state,
        byPatientId: {
          ...state.byPatientId,
          [patientId]: studiesWithHiddenPatientStudies,
        },
        submitById: { ...state.submitById, [patientId]: { ...submitStates.success, imagesInfoAborted: false } },
      }
    }
    case PATIENT_STUDIES_GET_STUDIES_FAIL: {
      const { patientId } = (action as DataAction<{ patientId: string }>).data
      return {
        ...state,
        submitById: { ...state.submitById, [patientId]: { ...submitStates.fail } },
      }
    }
    case PATIENT_IMAGES_INFO_ABORTED: {
      const { patientId, imagesInfoAborted } = (action as DataAction<PatientImagesInfoAbortedData>).data

      return {
        ...state,
        submitById: { ...state.submitById, [patientId]: { ...state.submitById[patientId], imagesInfoAborted } },
      }
    }
    default:
      return state
  }
}

export default PatientStudiesReducer
