import get from 'lodash/get'
import { BaseAction, ErrorAction } from '../../actions/commons'
import { BREAK_GLASS_CONSENT_FAIL, DISPLAY_FALLBACK_UI, RESET_ERROR_STATE } from '../../actions/errorActions'
import ErrorBase from '../../errors/ErrorBase'
import { PATIENT_SEARCH_SEARCH_FAIL } from '../../actions/PatientSearchActions/patientSearchActions'
import { GET_ACCOUNT_DETAILS_FAIL } from '../../actions/accountAction'
import { PATIENT_IMAGES_GET_IMAGES_DATA_FAIL } from '../../actions/PatientImagesActions/patientImagesActions'
import { PATIENT_GET_PATIENT_FAIL } from '../../actions/PatientDetailsActions/patientDetailsActions'
import { PATIENT_REPORT_GET_REPORT_FAIL } from '../../actions/PatientReportActions/patientReportActions'
import { LOGOUT_SUCCESS } from '../../actions/loginAction'

type ErrorState = {
  showError: boolean
  error: ErrorBase
}

export const initialErrorState: ErrorState = {
  showError: false,
  error: null,
}

const ROUTER_LOCATION_CHANGE = '@@router/LOCATION_CHANGE'

const errorBoundary = (state: ErrorState = initialErrorState, action: BaseAction): ErrorState => {
  switch (action.type) {
    case DISPLAY_FALLBACK_UI:
    case PATIENT_SEARCH_SEARCH_FAIL:
    case GET_ACCOUNT_DETAILS_FAIL:
    case PATIENT_IMAGES_GET_IMAGES_DATA_FAIL:
    case PATIENT_REPORT_GET_REPORT_FAIL:
    case PATIENT_GET_PATIENT_FAIL:
    case BREAK_GLASS_CONSENT_FAIL:
      const a = action as ErrorAction
      return {
        ...state,
        showError: get(a, 'data.show', false),
        error: { ...a.data.error },
      }

    case ROUTER_LOCATION_CHANGE:
    case RESET_ERROR_STATE:
    case LOGOUT_SUCCESS:
      return { ...initialErrorState }
    default:
      return state
  }
}

export default errorBoundary
