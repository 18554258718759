import { GoogleAnalyticsTrackerService } from './googleAnalyticsTrackerService'
import { GAEventNames } from '../../domain/models/commons/GoogleAnalytics'

export type AnalyticsPageViewParams = {
  pagePath?: string
  pageLocation?: string
}

export type AnalyticsTrackerService = {
  track(eventName: GAEventNames, eventData?: Record<string, unknown>): void

  pageView(page: string, pageViewParams?: AnalyticsPageViewParams): void
}

let googleAnalyticsTracker: GoogleAnalyticsTrackerService

type AnalyticsProvider = {
  gtag: UniversalAnalytics.ga
}

// Example of usage:
// analyticsTracker().track('testEvent', {
//   eventCategory: 'testCategory',
//   eventLabel: 'testLabel',
// })
export const analyticsTracker = (provider: AnalyticsProvider = window): AnalyticsTrackerService => {
  if (!googleAnalyticsTracker) {
    googleAnalyticsTracker = new GoogleAnalyticsTrackerService(provider.gtag)
  }

  // Default to Google Analytics
  return googleAnalyticsTracker
}
