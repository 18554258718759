import React, { useState } from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
  changePassword,
  updateChangePasswordField,
  ChangePasswordFormFieldTypes,
} from '../../../../actions/passwordActions'
import Icon from '../../../../components/atoms/Icon'
import AlertBox from '../../../../components/molecules/AlertBox'
import SingleLineTextInputField from '../../../../components/forms/fields/SingleLineTextInputField/SingleLineTextInputField'
import PasswordIndicator from '../../../../components/forms/validations/PasswordIndicator'
import icons from '../../../../_constants/icons'
import text from '../../../../_constants/text'

interface ChangePasswordModalProps {
  show: boolean
  handleShow: () => void
  currentPassword: string
  newPassword: string
  updateChangePasswordField: (type: ChangePasswordFormFieldTypes, value: string) => void
  changePassword: () => void
  changePasswordFail: boolean
  passwordNotComplexEnough: boolean
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({
  show,
  handleShow,
  currentPassword,
  newPassword,
  updateChangePasswordField,
  changePassword,
  changePasswordFail,
  passwordNotComplexEnough,
}) => {
  const [currentPasswordVisible, setCurrentPasswordVisible] = useState(false)
  const [newPasswordVisible, setNewPasswordVisible] = useState(false)
  const onCurrentPasswordToggle = () => {
    setCurrentPasswordVisible(!currentPasswordVisible)
  }
  const onNewPasswordToggle = () => {
    setNewPasswordVisible(!newPasswordVisible)
  }

  const onValueChange = (name: string, value: string) => {
    updateChangePasswordField(name as ChangePasswordFormFieldTypes, value)
  }

  return (
    <Modal show={show} onHide={handleShow} centered data-testid="ChangePasswordModal">
      <Modal.Body className="p-4">
        <div className="d-flex flex-row justify-content-between mb-3">
          <h2 className="mt-4">{text.CHANGE_PASSWORD}</h2>
          <div className="c-pointer" onClick={handleShow} data-testid="ChangePasswordModal-Close">
            <Icon name={icons.CLOSE} />
            <p className="blue bold">{text.CLOSE}</p>
          </div>
        </div>
        {changePasswordFail && <AlertBox type="error" message={text.CHANGE_PASSWORD_ERROR} className="mb-4" />}
        <form>
          <SingleLineTextInputField
            label={text.CURRENT_PASSWORD}
            type={currentPasswordVisible ? 'text' : 'password'}
            inputName="currentPassword"
            required={false}
            value={currentPassword}
            onChangeCb={onValueChange}
            error={false}
            errorMessage=""
          >
            <Icon
              name={currentPasswordVisible ? icons.HIDE : icons.SHOW}
              className="password-toggle"
              onClick={onCurrentPasswordToggle}
            />
          </SingleLineTextInputField>
          <SingleLineTextInputField
            label={text.NEW_PASSWORD}
            type={newPasswordVisible ? 'text' : 'password'}
            inputName="newPassword"
            required={false}
            value={newPassword}
            onChangeCb={onValueChange}
            error={passwordNotComplexEnough}
            errorMessage="Must satisfy complexity conditions"
          >
            <Icon
              name={newPasswordVisible ? icons.HIDE : icons.SHOW}
              className="password-toggle"
              onClick={onNewPasswordToggle}
            />
          </SingleLineTextInputField>
          <PasswordIndicator password={newPassword} />
          <Row>
            <Col className="d-flex flex-row justify-content-end ">
              <Button variant="light" className="mt-4 mx-3" onClick={handleShow}>
                {text.CANCEL}
              </Button>
              <Button className="mt-4" onClick={changePassword}>
                {text.UPDATE}
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { currentPassword, newPassword } = state.password.changePasswordForm
  const { changePasswordFail, passwordNotComplexEnough } = state.password
  return { currentPassword, newPassword, changePasswordFail, passwordNotComplexEnough }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateChangePasswordField: (type, value) => dispatch(updateChangePasswordField(type, value)),
    changePassword: () => dispatch(changePassword()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordModal)
