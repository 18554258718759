import React, { FC } from 'react'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Link } from 'react-router-dom'
import { NavigationItem } from '../../../domain/models/commons/NavigationItem'
import { ContentLinkModel } from '../../../domain/models/commons/Link'
import Icon from '../../atoms/Icon'
import ExternalLink from '../../atoms/ExternalLink'
import { history } from '../../../reducers/store'

const MAX_SINGLE_COLUMN_ITEMS = 6

export interface NavigationProps extends NavigationItem {
  className?: string
}

const Navigation: FC<NavigationProps> = ({ id, value, className }) => {
  const getDropdownClass = (items: ContentLinkModel[]) => (items.length > MAX_SINGLE_COLUMN_ITEMS ? 'two-cols' : '')
  return (
    <Nav as="nav" id={id} variant="pills" className={`desktop-nav ${className}`}>
      {value?.children.map((firstLvlItem: NavigationItem) => {
        // Ignore items without navigation targets
        if (!firstLvlItem.value.navigation.length) {
          return null
        }

        // Items with single nav target rendered as links
        if (firstLvlItem.value.navigation.length === 1) {
          const url = firstLvlItem.value.navigation[0].value.url
          const isExternalLink = firstLvlItem.value.navigation[0].value.external
          const linkId = firstLvlItem.id

          const pagePath = history.location?.pathname

          const navLink = (
            <Nav.Link as="div" eventKey={`navLvl1_${firstLvlItem.id}`} className="px-2">
              <Icon name={firstLvlItem.value.icon} className="mr-1 desktop-nav-line-height" />
              {firstLvlItem.value.name}
            </Nav.Link>
          )

          return (
            <Nav.Item key={`navLvl1_${firstLvlItem.id}`}>
              {isExternalLink ? (
                <ExternalLink href={url} linkId={linkId} pagePath={pagePath}>
                  {navLink}
                </ExternalLink>
              ) : (
                <Link to={url}>{navLink}</Link>
              )}
            </Nav.Item>
          )
        }

        // Items with multiple nav targets rendered as dropdowns
        return (
          <NavDropdown
            className={getDropdownClass(firstLvlItem.value.navigation)}
            key={firstLvlItem.id}
            id={firstLvlItem.id}
            title={firstLvlItem.value.name}
          >
            {firstLvlItem.value.navigation.map((secondLvlItem) => (
              <Link key={secondLvlItem.id} to={secondLvlItem.value.url}>
                <NavDropdown.Item id={secondLvlItem.id} eventKey={secondLvlItem.id}>
                  {secondLvlItem.value.name}
                </NavDropdown.Item>
              </Link>
            ))}
          </NavDropdown>
        )
      })}
    </Nav>
  )
}

export default Navigation
