import React, { PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'
import InputLabel from '../../../atoms/InputLabel'
import { Col, Row, Dropdown } from 'react-bootstrap'
import icons from '../../../../_constants/icons'
import Icon from '../../../atoms/Icon'
import text from '../../../../_constants/text'
type OnChangeCb = (name: string, value: string | undefined) => void

type Props = {
  label?: string
  inputName: string
  required?: boolean
  errorMessage?: string
  error?: boolean
  onChangeCb: OnChangeCb
  value: string | undefined
  options: string[]
  disabled?: boolean
  testId?: string
}

const DropdownSelect = ({
  inputName,
  errorMessage,
  error,
  onChangeCb,
  value,
  options,
  label = null,
  required = false,
  disabled = false,
  testId = 'DropdownSelect',
}: PropsWithChildren<Props>): JSX.Element => {
  const [show, setShow] = useState(false)
  const ref = useRef(null)

  const onChange = useCallback(
    (value: string) => {
      onChangeCb(inputName, value)
    },
    [value],
  )

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShow(false)
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [])

  return (
    <Col className="p-0 mt-0 mb-4 dropdown-select" data-testid={testId}>
      {label && (
        <Row className="m-0">
          <InputLabel label={`${label} ${required ? '*' : ''}`} />
        </Row>
      )}
      <Row className={`m-0 input ${error ? 'validation-error' : ''}`}>
        <Col className="p-0 justify-content-center">
          <div
            onClick={() => {
              !disabled && setShow(!show)
            }}
            ref={ref}
          >
            <Dropdown show={show} tabIndex={0}>
              <Dropdown.Toggle
                className={`dropdown-select-toggle${error ? ' error' : ''}`}
                data-testid="Dropdown-Toggle"
                disabled={disabled}
              >
                {value ? value : text.DROPDOWN_SELECT}
              </Dropdown.Toggle>
              <Dropdown.Menu className="dropdown-select-menu" data-testid="Dropdown-Menu">
                {options.map((option: string) => (
                  <Dropdown.Item
                    href="#"
                    key={option}
                    data-testid="Dropdown-Menu-Item"
                    onClick={() => {
                      onChange(option)
                      setShow(!show)
                    }}
                  >
                    {option}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Icon name={`${icons.ARROW_DOWN}${disabled ? '-disabled' : ''}`} className="keyboard-down-arrow-icon" />
          </div>
        </Col>
      </Row>
      {error && errorMessage && (
        <Row className="m-0 pl-3 pt-2">
          <p className="error">{errorMessage}</p>
        </Row>
      )}
    </Col>
  )
}

export default DropdownSelect
