import React, { PropsWithChildren } from 'react'
import { CustomDimensions, GAEventNames } from '../../../domain/models/commons/GoogleAnalytics'
import { analyticsTracker } from '../../../services/analytics/analyticsTrackerService'

type ExternalLinkProps = {
  className?: string
  href: string
  newTab?: boolean
  linkId?: string
  pagePath?: string
}

const ExternalLink: React.FC<PropsWithChildren<ExternalLinkProps>> = ({
  href,
  children,
  className,
  newTab = true,
  linkId,
  pagePath,
}) => {
  const trackEvent = (): void => {
    if (linkId === 'support' && !!pagePath) {
      analyticsTracker().track(GAEventNames.SUPPORT_PAGE, { [CustomDimensions.PAGE]: pagePath })
    }
  }
  const target = newTab ? '_blank' : ''
  return (
    <a
      target={target}
      rel="noopener noreferrer"
      className={className}
      href={href}
      data-testid="ExternalLink"
      onClick={trackEvent}
    >
      {children}
    </a>
  )
}

export default ExternalLink
