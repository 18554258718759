import { DataAction } from '../commons'
import {
  WebcomradPasswordResetErrorModel,
  WebcomradPasswordResetFormDataModel,
} from '../../reducers/WebcomradPasswordResetReducer/WebcomradPasswordResetReducer'

export const webcomradPasswordResetRequiredFields = {
  fullName: true,
  username: false,
  contactNumber: false,
  emailAddress: true,
}

export enum WebcomradPasswordResetFieldName {
  fullName = 'fullName',
  username = 'username',
  contactNumber = 'contactNumber',
  emailAddress = 'emailAddress',
}

export type WebcomradPasswordResetDataAction = DataAction<Partial<WebcomradPasswordResetFormDataModel>>

export interface WebcomradPasswordResetErrorAction {
  type: string
  errors: WebcomradPasswordResetErrorModel
}

export interface WebcomradPasswordResetFieldValidateAction {
  type: string
  data: {
    fieldName: WebcomradPasswordResetFieldName
    value: boolean
  }
}
