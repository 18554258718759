import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Col, Container, Row } from 'react-bootstrap'
import Layout from '../../components/wrappers/Layout'
import text from '../../_constants/text'
import ProfileCard from './ProfileCard'
import PracticesCard from './PracticesCard'
import { getAccountDetails } from '../../actions/accountAction'
import { Practice } from '../../reducers/AccountReducer/AccountReducer'
import { contactInfoReset } from '../../actions/contactInfoActions'
import Loader from '../../components/wrappers/Loader'
import { pageTitles } from '../../router/routes'
import { Helmet } from 'react-helmet'
import PageHeadingRow from '../../components/molecules/PageHeadingRow'
import OrderReferralPadsCard from './OrderReferralPadsCard/OrderReferralPadsCard'

interface AccountPageProps {
  email: string
  mobile: string
  displayName: string
  practices: Practice[]
  getAccountDetails: () => void
  contactInfoReset: () => void
  updateContactInfoFail: boolean
  updateContactInfoSuccess: boolean
  emailInvalid: boolean
  isLoading: boolean
}

const AccountPage: React.FC<AccountPageProps> = ({
  email,
  mobile,
  displayName,
  practices,
  getAccountDetails,
  updateContactInfoSuccess,
  updateContactInfoFail,
  emailInvalid,
  contactInfoReset,
  isLoading,
}) => {
  useEffect(() => {
    getAccountDetails()
    return () => {
      contactInfoReset()
    }
  }, [])
  return (
    <Layout>
      <Helmet>
        <title>{pageTitles.account}</title>
      </Helmet>
      <Loader isLoading={isLoading}>
        <div className="content-background content-background-min-height">
          <Container className="max-grid-width pb-5 content-padding" fluid>
            <PageHeadingRow title={text.ACCOUNT_DETAILS} />
            <Row className="px-0 justify-content-center">
              <Col xs={12} lg={6}>
                <ProfileCard
                  email={email}
                  mobile={mobile}
                  displayName={displayName}
                  updateContactInfoSuccess={updateContactInfoSuccess}
                  updateContactInfoFail={updateContactInfoFail}
                  emailInvalid={emailInvalid}
                />
              </Col>
              <Col xs={12} lg={6}>
                <PracticesCard practices={practices} />
                <OrderReferralPadsCard />
              </Col>
            </Row>
          </Container>
        </div>
      </Loader>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { email, mobile, displayName, practices, submit: accountSubmit } = state.account
  const {
    success: updateContactInfoSuccess,
    fail: updateContactInfoFail,
    inProgress: updateContactInProgress,
  } = state.contactInfo.submit
  const { fail: emailInvalid } = state.contactInfo.isEmailValidSubmit
  return {
    email,
    mobile,
    displayName,
    practices,
    updateContactInfoSuccess,
    updateContactInfoFail,
    emailInvalid,
    isLoading: accountSubmit.inProgress || updateContactInProgress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  getAccountDetails: () => dispatch(getAccountDetails()),
  contactInfoReset: () => dispatch(contactInfoReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage)
