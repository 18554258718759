import React from 'react'
import { Container } from 'react-bootstrap'
import { ProcedureRowKeys } from './index.types'
import { selectProceduresWithStudyInfo } from '../../../selectors/studies'
import ProcedureTableRow from '../ProcedureTableRow'
import ProcedureTableHeaderRow from '../ProcedureTableHeaderRow'
import { useAppSelector } from '../../../hooks/redux'
import useSort, { sortRows } from '../../../hooks/useSort'

type ProcedureTableProps = {
  patientId: string
}

const ProcedureTable: React.FC<ProcedureTableProps> = ({ patientId }) => {
  const { sort, handleSort } = useSort<ProcedureRowKeys>('appointment')

  const procedures = useAppSelector((state) => selectProceduresWithStudyInfo(state, patientId, 'appointment'))

  const sortedProceduresRows = sortRows(procedures, sort)

  return (
    <Container className="max-grid-width p-3 py-0 procedure-table" fluid>
      <ProcedureTableHeaderRow onSort={handleSort} />
      {sortedProceduresRows.map((procedureWithStudyInfo) => (
        <ProcedureTableRow
          key={`procedure-table-row-${procedureWithStudyInfo.procedureId}`}
          {...procedureWithStudyInfo}
        />
      ))}
    </Container>
  )
}

export default ProcedureTable
