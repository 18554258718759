import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'
import { DataAction } from '../../actions/commons'
import {
  PatientDetails,
  getPatientDetailsFail,
  getPatientDetailsSuccess,
  PATIENT_GET_PATIENT,
} from '../../actions/PatientDetailsActions/patientDetailsActions'
import { get, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { runWithRefresh } from '../RefreshAuthTokenSaga/refreshAuthTokenSaga'

const PATIENT_PATH = '/imedvisage/patient'

type GetPatientDetailsGenerator = Generator<
  SelectEffect | Promise<ResponseWithStatus<PatientDetails>>,
  ResponseWithStatus<PatientDetails>,
  AccessToken | ResponseWithStatus<PatientDetails>
>
export function* getPatientDetails(action: DataAction<{ patientId: string }>): GetPatientDetailsGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken
  const response = (yield get(
    `${process.env.REACT_APP_API_URL}${PATIENT_PATH}`,
    { Authorization: `${jwt.type} ${jwt.token}` },
    { patientUri: `/patient/${action.data.patientId}` },
  )) as ResponseWithStatus<PatientDetails>
  return response
}

export function* getPatientDetailsSaga(
  action: DataAction<{ patientId: string }>,
): Generator<unknown | PutEffect, void, AccessToken | ResponseWithStatus<PatientDetails>> {
  const response = (yield runWithRefresh(
    getPatientDetails,
    action,
  ) as GetPatientDetailsGenerator) as ResponseWithStatus<PatientDetails>
  if (!response) return
  if (response.status === ResponseStatus.SUCCESS) {
    yield put(getPatientDetailsSuccess(response.data as PatientDetails))
    return
  }
  yield put(getPatientDetailsFail())
}

function* watchGetAccountDetails(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(PATIENT_GET_PATIENT, getPatientDetailsSaga)
}

const detailsSaga = [fork(watchGetAccountDetails)]

export default detailsSaga
