import React, { FC, useEffect } from 'react'
import Layout from '../../components/wrappers/Layout'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { connect } from 'react-redux'
import { AppDispatch, RootState } from '../../reducers/store'
import ResetPasswordForm from './ResetPasswordForm'
import ResetPasswordSuccess from './ResetPasswordSuccess'
import { resetPasswordReset } from '../../actions/ResetPasswordActions/resetPasswordActions'
import { Helmet } from 'react-helmet'
import { pageTitles } from '../../router/routes'

type ResetPasswordPageProps = {
  success: boolean
  reset: () => void
}

const ResetPasswordPage: FC<ResetPasswordPageProps> = ({ success, reset }) => {
  useEffect(() => reset, [reset])
  return (
    <Layout>
      <Helmet>
        <title>{pageTitles.resetPassword}</title>
      </Helmet>
      <div className="d-flex align-items-center content-background content-unauthenticated-background-min-height py-5">
        <Container className="max-grid-width py-0 px-0" fluid>
          <Row className="px-0 justify-content-center">
            <Col xs={12} lg={6}>
              {success ? <ResetPasswordSuccess /> : <ResetPasswordForm />}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  )
}

const mapStateToProps = (state: RootState) => {
  const { success } = state.resetPassword.submit
  return { success }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  reset: () => dispatch(resetPasswordReset()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage)
