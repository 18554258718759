import React from 'react'
import { Card } from 'react-bootstrap'
import CallToAction from '../../../components/molecules/CallToAction'
import { CtaTypes } from '../../../domain/constants'
import { getImageSetsRoute } from '../../../router/routes'
import icons from '../../../_constants/icons'
import text from '../../../_constants/text'

interface PatientImagesCardProps {
  numImageSets: number
  patientId: string
  orderId: string
  accessionNo: string
  accessible: boolean
  handleConsentValidityCb: (link: string, target: string) => void
}

const PatientImagesCard: React.FC<PatientImagesCardProps> = ({
  numImageSets,
  patientId,
  orderId,
  accessionNo,
  accessible,
  handleConsentValidityCb,
}) => {
  return (
    <Card data-testid="PatientImagesCard" as="section">
      <Card.Body>
        <Card.Title as="h2" className="mb-4">
          {text.PATIENT_REPORT_IMAGES_TITLE}
        </Card.Title>

        <p>
          {numImageSets != null &&
            numImageSets +
              text.PATIENT_REPORT_IMAGES_AVAILABLES1 +
              (numImageSets == 1 ? 'set' : 'sets') +
              text.PATIENT_REPORT_IMAGES_AVAILABLES2}
        </p>
        <CallToAction
          action={{
            id: patientId,
            value: {
              link: getImageSetsRoute(patientId, orderId, accessible, accessionNo),
              icon: icons.IMAGE_SEARCH,
              buttonText: text.PATIENT_REPORT_IMAGES_BUTTON,
            },
            type: CtaTypes.LINK_WITH_PREVALIDATION,
          }}
          isDownloadable={false}
          onClick={() => {
            handleConsentValidityCb(getImageSetsRoute(patientId, orderId, accessible, accessionNo), '_self')
          }}
        />
      </Card.Body>
    </Card>
  )
}

export default PatientImagesCard
