import React from 'react'
import Icon from '../../atoms/Icon'
import icons from '../../../_constants/icons'

interface AlertBoxProps {
  type: 'error' | 'warning' | 'success' | 'primary'
  message: string
  className?: string
}

const AlertBox: React.FC<AlertBoxProps> = ({ type, message, className = '' }) => {
  return (
    <div data-testid={`AlertBox-${type}`} className={`AlertBox AlertBox-${type} px-3 ${className}`}>
      <Icon name={icons[type.toUpperCase()]} className="pe-2" />
      {message}
    </div>
  )
}

export default AlertBox
