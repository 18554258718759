import { AnalyticsTrackerService, AnalyticsPageViewParams } from './analyticsTrackerService'
import { GACommands, GAEventNames } from '../../domain/models/commons/GoogleAnalytics'

export class GoogleAnalyticsTrackerService implements AnalyticsTrackerService {
  gtag: UniversalAnalytics.ga

  constructor(gtag: UniversalAnalytics.ga) {
    this.gtag = gtag
  }

  track(eventName: GAEventNames, eventData?: Record<string, unknown>): void {
    this.gtag(GACommands.EVENT, eventName, eventData)
  }

  pageView(page: string, pageViewParams?: AnalyticsPageViewParams): void {
    const { pageLocation, pagePath } = pageViewParams
    this.gtag(GACommands.EVENT, GAEventNames.PAGE_VIEW, {
      page_title: page,
      page_location: pageLocation,
      page_path: pagePath,
    })
  }
}
