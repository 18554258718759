import React, { FormEvent, FC, useState, useEffect } from 'react'
import { Container, Col, Row, Button } from 'react-bootstrap'
import Card from 'react-bootstrap/Card'
import { connect } from 'react-redux'
import { login, LoginFormFieldType, updateField } from '../../../actions/loginAction'
import InternalLink from '../../../components/atoms/InternalLink'
import Separator from '../../../components/atoms/Separator'
import SingleLineTextInputField from '../../../components/forms/fields/SingleLineTextInputField/SingleLineTextInputField'
import AlertBox from '../../../components/molecules/AlertBox'
import icons from '../../../_constants/icons'
import Icon from '../../../components/atoms/Icon'
import text from '../../../_constants/text'
import routes from '../../../router/routes'
import { getServiceNotification } from '../../../services/serviceNotificationService'
import { analyticsTracker } from '../../../services/analytics/analyticsTrackerService'
import { GAEventNames } from '../../../domain/models/commons/GoogleAnalytics'

type LoginFormProps = {
  form: Record<LoginFormFieldType, string>
  hasSessionTimedOut: boolean
  login: () => void
  loginFailure: boolean
  updateField: (type: LoginFormFieldType, value: string) => void
}

const LoginForm: FC<LoginFormProps> = ({ form, hasSessionTimedOut, updateField, login, loginFailure }) => {
  const [passwordVisible, setPasswordVisible] = useState(false)
  const serviceNotification = getServiceNotification()

  const onValueChange = (name: string, value: string) => {
    updateField(name as LoginFormFieldType, value)
  }

  const onLogin = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    login()
  }

  const onPasswordToggle = () => {
    setPasswordVisible(!passwordVisible)
  }

  useEffect(() => {
    if (loginFailure) {
      analyticsTracker().track(GAEventNames.LOGIN_FAILURE, {})
    }

    if (hasSessionTimedOut) {
      analyticsTracker().track(GAEventNames.SESSION_TIMEOUT, {})
    }
  }, [loginFailure, hasSessionTimedOut])

  return (
    <Card as="section" className="my-3 my-lg-4">
      <Card.Body className="p-3 p-lg-4">
        {serviceNotification?.show && (
          <AlertBox type={serviceNotification.type} message={serviceNotification.message} />
        )}
        <Card.Title as="h2" className="mb-4">
          {text.SIGN_IN}
        </Card.Title>
        {loginFailure && <AlertBox type="error" message={text.LOGIN_ERROR} />}
        {hasSessionTimedOut && <AlertBox type="warning" message={text.LOGOUT_SESSION_TIMEOUT} />}
        {/* eslint-disable-next-line react/no-unknown-property */}
        <form datatest-id="login-form" onSubmit={onLogin}>
          <SingleLineTextInputField
            label={text.USER_ID}
            inputName="uid"
            required={false}
            value={form.uid}
            onChangeCb={onValueChange}
            error={false}
            errorMessage=""
          />
          <SingleLineTextInputField
            label={text.PASSWORD}
            type={passwordVisible ? 'text' : 'password'}
            inputName="password"
            required={false}
            value={form.password}
            onChangeCb={onValueChange}
            error={false}
            errorMessage=""
          >
            <Icon
              name={passwordVisible ? icons.HIDE : icons.SHOW}
              className="password-toggle"
              onClick={onPasswordToggle}
            />
          </SingleLineTextInputField>
          <Container className="d-flex flex-column px-2 px-lg-0 mt-4">
            <Row className="mb-4">
              <Col xs={12} lg={6} xl={4} className="mb-3 mb-lg-0">
                <Button type="submit" data-testid="login-button" className="m-auto w-100">
                  {text.SIGN_IN}
                </Button>
              </Col>
              <Col className="d-flex flex-row px-lg-0 w-auto" xs={12} lg={8}>
                <p className="m-auto anchor-text create-account-text">{text.OR}</p>
                <InternalLink href={routes.apply} className="m-auto px-1">
                  {text.CREATE_ACCOUNT}
                </InternalLink>
              </Col>
            </Row>
            <Row>
              <Separator />
              <InternalLink href={routes.forgotUsername} className="pt-4">
                {text.FORGOT_ID}
              </InternalLink>
              <InternalLink href={routes.forgotPassword}>{text.FORGOT_PASSWORD}</InternalLink>
            </Row>
          </Container>
        </form>
      </Card.Body>
    </Card>
  )
}
const mapStateToProps = (state) => {
  const { form, submit, hasSessionTimedOut } = state.login
  return { form, hasSessionTimedOut, loginFailure: submit.fail }
}

const mapDispatchToProps = (dispatch) => ({
  updateField: (type, value) => dispatch(updateField(type, value)),
  login: () => dispatch(login()),
})
export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
