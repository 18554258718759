import { AccountSuccessAction, GET_ACCOUNT_DETAILS_SUCCESS } from '../../actions/accountAction'
import { BaseAction, UpdateFieldAction } from '../../actions/commons'
import {
  ContactInfoFormFieldType,
  CONTACT_INFO_EMAIL_INVALID,
  CONTACT_INFO_RESET,
  CONTACT_INFO_SUBMIT,
  CONTACT_INFO_SUBMIT_FAIL,
  CONTACT_INFO_SUBMIT_SUCCESS,
  CONTACT_INFO_UPDATE_DETAILS,
} from '../../actions/contactInfoActions'
import { LOGOUT_SUCCESS } from '../../actions/loginAction'
import { SubmitControls, submitStates } from '../commons/submitControls'

export type ContactInfo = {
  email: string
  mobile: string
}

type ContactInfoState = {
  current: ContactInfo
  new: ContactInfo
  submit: SubmitControls
  isEmailValidSubmit: SubmitControls
}

export const initialContactInfoState = {
  current: {
    email: '',
    mobile: '',
  },
  new: { email: '', mobile: '' },
  submit: {
    inProgress: false,
    success: false,
    fail: false,
  },
  isEmailValidSubmit: {
    inProgress: false,
    success: false,
    fail: false,
  },
}

const contactInfoReducer = (state = initialContactInfoState, action: BaseAction): ContactInfoState => {
  switch (action.type) {
    case CONTACT_INFO_SUBMIT: {
      return { ...state, submit: { ...submitStates.inProgress } }
    }
    case CONTACT_INFO_UPDATE_DETAILS: {
      const a = action as UpdateFieldAction<ContactInfoFormFieldType>
      return {
        ...state,
        new: {
          ...state.new,
          ...a.data,
        },
        submit: {
          ...submitStates.default,
        },
        isEmailValidSubmit: {
          ...submitStates.default,
        },
      }
    }
    case GET_ACCOUNT_DETAILS_SUCCESS: {
      const a = action as AccountSuccessAction
      return {
        ...state,
        current: { ...a.data },
        new: { ...a.data },
      }
    }
    case CONTACT_INFO_SUBMIT_SUCCESS: {
      const a = action as UpdateFieldAction<ContactInfoFormFieldType>
      return {
        ...state,
        current: { ...state.current, ...a.data },
        new: { ...state.current, ...a.data },
        submit: {
          ...submitStates.success,
        },
      }
    }
    case CONTACT_INFO_EMAIL_INVALID: {
      return {
        ...state,
        isEmailValidSubmit: { ...submitStates.fail },
        submit: { ...submitStates.default },
      }
    }
    case CONTACT_INFO_SUBMIT_FAIL: {
      return {
        ...state,
        submit: { ...submitStates.fail },
      }
    }
    case CONTACT_INFO_RESET:
    case LOGOUT_SUCCESS:
      return { ...initialContactInfoState }
    default:
      return state
  }
}

export default contactInfoReducer
