import { CloudWatchLogsClient, PutLogEventsCommand, DescribeLogStreamsCommand } from '@aws-sdk/client-cloudwatch-logs'

const client = new CloudWatchLogsClient({
  region: process.env.REACT_APP_AWS_REGION,
  credentials: {
    accessKeyId: process.env.REACT_APP_AWS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_ACCESS_KEY,
  },
})

const logGroupName = process.env.REACT_APP_AWS_LOG_GROUP_NAME
const logStreamName = process.env.REACT_APP_AWS_LOG_STREAM_NAME || 'logs'

function sendLog(message: string): void {
  const params = {
    logGroupName,
    logStreamName,
  }

  const describeCommand = new DescribeLogStreamsCommand(params)

  const sendCb = (sequenceToken: string) => {
    const sendCommand = new PutLogEventsCommand({
      ...params,
      sequenceToken,
      logEvents: [
        {
          message,
          timestamp: +new Date(),
        },
      ],
    })

    client.send(sendCommand, function (err, data) {
      if (err) console.log(err, err.stack)
      // an error occurred
      else console.log(data) // successful response
    })
  }

  client.send(describeCommand, function (err, data) {
    if (err) console.log(err, err.stack)
    // an error occurred
    else {
      const { uploadSequenceToken } = data.logStreams?.[0]
      sendCb(uploadSequenceToken)
    }
  })
}

export { sendLog }
