import { BaseAction, UpdateFieldAction } from '../../actions/commons'
import {
  ForgotPasswordFormFieldType,
  FORGOT_PASSWORD_FAIL,
  FORGOT_PASSWORD_RESET,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_UPDATE_DETAILS,
  FORGOT_PASSWORD_VALIDATION_FAIL,
} from '../../actions/ForgotPasswordActions/forgotPasswordActions'
import { SubmitControls } from '../RegisterAccountReducer/RegisterAccountReducer.types'

export type ForgotPasswordState = {
  form: Record<ForgotPasswordFormFieldType, string>
  formErrors: Partial<Record<ForgotPasswordFormFieldType, boolean>>
  submit: SubmitControls
}

export const initialForgotPasswordState: ForgotPasswordState = {
  form: {
    email: '',
  },
  formErrors: {},
  submit: {
    success: false,
    fail: false,
    inProgress: false,
  },
}

const forgotPasswordReducer = (
  state: ForgotPasswordState = initialForgotPasswordState,
  action: BaseAction,
): ForgotPasswordState => {
  switch (action.type) {
    case FORGOT_PASSWORD_RESET:
      return {
        ...initialForgotPasswordState,
      }
    case FORGOT_PASSWORD_VALIDATION_FAIL:
      return { ...state, formErrors: { email: true } }
    case FORGOT_PASSWORD_UPDATE_DETAILS:
      const a = action as UpdateFieldAction<ForgotPasswordFormFieldType>
      return {
        ...state,
        form: {
          ...state.form,
          ...a.data,
        },
        formErrors: { ...initialForgotPasswordState.formErrors },
        submit: { ...state.submit, fail: false, success: false },
      }
    case FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        submit: { ...state.submit, fail: true, success: false },
      }
    case FORGOT_PASSWORD_SUCCESS: {
      return {
        ...initialForgotPasswordState,
        submit: { ...state.submit, fail: false, success: true },
      }
    }
    default:
      return state
  }
}

export default forgotPasswordReducer
