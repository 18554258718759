import React from 'react'
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from 'react-bootstrap'

type OverlayTooltipProps = Partial<OverlayTriggerProps> & {
  overlaidContent: React.ReactElement
  tooltipContent: React.ReactElement | string
  id: string
  className?: string
}

const OverlayTooltip: React.FC<OverlayTooltipProps> = ({
  overlaidContent,
  tooltipContent,
  id,
  className,
  ...overlayTriggerPropsRest
}) => {
  const tooltip = (
    <Tooltip className={className} id={id}>
      {tooltipContent}
    </Tooltip>
  )

  return (
    <OverlayTrigger overlay={tooltip} {...overlayTriggerPropsRest}>
      {overlaidContent}
    </OverlayTrigger>
  )
}

export default OverlayTooltip
