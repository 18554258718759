import React, { PropsWithChildren } from 'react'
import Icon from '../../../components/atoms/Icon'
interface PatientTableColumnLabelProps extends PropsWithChildren {
  onSortCb?: () => void
}

const PatientTableColumnLabel: React.FC<PatientTableColumnLabelProps> = ({ children, onSortCb }) => {
  return (
    <div className="d-flex flex-row search-table-col-label" data-testid="PatientTableColumnLabel">
      <span className="mr-2 text">{children}</span>
      {onSortCb && <Icon className="icon" name="sort" onClick={onSortCb} data-testid="PatientTableColumnLabel-Icon" />}
    </div>
  )
}

export default PatientTableColumnLabel
