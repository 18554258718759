import { JwtPayload } from 'jwt-decode'
import { BaseAction, DataAction, UpdateFieldAction } from './commons'

export const LOGIN = 'LOGIN/LOGIN'
export const LOGIN_SUCCESS = 'LOGIN/LOGIN_SUCCESS'
export const LOGIN_OBTAINED_TOKEN = 'LOGIN/OBTAINED_TOKEN'
export const LOGIN_FAIL = 'LOGIN/LOGIN_FAIL'
export const LOGOUT = 'LOGIN/LOGOUT'
export const LOGOUT_SUCCESS = 'LOGIN/LOGOUT_SUCCESS'
export const LOGOUT_FAIL = 'LOGIN/LOGOUT_FAIL'
export const LOGIN_UPDATE_DETAILS = 'LOGIN/UPDATE_DETAILS'

export const REFRESH_TOKEN = 'LOGIN/REFRESH_TOKEN'
export const REFRESH_TOKEN_SUCCESS = 'LOGIN/REFRESH_TOKEN_SUCCESS'
export const REFRESH_TOKEN_FAIL = 'LOGIN/REFRESH_TOKEN_FAIL'
export const REFRESH_TOKEN_FINISH = 'LOGIN/REFRESH_TOKEN_FINISH'

export const SESSION_TIMED_OUT = 'LOGIN/SESSION_TIMED_OUT'

export interface IMedJwtPayload extends JwtPayload {
  username: string
  groups: string[]
}

export type LoginFormFieldType = 'uid' | 'password'

export interface JwtToken {
  token: string
  type: string
}
export interface JwtTokenWithRefresh extends JwtToken {
  refreshToken: string
}

export type LogoutAction = {
  hasSessionTimedOut?: boolean
}

export const updateField = (type: LoginFormFieldType, value: string): UpdateFieldAction<LoginFormFieldType> => ({
  type: LOGIN_UPDATE_DETAILS,
  data: {
    [type]: value,
  },
})

export const login = (): BaseAction => ({ type: LOGIN })
export const loginObtainedToken = (loginResponse: JwtTokenWithRefresh): DataAction<JwtTokenWithRefresh> => ({
  type: LOGIN_OBTAINED_TOKEN,
  data: loginResponse,
})
export const loginSuccess = (tokenContent: IMedJwtPayload): DataAction<IMedJwtPayload> => ({
  type: LOGIN_SUCCESS,
  data: tokenContent,
})
export const loginFail = (): BaseAction => ({ type: LOGIN_FAIL })
export const logout = (hasSessionTimedOut = false): DataAction<LogoutAction> => ({
  type: LOGOUT,
  data: { hasSessionTimedOut },
})
export const logoutSuccess = (): BaseAction => ({ type: LOGOUT_SUCCESS })
export const logoutFail = (): BaseAction => ({ type: LOGOUT_FAIL })

export const refreshTokenStart = (): BaseAction => ({ type: REFRESH_TOKEN })
export const refreshTokenSuccess = (): BaseAction => ({ type: REFRESH_TOKEN_SUCCESS })
export const refreshTokenFail = (): BaseAction => ({ type: REFRESH_TOKEN_FAIL })
export const refreshTokenFinish = (): BaseAction => ({ type: REFRESH_TOKEN_FINISH })

export const sessionTimedOut = (): BaseAction => ({ type: SESSION_TIMED_OUT })
