import React, { FC } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import PageHeadingRow from '../../components/molecules/PageHeadingRow'
import text from '../../_constants/text'
import WebcomradWrapper from './WebcomradWrapper'
import WebcomradForm from './WebcomradForm'
import { WebcomradFormDataModel, WebcomradFormErrorModel } from '../../reducers/WebcomradReducer/WebcomradReducer'
import SubmitWebcomradSuccess from './Success'

interface WebcomradPageProps {
  submissionSuccess: boolean
  submissionFailed: boolean
  formData: WebcomradFormDataModel
  errors: WebcomradFormErrorModel
  onValueChange: (fieldName: string, value: string | boolean) => void
  onSubmit: () => void
  clearForm: () => void
}

const RIL_WEBCOMRAD_URL = 'https://i-medonline.ril.com.au/webcomrad/'

const WebcomradPage: FC<WebcomradPageProps> = ({
  submissionSuccess,
  submissionFailed,
  formData,
  errors,
  onValueChange,
  onSubmit,
  clearForm,
}) => {
  const heading = submissionSuccess ? text.REGISTRATION_SENT : text.WEBCOMRAD_REGISTRATION_FORM_TITLE
  return (
    <div data-testid="webcomrad-page" className="d-flex content-background content-background-min-height pb-5">
      <Container className="max-grid-width pb-2 content-padding" fluid>
        <PageHeadingRow title={heading} isSuccess={submissionSuccess} />
        <Row className="px-0 flex-column-reverse flex-lg-row">
          <Col xs={12} lg={8}>
            {submissionSuccess ? (
              <SubmitWebcomradSuccess
                clearForm={clearForm}
                messageOne={text.WEBCOMRAD_SUCCESS_MESSAGE_1}
                messageTwo={text.WEBCOMRAD_SUCCESS_MESSAGE_2}
                buttonText={text.BACK_TO_PATIENT_SEARCH}
                linkRoute={RIL_WEBCOMRAD_URL}
              />
            ) : (
              <WebcomradWrapper
                submitRegistration={onSubmit}
                submissionFail={submissionFailed}
                buttonText={text.SEND_REGISTRATION}
                errorMessage={text.REFERRAL_SUBMIT_ERROR}
              >
                <WebcomradForm formData={formData} errors={errors} onValueChange={onValueChange} />
              </WebcomradWrapper>
            )}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default WebcomradPage
