import React, { FC } from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import { TextSection as ITextSection } from '../../../domain/models/sections/TextSection'
import Section from '../Section'

type props = {
  content: ITextSection[]
}

const InfoContent: FC<props> = ({ content }) => {
  return (
    <div className="content-background" data-testid="InfoContent">
      <Container className="max-grid-width py-2 px-0" fluid>
        <Row className="mx-0 px-0 justify-content-left">
          <Col xs={12} lg={8}>
            {content && content.map((e) => <Section key={e.id} props={{ ...e }} />)}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default InfoContent
