import React, { ReactElement, useEffect } from 'react'
import Router from './router/Router'
import { store, history } from './reducers/store'

import './styles/index.scss'

import { Provider } from 'react-redux'
import { initBreakGlassConsentDatabase } from './services/indexedDB/breakGlassConsentsStorage/consentsDB'
import { analyticsTracker } from './services/analytics/analyticsTrackerService'
import SessionTimedOutModal from './components/organisms/SessionTimedOutModal'
import MaintenanceModal1457 from './components/organisms/MaintenanceModal2216'

history.listen(({ location }) => {
  analyticsTracker().pageView('App', { pagePath: location.pathname + location.search })
})
function App(): ReactElement {
  useEffect(() => {
    initBreakGlassConsentDatabase()
  }, [])

  return (
    <Provider store={store}>
      <SessionTimedOutModal />
      <MaintenanceModal1457 />
      <Router />
    </Provider>
  )
}

export default App
