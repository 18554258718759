import React from 'react'
import { Col, Row } from 'react-bootstrap'
import LoadingBox from '../../../components/atoms/LoadingBox'

type AppointmentTableLoadingRowProps = {
  index: number
}

const AppointmentTableLoadingRow: React.FC<AppointmentTableLoadingRowProps> = ({ index }) => (
  <div data-testid="AppointmentTableRow-Wrapper" className="search-table-row-wrapper">
    <Row data-testid="AppointmentTableRow" className="search-table-row">
      <Col xs={4} className="search-table-col d-flex">
        <LoadingBox isNarrow={index % 2 === 0} />
      </Col>
      <Col xs={5} className="search-table-col">
        <Row>
          <Col xs={3} className="search-table-col">
            <LoadingBox />
          </Col>
          <Col xs={4} className="search-table-col">
            <LoadingBox />
          </Col>
          <Col xs={5} className="search-table-col">
            <LoadingBox />
          </Col>
        </Row>
      </Col>
      <Col xs={3} className="search-table-col">
        <LoadingBox />
      </Col>
    </Row>
  </div>
)

export default AppointmentTableLoadingRow
