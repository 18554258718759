import React, { FC } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import ExternalLink from '../../../components/atoms/ExternalLink'

interface SubmitWebcomradSuccessProps {
  clearForm: () => void
  messageOne: string
  messageTwo: string
  buttonText: string
  linkRoute: string
}

const SubmitWebcomradSuccess: FC<SubmitWebcomradSuccessProps> = ({
  clearForm,
  messageOne,
  messageTwo,
  buttonText,
  linkRoute,
}) => {
  return (
    <Card as="section" className="my-3 my-lg-4" data-testid="submit-webcomrad-success">
      <Card.Body className="p-3 p-lg-4">
        <Card.Text as="div" data-testid="subtitle" className="mt-3 success-registration">
          <p data-testid="webcomrad-success-message-1">{messageOne}</p>
          <p data-testid="webcomrad-success-message-2">{messageTwo}</p>
        </Card.Text>
        <ExternalLink href={linkRoute}>
          <Button data-testid="back-to-search-button" className="mt-3" onClick={clearForm}>
            {buttonText}
          </Button>
        </ExternalLink>
      </Card.Body>
    </Card>
  )
}

export default SubmitWebcomradSuccess
