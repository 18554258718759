import { deleteRecord, getItem, getAllItems, initDatabase, ObjectStore, upsertRecord } from '../indexedDB'

export type ConsentRecord = {
  referrerUsername: string
  patientId: string
  startTime: Date
}

export type ConsentKey = {
  referrerUsername: string
  patientId: string
}

const objectStore: ObjectStore = { name: 'ConsentRecords', key: ['referrerUsername', 'patientId'] }

export const initBreakGlassConsentDatabase = (): void => {
  initDatabase([objectStore], 'BreakglassConsents')
}

export const insertConsent = async (userConsent: ConsentRecord): Promise<string | void> => {
  return await upsertRecord(objectStore.name, userConsent)
}

export const deleteConsent = async (userConsentKey: ConsentKey): Promise<string | void> => {
  return await deleteRecord(objectStore.name, [userConsentKey.referrerUsername, userConsentKey.patientId])
}

export const getConsent = async <ConsentRecord>(userConsentKey: ConsentKey): Promise<ConsentRecord | string> => {
  return await getItem(objectStore.name, [userConsentKey.referrerUsername, userConsentKey.patientId])
}

export const getAllConsents = async <ConsentRecord>(
  referrerUsername: string,
): Promise<Array<ConsentRecord> | string> => {
  return await getAllItems(objectStore.name, referrerUsername)
}
