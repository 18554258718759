import React from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import Icon from '../../../../components/atoms/Icon'
import icons from '../../../../_constants/icons'
import text from '../../../../_constants/text'
import SingleLineTextInputField from '../../../../components/forms/fields/SingleLineTextInputField'
import { updateField } from '../../../../actions/AddPractice/addPracticeActions'
import { connect } from 'react-redux'
import {
  PracticeDetailsErrorsType,
  PracticeDetailsType,
  SubmitControls,
} from '../../../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import DropdownSelect from '../../../../components/forms/fields/DropdownSelect'
import { addPractice } from '../../../../actions/AddPractice/addPracticeActions'
import AlertBox from '../../../../components/molecules/AlertBox'
import { AddPracticeFormFieldType } from '../../../../actions/AddPractice/addPracticeActions.types'

interface AddPracticeModalProps {
  show: boolean
  handleShow: () => void
  updateField: (type: AddPracticeFormFieldType, value: string) => void
  addPractice: () => void
  practiceForm: PracticeDetailsType
  errors: PracticeDetailsErrorsType
  submit: SubmitControls
}

const AddPracticeModal: React.FC<AddPracticeModalProps> = ({
  show,
  handleShow,
  updateField,
  practiceForm,
  errors,
  addPractice,
  submit,
}) => {
  const onValueChange = (name: string, value: string) => {
    updateField(name as AddPracticeFormFieldType, value)
  }

  return (
    <Modal show={show} onHide={handleShow} centered size="lg" data-testid="AddPracticeModal">
      <Modal.Body className="p-4">
        <div className="d-flex flex-row justify-content-between mb-1">
          <h2 className="mt-4">{text.REQUEST_ANOTHER_PRACTICE}</h2>
          <div className="c-pointer" onClick={handleShow} data-testid="AddPracticeModal-Close">
            <Icon name={icons.CLOSE} />
            <p className="blue bold">{text.CLOSE}</p>
          </div>
        </div>
        <p className="mt-0 mb-4">{text.ADD_PRACTICE_SUBHEADER}</p>
        {submit.fail && <AlertBox type="error" message={text.ADD_PRACTICE_FAIL} />}
        <form>
          <Row>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.PROVIDER_NUMBER}
                type="text"
                inputName="providerNumber"
                required={false}
                value={practiceForm.providerNumber}
                onChangeCb={onValueChange}
                error={errors.providerNumber}
                errorMessage="This field is required"
                placeholder={text.PRACTICE_NUMBER_PLACEHOLDER}
              />
            </Col>
            <Col xs={12} lg={6}>
              <SingleLineTextInputField
                label={text.PRACTICE_NAME}
                type="text"
                inputName="practiceName"
                required={false}
                value={practiceForm.practiceName}
                onChangeCb={onValueChange}
                error={errors.practiceName}
                errorMessage="This field is required"
                placeholder={text.PRACTICE_NAME_PLACEHOLDER}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DropdownSelect
                label={text.PRACTICE_TYPE}
                inputName="practiceType"
                required={false}
                value={practiceForm.practiceType}
                onChangeCb={onValueChange}
                error={errors.practiceType}
                errorMessage={text.FIELD_REQUIRED}
                options={[
                  'General Practitioner',
                  'Medical Specialist',
                  'Dentist',
                  'Physiotherapist',
                  'Chiropractor',
                  'Allied Health - Other',
                  'Multi-Disciplinary',
                ]}
              />
            </Col>
            <Col>
              <SingleLineTextInputField
                label={text.PRACTICE_PHONE}
                inputName="practicePhone"
                required={false}
                error={errors.practicePhone}
                value={practiceForm.practicePhone}
                onChangeCb={onValueChange}
                errorMessage={text.FIELD_REQUIRED}
                placeholder="e.g. 0200112233"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <SingleLineTextInputField
                label={text.PRACTICE_FAX}
                inputName="practiceFax"
                required={false}
                value={practiceForm.practiceFax}
                onChangeCb={onValueChange}
                error={errors.practiceFax}
                errorMessage=""
                placeholder="e.g. 0200112233"
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <SingleLineTextInputField
                label={text.ADDRESS}
                inputName="practiceAddress"
                required={false}
                value={practiceForm.practiceAddress}
                onChangeCb={onValueChange}
                error={errors.practiceAddress}
                errorMessage="This field is required"
                placeholder=""
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} lg={5}>
              <SingleLineTextInputField
                label={text.SUBURB}
                inputName="practiceSuburb"
                required={false}
                value={practiceForm.practiceSuburb}
                onChangeCb={onValueChange}
                error={errors.practiceSuburb}
                errorMessage="This field is required"
                placeholder=""
              />
            </Col>
            <Col xs={12} lg={3}>
              <SingleLineTextInputField
                label={text.POSTCODE}
                inputName="practicePostcode"
                required={false}
                value={practiceForm.practicePostcode}
                onChangeCb={onValueChange}
                error={errors.practicePostcode}
                errorMessage="This field is required"
                placeholder=""
              />
            </Col>
            <Col xs={12} lg={4}>
              <DropdownSelect
                label={text.STATE}
                inputName="practiceState"
                required={false}
                value={practiceForm.practiceState}
                onChangeCb={onValueChange}
                error={errors.practiceState}
                errorMessage="This field is required"
                options={[
                  'Queensland',
                  'New South Wales',
                  'Victoria',
                  'Australian Capital Territory',
                  'Northern Territory',
                  'Western Australia',
                  'South Australia',
                  'Tasmania',
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col className="d-flex flex-row justify-content-end">
              <Button variant="light" className="mx-3" onClick={handleShow}>
                {text.CANCEL}
              </Button>
              <Button onClick={addPractice}>{text.ADD_PRACTICE}</Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { practiceForm, submit, errors } = state.addPractice
  return { practiceForm, submit, errors }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateField: (type, value) => dispatch(updateField(type, value)),
    addPractice: () => dispatch(addPractice()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPracticeModal)
