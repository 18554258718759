import { BaseAction } from '../commons'
import { FieldValue } from '../registerAccountActions.types'
import {
  WebcomradPasswordResetDataAction,
  WebcomradPasswordResetErrorAction,
  WebcomradPasswordResetFieldName,
  WebcomradPasswordResetFieldValidateAction,
} from './webcomradPasswordResetActions.types'
import { WebcomradPasswordResetFormDataModel } from '../../reducers/WebcomradPasswordResetReducer/WebcomradPasswordResetReducer'
import { validateWebcomradPasswordResetFormData } from '../../components/forms/validations/WebcomradPasswordReset'

export const WEBCOMRAD_PASSWORD_RESET_FIELD_UPDATE = 'WEBCOMRAD_PASSWORD_RESET/FIELD_UPDATE'
export const WEBCOMRAD_PASSWORD_RESET_FIELD_VALIDATE = 'WEBCOMRAD_PASSWORD_RESET/FIELD_VALIDATE'
export const WEBCOMRAD_PASSWORD_RESET_ERROR = 'WEBCOMRAD_PASSWORD_RESET/ERROR'
export const WEBCOMRAD_PASSWORD_RESET_SUBMIT = 'WEBCOMRAD_PASSWORD_RESET/SUBMIT'
export const WEBCOMRAD_PASSWORD_RESET_SUBMIT_SUCCESS = 'WEBCOMRAD_PASSWORD_RESET/SUBMIT_SUCCESS'
export const WEBCOMRAD_PASSWORD_RESET_SUBMIT_FAIL = 'WEBCOMRAD_PASSWORD_RESET/SUBMIT_FAIL'
export const WEBCOMRAD_PASSWORD_RESET_SUBMIT_LOADING = 'WEBCOMRAD_PASSWORD_RESET/SUBMIT_LOADING'
export const WEBCOMRAD_PASSWORD_RESET_CLEAR = 'WEBCOMRAD_PASSWORD_RESET/CLEAR'

export const updateWebcomradPasswordResetFieldValue = (
  type: WebcomradPasswordResetFieldName,
  value: FieldValue,
): WebcomradPasswordResetDataAction => ({
  type: WEBCOMRAD_PASSWORD_RESET_FIELD_UPDATE,
  data: {
    [type]: value,
  },
})

export const setErrorWebcomradPasswordResetFormField = (
  value: boolean,
  type: WebcomradPasswordResetFieldName,
): WebcomradPasswordResetFieldValidateAction => ({
  type: WEBCOMRAD_PASSWORD_RESET_FIELD_VALIDATE,
  data: {
    fieldName: type,
    value,
  },
})

export const submitWebcomradPasswordResetForm = (
  formData: WebcomradPasswordResetFormDataModel,
): BaseAction | WebcomradPasswordResetErrorAction => {
  const { errors, isValid } = validateWebcomradPasswordResetFormData(formData)
  if (!isValid) {
    return {
      type: WEBCOMRAD_PASSWORD_RESET_ERROR,
      errors,
    } as WebcomradPasswordResetErrorAction
  }
  return {
    type: WEBCOMRAD_PASSWORD_RESET_SUBMIT,
  }
}

export const webcomradPasswordResetSubmitSuccess = (): BaseAction => ({
  type: WEBCOMRAD_PASSWORD_RESET_SUBMIT_SUCCESS,
})

export const webcomradPasswordResetSubmitFail = (): BaseAction => ({
  type: WEBCOMRAD_PASSWORD_RESET_SUBMIT_FAIL,
})

export const webcomradPasswordResetSubmitLoading = (): BaseAction => ({
  type: WEBCOMRAD_PASSWORD_RESET_SUBMIT_LOADING,
})

export const clearWebcomradPasswordResetForm = (): BaseAction => ({
  type: WEBCOMRAD_PASSWORD_RESET_CLEAR,
})
