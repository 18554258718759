import { BaseAction } from '../../actions/commons'
import {
  ALFRED_MATER_IMAGING_CLEAR,
  ALFRED_MATER_IMAGING_ERROR,
  ALFRED_MATER_IMAGING_FIELD_UPDATE,
  ALFRED_MATER_IMAGING_FIELD_VALIDATE,
  ALFRED_MATER_IMAGING_SUBMIT_FAIL,
  ALFRED_MATER_IMAGING_SUBMIT_LOADING,
  ALFRED_MATER_IMAGING_SUBMIT_SUCCESS,
} from '../../actions/AlfredMaterImagingActions/alfredMaterImagingActions'
import {
  AlfredMaterImagingDataAction,
  AlfredMaterImagingErrorAction,
  AlfredMaterImagingFieldValidateAction,
} from '../../actions/AlfredMaterImagingActions/alfredMaterImagingActions.types'
import { SubmitControls } from '../RegisterAccountReducer/RegisterAccountReducer.types'

export const alfredMaterImagingRequiredFields = {
  termsAndConditions: true,
  username: false,
  firstName: true,
  lastName: true,
  providerNumber: true,
  ahpranumber: true,
  referrerEmail: true,
  practiceName: true,
  practiceAddress: true,
  practiceSuburb: true,
  practiceState: true,
  practicePostcode: true,
  practicePhone: true,
  practiceFax: false,
  department: false,
  mobileNumber: true,
  speciality: true,
  otherSpeciality: false,
  subSpeciality: true,
  operatingSystemMac: true,
  operatingSystemPC: true,
  practiceManagementSystem: true,
  otherPracticeManagementSystem: false,
}

export interface AlfredMaterImagingFormDataModel {
  termsAndConditions: boolean
  username: string
  firstName: string
  lastName: string
  providerNumber: string
  ahpranumber: string
  referrerEmail: string
  practiceName: string
  practiceAddress: string
  practiceSuburb: string
  practiceState: string
  practicePostcode: string
  practicePhone: string
  practiceFax: string
  department: string
  mobileNumber: string
  speciality: string
  otherSpeciality: string
  subSpeciality: string
  operatingSystemMac: boolean
  operatingSystemPC: boolean
  practiceManagementSystem: string
  otherPracticeManagementSystem: string
}

export interface AlfredMaterImagingFormErrorModel {
  termsAndConditions: boolean
  username: boolean
  firstName: boolean
  familyName: boolean
  providerNumber: boolean
  ahpranumber: boolean
  referrerEmail: boolean
  practiceName: boolean
  practiceAddress: boolean
  practiceSuburb: boolean
  practiceState: boolean
  practicePostcode: boolean
  practicePhone: boolean
  practiceFax: boolean
  department: boolean
  mobileNumber: boolean
  speciality: boolean
  otherSpeciality: boolean
  subSpeciality: boolean
  operatingSystemMac: boolean
  operatingSystemPC: boolean
  practiceManagementSystem: boolean
  otherPracticeManagementSystem: boolean
}

type AlfredMaterImagingState = {
  formData: AlfredMaterImagingFormDataModel
  errors: AlfredMaterImagingFormErrorModel
  submit: SubmitControls
}

export const initialAlfredMaterImagingState: AlfredMaterImagingState = {
  formData: {
    termsAndConditions: false,
    username: '',
    firstName: '',
    lastName: '',
    providerNumber: '',
    ahpranumber: '',
    referrerEmail: '',
    practiceName: '',
    practiceAddress: '',
    practiceSuburb: '',
    practiceState: '',
    practicePostcode: '',
    practicePhone: '',
    practiceFax: '',
    department: '',
    mobileNumber: '',
    speciality: '',
    otherSpeciality: '',
    subSpeciality: '',
    operatingSystemMac: false,
    operatingSystemPC: false,
    practiceManagementSystem: '',
    otherPracticeManagementSystem: '',
  } as AlfredMaterImagingFormDataModel,
  errors: {
    termsAndConditions: false,
    username: false,
    firstName: false,
    familyName: false,
    providerNumber: false,
    ahpranumber: false,
    referrerEmail: false,
    practiceName: false,
    practiceAddress: false,
    practiceSuburb: false,
    practiceState: false,
    practicePostcode: false,
    practicePhone: false,
    practiceFax: false,
    department: false,
    mobileNumber: false,
    speciality: false,
    otherSpeciality: false,
    subSpeciality: false,
    operatingSystemMac: false,
    operatingSystemPC: false,
    practiceManagementSystem: false,
    otherPracticeManagementSystem: false,
  } as AlfredMaterImagingFormErrorModel,
  submit: {
    inProgress: false,
    success: false,
    fail: false,
  } as SubmitControls,
}

const AlfredMaterImagingReducer = (
  state: AlfredMaterImagingState = initialAlfredMaterImagingState,
  action: BaseAction,
): AlfredMaterImagingState => {
  switch (action.type) {
    case ALFRED_MATER_IMAGING_FIELD_UPDATE:
      const a = action as AlfredMaterImagingDataAction
      return {
        ...state,
        formData: {
          ...state.formData,
          ...a.data,
        },
      }
    case ALFRED_MATER_IMAGING_ERROR:
      const errorsAction = action as AlfredMaterImagingErrorAction
      return {
        ...state,
        errors: {
          ...errorsAction.errors,
        },
      }
    case ALFRED_MATER_IMAGING_FIELD_VALIDATE:
      const validateFieldAction = action as AlfredMaterImagingFieldValidateAction
      const fieldData = validateFieldAction.data
      return {
        ...state,
        errors: {
          ...state.errors,
          [fieldData.fieldName]: fieldData.value,
        },
      }
    case ALFRED_MATER_IMAGING_SUBMIT_LOADING:
      return { ...state, submit: { inProgress: true, success: false, fail: false } }
    case ALFRED_MATER_IMAGING_SUBMIT_SUCCESS:
      return { ...state, submit: { inProgress: false, success: true, fail: false } }
    case ALFRED_MATER_IMAGING_SUBMIT_FAIL:
      return { ...state, submit: { inProgress: false, fail: true, success: false } }
    case ALFRED_MATER_IMAGING_CLEAR:
      return { ...initialAlfredMaterImagingState }
    default:
      return state
  }
}

export default AlfredMaterImagingReducer
