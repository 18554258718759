import React, { FC } from 'react'
import {
  WebcomradPasswordResetErrorModel,
  WebcomradPasswordResetFormDataModel,
} from '../../../reducers/WebcomradPasswordResetReducer/WebcomradPasswordResetReducer'
import { Col, Row } from 'react-bootstrap'
import SingleLineTextInputField from '../../../components/forms/fields/SingleLineTextInputField'
import text from '../../../_constants/text'
import {
  WebcomradPasswordResetFieldName,
  webcomradPasswordResetRequiredFields,
} from '../../../actions/WebcomradPasswordResetActions/webcomradPasswordResetActions.types'

interface WebcomradPasswordResetFormModel {
  formData: WebcomradPasswordResetFormDataModel
  errors: WebcomradPasswordResetErrorModel
  onValueChange: (fieldName: string, value: string | boolean) => void
}

const WebcomradResetPasswordForm: FC<WebcomradPasswordResetFormModel> = ({ formData, errors, onValueChange }) => {
  return (
    <div data-testid="webcomrad-password-reset-form">
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.FULL_NAME}
            inputName={WebcomradPasswordResetFieldName.fullName}
            required={webcomradPasswordResetRequiredFields.fullName}
            value={formData.fullName}
            errorMessage={`${text.FULL_NAME_ERROR_MESSAGE} ${text.PLACEHOLDER_PATIENT_NAME}`}
            onChangeCb={onValueChange}
            error={errors.fullName}
            placeholder={text.PLACEHOLDER_PATIENT_NAME}
            labelTestId="full-name-label"
            inputTestId="full-name-input"
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.USER_ID}
            inputName={WebcomradPasswordResetFieldName.username}
            required={webcomradPasswordResetRequiredFields.username}
            value={formData.username}
            errorMessage={text.USERNAME_ERROR_MESSAGE}
            onChangeCb={onValueChange}
            error={errors.username}
            placeholder={text.PLACEHOLDER_PATIENT_EMAIL}
            labelTestId="username-label"
            inputTestId="username-input"
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.CONTACT_NUMBER}
            inputName={WebcomradPasswordResetFieldName.contactNumber}
            required={webcomradPasswordResetRequiredFields.contactNumber}
            value={formData.contactNumber}
            errorMessage={text.PHONE_NUMBER_ERROR_MESSAGE}
            onChangeCb={onValueChange}
            error={errors.contactNumber}
            placeholder={text.PLACEHOLDER_MOBILE}
            labelTestId="contact-number-label"
            inputTestId="contact-number-input"
          />
        </Col>
        <Col xs={12} lg={6}>
          <SingleLineTextInputField
            label={text.EMAIL_ADDRESS}
            inputName={WebcomradPasswordResetFieldName.emailAddress}
            required={webcomradPasswordResetRequiredFields.emailAddress}
            value={formData.emailAddress}
            errorMessage={text.EMAIL_ERROR_MESSAGE}
            onChangeCb={onValueChange}
            error={errors.emailAddress}
            placeholder={text.PLACEHOLDER_PATIENT_EMAIL}
            labelTestId="email-address-label"
            inputTestId="email-address-input"
          />
        </Col>
      </Row>
    </div>
  )
}

export default WebcomradResetPasswordForm
