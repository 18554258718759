import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { ReduxRouter } from '@lagunovsky/redux-react-router'
import LoginPage from '../pages/LoginPage'
import RegisterAccountPage from '../pages/RegisterAccount'
import ProtectedRoute from './ProtectedRoute'

import routes from './routes'
import PrivacyPolicyPage from '../pages/PrivacyPolicyPage'
import TermsOfUsePage from '../pages/TermsOfUsePage'
import AccountPage from '../pages/AccountPage'
import ForgotUsernamePage from '../pages/ForgotUsernamePage'
import ForgotPasswordPage from '../pages/ForgotPasswordPage'
import ResetPasswordPage from '../pages/ResetPasswordPage'
import PatientSearchPage from '../pages/PatientSearchPage'
import PatientReportPage from '../pages/PatientReportPage'
import PatientImageSetsPage from '../pages/PatientImageSetsPage'
import NotFoundPage from '../pages/NotFoundPage'
import ErrorBoundary from './ErrorBoundary'
import ReferralPage from '../pages/ReferralPage'
import { history } from '../reducers/store'
import WebcomradContainer from '../pages/WebcomradPage/WebcomradContainer'
import WebcomradPasswordResetContainer from '../pages/WebcomradPasswordResetPage/WebcomradPasswordResetContainer'
import AppointmentSearchPage from '../pages/AppointmentSearchPage'
import AlfredMaterImagingContainer from '../pages/AlfredMaterImagingPage/AlfredMaterImagingContainer'

const protectedRoute = (children) => <ProtectedRoute>{children}</ProtectedRoute>

const Router: React.FC = () => {
  return (
    <ReduxRouter history={history}>
      <ErrorBoundary history={history}>
        <Routes>
          <Route path={routes.home} element={<LoginPage />} />
          <Route path={routes.forgotUsername} element={<ForgotUsernamePage />} />
          <Route path={routes.apply} element={<RegisterAccountPage />} />
          <Route path={routes.appointments} element={protectedRoute(<AppointmentSearchPage />)} />
          <Route path={routes.privacyPolicy} element={<PrivacyPolicyPage />} />
          <Route path={routes.termsOfUse} element={<TermsOfUsePage />} />
          <Route path={routes.forgotPassword} element={<ForgotPasswordPage />} />
          <Route path={routes.resetPassword} element={<ResetPasswordPage />} />
          <Route path={routes.webcomrad} element={<WebcomradContainer />} />
          <Route path={routes.webcomradPasswordReset} element={<WebcomradPasswordResetContainer />} />
          <Route path={routes.alfredMaterImaging} element={<AlfredMaterImagingContainer />} />
          <Route path={routes.account} element={protectedRoute(<AccountPage />)} />
          <Route path={routes.results} element={protectedRoute(<PatientSearchPage />)} />
          <Route path={routes.patientReport} element={protectedRoute(<PatientReportPage />)} />
          <Route path={routes.patientImageSets} element={protectedRoute(<PatientImageSetsPage />)} />
          <Route path={routes.referral} element={protectedRoute(<ReferralPage />)} />
          <Route path={routes.login} element={<Navigate to={routes.home} />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </ErrorBoundary>
    </ReduxRouter>
  )
}

export default Router
