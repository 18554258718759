import RequestError from '../../errors/RequestError'
import { BaseAction, ErrorAction } from '../commons'

export const ACCEPT_TC = 'ACCEPT_TC'
export const ACCEPT_TC_SUCCESS = 'ACCEPT_TC/SUCCESS'
export const ACCEPT_TC_DENIED = 'ACCEPT_TC/DENIED'
export const ACCEPT_TC_FAIL = 'ACCEPT_TC/FAIL'
export const ACCEPT_TC_FINISH = 'ACCEPT_TC/FINISH'
export const ACCEPT_TC_NOT_ACCEPTED = 'ACCEPT_TC/NOT_ACCEPTED'

export const acceptTC = (): BaseAction => ({ type: ACCEPT_TC })
export const acceptTCSuccess = (): BaseAction => ({
  type: ACCEPT_TC_SUCCESS,
})

export const acceptTCDenied = (): BaseAction => ({
  type: ACCEPT_TC_DENIED,
})

export const acceptTCFail = (): ErrorAction => ({
  type: ACCEPT_TC_FAIL,
  data: { error: new RequestError('Error while accepting tc'), show: true },
})

export const acceptTCNotAccepted = (): BaseAction => ({
  type: ACCEPT_TC_NOT_ACCEPTED,
})

export const acceptTCFinish = (): BaseAction => ({
  type: ACCEPT_TC_FINISH,
})
