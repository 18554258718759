import React from 'react'
import { Col, Row } from 'react-bootstrap'
import LoadingBox from '../../../components/atoms/LoadingBox'

type PatientTableLoadingRowProps = {
  index: number
}
const PatientTableLoadingRow: React.FC<PatientTableLoadingRowProps> = ({ index }) => (
  <div data-testid="PatientTableRow-Wrapper" className="search-table-row-wrapper">
    <Row data-testid="PatientTableRow" className="search-table-row p-0">
      <Col xs={4} className="py-3">
        <div className="px-3">
          <LoadingBox isNarrow={index % 2 === 0} />
        </div>
      </Col>
      <Col xs={3} className="search-table-col py-3">
        <Row>
          <Col xs={5} className="search-table-col">
            <LoadingBox />
          </Col>
          <Col xs={7} className="search-table-col">
            <LoadingBox />
          </Col>
        </Row>
      </Col>
      <Col xs={4} className="search-table-col py-3">
        <Row>
          <Col xs={5} className="search-table-col">
            <LoadingBox />
          </Col>
          <Col xs={3} className="search-table-col">
            <LoadingBox />
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
)

export default PatientTableLoadingRow
