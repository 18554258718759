import text from '../../../_constants/text'
import React, { FC } from 'react'

const LoadingMessage: FC = () => {
  return (
    <>
      <div className="d-flex justify-content-center">{text.LOADING_PART1}</div>
    </>
  )
}

export default LoadingMessage
