export enum FieldValueType {
  email = 'email',
}

export const validateString = (valueType: string, required: boolean, value: string): boolean => {
  if (!required && !value) {
    return true
  }
  if (required && !value) {
    return false
  }
  switch (valueType as FieldValueType) {
    case FieldValueType.email:
      return /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/.test(value)
    default:
      return true
  }
}
