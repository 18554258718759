import React, { FC, ReactElement, useEffect } from 'react'
import { connect } from 'react-redux'
import { Navigate, RouteProps } from 'react-router-dom'
import { JwtTokenWithRefresh, loginObtainedToken, logout } from '../../actions/loginAction'
import LoadingMessage from '../../components/atoms/LoadingMessage'
import { getLocalStorageValue, getLocalStorageAuthToken } from '../../services/localStorageService'
import { LOCAL_REFRESH_KEY, LOCAL_TOKEN_KEY } from '../../_constants/variables'
import routes from '../routes'

declare type ProtectedRouteProps = RouteProps & {
  isLoggedIn: boolean
  sessionExists: (params: JwtTokenWithRefresh) => void
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ isLoggedIn, sessionExists, children }) => {
  const tokenInStorage = getLocalStorageAuthToken(LOCAL_TOKEN_KEY)
  const refreshTokenInStorage = getLocalStorageValue(LOCAL_REFRESH_KEY)

  useEffect(() => {
    const existingCredentials = getLocalStorageAuthToken(LOCAL_TOKEN_KEY)
    const refreshToken = getLocalStorageValue(LOCAL_REFRESH_KEY)
    if (refreshToken && existingCredentials) {
      sessionExists({ ...existingCredentials, refreshToken })
    }
  }, [isLoggedIn])

  if (!isLoggedIn && (!tokenInStorage || !refreshTokenInStorage)) {
    return <Navigate to={routes.home} replace />
  }
  if (!isLoggedIn && tokenInStorage && refreshTokenInStorage) {
    return <LoadingMessage />
  }
  return children as ReactElement
}

const mapStateToProps = (state) => {
  const { isLoggedIn } = state.login
  return { isLoggedIn }
}

const mapDispatchToProps = (dispatch) => ({
  sessionExists: (params: JwtTokenWithRefresh) => dispatch(loginObtainedToken(params)),
  logout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
