import React from 'react'
import Card from 'react-bootstrap/Card'
import { VideoSection as IVideoSection } from '../../../domain/models/sections/VideoSection'

interface ISection extends IVideoSection {
  className?: string
}
const VideoSection: React.FC<ISection> = ({ value, className }) => {
  return (
    <Card className={`my-2 ${className}`}>
      <Card.Body className="p-3 p-lg-4">
        <Card.Title as="h2">{value.title}</Card.Title>
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            title={value.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            className="embed-responsive-item"
            src={value.videoEmbedLink + '?rel=0&showinfo=0'}
          />
        </div>
      </Card.Body>
    </Card>
  )
}

VideoSection.defaultProps = {
  className: '',
}

export default VideoSection
