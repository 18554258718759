import { DataAction } from '../../actions/commons'
import {
  PATIENT_ROW_SEARCH_STATE_CLOSE,
  PATIENT_ROW_SEARCH_STATE_OPEN,
  PATIENT_ROW_SEARCH_STATE_RESET,
  SearchResultsData,
} from '../../actions/PatientRowSearchStateActions/PatientRowSearchStateActions'

export type PatientRowSearchState = {
  openPatients: Map<string, boolean>
}

export const initialPatientSearchState: PatientRowSearchState = {
  openPatients: new Map(),
}

const patientSearchStateReducer = (
  state: PatientRowSearchState = initialPatientSearchState,
  action: DataAction<SearchResultsData>,
): PatientRowSearchState => {
  switch (action.type) {
    case PATIENT_ROW_SEARCH_STATE_RESET: {
      return {
        ...state,
        openPatients: new Map<string, boolean>(),
      }
    }
    case PATIENT_ROW_SEARCH_STATE_CLOSE: {
      state.openPatients.set(action.data.patientId, false)
      return state
    }
    case PATIENT_ROW_SEARCH_STATE_OPEN: {
      state.openPatients.set(action.data.patientId, true)
      return state
    }
    default:
      return state
  }
}

export default patientSearchStateReducer
