import React, { FC } from 'react'

type props = {
  label: string
}

const InputLabel: FC<props> = ({ label }) => (
  <label htmlFor={label} className="mb-0 px-0">
    <p className="input-label">{label}</p>
  </label>
)

export default InputLabel
