import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'
import { BaseAction } from '../../actions/commons'
import {
  RECOVER_USERNAME,
  recoverUsernameFail,
  RecoverUsernameFormFieldType,
  recoverUsernameSuccess,
  recoverUsernameInvalidEmail,
  invalidAhpra,
  recoverUsernameValidEmail,
  validAhpra,
} from '../../actions/recoverUsernameAction'
import { validateString } from '../../components/forms/validations/RegisterAccount'
import { get, ResponseStatus, ResponseWithStatus } from '../../fetch'

const RECOVER_USERNAME_PATH = '/referreraccount/recoverUID'

export function* recoverUsername(): Generator<
  SelectEffect | Promise<ResponseWithStatus<void>> | PutEffect<BaseAction>,
  void,
  Record<RecoverUsernameFormFieldType, string> | ResponseWithStatus<void>
> {
  const recoverUsernameDetails = (yield select((state) => state.recoverUsername.form)) as Record<
    RecoverUsernameFormFieldType,
    string
  >

  if (!validateString('email', true, recoverUsernameDetails.email)) {
    yield put(recoverUsernameInvalidEmail())
    return
  } else {
    yield put(recoverUsernameValidEmail())
  }

  if (!validateString('ahpraNumber', true, recoverUsernameDetails.ahpra)) {
    yield put(invalidAhpra())
    return
  } else {
    yield put(validAhpra())
  }

  const recoverUsernameResponse = (yield get(
    `${process.env.REACT_APP_API_URL}${RECOVER_USERNAME_PATH}`,
    {},
    recoverUsernameDetails,
  )) as ResponseWithStatus<void>
  if (recoverUsernameResponse.status === ResponseStatus.FAILURE) {
    yield put(recoverUsernameFail())
  } else {
    yield put(recoverUsernameSuccess())
  }
}

function* watchRecoverUsername(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(RECOVER_USERNAME, recoverUsername)
}

const recoverUsernameSagas = [fork(watchRecoverUsername)]

export default recoverUsernameSagas
