import RequestError from '../errors/RequestError'
import { Practice } from '../reducers/AccountReducer/AccountReducer'
import { BaseAction, ErrorAction } from './commons'

export const GET_ACCOUNT_DETAILS = 'ACCOUNT/GET_ACCOUNT_DETAILS'
export const GET_ACCOUNT_DETAILS_SUCCESS = 'ACCOUNT/GET_ACCOUNT_DETAILS_SUCCESS'
export const GET_ACCOUNT_DETAILS_FAIL = 'ACCOUNT/GET_ACCOUNT_DETAILS_FAIL'

export interface AccountDetailsResponse {
  email: string
  mobile: string
  displayName: string
  practices: Practice[]
}

export interface AccountSuccessAction extends BaseAction {
  data: {
    email: string
    mobile: string
    displayName: string
    practices: Practice[]
  }
}

export const getAccountDetails = (): BaseAction => ({ type: GET_ACCOUNT_DETAILS })
export const getAccountDetailsSuccess = (accountDetailsResponse: AccountDetailsResponse): AccountSuccessAction => ({
  type: GET_ACCOUNT_DETAILS_SUCCESS,
  data: accountDetailsResponse,
})
export const getAccountDetailsFail = (): ErrorAction => ({
  type: GET_ACCOUNT_DETAILS_FAIL,
  data: { error: new RequestError('Error fetching account data'), show: true },
})
