import React from 'react'
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import Layout from '../../components/wrappers/Layout'
import StepIndicator from '../../components/molecules/StepIndicator'
import RegisterAccountForm from './RegisterAccountForm'
import { connect } from 'react-redux'
import { clearForm, navigateBack, navigateToPage, submitRegistrationFrom } from '../../actions/registerAccountActions'
import CardWrapper from './CardWrapper'
import text from '../../_constants/text'
import Success from './Success'
import { RegistrationFormType } from '../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { RegisterAccountPageType } from '../../actions/registerAccountActions.types'
import Loader from '../../components/wrappers/Loader'
import { Helmet } from 'react-helmet'
import { pageTitles } from '../../router/routes'
import PageHeadingRow from '../../components/molecules/PageHeadingRow'

export const registerAccountTitles: Record<RegisterAccountPageType, string> = {
  generalInfo: text.GENERAL_INFO,
  practiceDetails: text.PRACTICE_DETAILS,
  finish: text.FINISH,
}

type RegisterAccountPageProps = {
  registrationForm: RegistrationFormType
  currentPage: RegisterAccountPageType
  submissionSuccessful: boolean
  submissionFail: boolean
  isLoading: boolean
  navigateToPage: (pageId: RegisterAccountPageType) => void
  navigateBack: (pageId: RegisterAccountPageType) => void
  submitRegistrationFrom: () => void
  clearForm: () => void
}
const RegisterAccountPage: React.FC<RegisterAccountPageProps> = ({
  navigateToPage,
  navigateBack,
  currentPage,
  submitRegistrationFrom,
  clearForm,
  registrationForm,
  submissionSuccessful,
  submissionFail,
  isLoading,
}) => {
  const title = submissionSuccessful ? 'Registration submitted' : text.WELCOME_TO_IMOL
  return (
    <Layout>
      <Helmet>
        <title>{pageTitles.apply}</title>
      </Helmet>
      <Loader isLoading={isLoading}>
        <div className="d-flex content-background content-unauthenticated-background-min-height">
          <Container className="max-grid-width py-2 px-lg-0" fluid>
            <PageHeadingRow isSuccess={submissionSuccessful} title={title} />
            <Row className="px-0 flex-column-reverse flex-lg-row">
              <Col xs={12} lg={8}>
                {submissionSuccessful ? (
                  <Success clearForm={clearForm} email={registrationForm.email} />
                ) : (
                  <CardWrapper
                    submissionFail={submissionFail}
                    currentPage={currentPage}
                    navigateToPage={navigateToPage}
                    submitForm={submitRegistrationFrom}
                  >
                    <RegisterAccountForm />
                  </CardWrapper>
                )}
              </Col>
              <Col xs={12} lg={4}>
                {!submissionSuccessful && (
                  <StepIndicator
                    sectionIds={registerAccountTitles}
                    navigateToPage={navigateBack}
                    currentSection={currentPage}
                  />
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </Loader>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { currentPage, registrationForm, submit } = state.registerAccount
  return {
    currentPage,
    registrationForm,
    submissionSuccessful: submit.success,
    submissionFail: submit.fail,
    isLoading: submit.inProgress,
  }
}

const mapDispatchToProps = (dispatch) => ({
  navigateToPage: (pageId: RegisterAccountPageType) => dispatch(navigateToPage(pageId)),
  navigateBack: (pageId: RegisterAccountPageType) => dispatch(navigateBack(pageId)),
  submitRegistrationFrom: () => dispatch(submitRegistrationFrom()),
  clearForm: () => dispatch(clearForm()),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAccountPage)
