import React, { FC } from 'react'
import { Card, Container } from 'react-bootstrap'
import ExternalLink from '../../atoms/ExternalLink'
import text from '../../../_constants/text'

const chromeLink = 'https://www.google.com.au/chrome/'
const edgeLink = 'https://www.microsoft.com/en-us/edge'
const firefoxLink = 'https://www.mozilla.org/en-US/firefox/new/'
const safariLink = 'https://support.apple.com/en_AU/downloads/safari'

const UnsupportedBrowser: FC = () => {
  return (
    <div className="content-background" data-testid="unsupported-browser">
      <Container className="max-grid-width py-2 px-0 vh-100" fluid>
        <Card as="section" className="my-2 my-lg-3">
          <Card.Body className="p-3 p-lg-4">
            <Card.Title data-testid="title" as="h2">
              {text.UNSUPPORTED_BROWSER_TITLE}
            </Card.Title>
            <div data-testid="message">{text.UNSUPPORTED_BROWSER_MESSAGE}</div>
            <ul>
              <li data-testid="chrome-link">
                <ExternalLink href={chromeLink}>{text.CHROME}</ExternalLink>
              </li>
              <li data-testid="edge-link">
                <ExternalLink href={edgeLink}>{text.EDGE}</ExternalLink>
              </li>
              <li data-testid="firefox-link">
                <ExternalLink href={firefoxLink}>{text.FIREFOX}</ExternalLink>
              </li>
              <li data-testid="safari-link">
                <ExternalLink href={safariLink}>{text.SAFARI}</ExternalLink>
              </li>
            </ul>
          </Card.Body>
        </Card>
      </Container>
    </div>
  )
}

export default UnsupportedBrowser
