import { StudyRow } from '../../../pages/PatientSearchPage/StudyTable/index.types'
import { SubmitControls } from '../../../reducers/commons/submitControls'
import { Attachment } from './Attachment'
import { Dicom } from './Dicom'
import { Patient } from './Patient'
import { Procedure } from './Procedure'
import { Referrer } from './Referrer'
import { Status } from './Report'

export class Study {
  accessible: boolean
  accessionNumber: string
  date: string
  description: string
  facility: string
  reportUri: string
  status: Status
  uri: string
  dicom?: {
    data: Dicom[]
    state: SubmitControls
  }
  patient: Patient
  procedures: Procedure[]
  referrer: Referrer

  static isStudyInFuture(study: Study | StudyRow): boolean {
    if (study.status === Status.PENDING || study.status === Status.APPOINTMENT_PENDING) {
      return true
    }
    if (study instanceof Study) {
      return new Date(study.date) > new Date()
    } else {
      return new Date(study.studyDate) > new Date()
    }
  }

  static getAccessionNumbers(study: Study): string[] {
    const result: string[] = []
    if (study.accessionNumber) {
      result.push(study.accessionNumber)
    }
    study.procedures.forEach((procedure: Procedure) => {
      if (procedure.accessionNumber) {
        result.push(procedure.accessionNumber)
      }
    })
    return result
  }

  static getReferralPdfUri(attachments: Attachment[] | null): string | null {
    if (!attachments) {
      return null
    }
    const requests = attachments
      .filter((attachment) => attachment.type === 'Request')
      .sort((a, b) => (a.dateTime > b.dateTime ? -1 : 1))
    if (requests && requests.length) {
      return requests[0].pages[0].uri
    }
    return null
  }
}
