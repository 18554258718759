import text from '../../../_constants/text'
import { Col, Row, Card, Container, Button } from 'react-bootstrap'
import React, { FC } from 'react'
import InternalLink from '../../../components/atoms/InternalLink'
import routes from '../../../router/routes'
import { ReactComponent as Check } from './check_circle.svg'

const ResetPasswordSuccess: FC = () => {
  return (
    <Card as="section" className="my-3 my-lg-4">
      <Card.Body className="p-3 p-lg-4">
        <Card.Title className="mb-3" as="h2">
          <span>{text.PASSWORD_RESET_UPDATED} </span>
          <Check width={30} height={30} />
        </Card.Title>
        <Container className="px-0">
          <p className="pb-4">{text.PASSWORD_RESET_SUCCESS_MSG}</p>
          <Row className="pb-2">
            <Col xs={12} lg={6}>
              <InternalLink href={routes.home}>
                <Button data-testid="BackHomeButton" className="w-100 my-auto" variant="primary">
                  {text.PASSWORD_RESET_SUCCESS_BUTTON}
                </Button>
              </InternalLink>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  )
}

export default ResetPasswordSuccess
