import { takeEvery, fork, select, put, ForkEffect, SelectEffect, PutEffect, call, CallEffect } from 'redux-saga/effects'
import {
  contactInfoEmailInvalid,
  contactInfoSubmitFail,
  contactInfoSubmitSuccess,
  CONTACT_INFO_SUBMIT,
} from '../../actions/contactInfoActions'

import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { ContactInfo } from '../../reducers/ContactInfoReducer/ContactInfoReducer'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { AvailableGenerator, isEmailAvailable } from '../AvailableSaga/AvailableSaga'
import runWithRefresh from '../RefreshAuthTokenSaga/runWithRefresh'

const SUBMIT_PATH = '/account/details'

type UpdateContactInfoGenerator = Generator<
  | SelectEffect
  | Promise<ResponseWithStatus<Record<string, boolean> | ContactInfo>>
  | PutEffect
  | CallEffect<AvailableGenerator>,
  ResponseWithStatus<ContactInfo>,
  ResponseWithStatus<Record<string, boolean> | ContactInfo> | AccessToken | ContactInfo
>

export function* updateContactInfo(): UpdateContactInfoGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken
  const newContactInfo = (yield select((state) => state.contactInfo.new)) as ContactInfo
  const currentContactInfo = (yield select((state) => state.contactInfo.current)) as ContactInfo
  if (newContactInfo.email !== currentContactInfo.email) {
    const isEmailValid = yield call(() => isEmailAvailable(newContactInfo.email))
    if (!isEmailValid) {
      yield put(contactInfoEmailInvalid())
      return
    }
  }
  const submitResponse = (yield post(
    `${process.env.REACT_APP_API_URL}${SUBMIT_PATH}`,
    {
      Authorization: `${jwt.type} ${jwt.token}`,
    },
    { ...newContactInfo },
  )) as ResponseWithStatus<ContactInfo>
  return submitResponse
}

export function* updateContactInfoSaga(): Generator<
  unknown | PutEffect,
  void,
  ResponseWithStatus<Record<string, boolean> | ContactInfo> | AccessToken | ContactInfo
> {
  const submitResponse = (yield runWithRefresh(
    updateContactInfo,
  ) as UpdateContactInfoGenerator) as ResponseWithStatus<ContactInfo>
  if (!submitResponse) return
  if (submitResponse.status === ResponseStatus.SUCCESS) {
    const { email, mobile } = submitResponse.data
    yield put(contactInfoSubmitSuccess({ email, mobile }))
    return
  } else {
    yield put(contactInfoSubmitFail())
  }
}

function* watchUpdateContactInfo(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(CONTACT_INFO_SUBMIT, updateContactInfoSaga)
}

const contactInfoSagas = [fork(watchUpdateContactInfo)]

export default contactInfoSagas
