import React from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import InternalLink from '../../../components/atoms/InternalLink'
import routes from '../../../router/routes'
import text from '../../../_constants/text'

type RegisterAccountSuccessProps = { clearForm: () => void; email: string }
const RegisterAccountSuccess: React.FC<RegisterAccountSuccessProps> = ({ clearForm, email }) => {
  return (
    <Card as="section" className="my-3 my-lg-4">
      <Card.Body className="p-3 p-lg-4">
        <Card.Title data-testid="title" as="h2">
          {text.REGISTER_SUCCESS}
        </Card.Title>
        <Card.Text as="div" data-testid="subtitle" className="mt-3 success-registration">
          <p className="pb-4">
            {text.REGISTER_EMAIL_SENT_TO}
            <strong>{email}</strong>
            {text.REGISTER_EMAIL_FURTHER_INSTRUCTIONS}
          </p>
          <p className="mb-5">{text.REGISTER_THESE_CREDENTIALS}</p>
          <p>
            <strong>{text.REGISTER_DID_NOT_RECEIVE}</strong>
          </p>
          <p>{text.REGISTER_CHECK_SPAM}</p>
        </Card.Text>

        <InternalLink href={routes.home}>
          <Button data-testid="continueButton" className="my-4" onClick={clearForm}>
            {text.REGISTER_BACK_TO_SIGNIN}
          </Button>
        </InternalLink>
      </Card.Body>
    </Card>
  )
}

export default RegisterAccountSuccess
