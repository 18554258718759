import { BaseAction, DataAction } from '../../actions/commons'
import { LOGOUT_SUCCESS } from '../../actions/loginAction'
import {
  ImagesSection,
  ImagesStoreParams,
  ImagesUrls,
  PATIENT_IMAGES_CONSENT_EXPIRED,
  PATIENT_IMAGES_GET_IMAGES_DATA,
  PATIENT_IMAGES_GET_IMAGES_DATA_FAIL,
  PATIENT_IMAGES_GET_IMAGES_DATA_SUCCESS,
  PATIENT_IMAGES_RESET,
  PATIENT_IMAGES_STORE_IMAGES_PARAMS,
} from '../../actions/PatientImagesActions/patientImagesActions'
import { SubmitControls, submitStates } from '../commons/submitControls'

type PatientImagesState = {
  imagesData: ImagesSection
  imagesDataGetControls: SubmitControls
  accessionNumber: string
  prevUrl: string
  expired: boolean
}

export const initialPatientImagesState: PatientImagesState = {
  imagesData: {
    inteleviewerURL: '',
  },
  accessionNumber: '',
  prevUrl: '',
  imagesDataGetControls: { ...submitStates.default },
  expired: false,
}

const patientImagesReducer = (state = initialPatientImagesState, action: BaseAction): PatientImagesState => {
  switch (action.type) {
    case PATIENT_IMAGES_GET_IMAGES_DATA: {
      return {
        ...state,
        imagesDataGetControls: { ...submitStates.inProgress },
      }
    }
    case PATIENT_IMAGES_GET_IMAGES_DATA_SUCCESS: {
      const a = action as DataAction<ImagesUrls>
      return {
        ...state,
        imagesDataGetControls: { ...submitStates.success },
        imagesData: { inteleviewerURL: a.data.urls[0] },
      }
    }
    case PATIENT_IMAGES_GET_IMAGES_DATA_FAIL:
      return {
        ...state,
        imagesDataGetControls: { ...submitStates.fail },
      }
    case PATIENT_IMAGES_STORE_IMAGES_PARAMS: {
      const a = action as DataAction<ImagesStoreParams>
      return {
        ...state,
        accessionNumber: a.data.accessionNumber,
      }
    }
    case PATIENT_IMAGES_CONSENT_EXPIRED: {
      return {
        ...state,
        expired: true,
      }
    }
    case PATIENT_IMAGES_RESET:
    case LOGOUT_SUCCESS:
      return {
        ...initialPatientImagesState,
      }
    default:
      return state
  }
}

export default patientImagesReducer
