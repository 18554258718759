export enum GAEventNames {
  BREAK_GLASS = 'Break Glass',
  CHANGE_PASSWORD = 'Change Password',
  ERROR_PAGE = 'Error Page',
  LOGIN = 'Login',
  LOGIN_FAILURE = 'Login Failure',
  PAGE_VIEW = 'Page View',
  REPORT_PAGE = 'Report Page',
  RESULTS_PAGE = 'Results Page',
  SESSION_TIMEOUT = 'Session Timeout',
  SUPPORT_PAGE = 'Support Page',
  E_REFERRALS_PAGE = 'E-Referrals Page',
}

// New custom dimensions will need to be added to Google Analytics through the dashboard Configure tab - only users with 'Editor' access can create them.
export enum CustomDimensions {
  BREAK_GLASS = 'break_glass',
  DATE = 'date',
  INTERVALS = 'intervals',
  PAGE = 'page',
}

export enum EReferralIntervals {
  STARTED = 'Started',
  PATIENT_DETAILS_COMPLETE = 'Patient Details Complete',
  EXAMINATION_AND_CLINICAL_DETAILS_COMPLETE = 'Examination and Clinical Details Complete',
  REFERRING_PRACTITIONER_COMPLETE = 'Referring Practitioner Complete',
  FINISHED = 'Finished',
}

export enum GACommands {
  EVENT = 'event',
}
