import {
  AccountSuccessAction,
  GET_ACCOUNT_DETAILS,
  GET_ACCOUNT_DETAILS_FAIL,
  GET_ACCOUNT_DETAILS_SUCCESS,
} from '../../actions/accountAction'
import { BaseAction, DataAction, UpdateFieldAction } from '../../actions/commons'
import { ContactInfoFormFieldType, CONTACT_INFO_SUBMIT_SUCCESS } from '../../actions/contactInfoActions'
import { Address } from '../../domain/models/commons/Address'
import { IMedJwtPayload, LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../../actions/loginAction'
import { SubmitControls, submitStates } from '../commons/submitControls'

export type Practice = {
  uri: string
  fullName: string
  practiceName: string
  providerNumber: string
  specialty: string
  phone1: string
  phone2: string
  mobile: string
  email: string
  fax: string
  businessUnit: string
  address: Address
}

type AccountState = {
  email: string
  mobile: string
  displayName: string
  practices: Practice[]
  submit: SubmitControls
}

export const initialAccountState = {
  email: '',
  mobile: '',
  displayName: '',
  practices: [],
  submit: { ...submitStates.default },
}

const accountReducer = (state = initialAccountState, action: BaseAction): AccountState => {
  switch (action.type) {
    case GET_ACCOUNT_DETAILS: {
      return {
        ...state,
        submit: { ...submitStates.inProgress },
      }
    }
    case LOGIN_SUCCESS: {
      const a = action as DataAction<IMedJwtPayload>
      return {
        ...state,
        displayName: a.data.username,
      }
    }
    case GET_ACCOUNT_DETAILS_SUCCESS: {
      const a = action as AccountSuccessAction
      return {
        ...state,
        email: a.data.email,
        mobile: a.data.mobile,
        practices: a.data.practices,
        submit: { ...submitStates.success },
      }
    }
    case GET_ACCOUNT_DETAILS_FAIL:
      return {
        ...state,
        submit: { ...submitStates.fail },
      }
    case CONTACT_INFO_SUBMIT_SUCCESS: {
      const a = action as UpdateFieldAction<ContactInfoFormFieldType>
      return {
        ...state,
        email: a.data.email,
        mobile: a.data.mobile,
      }
    }
    case LOGOUT_SUCCESS:
      return { ...initialAccountState }
    default:
      return state
  }
}

export default accountReducer
