import { Action, Reducer } from 'redux'
import {
  DataAction,
  InitialSearch,
  SearchFormFieldType,
  UpdateFieldAction,
  ValidationErrorsAction,
} from '../../actions/commons'
import { submitStates } from './submitControls'
import { Search, SearchState } from './types'
import text from '../../_constants/text'
import { isEmpty } from 'lodash'
import { Practice } from '../../domain/models/commons/Practice'
import { LOGOUT_SUCCESS } from '../../actions/loginAction'

export const applyFilter = (currentFilter: string[], filter: string, allFilters: string): string[] => {
  let newFilter = currentFilter
  newFilter = newFilter.filter((existingFilter) => {
    return existingFilter !== allFilters
  })

  if (newFilter.includes(filter)) {
    newFilter = newFilter.filter((existingFilter) => {
      return existingFilter !== filter
    })
  } else {
    newFilter.push(filter)
  }

  if (isEmpty(newFilter)) {
    newFilter.push(allFilters)
  }
  return newFilter
}

export type SearchName = 'patient' | 'appointment'

function createSearchReducer(searchName: SearchName, defaultState: SearchState, defaultSearchInputs: Search): Reducer {
  const actionNamePrefix = searchName.toUpperCase()
  return function reducer(state: SearchState = defaultState, action: Action | DataAction<unknown>) {
    switch (action.type) {
      case `${actionNamePrefix}_SEARCH/SEARCH`: {
        const a = action as DataAction<InitialSearch>
        const firstLoad = a.data?.isInitialSearch
        return { ...state, submit: { ...submitStates.inProgress }, firstLoad }
      }
      case `${actionNamePrefix}_SEARCH/SEARCH_SUCCESS`: {
        return {
          ...state,
          submit: { ...submitStates.success },
          activeSearch: { ...state.currentSearch },
        }
      }
      case `${actionNamePrefix}_SEARCH/ABORT_SEARCH`: {
        return { ...state, submit: { ...submitStates.default } }
      }
      case `${actionNamePrefix}_SEARCH/SEARCH_FAIL`: {
        return { ...state, submit: { ...submitStates.fail } }
      }
      case `${actionNamePrefix}_SEARCH/UPDATE_INPUTS`: {
        const a = action as UpdateFieldAction<SearchFormFieldType>
        const updateKey = Object.keys(a.data)
        const errors = { ...state.searchErrors }
        delete errors[updateKey[0]]
        return {
          ...state,
          currentSearch: { ...state.currentSearch, ...a.data },
          submit: { inProgress: false, success: false, fail: false },
          searchErrors: { ...errors },
        }
      }
      case `${actionNamePrefix}_SEARCH/UPDATE_PRACTICE_FILTER`: {
        const a = action as DataAction<string>
        const filter = a.data

        if (filter === text.ALL_PRACTICES) {
          return {
            ...state,
            currentSearch: { ...state.currentSearch, practices: [text.ALL_PRACTICES] },
          }
        }

        const newPracticeFilter = applyFilter(state.currentSearch.practices, filter, text.ALL_PRACTICES)

        return {
          ...state,
          currentSearch: { ...state.currentSearch, practices: newPracticeFilter },
        }
      }
      case `${actionNamePrefix}_SEARCH/UPDATE_MODALITY_FILTER`: {
        const a = action as DataAction<string>
        const filter = a.data

        if (filter === text.ALL_MODALITIES) {
          return {
            ...state,
            currentSearch: { ...state.currentSearch, modalities: [text.ALL_MODALITIES] },
          }
        }

        const newModalityFilter = applyFilter(state.currentSearch.modalities, filter, text.ALL_MODALITIES)

        return {
          ...state,
          currentSearch: { ...state.currentSearch, modalities: newModalityFilter },
        }
      }
      case `${actionNamePrefix}_SEARCH/UPDATE_SELECTED_PRACTICE_LIST`: {
        const a = action as DataAction<Practice[]>
        if (state.currentSearch.practices[0] === text.ALL_PRACTICES) {
          return {
            ...state,
            selectedPractices: [text.ALL_PRACTICES],
          }
        }
        const practiceNames = state.currentSearch.practices.map((practiceUri) => {
          return a.data.find((practice) => {
            if (practice.uri === practiceUri) {
              return practice.practiceName
            }
          }).practiceName
        })
        return {
          ...state,
          selectedPractices: practiceNames,
        }
      }
      case `${actionNamePrefix}_SEARCH/UPDATE_ERRORS`: {
        const a = action as ValidationErrorsAction<SearchFormFieldType>
        return {
          ...state,
          searchErrors: {
            ...state.searchErrors,
            ...a.data.errors,
          },
          submit: { ...submitStates.fail },
        }
      }
      case `${actionNamePrefix}_SEARCH/SET_SEARCH_TYPE`: {
        const currentSearch = {
          ...defaultState.currentSearch,
          dateRange: text.SEARCH_DATE_RANGE_ALL_TIME,
        }
        return {
          ...state,
          currentSearch,
          activeSearch: { ...defaultState.activeSearch },
          submit: { ...submitStates.default },
          selectedPractices: defaultState.selectedPractices,
        }
      }
      case `${actionNamePrefix}_SEARCH/UPDATE_ERRORS`: {
        const a = action as ValidationErrorsAction<SearchFormFieldType>
        return {
          ...state,
          searchErrors: {
            ...state.searchErrors,
            ...a.data.errors,
          },
          submit: { ...submitStates.fail },
        }
      }
      case `${actionNamePrefix}_SEARCH/CLEAR_ALL`: {
        return {
          ...state,
          currentSearch: { ...defaultSearchInputs },
          selectedPractices: defaultState.selectedPractices,
          submit: { ...submitStates.default },
        }
      }
      case `${actionNamePrefix}_SEARCH/SET_CURRENT_SEARCH`: {
        const a = action as DataAction<Search>

        return {
          ...state,
          currentSearch: { ...a.data },
        }
      }
      case `${actionNamePrefix}_SEARCH/SET_FIRST_LOAD`: {
        const a = action as DataAction<boolean>

        return {
          ...state,
          firstLoad: a.data,
        }
      }
      case `${actionNamePrefix}_SEARCH/DEFAULT_SEARCH_FILTERS_SAVED`: {
        const a = action as DataAction<boolean>

        return {
          ...state,
          defaultSearchFiltersSaved: a.data,
        }
      }
      case LOGOUT_SUCCESS:
        return { ...defaultState }
      default:
        return state
    }
  }
}

export default createSearchReducer
