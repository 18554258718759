import React, { useRef, useState } from 'react'
import { Col, Collapse, Row } from 'react-bootstrap'
import moment from 'moment'
import Icon from '../../../components/atoms/Icon'
import icons from '../../../_constants/icons'
import text from '../../../_constants/text'
import ProcedureTable from '../ProcedureTable'
import { PatientWithNextAppointmentInfo } from '../../../selectors/studies'

type AppointmentTableRowProps = PatientWithNextAppointmentInfo

const AppointmentTableRow: React.FC<AppointmentTableRowProps> = ({
  id: patientId,
  name: patientName,
  dob,
  referrerName,
  nextAppointmentDate,
}) => {
  const patientRowRef = useRef(null)

  const [showProcedures, setShowProcedures] = useState(false)

  const handleShowProceduresClick = () => setShowProcedures((prevShowProcedures) => !prevShowProcedures)

  return (
    <div
      data-testid="AppointmentTableRow-Wrapper"
      className={`search-table-row-wrapper ${showProcedures ? 'active' : ''}`}
      ref={patientRowRef}
    >
      <Row data-testid="AppointmentTableRow" onClick={handleShowProceduresClick} className="search-table-row">
        <Col xs={4} className="search-table-col d-flex">
          <div className="me-2">
            <Icon className="pe-2" name={showProcedures ? icons.ARROW_UP : icons.ARROW_DOWN} />
          </div>
          <div>
            <b>{patientName}</b>
          </div>
        </Col>
        <Col xs={5} className="search-table-col">
          <Row>
            <Col xs={3} className="search-table-col">
              {moment(dob, text.DATE_SEARCH_FORMAT).format(text.DATE_FORMAT).toString()}
            </Col>
            <Col xs={4} className="search-table-col">
              {patientId}
            </Col>
            <Col xs={5} className="search-table-col text-truncate" title={referrerName}>
              {referrerName}
            </Col>
          </Row>
        </Col>
        <Col xs={3} className="search-table-col">
          <span>{moment(nextAppointmentDate).format(text.DATE_FORMAT).toString()}</span>
        </Col>
      </Row>
      <Collapse in={showProcedures}>
        <div className="search-table-row-wrapper" data-testid="AppointmentTableRow-ProcedureTable">
          {showProcedures ? <ProcedureTable patientId={patientId} /> : <div />}
        </div>
      </Collapse>
    </div>
  )
}

export default AppointmentTableRow
