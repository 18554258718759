import React, { Dispatch, useState } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import {
  acceptTC,
  acceptTCFinish,
  acceptTCDenied,
  acceptTCNotAccepted,
} from '../../../actions/AcceptTC/acceptTCActions'
import { BaseAction } from '../../../actions/commons'
import { logout } from '../../../actions/loginAction'
import { patientSearch } from '../../../actions/PatientSearchActions/patientSearchActions'
import { RootState } from '../../../reducers/store'
import routes from '../../../router/routes'
import TCAcceptanceModal from './TCAcceptanceModal'
import TCAccessDeniedModal from './TCAccessDeniedModal'
import TCAccessGrantedModal from './TCAccessGrantedModal'

interface AcceptTCProps {
  isLoading: boolean
  tcAccepted: boolean
  tcDenied: boolean
  tcRequired: boolean
  acceptTC: () => void
  acceptTCDenied: () => void
  acceptTCFinish: () => void
  logout: () => void
  patientSearch: () => void
  patientSearchTCNotAccepted: () => void
}

const AcceptTC: React.FC<AcceptTCProps> = ({
  tcAccepted,
  tcDenied,
  tcRequired,
  patientSearch,
  acceptTC,
  acceptTCFinish,
  acceptTCDenied,
  patientSearchTCNotAccepted,
  logout,
}) => {
  const navigate = useNavigate()

  const [showTCAccessGrantedModal, setShowTCAccessGrantedModal] = useState(true)

  const shouldShowTCAccessGrantedModal = showTCAccessGrantedModal && tcAccepted

  return (
    <>
      <TCAcceptanceModal show={tcRequired} onDeny={acceptTCDenied} tcAcceptanceCB={acceptTC} />
      <TCAccessGrantedModal
        show={shouldShowTCAccessGrantedModal}
        handleShow={() => {
          patientSearch()
          acceptTCFinish()
          setShowTCAccessGrantedModal(false)
        }}
      />
      <TCAccessDeniedModal
        show={tcDenied}
        handleShow={() => {
          logout()
          navigate(routes.home)
        }}
        tcAccessDeniedCB={patientSearchTCNotAccepted}
      />
    </>
  )
}

const mapStateToProps = (state: RootState) => {
  const { data, submit } = state.patientSearch
  const { tcAccepted, submit: tcSubmit, tcDenied, tcRequired } = state.acceptTC
  return { studies: data, isLoading: submit.inProgress || tcSubmit.inProgress, tcAccepted, tcDenied, tcRequired }
}

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>) => ({
  acceptTC: () => dispatch(acceptTC()),
  acceptTCFinish: () => dispatch(acceptTCFinish()),
  patientSearch: () => dispatch(patientSearch()),
  acceptTCDenied: () => dispatch(acceptTCDenied()),
  patientSearchTCNotAccepted: () => dispatch(acceptTCNotAccepted()),
  logout: () => dispatch(logout()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AcceptTC)
