import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'
import {
  submitWebcomradLoading,
  submitWebcomradSubmitFail,
  submitWebcomradSubmitSuccess,
  WEBCOMRAD_REGISTRATION_SUBMIT,
} from '../../actions/WebcomradActions/webcomradActions'
import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import runWithRefresh from '../RefreshAuthTokenSaga/runWithRefresh'

const WEBCOMRAD_PATH = '/portal/register/web-comrad'

type SubmitWebcomradGenerator = Generator<
  SelectEffect | Promise<ResponseWithStatus<void>>,
  ResponseWithStatus<void>,
  ResponseWithStatus<void> | AccessToken
>

export function* submitWebcomradForm(): SubmitWebcomradGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken
  const formData = yield select((state) => state.webcomrad.formData)

  return (yield post(
    `${process.env.REACT_APP_API_URL}${WEBCOMRAD_PATH}`,
    { Authorization: `${jwt.type} ${jwt.token}` },
    formData,
  )) as ResponseWithStatus<void>
}

type SubmitWebcomradSagaGenerator = Generator<PutEffect | SelectEffect | unknown, void, ResponseWithStatus<void>>

export function* submitWebcomradSaga(): SubmitWebcomradSagaGenerator {
  yield put(submitWebcomradLoading())
  const response = (yield runWithRefresh(submitWebcomradForm) as SubmitWebcomradGenerator) as ResponseWithStatus<void>

  if (response.status === ResponseStatus.SUCCESS) {
    yield put(submitWebcomradSubmitSuccess())
    return
  }
  yield put(submitWebcomradSubmitFail())
}

function* watchSubmitWebcomradRegistration(): Generator<ForkEffect<never>, void> {
  yield takeEvery([WEBCOMRAD_REGISTRATION_SUBMIT], submitWebcomradSaga)
}

const webcomradSagas = [fork(watchSubmitWebcomradRegistration)]

export default webcomradSagas
