import { FC, useEffect } from 'react'
import WebcomradPage from '../WebcomradPage'
import { WebcomradFieldName } from '../../../actions/WebcomradActions/webcomradActions.types'
import { connect } from 'react-redux'
import {
  clearWebcomradForm,
  submitWebcomradRegistration,
  updateWebcomradFieldValue,
  validateWebcomradFormField,
} from '../../../actions/WebcomradActions/webcomradActions'
import { FieldValue } from '../../../actions/registerAccountActions.types'
import { WebcomradFormDataModel, WebcomradFormErrorModel } from '../../../reducers/WebcomradReducer/WebcomradReducer'
import { SubmitControls } from '../../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { validateWebcomradField } from '../../../components/forms/validations/Webcomrad'
import Layout from '../../../components/wrappers/Layout'
import Loader from '../../../components/wrappers/Loader'
import text from '../../../_constants/text'

interface WebcomradProps {
  formData: WebcomradFormDataModel
  submitRegistration: (formData: WebcomradFormDataModel) => void
  validateField: (value: boolean, type: WebcomradFieldName) => void
  clearForm: () => void
  updateFieldValue: (type: WebcomradFieldName, value: FieldValue) => void
  errors: WebcomradFormErrorModel
  submit: SubmitControls
}

const WebcomradContainer: FC<WebcomradProps> = ({
  formData,
  submitRegistration,
  clearForm,
  updateFieldValue,
  errors,
  submit,
  validateField,
}) => {
  useEffect(() => {
    return () => {
      clearForm()
    }
  }, [])

  const onValueChange = (fieldName: WebcomradFieldName, value: FieldValue) => {
    const isValid = validateWebcomradField(fieldName, value)
    validateField(isValid, fieldName)
    updateFieldValue(fieldName, value)
  }

  const onSubmit = (): void => {
    submitRegistration(formData)
  }

  return (
    <Layout>
      <Loader isLoading={submit.inProgress} message={text.SUBMITTING_FORM_MESSAGE}>
        <WebcomradPage
          submissionSuccess={submit.success}
          submissionFailed={submit.fail}
          formData={formData}
          errors={errors}
          onValueChange={onValueChange}
          onSubmit={onSubmit}
          clearForm={clearForm}
        />
      </Loader>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { formData, errors, submit } = state.webcomrad
  return { formData, errors, submit }
}

const mapDispatchToProps = (dispatch) => ({
  submitRegistration: (formData) => dispatch(submitWebcomradRegistration(formData)),
  updateFieldValue: (type: WebcomradFieldName, value: FieldValue) => dispatch(updateWebcomradFieldValue(type, value)),
  validateField: (value: boolean, type: WebcomradFieldName) => dispatch(validateWebcomradFormField(value, type)),
  clearForm: () => dispatch(clearWebcomradForm()),
})

export default connect(mapStateToProps, mapDispatchToProps)(WebcomradContainer)
