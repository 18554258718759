import React, { FC, PropsWithChildren } from 'react'
import { Button, Card } from 'react-bootstrap'
import AlertBox from '../../../components/molecules/AlertBox'

interface AlfredMaterImagingWrapperProps extends PropsWithChildren {
  submitRegistration: () => void
  submissionFail: boolean
  buttonText: string
  errorMessage: string
}

const AlfredMaterImagingWrapper: FC<AlfredMaterImagingWrapperProps> = ({
  children,
  submitRegistration,
  submissionFail,
  buttonText,
  errorMessage,
}) => {
  return (
    <Card as="section" className="mb-3 mb-lg-4" data-testid="alfred-mater-imaging-wrapper">
      <Card.Body className="p-3 p-lg-4">
        <form
          data-testid="alfred-mater-imaging-form-controller"
          onSubmit={(e) => {
            e.preventDefault()
            submitRegistration()
          }}
        >
          {children}
          {submissionFail && <AlertBox type="error" message={errorMessage} className="mb-4" />}
          <Button data-testid="alfred-mater-imaging-submit-button" type="submit">
            {buttonText}
          </Button>
        </form>
      </Card.Body>
    </Card>
  )
}

export default AlfredMaterImagingWrapper
