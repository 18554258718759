import React, { PropsWithChildren } from 'react'
import Icon from '../../../components/atoms/Icon'

interface StudyTableColumnLabelProps extends PropsWithChildren {
  onSortCb?: () => void
}

const StudyTableColumnLabel: React.FC<StudyTableColumnLabelProps> = ({ children, onSortCb }) => {
  return (
    <div className="d-flex flex-row" data-testid="StudyTableColumnLabel">
      <span className="mr-2">{children}</span>
      {onSortCb && <Icon className="study-table-col-label-icon" name="sort" onClick={onSortCb} />}
    </div>
  )
}

export default StudyTableColumnLabel
