import React, { PropsWithChildren } from 'react'
import Icon from '../../../components/atoms/Icon'

interface ProcedureTableColumnLabelProps extends PropsWithChildren {
  onSortCb?: () => void
}

const ProcedureTableColumnLabel: React.FC<ProcedureTableColumnLabelProps> = ({ children, onSortCb }) => {
  return (
    <div className="d-flex flex-row" data-testid="ProcedureTableColumnLabel">
      <span className="mr-2">{children}</span>
      {onSortCb && <Icon className="procedure-table-col-label-icon" name="sort" onClick={onSortCb} />}
    </div>
  )
}

export default ProcedureTableColumnLabel
