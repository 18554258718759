import React, { useEffect } from 'react'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import Layout from '../../components/wrappers/Layout'
import routes, { pageTitles } from '../../router/routes'
import LoginForm from './LoginForm'
import Button from 'react-bootstrap/Button'
import Icon from '../../components/atoms/Icon'
import ExternalLink from '../../components/atoms/ExternalLink'
import icons from '../../_constants/icons'
import text from '../../_constants/text'
import urls from '../../_constants/urls'
import Loader from '../../components/wrappers/Loader'
import { Helmet } from 'react-helmet'
import AlertBox from '../../components/molecules/AlertBox'

type LoginPageProps = {
  isLoggedIn: boolean
  isSubmitting: boolean
}

const LoginPage: React.FC<LoginPageProps> = ({ isLoggedIn, isSubmitting }) => {
  const navigate = useNavigate()
  const returnButtonData = {
    icon: icons.BACK,
    text: text.BACK_TO_WEBSITE,
    linkUrl: urls.RETURN_TO_IMED,
  }

  useEffect(() => {
    if (isLoggedIn) {
      navigate(routes.results)
    }
  }, [isLoggedIn])

  return (
    <Layout>
      <Helmet>
        <title>{pageTitles.login}</title>
      </Helmet>
      <Loader isLoading={isSubmitting}>
        <div className="d-flex align-items-center content-background content-unauthenticated-background-min-height py-5">
          <Container className="max-grid-width py-2" fluid>
            <Row className="justify-content-center">
              <Col xs={12} lg={6}>
                <AlertBox type="primary" message={text.LOGIN_USER_CREDENTIALS_MESSAGE} className="primary" />
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col xs={12} lg={6}>
                <LoginForm />
              </Col>
            </Row>
            <Row className="d-lg-none mx-0 px-0 mt-4 justify-content-center align-content-center">
              <Col xs={12} lg={6}>
                <ExternalLink href={returnButtonData.linkUrl} newTab={false}>
                  <Button variant="light" className="btn-menu w-100">
                    <Icon name={returnButtonData.icon} className="mr-2" />
                    {returnButtonData.text}
                  </Button>
                </ExternalLink>
              </Col>
            </Row>
          </Container>
        </div>
      </Loader>
    </Layout>
  )
}

const mapStateToProps = (state) => {
  const { isLoggedIn, submit } = state.login
  return { isLoggedIn, isSubmitting: submit.inProgress }
}

export default connect(mapStateToProps)(LoginPage)
