import { Study } from '../../domain/models/commons/Study'
import RequestError from '../../errors/RequestError'
import {
  BaseAction,
  SearchFormFieldType,
  createDataAction,
  createSearchValidationErrorsAction,
  createUpdateFieldAction,
  searchActionCreator,
} from '../commons'
import { Practice } from '../../reducers/AccountReducer/AccountReducer'
import { createErrorAction } from '../commons'
import { Search } from '../../reducers/commons/types'

export const appointmentSearchActionCreator = searchActionCreator('APPOINTMENT')

export const APPOINTMENT_SEARCH_SEARCH = appointmentSearchActionCreator('SEARCH')
export const APPOINTMENT_SEARCH_SET_FIRST_LOAD = appointmentSearchActionCreator('FIRST_LOAD')
export const APPOINTMENT_SEARCH_CLEAR_ALL = appointmentSearchActionCreator('CLEAR_ALL')
export const APPOINTMENT_SEARCH_SEARCH_SUCCESS = appointmentSearchActionCreator('SEARCH_SUCCESS')
export const APPOINTMENT_SEARCH_SEARCH_FAIL = appointmentSearchActionCreator('SEARCH_FAIL')
export const APPOINTMENT_SEARCH_SEARCH_ALL = appointmentSearchActionCreator('SEARCH_ALL')
export const APPOINTMENT_SEARCH_SET_CURRENT_SEARCH = appointmentSearchActionCreator('SET_CURRENT_SEARCH')

export const APPOINTMENT_SEARCH_UPDATE_INPUTS = appointmentSearchActionCreator('UPDATE_INPUTS')
export const APPOINTMENT_SEARCH_UPDATE_MODALITY_FILTER = appointmentSearchActionCreator('UPDATE_MODALITY_FILTER')
export const APPOINTMENT_SEARCH_UPDATE_PRACTICE_FILTER = appointmentSearchActionCreator('UPDATE_PRACTICE_FILTER')
export const APPOINTMENT_SEARCH_UPDATE_ERRORS = appointmentSearchActionCreator('UPDATE_ERRORS')
export const APPOINTMENT_SEARCH_SET_SEARCH_TYPE = appointmentSearchActionCreator('SET_SEARCH_TYPE')
export const APPOINTMENT_SEARCH_UPDATE_SELECTED_PRACTICE_LIST = appointmentSearchActionCreator(
  'UPDATE_SELECTED_PRACTICE_LIST',
)

export interface PatientSearchSetSearchAction extends BaseAction {
  data: { active: string }
}

export type PatientSearchAllActionData = {
  appointmentId: string
  dob: string
}

export const appointmentSearch = createDataAction<boolean>(APPOINTMENT_SEARCH_SEARCH, false)

export const clearAllSearch = (): BaseAction => ({ type: APPOINTMENT_SEARCH_CLEAR_ALL })

export const appointmentSearchSuccess = createDataAction<Study[]>(APPOINTMENT_SEARCH_SEARCH_SUCCESS)

export const appointmentSearchFail = createErrorAction<RequestError>(
  APPOINTMENT_SEARCH_SEARCH_FAIL,
  new RequestError('Error while searching appointments'),
)

export const updateField = createUpdateFieldAction<SearchFormFieldType>(APPOINTMENT_SEARCH_UPDATE_INPUTS)

export const updateModalityFilter = createDataAction<string>(APPOINTMENT_SEARCH_UPDATE_MODALITY_FILTER)

export const updatePracticeFilter = createDataAction<string>(APPOINTMENT_SEARCH_UPDATE_PRACTICE_FILTER)

export const updateSelectedPracticeList = createDataAction<Practice[] | string[]>(
  APPOINTMENT_SEARCH_UPDATE_SELECTED_PRACTICE_LIST,
)

export const appointmentSearchValidationErrors = createSearchValidationErrorsAction(APPOINTMENT_SEARCH_UPDATE_ERRORS)

export const appointmentSearchSetSearchType = createDataAction<PatientSearchSetSearchAction>(
  APPOINTMENT_SEARCH_SET_SEARCH_TYPE,
)

export const appointmentSearchAll = createDataAction<PatientSearchAllActionData>(APPOINTMENT_SEARCH_SEARCH_ALL)

export const setCurrentSearch = createDataAction<Search>(APPOINTMENT_SEARCH_SET_CURRENT_SEARCH)

export const appointmentSearchSetFirstLoad = createDataAction<boolean>(APPOINTMENT_SEARCH_SET_FIRST_LOAD)
