import React, { MouseEvent } from 'react'
import { Col } from 'react-bootstrap'
import IndicatorInternalLink from '../../../components/atoms/IndicatorInternalLink'
import { Status } from '../../../domain/models/commons/Report'
import text from '../../../_constants/text'

type ReportCellProps = {
  reportUrl: string
  status: Status
  handleShowReport: (e: MouseEvent) => void
}

/**
 *  Renders a report status indicating whether a report can be viewed or is pending
 */
const ReportCell: React.FC<ReportCellProps> = ({ reportUrl, status, handleShowReport }) => {
  let reportStatusComponent

  switch (status) {
    case Status.STARTED:
    case Status.PENDING:
      reportStatusComponent = (
        <IndicatorInternalLink
          placeholder={text.PENDING}
          type="warning"
          href={reportUrl}
          onClick={(e) => e.preventDefault()}
        >
          {text.PENDING}
        </IndicatorInternalLink>
      )
      break
    case Status.APPOINTMENT_PENDING:
      reportStatusComponent = (
        <IndicatorInternalLink
          placeholder={text.APPPOINTMENT_PENDING}
          type="warning"
          href={reportUrl}
          onClick={(e) => e.preventDefault()}
        >
          {text.PENDING}
        </IndicatorInternalLink>
      )
      break
    case Status.IMAGES_AVAILABLE:
      reportStatusComponent = (
        <IndicatorInternalLink placeholder={text.PENDING} type="warning" href={reportUrl} onClick={handleShowReport}>
          {text.STUDY_TABLE_IN_PROGRESS}
        </IndicatorInternalLink>
      )
      break
    case Status.COMPLETE:
      reportStatusComponent = (
        <IndicatorInternalLink placeholder={text.PENDING} type="success" href={reportUrl} onClick={handleShowReport}>
          {text.STUDY_TABLE_SHOW_REPORT}
        </IndicatorInternalLink>
      )
      break
  }
  return (
    <Col data-testid="ReportCell" className="study-table-col link-col" xs={6}>
      {reportStatusComponent}
    </Col>
  )
}

export default ReportCell
