import text from '../../_constants/text'
import { submitStates } from '../commons/submitControls'
import { Status } from '../../domain/models/commons/Report'
import { Search } from '../commons/types'
import createSearchReducer from '../commons/createSearchReducer'

export const defaultPatientSearchInputs: Search = {
  nameOrPatientId: '',
  dob: '',
  modalities: [text.ALL_MODALITIES],
  dateRange: text.SEARCH_DATE_RANGE_ALL_TIME,
  practices: [text.ALL_PRACTICES],
  orderStatus: [Status.IMAGES_AVAILABLE, Status.COMPLETE, Status.PENDING],
  searchType: text.SEARCH_REFERRED_BY_ME,
}

export const initialPatientSearchState = {
  firstLoad: true,
  activeSearchType: text.SEARCH_REFERRED_BY_ME,
  selectedPractices: [text.ALL_PRACTICES],
  defaultSearchFiltersSaved: false,
  searchErrors: {},
  currentSearch: {
    nameOrPatientId: '',
    dob: '',
    modalities: [text.ALL_MODALITIES],
    dateRange: text.SEARCH_DATE_RANGE_PAST_WEEK,
    practices: [text.ALL_PRACTICES],
    orderStatus: [Status.IMAGES_AVAILABLE, Status.COMPLETE, Status.PENDING],
    searchType: text.SEARCH_REFERRED_BY_ME,
  },
  activeSearch: {
    nameOrPatientId: '',
    dob: '',
    dateRange: text.SEARCH_DATE_RANGE_PAST_WEEK,
    modalities: [text.ALL_MODALITIES],
    practices: [text.ALL_PRACTICES],
    orderStatus: [Status.IMAGES_AVAILABLE, Status.COMPLETE, Status.PENDING],
    searchType: text.SEARCH_REFERRED_BY_ME,
  },
  submit: {
    ...submitStates.default,
  },
}

const patientSearchReducer = createSearchReducer('patient', initialPatientSearchState, defaultPatientSearchInputs)

export default patientSearchReducer
