import React from 'react'
import Icon from '../../../atoms/Icon'
import icons from '../../../../_constants/icons'
import text from '../../../../_constants/text'
import { passwordRequirements } from '../../../../utils/passwordValidation'
import { MIN_PASSWORD_LENGTH } from '../../../../_constants/variables'

interface PasswordIndicatorProps {
  password: string
}

const PasswordIndicator: React.FC<PasswordIndicatorProps> = ({ password }) => {
  const minLength = passwordRequirements.minLength(password)
  const oneUpperCaseLetter = passwordRequirements.oneUpperCaseLetter(password)
  const oneLowerCaseLetter = passwordRequirements.oneLowerCaseLetter(password)
  const oneDigit = password.length > 0 && passwordRequirements.oneDigit(password)
  const oneSymbol = passwordRequirements.oneSymbol(password)

  return (
    <>
      <div className="password-indicator d-flex flex-row mt-3">
        <Icon name={minLength ? icons.INDICATOR_LIGHT : icons.INDICATOR} className="password-indicator-icon" />
        <p className={minLength ? 'light' : 'regular'}>{text.PASSWORD_REQUIREMENT_LENGTH(MIN_PASSWORD_LENGTH)}</p>
      </div>
      <div className="password-indicator d-flex flex-row">
        <Icon name={oneUpperCaseLetter ? icons.INDICATOR_LIGHT : icons.INDICATOR} className="password-indicator-icon" />
        <p className={oneUpperCaseLetter ? 'light' : 'regular'}>{text.PASSWORD_REQUIREMENT_UPPERCASE}</p>
      </div>
      <div className="password-indicator d-flex flex-row">
        <Icon name={oneLowerCaseLetter ? icons.INDICATOR_LIGHT : icons.INDICATOR} className="password-indicator-icon" />
        <p className={oneLowerCaseLetter ? 'light' : 'regular'}>{text.PASSWORD_REQUIREMENT_LOWERCASE}</p>
      </div>
      <div className="password-indicator d-flex flex-row">
        <Icon name={oneDigit ? icons.INDICATOR_LIGHT : icons.INDICATOR} className="password-indicator-icon" />
        <p className={oneDigit ? 'light' : 'regular'}>{text.PASSWORD_REQUIREMENT_NUMBER}</p>
      </div>
      <div className="password-indicator d-flex flex-row">
        <Icon name={oneSymbol ? icons.INDICATOR_LIGHT : icons.INDICATOR} className="password-indicator-icon" />
        <p className={oneSymbol ? 'light' : 'regular'}>{text.PASSWORD_REQUIREMENT_SYMBOL}</p>
      </div>
    </>
  )
}

export default PasswordIndicator
