import React, { ReactNode, ReactElement, Dispatch } from 'react'
import { connect } from 'react-redux'
import { BaseAction } from '../../actions/commons'
import { displayFallbackUi, resetErrorState } from '../../actions/errorActions'
import ErrorBase from '../../errors/ErrorBase'
import RuntimeError from '../../errors/RuntimeError'
import ErrorPage from '../../pages/ErrorPage'
import { RootState } from '../../reducers/store'
import { History } from 'history'
import { sendLog } from '../../services/monitoring/cloudwatch'

type ErrorBoundaryStateProps = {
  showError: boolean
}
type ErrorBoundaryActionProps = {
  displayFallbackUi: (error: Error) => void
  resetErrorState: () => void
}
interface ErrorBoundaryProps extends ErrorBoundaryActionProps, ErrorBoundaryStateProps {
  children: ReactElement
  history: History
}

class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  componentDidCatch(error: Error): void {
    // BUG-1450: Referrer Portal issue
    // Referral form submission sometimes fails and we don't have visibility
    // on errors and exceptions that may be triggered in the browser.
    // This aims to help catch potential FE errors that may occur when the user submits a referral.
    sendLog(error.message)
    this.props.displayFallbackUi(new RuntimeError(error.message))
  }
  static getDerivedStateFromError() {
    return { showError: true }
  }

  render(): ReactNode {
    if (this.props.showError) {
      const errorPath = this.props.history.location?.pathname
      return <ErrorPage clearError={resetErrorState} errorPath={errorPath} />
    }
    const { children } = this.props
    return <>{children}</>
  }
}

const mapStateToProps = (state: RootState): ErrorBoundaryStateProps => {
  const { showError } = state.errorBoundary
  return {
    showError,
  }
}

const mapDispatchToProps = (dispatch: Dispatch<BaseAction>): ErrorBoundaryActionProps => ({
  displayFallbackUi: (error: ErrorBase) => dispatch(displayFallbackUi(error)),
  resetErrorState: () => dispatch(resetErrorState()),
})
export default connect<ErrorBoundaryStateProps, ErrorBoundaryActionProps, {}>(
  mapStateToProps,
  mapDispatchToProps,
)(ErrorBoundary)
