import React from 'react'
import { Container } from 'react-bootstrap'
import { connect } from 'react-redux'
import { updateField, updatePracticeField, addPractice, removePractice } from '../../../actions/registerAccountActions'
import { RegistrationFormFieldType, RegisterAccountPageType } from '../../../actions/registerAccountActions.types'
import {
  PracticeDetailsErrorsType,
  PracticeDetailsType,
  RegistrationFormType,
} from '../../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { GeneralInfo, PracticeDetails, Finish } from './FormSections'

type RegisterAccountFormProps = {
  registrationForm: RegistrationFormType
  currentPage: RegisterAccountPageType
  practices: PracticeDetailsType[]
  practicesErrors: PracticeDetailsErrorsType[]
  updatePracticeField: (index: number, type: string, value: string) => void
  registrationFormErrors: Partial<Record<RegistrationFormFieldType, boolean>>
  updateField: (type: RegistrationFormFieldType, value: string | boolean) => void
  addPractice: () => void
  removePractice: (index: number) => void
}

const RegisterAccountForm: React.FC<RegisterAccountFormProps> = ({
  registrationForm,
  registrationFormErrors,
  updateField,
  currentPage,
  addPractice,
  removePractice,
  updatePracticeField,
  practices,
  practicesErrors,
}) => {
  const onValueChange = (name: string, value: string | boolean) => {
    updateField(name as RegistrationFormFieldType, value)
  }
  switch (currentPage) {
    case 'finish':
      return (
        <Container className="p-0">
          <Finish
            onValueChange={onValueChange}
            registrationForm={registrationForm}
            registrationFormErrors={registrationFormErrors}
          />
        </Container>
      )
    case 'practiceDetails':
      return (
        <Container className="p-0">
          <PracticeDetails
            practicesErrors={practicesErrors}
            addPractice={addPractice}
            removePractice={removePractice}
            onPracticeValueChange={updatePracticeField}
            practices={practices}
          />
        </Container>
      )
    case 'generalInfo':
      return (
        <Container className="p-0">
          <GeneralInfo
            onValueChange={onValueChange}
            registrationForm={registrationForm}
            registrationFormErrors={registrationFormErrors}
          />
        </Container>
      )
    default:
      return (
        <Container className="p-0">
          <GeneralInfo
            onValueChange={onValueChange}
            registrationForm={registrationForm}
            registrationFormErrors={registrationFormErrors}
          />
        </Container>
      )
  }
}

const mapStateToProps = (state) => {
  const { registrationFormErrors, registrationForm, currentPage, practices, practicesErrors } = state.registerAccount
  return { registrationFormErrors, registrationForm, currentPage, practices, practicesErrors }
}

const mapDispatchToProps = (dispatch) => ({
  updateField: (type, value) => dispatch(updateField(type, value)),
  updatePracticeField: (index, type, value) => dispatch(updatePracticeField(index, type, value)),
  addPractice: () => dispatch(addPractice()),
  removePractice: (index: number) => dispatch(removePractice(index)),
})

export default connect(mapStateToProps, mapDispatchToProps)(RegisterAccountForm)
