import React, { ChangeEvent, PropsWithChildren } from 'react'
import InputLabel from '../../../atoms/InputLabel'
import { Col, FormControl, InputGroup, Row } from 'react-bootstrap'
import Icon from '../../../atoms/Icon'

type OnChangeCb = (name: string, value: string | undefined) => void

type Props = {
  type?: 'text' | 'password'
  className?: string
  label?: string
  inputName: string
  required?: boolean
  errorMessage?: string
  instructionMessage?: string
  placeholder?: string
  error?: boolean
  onChangeCb: OnChangeCb
  value: string | undefined
  children?: React.ReactElement
  large?: boolean
  iconLeft?: string
  disabled?: boolean
  labelTestId?: string
  inputTestId?: string
}

const SingleLineTextInputField = ({
  inputName,
  errorMessage,
  instructionMessage,
  placeholder,
  error,
  onChangeCb,
  value = '',
  label = '',
  children,
  className = '',
  large = false,
  required = false,
  type = 'text',
  iconLeft = '',
  disabled = false,
  labelTestId = 'SingleLineTextInputField',
  inputTestId = 'SingleLineTextInputField-FormControl',
}: PropsWithChildren<Props>): JSX.Element => {
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    const value = e.currentTarget.value
    const name = e.currentTarget.name
    onChangeCb(name, value)
  }
  return (
    <Col className={`p-0 single-line-text-input-field ${className}`} data-testid={labelTestId}>
      {label && (
        <Row className="m-0">
          <InputLabel label={`${label} ${required ? '*' : ''}`} />
          {instructionMessage && <p className="input instruction">{instructionMessage}</p>}
        </Row>
      )}
      <Row className={`m-0 input ${error ? 'validation-error' : ''}`}>
        <Col className="p-0 justify-content-center">
          <InputGroup>
            {iconLeft ? (
              <Icon name={`${iconLeft}${disabled ? '-disabled' : ''}`} className="icon" />
            ) : (
              <div className="pl-3" />
            )}
            <FormControl
              data-testid={inputTestId}
              onChange={onChange}
              className={`${error ? 'error' : ''} ${large ? 'large' : ''} ${
                iconLeft ? 'with-icon' : ''
              } single-line-input p-0`}
              type={type}
              maxLength={50}
              name={inputName}
              value={value}
              placeholder={placeholder}
              disabled={disabled}
            />
          </InputGroup>
          {children}
        </Col>
      </Row>
      {error && errorMessage && (
        <Row className="m-0 pl-3 pt-2">
          <p className="error">{errorMessage}</p>
        </Row>
      )}
    </Col>
  )
}

export default SingleLineTextInputField
