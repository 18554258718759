import React, { FC } from 'react'
import { ReactComponent as WarningAlertSvg } from './warning-alert.svg'

interface WarningAlertProps {
  className?: string
}

const WarningAlert: FC<WarningAlertProps> = ({ className = '' }) => <WarningAlertSvg className={className} />

export default WarningAlert
