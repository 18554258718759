import { takeEvery, fork, select, put, ForkEffect, SelectEffect, PutEffect, call, CallEffect } from 'redux-saga/effects'
import { BaseAction } from '../../actions/commons'
import {
  REGISTER_NAVIGATE,
  REGISTER_SUBMIT,
  validatePageFail,
  validatePageSuccess,
  validatePracticesFail,
  submitRegistrationFail,
  submitRegistrationSuccess,
} from '../../actions/registerAccountActions'
import { RegisterPageAction, RegistrationFormFieldType } from '../../actions/registerAccountActions.types'
import { validateBool, validateString } from '../../components/forms/validations/RegisterAccount'
import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import mapRegisterAccountStateToApi from '../../mappers/mapRegisterAccountStateToApi/mapRegisterAccountStateToApi'
import { fieldsToValidate } from '../../reducers/RegisterAccountReducer/RegisterAccountReducer'
import {
  PracticeDetailsType,
  RegistrationFormType,
} from '../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import {
  AvailableGenerator,
  isAhpraAvailable,
  isEmailAvailablePortal,
  isUidAvailable,
} from '../AvailableSaga/AvailableSaga'

const REGISTRATION_PATH = '/portal/register'

export function* validate(
  action: RegisterPageAction,
): Generator<
  CallEffect<AvailableGenerator> | SelectEffect | PutEffect,
  void,
  RegistrationFormType | PracticeDetailsType[] | string
> {
  const currentPage = (yield select((state) => state.registerAccount.currentPage)) as string
  const registrationFormAnswers = (yield select(
    (state) => state.registerAccount.registrationForm,
  )) as RegistrationFormType
  const practicesAnswers = (yield select((state) => state.registerAccount.practices)) as PracticeDetailsType[]
  const errors: Partial<Record<RegistrationFormFieldType, boolean>> = {}
  const pA = practicesAnswers as PracticeDetailsType[]
  const practicesErrors = pA.map(() => ({}))
  const pageFieldsToValidate = fieldsToValidate[currentPage]
  if (currentPage === 'generalInfo') {
    pageFieldsToValidate.forEach((x: string) => {
      if (!validateString(x, true, registrationFormAnswers[x] as string)) {
        errors[x] = true
      }
    })
    if (!errors.ahpraNumber) {
      const ahpraAvailable = yield call(() => isAhpraAvailable(registrationFormAnswers.ahpraNumber))
      if (!ahpraAvailable) {
        errors.ahpraNumber = true
      }
    }
    if (!errors.email) {
      const emailAvailable = yield call(() => isEmailAvailablePortal(registrationFormAnswers.email))
      if (!emailAvailable) {
        errors.email = true
      }
    }
    if (registrationFormAnswers.userid && !errors.userid) {
      const uidAvailable = yield call(() => isUidAvailable(registrationFormAnswers.userid))
      if (!uidAvailable) {
        errors.userid = true
      }
    }
    if (Object.keys(errors).length === 0) {
      yield put(validatePageSuccess(action.data.page))
      return
    }
  } else if (currentPage === 'practiceDetails') {
    pA.forEach((practice: PracticeDetailsType, i: number) => {
      pageFieldsToValidate.forEach((x: string) => {
        if (!validateString(x, true, practice[x] as string)) {
          practicesErrors[i][x] = true
        }
      })
    })
    if (practicesErrors.reduce((acc, x) => Object.keys(x).length === 0 && acc, true)) {
      yield put(validatePageSuccess(action.data.page))
      return
    } else {
      yield put(validatePracticesFail(practicesErrors))
      return
    }
  }
  yield put(validatePageFail(errors))
  return
}

export function* submit(): Generator<
  SelectEffect | Promise<ResponseWithStatus<void>> | PutEffect<BaseAction>,
  void,
  ResponseWithStatus<void> | PracticeDetailsType[] | string | RegistrationFormType
> {
  const currentPage = yield select((state) => state.registerAccount.currentPage)
  const registrationFormAnswers = yield select((state) => state.registerAccount.registrationForm)
  const practices = yield select((state) => state.registerAccount.practices)
  const errors = {}
  const pageFieldsToValidate = fieldsToValidate[currentPage as string]
  if (currentPage === 'finish') {
    pageFieldsToValidate.forEach((x: string) => {
      if (!validateBool(true, registrationFormAnswers[x] as boolean)) {
        errors[x] = true
      }
    })
    if (Object.keys(errors).length === 0) {
      const registerResponse = (yield post(
        `${process.env.REACT_APP_API_URL}${REGISTRATION_PATH}`,
        {},
        mapRegisterAccountStateToApi(
          practices as PracticeDetailsType[],
          registrationFormAnswers as RegistrationFormType,
        ),
      )) as ResponseWithStatus<void>
      if (registerResponse.status === ResponseStatus.FAILURE) {
        yield put(submitRegistrationFail())
      } else {
        yield put(submitRegistrationSuccess())
      }
      return
    }
    yield put(validatePageFail(errors))
    return
  }
}

function* watchNavigate(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(REGISTER_NAVIGATE, validate)
}
function* watchSubmit(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(REGISTER_SUBMIT, submit)
}

const registerAccountSagas = [fork(watchNavigate), fork(watchSubmit)]

export default registerAccountSagas
