import { MIN_PASSWORD_LENGTH } from '../../_constants/variables'

function alphanumeric(password: string): boolean {
  return /[a-zA-Z0-9]+/g.test(password)
}

function minLength(password: string, len: number = MIN_PASSWORD_LENGTH): boolean {
  return password.length >= len
}

function oneUpperCaseLetter(password: string): boolean {
  return /[A-Z]/g.test(password)
}

export function oneLowerCaseLetter(password: string): boolean {
  return /[a-z]/g.test(password)
}

function oneSymbol(password: string): boolean {
  return /[!@#$%^&*(),.?":{}|<>\-_=+\[\]\\';/~`]/g.test(password)
}

function oneDigit(password: string): boolean {
  return /\d/g.test(password)
}

export function validatePassword(password: string): boolean {
  return (
    alphanumeric(password) &&
    minLength(password) &&
    oneUpperCaseLetter(password) &&
    oneLowerCaseLetter(password) &&
    oneSymbol(password) &&
    oneDigit(password)
  )
}

export const passwordRequirements = {
  alphanumeric,
  minLength,
  oneUpperCaseLetter,
  oneLowerCaseLetter,
  oneSymbol,
  oneDigit,
}
