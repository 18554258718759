import React, { FC } from 'react'
import Button from 'react-bootstrap/Button'
import { ICallToActionModel } from '../../../domain/models/pages/ListPage/ICallToActionModel'
import { CtaTypes } from '../../../domain/constants'
import InternalLink from '../../atoms/InternalLink'
import ExternalLink from '../../atoms/ExternalLink'
import Icon from '../../atoms/Icon'

type props = {
  action: ICallToActionModel
  isDownloadable: boolean
  onClick?: () => void
}

const CallToAction: FC<props> = ({ action, isDownloadable, onClick = () => null }) => {
  const { buttonText, link, icon } = action.value
  const buttonVariant = isDownloadable ? 'light' : 'primary'
  const DisplayButton = () => (
    <Button
      variant={buttonVariant}
      onClick={onClick}
      className="w-100 d-flex justify-content-center align-items-center"
    >
      <Icon name={icon} className="me-2" /> {buttonText}
    </Button>
  )
  switch (action.type) {
    case CtaTypes.LINK_WITH_PREVALIDATION:
      return (
        <div className="call-to-action-wrapper">
          <DisplayButton />
        </div>
      )
    case CtaTypes.EXTERNAL_LINK:
      return (
        <div className="call-to-action-wrapper">
          <a className="call-to-action-a-tag" href={link} target="_blank" rel="noreferrer" download={isDownloadable}>
            <DisplayButton />
          </a>
        </div>
      )
    case CtaTypes.INTERNAL_LINK:
      return (
        <div className="call-to-action-wrapper">
          <InternalLink href={link} className="text-decoration-none">
            <DisplayButton />
          </InternalLink>
        </div>
      )
    default:
      return (
        <div className="call-to-action-wrapper">
          <ExternalLink className="call-to-action-a-tag" href={link}>
            <DisplayButton />
          </ExternalLink>
        </div>
      )
  }
}

export default CallToAction
