import { LinkTypes, PageTypes } from '../domain/constants'
import urls from './urls'

const footerLinks = {
  REFERRER_LINKS: {
    leftColumn: [
      {
        id: 'privacy-policy',
        type: PageTypes.ARTICLE_DETAIL,
        value: {
          name: 'Privacy Policy',
          url: '/privacy-policy',
        },
      },
      {
        id: 'terms-of-use',
        type: PageTypes.ARTICLE_DETAIL,
        value: {
          name: 'Terms of Use',
          url: '/terms-of-use',
        },
      },
    ],
    rightColumn: [
      {
        id: 'support',
        type: LinkTypes.EXTERNAL_LINK,
        value: {
          name: 'Support',
          url: urls.SUPPORT,
        },
      },
      {
        id: 'feedback',
        type: LinkTypes.EXTERNAL_LINK,
        value: {
          name: 'Feedback',
          url: urls.FEEDBACK,
        },
      },
    ],
  },
  ADMIN_LINKS: {
    leftColumn: [
      {
        id: 'privacy-policy',
        type: PageTypes.ARTICLE_DETAIL,
        value: {
          name: 'Privacy Policy',
          url: '/privacy-policy',
        },
      },
      {
        id: 'terms-of-use',
        type: PageTypes.ARTICLE_DETAIL,
        value: {
          name: 'Terms of Use',
          url: '/terms-of-use',
        },
      },
    ],
    rightColumn: [],
  },
  NO_AUTH_LINKS: {
    leftColumn: [],
    rightColumn: [
      {
        id: 'support',
        type: LinkTypes.EXTERNAL_LINK,
        value: {
          name: 'Support',
          url: urls.SUPPORT,
        },
      },
      {
        id: 'feedback',
        type: LinkTypes.EXTERNAL_LINK,
        value: {
          name: 'Feedback',
          url: urls.FEEDBACK,
        },
      },
    ],
  },
}

export default footerLinks
