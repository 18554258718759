import React, { FC, useEffect } from 'react'
import text from '../../_constants/text'
import { Button, Card, Col, Container, Row } from 'react-bootstrap'
import routes from '../../router/routes'
import Separator from '../../components/atoms/Separator'
import FooterlessLayout from '../../components/wrappers/Layout/FooterlessLayout'
import { analyticsTracker } from '../../services/analytics/analyticsTrackerService'
import { CustomDimensions, GAEventNames } from '../../domain/models/commons/GoogleAnalytics'

type ErrorPageProps = {
  clearError: () => void
  errorPath: string
}
const ErrorPage: FC<ErrorPageProps> = ({ clearError, errorPath }) => {
  useEffect(() => {
    analyticsTracker().track(GAEventNames.ERROR_PAGE, {
      [CustomDimensions.PAGE]: errorPath,
    })
  }, [errorPath])
  return (
    <FooterlessLayout>
      <div className="d-flex align-items-center content-background content-unauthenticated-background-min-height py-5">
        <Container className="max-grid-width p-lg-0 " fluid>
          <Row className="p-0 justify-content-center">
            <Col xs={12} lg={6}>
              <Card as="section" className="my-3 my-lg-4">
                <Card.Body className="p-3 p-lg-4">
                  <Card.Title className="mb-3" data-testid="title" as="h2">
                    {text.ERROR_TITLE}
                  </Card.Title>
                  <Container className="d-flex flex-column p-0">
                    <Row className="mb-3">
                      <Col xs={12}>{text.ERROR_MESSAGE}</Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={12}>
                        <a href={routes.results}>
                          <Button onClick={clearError} data-testid="backButton">
                            {text.BACK_TO_PATIENT_SEARCH}
                          </Button>
                        </a>
                      </Col>
                    </Row>
                    <Row>
                      <Separator />
                      <p className="pt-4">{text.NEED_SUPPORT}</p>
                    </Row>
                  </Container>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </FooterlessLayout>
  )
}

export default ErrorPage
