import React, { FC } from 'react'
import { Col, Row } from 'react-bootstrap'
import TextareaField from '../../../../../components/forms/fields/TextareaField/TextareaField'
import { Referral, ReferralErrors } from '../../../../../reducers/ReferralReducer/ReferralReducer.types'
import text from '../../../../../_constants/text'

interface ExaminationAndClinicalDetailsProps {
  onValueChange: (name: string, value: string | boolean) => void
  referral: Referral
  errors: ReferralErrors
}

const ExaminationAndClinicalDetails: FC<ExaminationAndClinicalDetailsProps> = ({ onValueChange, referral, errors }) => {
  return (
    <>
      <Row>
        <Col xs={12}>
          <TextareaField
            label={text.REFERRAL_EXAM_DESCRIPTION}
            inputName="examDetails"
            required={false}
            onChangeEvent={onValueChange}
            value={referral.examDetails}
            error={errors.examDetails}
            errorMessage={text.FIELD_REQUIRED}
            maxLength={255}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <TextareaField
            label={text.REFERRAL_CLINICAL_DESCRIPTION}
            inputName="clinicalDetails"
            required={false}
            onChangeEvent={onValueChange}
            value={referral.clinicalDetails}
            error={errors.clinicalDetails}
            errorMessage={text.FIELD_REQUIRED}
            maxLength={255}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <TextareaField
            label={text.IV_CONTRAST_ALERT}
            instructionMessage={text.REFERRAL_IV_CONTRAST_INSTRUCTION}
            inputName="ivContrastDetails"
            required={false}
            onChangeEvent={onValueChange}
            value={referral.ivContrastDetails}
            error={errors.ivContrastDetails}
            errorMessage={text.FIELD_REQUIRED}
            maxLength={255}
          />
        </Col>
      </Row>
    </>
  )
}

export default ExaminationAndClinicalDetails
