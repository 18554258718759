import React, { FC } from 'react'

type props = {
  html: string
}

const RichText: FC<props> = ({ html }) => {
  return <div className="richtext" dangerouslySetInnerHTML={{ __html: html }} />
}

export default RichText
