import { BaseAction, UpdateFieldAction, ValidationErrorsAction } from '../../actions/commons'
import {
  ResetPasswordFieldType,
  RESET_PASSWORD_RESET,
  RESET_PASSWORD_SUBMIT_FAIL,
  RESET_PASSWORD_SUBMIT_SUCCESS,
  RESET_PASSWORD_UPDATE_DETAILS,
  RESET_PASSWORD_VALIDATE_FAIL,
  RESET_PASSWORD_VALIDATE_SUCCESS,
} from '../../actions/ResetPasswordActions/resetPasswordActions'
import { SubmitControls } from '../RegisterAccountReducer/RegisterAccountReducer.types'

export type ResetPasswordState = {
  form: Record<ResetPasswordFieldType, string>
  formErrors: Partial<Record<ResetPasswordFieldType, boolean>>
  submit: SubmitControls
}

export const initialResetPasswordState: ResetPasswordState = {
  form: {
    code: '',
    password: '',
    secret: '',
  },
  formErrors: {},
  submit: {
    success: false,
    fail: false,
    inProgress: false,
  },
}

const resetPasswordReducer = (
  state: ResetPasswordState = initialResetPasswordState,
  action: BaseAction,
): ResetPasswordState => {
  switch (action.type) {
    case RESET_PASSWORD_UPDATE_DETAILS: {
      const a = action as UpdateFieldAction<ResetPasswordFieldType>
      const updatedFormErrors = { ...state.formErrors }
      delete updatedFormErrors[Object.keys(a.data)[0]]
      return {
        ...state,
        form: { ...state.form, ...a.data },
        submit: { ...initialResetPasswordState.submit },
        formErrors: { ...updatedFormErrors },
      }
    }
    case RESET_PASSWORD_SUBMIT_FAIL: {
      return { ...state, submit: { fail: true, inProgress: false, success: false } }
    }
    case RESET_PASSWORD_SUBMIT_SUCCESS: {
      return { ...state, submit: { success: true, fail: false, inProgress: false } }
    }
    case RESET_PASSWORD_VALIDATE_SUCCESS: {
      return { ...state, formErrors: {} }
    }
    case RESET_PASSWORD_VALIDATE_FAIL: {
      const a = action as ValidationErrorsAction<ResetPasswordFieldType>
      return { ...state, formErrors: { ...a.data.errors } }
    }
    case RESET_PASSWORD_RESET: {
      return { ...initialResetPasswordState }
    }
    default:
      return state
  }
}

export default resetPasswordReducer
