import Card from 'react-bootstrap/Card'
import text from '../../../_constants/text'
import AlertBox from '../../../components/molecules/AlertBox'
import SingleLineTextInputField from '../../../components/forms/fields/SingleLineTextInputField/SingleLineTextInputField'
import { Button, Col, Container, Row } from 'react-bootstrap'
import InternalLink from '../../../components/atoms/InternalLink'
import Separator from '../../../components/atoms/Separator'
import React from 'react'
import {
  recoverUsername,
  RecoverUsernameFormFieldType,
  resetRecover,
  updateField,
} from '../../../actions/recoverUsernameAction'
import { connect } from 'react-redux'
import routes from '../../../router/routes'
import { SubmitControls } from '../../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'

interface ForgotUsernameFormInputCardProps {
  form: Record<RecoverUsernameFormFieldType, string>
  submit: SubmitControls
  updateField: (type: RecoverUsernameFormFieldType, value: string) => void
  recoverUsername: () => void
  emailIsInvalid: boolean
  ahpraIsInvalid: boolean
}

const ForgotUsernameFormInputCard: React.FC<ForgotUsernameFormInputCardProps> = ({
  form,
  submit,
  updateField,
  recoverUsername,
  emailIsInvalid,
  ahpraIsInvalid,
}) => {
  const onValueChange = (name: string, value: string) => {
    updateField(name as RecoverUsernameFormFieldType, value)
  }

  return (
    <Card as="section" className="my-3 my-lg-4" data-testid="recover-username-form">
      <Card.Body className="p-3 p-lg-4">
        <Card.Title as="h2">{text.RECOVER_USER_ID}</Card.Title>
        <p>{text.RECOVER_INFO}</p>
        {submit.fail && <AlertBox type="error" message={text.RECOVER_USER_ID_FAILURE} />}
        <form className="mt-4">
          <SingleLineTextInputField
            label={text.EMAIL_ADDRESS}
            inputName="email"
            required={false}
            value={form.email}
            onChangeCb={onValueChange}
            error={emailIsInvalid}
            errorMessage="Please enter a valid email address"
          />
          <SingleLineTextInputField
            label={text.AHPRA}
            inputName="ahpra"
            required={false}
            instructionMessage={text.AHPRA_WARNING}
            value={form.ahpra}
            onChangeCb={onValueChange}
            error={ahpraIsInvalid}
            errorMessage="Please enter a valid AHPRA number"
          />
          <Container className="d-flex flex-column px-0 mt-4">
            <Row className="mb-3 mt-2">
              <Col xs={12} md={6}>
                <Button onClick={recoverUsername} className="m-auto w-100">
                  {text.RECOVER_BUTTON}
                </Button>
              </Col>
              <Col className="d-flex flex-row m-auto m-md-0 mt-3 mt-md-0 px-0 w-auto" xs={12} md={6}>
                <p className="m-auto anchor-text">{text.OR}</p>
                <InternalLink href={routes.home} className="m-auto px-1">
                  {text.RETURN_TO_SIGNIN}
                </InternalLink>
              </Col>
            </Row>
            <Row>
              <Separator />
              <p className="pt-4">{text.NEED_SUPPORT}</p>
            </Row>
          </Container>
        </form>
      </Card.Body>
    </Card>
  )
}

const mapStateToProps = (state) => {
  const { form, submit, emailIsInvalid, ahpraIsInvalid } = state.recoverUsername
  return { form, submit, emailIsInvalid, ahpraIsInvalid }
}

const mapDispatchToProps = (dispatch) => ({
  updateField: (type, value) => dispatch(updateField(type, value)),
  resetRecover: () => dispatch(resetRecover()),
  recoverUsername: () => dispatch(recoverUsername()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotUsernameFormInputCard)
