import { ContentLinkModel } from '../../../../domain/models/commons/Link'
import React, { FC } from 'react'
import { analyticsTracker } from '../../../../services/analytics/analyticsTrackerService'
import { CustomDimensions, GAEventNames } from '../../../../domain/models/commons/GoogleAnalytics'
import ContentLink from '../../../atoms/ContentLink'

type FooterLink = {
  link: ContentLinkModel
  pagePath?: string
}

const FooterLink: FC<FooterLink> = ({ link, pagePath }) => {
  const trackEvent = (linkId: string): void => {
    if (linkId === 'support' && !!pagePath) {
      analyticsTracker().track(GAEventNames.SUPPORT_PAGE, { [CustomDimensions.PAGE]: pagePath })
    }
  }
  const { id, value } = link
  const { name } = value
  return (
    <div data-testid={id} onClick={() => trackEvent(id)}>
      <ContentLink className="text-decoration-none" {...link}>
        {name}
      </ContentLink>
    </div>
  )
}

export default FooterLink
