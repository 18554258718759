import sortedUniq from 'lodash/sortedUniq'
import sortBy from 'lodash/sortBy'
import { Image } from '../../domain/models/commons/Image'

const getSrcSet = (imgUrl: string, maxWidth: number): string => {
  const widths: number[] = sortedUniq(sortBy([576, 720, 1024, 1532, 1920, maxWidth]))
  return widths
    .map((width) => {
      if (maxWidth < width) {
        return ''
      }
      return `${imgUrl}?w=${width} ${width}w`
    })
    .filter((x) => x !== '')
    .join(', ')
}

const safeGetSrcSet = (img: Image): string => {
  if (!img || !img.url || !img.width) {
    return ''
  }
  return getSrcSet(img.url, img.width)
}

export default safeGetSrcSet
