import { BaseAction } from './commons'
import {
  FieldValue,
  RegisterAccountPageType,
  RegisterDataAction,
  RegisterPageAction,
  RegisterPageErrorsAction,
  RegisterPracticeDataAction,
  RegisterPracticesErrorsAction,
  RegistrationFormFieldType,
  UnregisterPracticeAction,
} from './registerAccountActions.types'

export const REGISTER_UPDATE_DETAILS = 'REGISTER/UPDATE_DETAILS'
export const REGISTER_UPDATE_PRACTICE_DETAILS = 'REGISTER/UPDATE_PRACTICE_DETAILS'
export const REGISTER_ADD_PRACTICE = 'REGISTER/ADD_PRACTICE'
export const REGISTER_REMOVE_PRACTICE = 'REGISTER/REMOVE_PRACTICE'
export const REGISTER_VALIDATE_PAGE = 'REGISTER/VALIDATE_PAGE'
export const REGISTER_VALIDATE_PAGE_SUCCESS = 'REGISTER/VALIDATE_PAGE_SUCCESS'
export const REGISTER_VALIDATE_PAGE_FAIL = 'REGISTER/VALIDATE_PAGE_FAIL'
export const REGISTER_VALIDATE_PRACTICES_FAIL = 'REGISTER/VALIDATE_PRACTICES_FAIL'
export const REGISTER_ADD_AGREEMENT = 'REGISTER/ADD_AGREEMENT'
export const REGISTER_SUBMIT = 'REGISTER/SUBMIT'
export const REGISTER_SUBMIT_SUCCESS = 'REGISTER/SUBMIT_SUCCESS'
export const REGISTER_SUBMIT_FAIL = 'REGISTER/SUBMIT_FAIL'

export const REGISTER_NAVIGATE = 'REGISTER/NAVIGATE'
export const REGISTER_CLEAR = 'REGISTER/CLEAR'
export const REGISTER_NAVIGATE_BACK = 'REGISTER/NAVIGATE_BACK'

export const updateField = (type: RegistrationFormFieldType, value: FieldValue): RegisterDataAction => ({
  type: REGISTER_UPDATE_DETAILS,
  data: {
    [type]: value,
  },
})

export const updatePracticeField = (index: number, type: string, value: string): RegisterPracticeDataAction => ({
  type: REGISTER_UPDATE_PRACTICE_DETAILS,
  data: {
    practiceDetails: {
      [type]: value,
    },
    index,
  },
})

export const addPractice = (): BaseAction => ({
  type: REGISTER_ADD_PRACTICE,
})

export const clearForm = (): BaseAction => ({
  type: REGISTER_CLEAR,
})

export const removePractice = (index: number): UnregisterPracticeAction => ({
  type: REGISTER_REMOVE_PRACTICE,
  data: { index },
})

export const navigateToPage = (pageId: RegisterAccountPageType): RegisterPageAction => ({
  type: REGISTER_NAVIGATE,
  data: { page: pageId },
})

export const navigateBack = (pageId: RegisterAccountPageType): RegisterPageAction => ({
  type: REGISTER_NAVIGATE_BACK,
  data: { page: pageId },
})

export const submitRegistrationFrom = (): BaseAction => ({
  type: REGISTER_SUBMIT,
})

export const submitRegistrationSuccess = (): BaseAction => ({
  type: REGISTER_SUBMIT_SUCCESS,
})
export const submitRegistrationFail = (): BaseAction => ({
  type: REGISTER_SUBMIT_FAIL,
})

export const validatePage = (pageId: RegisterAccountPageType): RegisterPageAction => ({
  type: REGISTER_VALIDATE_PAGE,
  data: { page: pageId },
})

export const validatePageSuccess = (pageId: RegisterAccountPageType): RegisterPageAction => ({
  type: REGISTER_VALIDATE_PAGE_SUCCESS,
  data: { page: pageId },
})

export const validatePageFail = (errors: Record<string, boolean>): RegisterPageErrorsAction => ({
  type: REGISTER_VALIDATE_PAGE_FAIL,
  data: { errors },
})
export const validatePracticesFail = (practicesErrors: Record<string, boolean>[]): RegisterPracticesErrorsAction => ({
  type: REGISTER_VALIDATE_PRACTICES_FAIL,
  data: { practicesErrors },
})
