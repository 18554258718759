import React, { FC, PropsWithChildren, forwardRef } from 'react'
import Button from 'react-bootstrap/Button'

type SmallButtonProps = {
  onClick: () => void
  disabled?: boolean
  className?: string
  ref?: React.Ref<HTMLButtonElement>
} & PropsWithChildren

const SmallButton: FC<SmallButtonProps> = forwardRef(function SmallButton(props, ref) {
  const { onClick, children, className, disabled = false, ...rest } = props
  const dataTestId = props['data-testid'] || 'SmallButton'

  return (
    <Button
      ref={ref}
      data-testid={dataTestId}
      variant="light"
      className={`small-button ${className}`}
      onClick={onClick}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  )
})

export default SmallButton
