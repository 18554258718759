import { BaseAction } from '../commons'
import { FieldValue } from '../registerAccountActions.types'
import {
  AlfredMaterImagingDataAction,
  AlfredMaterImagingErrorAction,
  AlfredMaterImagingFieldName,
  AlfredMaterImagingFieldValidateAction,
} from './alfredMaterImagingActions.types'
import { AlfredMaterImagingFormDataModel } from '../../reducers/AlfredMaterImagingReducer/AlfredMaterImagingReducer'
import validateFormData from '../../components/forms/validations/AlfredMaterImaging'

export const ALFRED_MATER_IMAGING_REGISTRATION_SUBMIT = 'ALFRED_MATER_IMAGING/SUBMIT'
export const ALFRED_MATER_IMAGING_FIELD_UPDATE = 'ALFRED_MATER_IMAGING/FIELD_VALUE_UPDATE'
export const ALFRED_MATER_IMAGING_FIELD_VALIDATE = 'ALFRED_MATER_IMAGING/FIELD_VALUE_VALIDATE'
export const ALFRED_MATER_IMAGING_CLEAR = 'ALFRED_MATER_IMAGING/CLEAR'
export const ALFRED_MATER_IMAGING_ERROR = 'ALFRED_MATER_IMAGING/ERROR'
export const ALFRED_MATER_IMAGING_SUBMIT_SUCCESS = 'ALFRED_MATER_IMAGING/SUBMIT_SUCCESS'
export const ALFRED_MATER_IMAGING_SUBMIT_FAIL = 'ALFRED_MATER_IMAGING/SUBMIT_FAIL'
export const ALFRED_MATER_IMAGING_SUBMIT_LOADING = 'ALFRED_MATER_IMAGING/SUBMIT_LOADING'

export const updateAlfredMaterImagingFieldValue = (
  type: AlfredMaterImagingFieldName,
  value: FieldValue,
): AlfredMaterImagingDataAction => ({
  type: ALFRED_MATER_IMAGING_FIELD_UPDATE,
  data: {
    [type]: value,
  },
})

export const submitAlfredMaterImagingRegistration = (
  formData: AlfredMaterImagingFormDataModel,
): BaseAction | AlfredMaterImagingErrorAction => {
  const { errors, isValid } = validateFormData(formData)
  if (!isValid) {
    return {
      type: ALFRED_MATER_IMAGING_ERROR,
      errors,
    } as AlfredMaterImagingErrorAction
  }
  return {
    type: ALFRED_MATER_IMAGING_REGISTRATION_SUBMIT,
  }
}

export const validateAlfredMaterImagingFormField = (
  value: boolean,
  type: AlfredMaterImagingFieldName,
): AlfredMaterImagingFieldValidateAction => ({
  type: ALFRED_MATER_IMAGING_FIELD_VALIDATE,
  data: {
    fieldName: type,
    value,
  },
})

export const clearAlfredMaterImagingForm = (): BaseAction => ({
  type: ALFRED_MATER_IMAGING_CLEAR,
})

export const submitAlfredMaterImagingSubmitSuccess = (): BaseAction => ({
  type: ALFRED_MATER_IMAGING_SUBMIT_SUCCESS,
})

export const submitAlfredMaterImagingSubmitFail = (): BaseAction => ({
  type: ALFRED_MATER_IMAGING_SUBMIT_FAIL,
})

export const submitAlfredMaterImagingLoading = (): BaseAction => ({
  type: ALFRED_MATER_IMAGING_SUBMIT_LOADING,
})
