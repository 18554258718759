import jwtDecode from 'jwt-decode'
import {
  takeEvery,
  fork,
  select,
  put,
  delay,
  ForkEffect,
  SelectEffect,
  PutEffect,
  CallEffect,
  takeLatest,
} from 'redux-saga/effects'
import { BaseAction, DataAction } from '../../actions/commons'
import {
  LOGIN,
  LoginFormFieldType,
  loginSuccess,
  loginFail,
  LOGOUT,
  logoutSuccess,
  logoutFail,
  JwtTokenWithRefresh,
  loginObtainedToken,
  IMedJwtPayload,
  LOGIN_OBTAINED_TOKEN,
  sessionTimedOut,
} from '../../actions/loginAction'
import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { addLocalStorageValue, deleteLocalStorageValue } from '../../services/localStorageService'
import { LOCAL_REFRESH_KEY, LOCAL_TOKEN_KEY } from '../../_constants/variables'
import { analyticsTracker } from '../../services/analytics/analyticsTrackerService'
import { GAEventNames } from '../../domain/models/commons/GoogleAnalytics'

const LOGIN_PATH = '/portal/login'

export function* login(): Generator<
  SelectEffect | Promise<ResponseWithStatus<JwtTokenWithRefresh>> | PutEffect<BaseAction>,
  void,
  ResponseWithStatus<JwtTokenWithRefresh> | Record<LoginFormFieldType, string>
> {
  const loginDetails = (yield select((state) => state.login.form)) as Record<LoginFormFieldType, string>
  const loginResponse = (yield post<JwtTokenWithRefresh>(
    `${process.env.REACT_APP_API_URL}${LOGIN_PATH}`,
    {},
    loginDetails,
  )) as ResponseWithStatus<JwtTokenWithRefresh>

  if (loginResponse.status === ResponseStatus.SUCCESS) {
    addLocalStorageValue(LOCAL_TOKEN_KEY, loginResponse.data.token)
    addLocalStorageValue(LOCAL_REFRESH_KEY, loginResponse.data.refreshToken)
    yield put(loginObtainedToken(loginResponse.data))

    analyticsTracker().track(GAEventNames.LOGIN, {})
  } else {
    deleteLocalStorageValue(LOCAL_TOKEN_KEY)
    deleteLocalStorageValue(LOCAL_REFRESH_KEY)
    yield put(loginFail())
  }
}

export function* logout(): Generator<PutEffect<BaseAction>, void, unknown> {
  try {
    deleteLocalStorageValue(LOCAL_TOKEN_KEY)
    deleteLocalStorageValue(LOCAL_REFRESH_KEY)
    yield put(logoutSuccess())
  } catch (e) {
    yield put(logoutFail())
  }
}

export function* parseLoginToken(
  action: DataAction<JwtTokenWithRefresh>,
): Generator<PutEffect<BaseAction> | CallEffect<true>, void, unknown> {
  try {
    const decodedToken = jwtDecode(action.data.token) as IMedJwtPayload
    const refreshToken = jwtDecode(action.data.refreshToken) as IMedJwtPayload
    yield put(loginSuccess(decodedToken))

    const timeTilSessionExpiresInMs = new Date(refreshToken?.exp * 1000).getTime() - new Date().getTime()

    yield delay(timeTilSessionExpiresInMs)

    yield put(sessionTimedOut())
  } catch (e) {
    console.error(e)
    yield put(loginFail())
  }
}

function* watchLogin(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(LOGIN, login)
}

function* watchLogout(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(LOGOUT, logout)
}

function* watchLoginObtainedToken(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(LOGIN_OBTAINED_TOKEN, parseLoginToken)
}

const loginSagas = [fork(watchLogin), fork(watchLogout), fork(watchLoginObtainedToken)]

export default loginSagas
