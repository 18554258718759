import { ResponseWithStatus, ResponseStatus, get } from '../../fetch'

const AHPRA_AVAILABLE_PATH = '/referreraccount/isAhpraAvailable'
const EMAIL_AVAILABLE_PORTAL_PATH = '/referreraccount/isEmailAvailablePortal'
const EMAIL_AVAILABLE_PATH = '/referreraccount/isEmailAvailable'
const UID_AVAILABLE_PATH = '/referreraccount/isUidAvailable'

export type AvailableResponse = {
  available: boolean
}

export type AvailableGenerator = Generator<
  Promise<ResponseWithStatus<AvailableResponse>>,
  boolean,
  ResponseWithStatus<AvailableResponse>
>
export function* isAhpraAvailable(ahpra: string): AvailableGenerator {
  const availableResponse = (yield get(
    `${process.env.REACT_APP_LEGACY_API_URL}${AHPRA_AVAILABLE_PATH}`,
    {},
    { ahpra },
  )) as ResponseWithStatus<AvailableResponse>
  if (availableResponse.status !== ResponseStatus.SUCCESS) {
    return false
  }
  return availableResponse.data.available
}

export function* isEmailAvailablePortal(email: string): AvailableGenerator {
  const availableResponse = (yield get(
    `${process.env.REACT_APP_LEGACY_API_URL}${EMAIL_AVAILABLE_PORTAL_PATH}`,
    {},
    { email },
  )) as ResponseWithStatus<AvailableResponse>
  if (availableResponse.status !== ResponseStatus.SUCCESS) {
    return false
  }
  return availableResponse.data.available
}

export function* isEmailAvailable(email: string): AvailableGenerator {
  const availableResponse = (yield get(
    `${process.env.REACT_APP_LEGACY_API_URL}${EMAIL_AVAILABLE_PATH}`,
    {},
    { email },
  )) as ResponseWithStatus<AvailableResponse>
  if (availableResponse.status !== ResponseStatus.SUCCESS) {
    return false
  }
  return availableResponse.data.available
}

export function* isUidAvailable(userid: string): AvailableGenerator {
  const availableResponse = (yield get(
    `${process.env.REACT_APP_LEGACY_API_URL}${UID_AVAILABLE_PATH}`,
    {},
    { uid: userid },
  )) as ResponseWithStatus<AvailableResponse>
  if (availableResponse.status !== ResponseStatus.SUCCESS) {
    return false
  }
  return availableResponse.data.available
}
