import React, { FunctionComponent, MouseEventHandler, PropsWithChildren } from 'react'
import InternalLink from '../InternalLink'

type IndicatorInternalLinkProps = {
  className?: string
  href?: string
  type: 'success' | 'warning' | 'error'
  placeholder: string
  onClick?: MouseEventHandler<HTMLAnchorElement>
}
const IndicatorInternalLink: FunctionComponent<PropsWithChildren<IndicatorInternalLinkProps>> = ({
  href,
  className,
  children,
  type,
  placeholder,
  onClick,
}) => {
  if (!href) {
    return (
      <div>
        <span className="indicator-text">{placeholder}</span>
      </div>
    )
  }
  return (
    <div className="d-flex flex-row">
      <div className={`indicator ${type}`} />
      <InternalLink className={`indicator-text mb-0 ${className ? className : ''}`} href={href} onClick={onClick}>
        {children}
      </InternalLink>
    </div>
  )
}

export default IndicatorInternalLink
