import text from '../../../_constants/text'
import { Col, Row } from 'react-bootstrap'
import React, { FC } from 'react'
import RecoverFormsWrapper from '../../../components/molecules/RecoverFormsWrapper'
import InternalLink from '../../../components/atoms/InternalLink'
import routes from '../../../router/routes'

const ForgotPasswordSuccess: FC = () => {
  return (
    <RecoverFormsWrapper title={text.CHECK_EMAIL}>
      <Row className="mb-4" data-testid="forgot-password-success-message">
        <p className="new-line">{text.FORGOT_PASSWORD_SUCCESS_MESSAGE}</p>
      </Row>
      <Row className="mb-1" data-testid="forgot-password-success-not-received">
        <p>
          <b>{text.DIDNT_RECEIVE_EMAIL_SMS}</b>
        </p>
      </Row>
      <Row className="mb-4" data-testid="forgot-password-success-prompt">
        <p>{text.RECOVER_PASSWORD_PROMPT}</p>
      </Row>
      <Row className="mb-3">
        <Col xs={12} data-testid="internal-link">
          <InternalLink href={routes.home} className="pt-4 px-1">
            {text.RETURN_TO_SIGNIN}
          </InternalLink>
        </Col>
      </Row>
    </RecoverFormsWrapper>
  )
}

export default ForgotPasswordSuccess
