import { fork, ForkEffect, put, PutEffect, select, SelectEffect, takeEvery } from 'redux-saga/effects'
import moment from 'moment'
import {
  REFERRAL_NAVIGATE,
  REFERRAL_SUBMIT,
  submitReferralFail,
  submitReferralSuccess,
  validatePracticesFail,
  validateSectionFail,
  validateSectionSuccess,
} from '../../actions/ReferralActions/referralActions'
import {
  ReferralField,
  ReferralSectionAction,
  ReferralSectionErrorsAction,
  ReferralPracticesErrorsAction,
} from '../../actions/ReferralActions/referralActions.types'
import { validateDob, validateString } from '../../components/forms/validations/Referral'
import { post, ResponseStatus, ResponseWithStatus } from '../../fetch'
import { Practice } from '../../reducers/AccountReducer/AccountReducer'
import { AccessToken } from '../../reducers/LoginReducer/LoginReducer'
import { EReferralStates, requiredReferralFormFields } from '../../reducers/ReferralReducer/ReferralReducer'
import runWithRefresh from '../RefreshAuthTokenSaga/runWithRefresh'
import { analyticsTracker } from '../../services/analytics/analyticsTrackerService'
import { CustomDimensions, EReferralIntervals, GAEventNames } from '../../domain/models/commons/GoogleAnalytics'
import { PracticeDetailsErrorsType, PracticeDetailsType } from '../../reducers/ReferralReducer/ReferralReducer.types'
import { BaseAction } from '../../actions/commons'
import text from '../../_constants/text'

const REFERRAL_PATH = '/referral'

const getEventInterval = (sectionId: string): string => {
  switch (sectionId) {
    case EReferralStates.PATIENT_DETAILS:
      return EReferralIntervals.PATIENT_DETAILS_COMPLETE
    case EReferralStates.EXAMINATION_AND_CLINICAL_DETAILS:
      return EReferralIntervals.EXAMINATION_AND_CLINICAL_DETAILS_COMPLETE
    case EReferralStates.REFERRING_PRACTITIONER:
      return EReferralIntervals.REFERRING_PRACTITIONER_COMPLETE
    case EReferralStates.CONFIRM_AND_SEND:
      return EReferralIntervals.FINISHED
  }
}

const trackSectionComplete = (section: EReferralStates) => {
  const eventInterval = getEventInterval(section)
  analyticsTracker().track(GAEventNames.E_REFERRALS_PAGE, {
    [CustomDimensions.INTERVALS]: eventInterval,
  })
}

export function* validate(
  action: ReferralSectionAction,
): Generator<
  | SelectEffect
  | PutEffect<ReferralSectionErrorsAction>
  | PutEffect<ReferralSectionAction>
  | PutEffect<ReferralPracticesErrorsAction>,
  void,
  string & Practice[] & PracticeDetailsType[]
> {
  const referral = yield select((state) => state.referral.referral)
  const currentSection = (yield select((state) => state.referral.currentSection)) as string
  const errors: Partial<Record<ReferralField, boolean>> = {}
  const ccDoctor = yield select((state) => state.referral.referral.ccDoctor)
  const ccDoctorFieldsRequireValidation = ccDoctor === 'Yes' && currentSection === 'referringPractitioner'
  const practices = (yield select((state) => state.account.practices)) as Practice[]
  const practicesDetails = (yield select((state) => state.referral.practicesDetails)) as PracticeDetailsType[]
  const practicesDetailsErrors = practicesDetails?.map(() => ({})) as PracticeDetailsErrorsType[]

  const isUserSoloPractitioner = practices.filter((practice) => practice.practiceName !== '').length === 0

  if (ccDoctorFieldsRequireValidation) {
    practicesDetails.forEach((practiceDetail: PracticeDetailsType, i: number) => {
      requiredReferralFormFields.practiceDetails().forEach((x: string) => {
        if (!validateString(x, true, practiceDetail[x] as string)) {
          practicesDetailsErrors[i][x] = true
        }
      })
    })

    if (!practicesDetailsErrors.reduce((acc, x) => Object.keys(x).length === 0 && acc, true)) {
      yield put(validatePracticesFail(practicesDetailsErrors))
      return
    }
  } else {
    requiredReferralFormFields[currentSection](isUserSoloPractitioner).forEach((field: string) => {
      if (!validateString(field, true, referral[field] as string)) {
        errors[field] = true
      }
    })

    if (!validateString('patientEmail', false, referral['patientEmail'] as string)) {
      errors['patientEmail'] = true
    }

    const dob = moment(referral['patientDob'], text.DATE_FORMAT)
    if (!validateDob(dob)) {
      errors['patientDob'] = true
    }
  }

  if (Object.keys(errors).length !== 0) {
    yield put(validateSectionFail(errors))
    return
  }
  trackSectionComplete(currentSection as EReferralStates)
  yield put(validateSectionSuccess(action.data.section))
}

type SubmitReferralGenerator = Generator<
  SelectEffect | Promise<ResponseWithStatus<unknown>>,
  ResponseWithStatus<void>,
  (AccessToken & ResponseWithStatus<void>) | PracticeDetailsType[]
>

export function* submitReferral(): SubmitReferralGenerator {
  const jwt = (yield select((state) => state.login.token)) as AccessToken
  const ccPracticesDetails = (yield select((state) => state.referral.practicesDetails)) as PracticeDetailsType[]
  const referral = yield select((state) => state.referral.referral)
  const ccDoctorNamesConcat = ccPracticesDetails.reduce(
    (acc, ccPracticeDetail: PracticeDetailsType) => `${acc};${ccPracticeDetail.doctorName}`,
    '',
  )
  const payloadReferral = { ...referral, ccDoctorName: ccDoctorNamesConcat.substring(1) }
  return (yield post(
    `${process.env.REACT_APP_API_URL}${REFERRAL_PATH}`,
    { Authorization: `${jwt.type} ${jwt.token}` },
    payloadReferral,
  )) as ResponseWithStatus<void>
}

type SubmitReferralSagaGenerator = Generator<
  SelectEffect | Generator<unknown, ResponseWithStatus<unknown>, unknown> | PutEffect<BaseAction>,
  void,
  ResponseWithStatus<void>
>

export function* submitReferralSaga(): SubmitReferralSagaGenerator {
  const referral = yield select((state) => state.referral.referral)
  const errors: Partial<Record<ReferralField, boolean>> = {}

  requiredReferralFormFields.confirmAndSend().forEach((field: string) => {
    if (!validateString(field, true, referral[field] as string)) {
      errors[field] = true
    }
  })

  if (Object.keys(errors).length !== 0) {
    yield put(validateSectionFail(errors))
    return
  }

  const submitReferralResponse = (yield runWithRefresh(submitReferral)) as ResponseWithStatus<void>

  if (submitReferralResponse.status === ResponseStatus.SUCCESS) {
    yield put(submitReferralSuccess())
    trackSectionComplete(EReferralStates.CONFIRM_AND_SEND)
  } else {
    yield put(submitReferralFail())
  }
}

function* watchNavigate(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(REFERRAL_NAVIGATE, validate)
}

function* watchSubmitReferralSaga(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery([REFERRAL_SUBMIT], submitReferralSaga)
}

const referralSagas = [fork(watchNavigate), fork(watchSubmitReferralSaga)]

export default referralSagas
