import text from '../../../_constants/text'
import AlertBox from '../../../components/molecules/AlertBox'
import SingleLineTextInputField from '../../../components/forms/fields/SingleLineTextInputField/SingleLineTextInputField'
import { Button, Col, Row } from 'react-bootstrap'
import InternalLink from '../../../components/atoms/InternalLink'
import { FC, FormEvent, useCallback } from 'react'

import routes from '../../../router/routes'
import { RootState } from '../../../reducers/store'
import { useAppDispatch, useAppSelector } from '../../../hooks/redux'
import {
  ForgotPasswordFormFieldType,
  forgotPasswordSubmit,
  updateField,
} from '../../../actions/ForgotPasswordActions/forgotPasswordActions'
import RecoverFormsWrapper from '../../../components/molecules/RecoverFormsWrapper'

/* This is an example of a component connected to redux via hooks.
 * IMO it's less readable compared to connecting via connect
 * But in case anyone prefers this I'm leaving it as an example
 */
const ForgotPasswordForm: FC = () => {
  const dispatch = useAppDispatch()
  const form = useAppSelector<Record<string, string>>((state) => state.forgotPassword.form)
  const formErrors = useAppSelector<Record<string, boolean>>((state) => state.forgotPassword.formErrors)
  const recoverUsernameFailure = useAppSelector<boolean>((state: RootState) => state.forgotPassword.submit.fail)
  const onValueChange = useCallback(
    (name: ForgotPasswordFormFieldType, value: string) => {
      dispatch(updateField(name, value))
    },
    [dispatch, updateField],
  )
  const onRecoverUsername = (ev: FormEvent<HTMLFormElement>) => {
    ev.preventDefault()
    dispatch(forgotPasswordSubmit())
  }
  return (
    <RecoverFormsWrapper title={text.RECOVER_USER_PASSWORD}>
      <p data-testid="password-standfirst">{text.RECOVER_USER_PASSWORD_INFO}</p>
      {recoverUsernameFailure && <AlertBox type="error" message={text.RECOVER_USER_PASSWORD_FAILURE} />}
      <form onSubmit={onRecoverUsername} className="mt-4">
        <Row>
          <SingleLineTextInputField
            className="px-4"
            label={text.EMAIL_ADDRESS}
            inputName="email"
            required={false}
            value={form.email}
            onChangeCb={onValueChange}
            error={formErrors.email}
            errorMessage={text.UPDATE_CONTACT_INFO_EMAIL_FAIL}
          />
        </Row>
        <Row className="mb-4">
          <Col xs={12} md={6}>
            <Button className="w-100 my-auto" variant="primary" type="submit">
              {text.RECOVER_USER_PASSWORD_BUTTON}
            </Button>
          </Col>
          <Col xs={12} md={6} className="d-flex flex-row px-0 pl-0">
            <p className="anchor-text create-account-text my-auto">{text.OR}</p>
            <InternalLink href={routes.home} className="px-1 my-auto">
              {text.RETURN_TO_SIGNIN}
            </InternalLink>
          </Col>
        </Row>
      </form>
    </RecoverFormsWrapper>
  )
}

export default ForgotPasswordForm
