import React, { useState } from 'react'
import Button from 'react-bootstrap/Button'
import MobileBarButton from '../../molecules/MobileBarButton'
import IMedLogo from '../../atoms/IMedLogo'
import { connect } from 'react-redux'
import MenuIcon from '../../atoms/MenuIcon'
import icons from '../../../_constants/icons'
import text from '../../../_constants/text'
import MobileNavigation from '../MobileNavigation'
import { NavigationItem } from '../../../domain/models/commons/NavigationItem'
import Icon from '../../atoms/Icon'
import ExternalLink from '../../atoms/ExternalLink'
import { logout } from '../../../actions/loginAction'
import urls from '../../../_constants/urls'
import Navigation from '../Navigation'
import { resetErrorState } from '../../../actions/errorActions'
import routes from '../../../router/routes'

interface TopBarProps {
  navLinks: {
    id: string
    type: string
    value: {
      icon: string
      name: string
      children: NavigationItem[]
    }
  }
  isLoggedIn?: boolean
  logout?: () => void
  resetErrorState?: () => void
}
const Header: React.FC<TopBarProps> = ({ navLinks, isLoggedIn, logout, resetErrorState }) => {
  const [mobileMenuOpen, toggleMobileMenuOpen] = useState(false)
  const useSecondaryTheme = true
  const useBasicTheme = !useSecondaryTheme
  const topBarClass = useBasicTheme ? 'primary-theme' : 'secondary-theme'
  const backToWebsiteButtonData = {
    icon: icons.MOVE_FROM,
    text: text.LOGOUT,
    useSecondaryTheme,
  }

  return (
    <>
      <header className={`top-bar d-flex flex-row justify-content-center ${topBarClass}`}>
        <div className="max-top-bar-width d-flex flex-row justify-content-between">
          {isLoggedIn && navLinks ? (
            <MenuIcon
              useSecondaryTheme={useSecondaryTheme}
              icon={icons.MENU}
              iconLabel={text.MENU}
              onClick={() => toggleMobileMenuOpen(true)}
            />
          ) : (
            <MenuIcon useSecondaryTheme={useSecondaryTheme} />
          )}
          <IMedLogo
            className="mx-2 mx-lg-3"
            linkUrl="/"
            useSecondaryTheme={useSecondaryTheme}
            onLinkClick={() => resetErrorState}
          />
          <MenuIcon useSecondaryTheme={true} />
          <div className="d-none w-100 d-lg-flex flex-row justify-content-center">
            <Navigation {...navLinks.value.children[0]} className={`align-self-center ${topBarClass}`} />
          </div>

          {isLoggedIn ? (
            <div className="d-none d-lg-block mb-0 mx-3 py-2">
              <Button
                variant={useBasicTheme ? 'primary' : 'light'}
                className="btn-menu logout"
                onClick={() => logout()}
              >
                <Icon name={backToWebsiteButtonData.icon} className="mr-2" />
                {backToWebsiteButtonData.text}
              </Button>
            </div>
          ) : (
            <ExternalLink className="d-none d-lg-block mb-0 mx-3 py-2" href={urls.RETURN_TO_IMED} newTab={false}>
              <Button variant={useBasicTheme ? 'primary' : 'light'} className="btn-menu back">
                <Icon name={icons.BACK} className="mr-2" />
                {text.BACK_TO_WEBSITE}
              </Button>
            </ExternalLink>
          )}
        </div>
      </header>

      <div
        className={`mobile-menu ${mobileMenuOpen ? 'd-block d-lg-none' : 'd-none'} ${useBasicTheme ? '' : 'active'}`}
      >
        <div className={`top-bar d-flex flex-row justify-content-center ${topBarClass}`}>
          <div className="max-top-bar-width d-flex flex-row justify-content-between">
            <MenuIcon useSecondaryTheme={useSecondaryTheme} />
            <IMedLogo
              onLinkClick={() => {
                toggleMobileMenuOpen(false)
                resetErrorState()
              }}
              linkUrl="/"
              useSecondaryTheme={useSecondaryTheme}
            />
            <MenuIcon
              useSecondaryTheme={useSecondaryTheme}
              icon={icons.CLOSE}
              iconLabel={text.CLOSE}
              onClick={() => toggleMobileMenuOpen(false)}
            />
          </div>
        </div>
        <MobileNavigation
          {...navLinks.value.children[0]}
          className={`flex-column ${topBarClass}`}
          onLinkClick={() => toggleMobileMenuOpen(false)}
        />
        <MobileBarButton
          {...backToWebsiteButtonData}
          onLinkClick={() => {
            if (isLoggedIn) {
              logout()
            }
          }}
          linkUrl={isLoggedIn ? routes.home : urls.RETURN_TO_IMED}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state) => {
  const { navLinks } = state.nav
  const { isLoggedIn } = state.login
  return { navLinks, isLoggedIn }
}

const mapDispatchToProps = (dispatch) => ({
  logout: () => dispatch(logout()),
  resetErrorState: () => dispatch(resetErrorState()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
