import {
  WebcomradPasswordResetFieldName,
  webcomradPasswordResetRequiredFields,
} from '../../../../actions/WebcomradPasswordResetActions/webcomradPasswordResetActions.types'
import { FieldValue } from '../../../../actions/registerAccountActions.types'
import {
  WebcomradPasswordResetErrorModel,
  WebcomradPasswordResetFormDataModel,
} from '../../../../reducers/WebcomradPasswordResetReducer/WebcomradPasswordResetReducer'
import genericValidators from '../GenericValidators'

export const validateWebcomradPasswordResetField = (
  field: WebcomradPasswordResetFieldName,
  value: FieldValue,
): boolean => {
  switch (field) {
    case WebcomradPasswordResetFieldName.fullName:
    case WebcomradPasswordResetFieldName.contactNumber:
    case WebcomradPasswordResetFieldName.emailAddress:
      return !genericValidators(field, value)
    case WebcomradPasswordResetFieldName.username:
      return !value
    default:
      return false
  }
}

const validateWebcomradPasswordResetFormValues = (
  formData: WebcomradPasswordResetFormDataModel,
): WebcomradPasswordResetErrorModel => {
  const errors = {} as WebcomradPasswordResetErrorModel
  for (const field in formData) {
    const newField = field as WebcomradPasswordResetFieldName
    const value = formData[newField]
    const notRequiredAndHasNoValue = !webcomradPasswordResetRequiredFields[newField] && !value
    errors[newField] = validateWebcomradPasswordResetField(newField, value)
    if (notRequiredAndHasNoValue) {
      errors[field] = false
    }
  }
  return errors
}

export const validateWebcomradPasswordResetFormData = (
  formData: WebcomradPasswordResetFormDataModel,
): { errors: WebcomradPasswordResetErrorModel; isValid: boolean } => {
  const errors = validateWebcomradPasswordResetFormValues(formData)
  const isValid = Object.keys(errors).find((value) => {
    return !!errors[value]
  })
  return { errors, isValid: !isValid }
}
