import { UpdateFieldAction } from './commons'

export const UPDATE_CHANGE_PASSWORD_FORM = 'PASSWORD/UPDATE_PASSWORD_DETAILS'
export const CLEAR_CHANGE_PASSWORD_FORM = 'PASSWORD/CLEAR_CHANGE_PASSWORD_FORM'
export const CHANGE_PASSWORD = 'PASSWORD/CHANGE_PASSWORD'
export const CHANGE_PASSWORD_FAIL = 'PASSWORD/CHANGE_PASSWORD_FAIL'
export const CHANGE_PASSWORD_SUCCESS = 'PASSWORD/CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_COMPLEXITY_FAIL = 'PASSWORD/CHANGE_PASSWORD_COMPLEXITY_FAIL'
export const CHANGE_PASSWORD_COMPLEXITY_SUCCESS = 'PASSWORD/CHANGE_PASSWORD_COMPLEXITY_SUCCESS'

export interface PasswordAction {
  type: string
}
export type ChangePasswordFormFieldTypes = 'currentPassword' | 'newPassword'

export const changePassword = (): PasswordAction => ({ type: CHANGE_PASSWORD })
export const clearChangePasswordField = (): PasswordAction => ({ type: CLEAR_CHANGE_PASSWORD_FORM })
export const updateChangePasswordField = (
  type: ChangePasswordFormFieldTypes,
  value: string,
): UpdateFieldAction<ChangePasswordFormFieldTypes> => ({
  type: UPDATE_CHANGE_PASSWORD_FORM,
  data: {
    [type]: value,
  },
})
export const changePasswordFail = (): PasswordAction => ({ type: CHANGE_PASSWORD_FAIL })
export const changePasswordSuccess = (): PasswordAction => ({ type: CHANGE_PASSWORD_SUCCESS })
export const changePasswordComplexityFail = (): PasswordAction => ({ type: CHANGE_PASSWORD_COMPLEXITY_FAIL })
export const changePasswordComplexitySuccess = (): PasswordAction => ({ type: CHANGE_PASSWORD_COMPLEXITY_SUCCESS })
