import Card from 'react-bootstrap/Card'
import text from '../../../_constants/text'
import Row from 'react-bootstrap/Row'
import Separator from '../../../components/atoms/Separator'
import React, { FC, PropsWithChildren } from 'react'
import Container from 'react-bootstrap/Container'

type RecoverFormsWrapperProps = {
  title: string
} & PropsWithChildren

const RecoverFormsWrapper: FC<RecoverFormsWrapperProps> = ({ title, children }) => (
  <Card as="section" className="my-3 my-lg-4" data-testid="recover-form-wrapper">
    <Card.Body className="p-3 p-lg-4" data-testid="recover-form-body">
      <Card.Title className="mb-3" as="h2" data-testid="recover-form-title">
        {title}
      </Card.Title>
      <Container className="px-0" data-testid="recover-form-container">
        {children}
        <Row data-testid="recover-form-row">
          <Separator />
          <p className="pt-4">{text.NEED_SUPPORT}</p>
        </Row>
      </Container>
    </Card.Body>
  </Card>
)

export default RecoverFormsWrapper
