import React from 'react'
import { Modal, Button, Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import {
  ContactInfoFormFieldType,
  contactInfoSubmit,
  updateContactInfoField,
} from '../../../../actions/contactInfoActions'
import Icon from '../../../../components/atoms/Icon'
import SingleLineTextInputField from '../../../../components/forms/fields/SingleLineTextInputField/SingleLineTextInputField'
import icons from '../../../../_constants/icons'
import text from '../../../../_constants/text'

interface ChangeContactInfoModalProps {
  show: boolean
  handleShow: () => void
  submit: () => void
  email: string
  mobile: string
  updateField: (type: string, value: string) => void
}

const ChangeContactInfoModal: React.FC<ChangeContactInfoModalProps> = ({
  show,
  handleShow,
  email,
  mobile,
  updateField,
  submit,
}) => {
  return (
    <Modal show={show} onHide={handleShow} centered data-testid="ChangeContactInfoModal">
      <Modal.Body className="p-4">
        <div className="d-flex flex-row justify-content-between mb-3">
          <h2 className="mt-4">{text.UPDATE_CONTACT}</h2>
          <div className="c-pointer" onClick={handleShow} data-testid="ChangeContactInfoModal-Close">
            <Icon name={icons.CLOSE} />
            <p className="blue bold">{text.CLOSE}</p>
          </div>
        </div>
        <form>
          <SingleLineTextInputField
            label="Email address"
            instructionMessage="Cannot be generic or shared"
            type="text"
            inputName="email"
            required={false}
            value={email}
            onChangeCb={updateField}
            error={false}
            errorMessage=""
          />
          <SingleLineTextInputField
            label="Mobile number"
            instructionMessage="For contact and two factor password reset"
            type="text"
            inputName="mobile"
            required={false}
            value={mobile}
            onChangeCb={updateField}
            error={false}
            errorMessage=""
          />

          <Row>
            <Col className="d-flex flex-row justify-content-end ">
              <Button variant="light" className="mx-3" onClick={handleShow}>
                {text.CANCEL}
              </Button>
              <Button
                onClick={() => {
                  submit()
                  handleShow()
                }}
              >
                {text.UPDATE}
              </Button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = (state) => {
  const { email, mobile }: Record<string, string> = state.contactInfo.new
  return { email, mobile }
}

const mapDispatchToProps = (dispatch) => ({
  updateField: (type: ContactInfoFormFieldType, value: string) => dispatch(updateContactInfoField(type, value)),
  submit: () => dispatch(contactInfoSubmit()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangeContactInfoModal)
