import React, { PropsWithChildren, useState } from 'react'
import { Card, Col, useAccordionButton } from 'react-bootstrap'
import text from '../../../_constants/text'
import ExternalLink from '../../../components/atoms/ExternalLink'
import Accordion from 'react-bootstrap/Accordion'
import { AccessToken } from '../../../reducers/LoginReducer/LoginReducer'
import { getAttachmentLink } from '../PatientReportCard/PatientReportCard'
import { Attachment, AttachmentPage } from '../../../domain/models/commons/Attachment'

type PatientStudyDocumentsCardProps = {
  documents: Attachment[]
  token: AccessToken
  accessible: boolean
}

type AccordionToggleProps = {
  eventKey: string
} & PropsWithChildren
const AccordionToggle: React.FC<AccordionToggleProps> = ({ eventKey, children }) => {
  const [isActive, setActive] = useState(true)

  const onClickButton = useAccordionButton(eventKey, () => {
    setActive(!isActive)
  })

  return (
    <div className={!isActive ? 'mb-0' : ''} onClick={onClickButton}>
      <Card.Title className="mb-2 d-flex justify-content-between">
        <h2>{children}</h2>
        <span data-testid="FontIcon" className={`icon-documents-keyboard-arrow-${isActive ? 'up' : 'down'}`}></span>
      </Card.Title>
    </div>
  )
}

const PatientStudyDocumentsCard: React.FC<PatientStudyDocumentsCardProps> = ({ documents, token, accessible }) => {
  function getDocumentTitle(document: Attachment, page: AttachmentPage): string {
    if (document.pages.length > 1) {
      return document.type + ' - ' + (page.pageNumber + 1)
    } else {
      return document.type
    }
  }

  const nbOfDocuments = documents?.reduce((acc, doc) => (acc += doc.pages.length), 0) || 0

  return (
    <Accordion data-testid="PatientStudyDocuments" defaultActiveKey="documents">
      <Card data-testid="PatientStudyDocumentsCard" as="section" className="mb-4">
        <Card.Body>
          <AccordionToggle eventKey="documents">{text.PATIENT_REPORT_DOCUMENTS_TITLE(nbOfDocuments)}</AccordionToggle>
          <Accordion.Collapse eventKey="documents">
            <Col xs={12} lg={12}>
              {documents?.map((document, doc) => (
                <>
                  {document.pages.map((page) => (
                    <div key={`doc-page-${doc}-${page.pageNumber}`} data-testid="StudyDocument">
                      <ExternalLink href={getAttachmentLink(page.uri, token, accessible)}>
                        {getDocumentTitle(document, page)}
                      </ExternalLink>
                    </div>
                  ))}
                </>
              ))}
            </Col>
          </Accordion.Collapse>
        </Card.Body>
      </Card>
    </Accordion>
  )
}

export default PatientStudyDocumentsCard
