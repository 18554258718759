import React from 'react'
import { Container } from 'react-bootstrap'
import PatientTableRow from '../PatientTableRow'
import PatientTableHeaderRow from '../PatientTableHeaderRow'
import PatientTableLoadingRow from '../PatientTableLoadingRow'
import { PatientRowKeys, SortOrder } from '../../types'
import useSort, { sortRows } from '../../../hooks/useSort'
import { PatientWithStudies } from '../../../selectors/studies'

interface PatientTableProps {
  studies: PatientWithStudies[]
  isLoading: boolean
}

const PatientTable: React.FC<PatientTableProps> = ({ studies, isLoading }) => {
  const { sort, handleSort } = useSort<PatientRowKeys>('latestStudyDate', SortOrder.DESC)

  const showLoader = !Object.keys(studies).length || isLoading

  const sortedPatientRows = sortRows(studies, sort)

  return (
    <Container className="max-grid-width px-0 search-table overflow-x-scroll-mobile" fluid>
      <PatientTableHeaderRow onSort={handleSort} />
      {showLoader
        ? [1, 2, 3, 4].map((i) => <PatientTableLoadingRow key={i} index={i} />)
        : sortedPatientRows.map((patientRow) => {
            return <PatientTableRow key={patientRow.id} accessible={patientRow.accessible} patient={patientRow} />
          })}
    </Container>
  )
}

export default PatientTable
