import {
  AlfredMaterImagingFormDataModel,
  AlfredMaterImagingFormErrorModel,
  alfredMaterImagingRequiredFields,
} from '../../../../reducers/AlfredMaterImagingReducer/AlfredMaterImagingReducer'
import { FieldValue } from '../../../../actions/registerAccountActions.types'
import genericValidators, { genericFieldNames } from '../GenericValidators'

export enum alfredMaterImagingFieldNames {
  termsAndConditions = 'termsAndConditions',
  username = 'username',
  firstName = 'firstName',
  lastName = 'lastName',
  providerNumber = 'providerNumber',
  ahpranumber = 'ahpranumber',
  referrerEmail = 'referrerEmail',
  practiceName = 'practiceName',
  practiceAddress = 'practiceAddress',
  practiceSuburb = 'practiceSuburb',
  practiceState = 'practiceState',
  practicePostcode = 'practicePostcode',
  practicePhone = 'practicePhone',
  practiceFax = 'practiceFax',
  department = 'department',
  mobileNumber = 'mobileNumber',
  speciality = 'speciality',
  otherSpeciality = 'otherSpeciality',
  subSpeciality = 'subSpeciality',
  operatingSystemMac = 'operatingSystemMac',
  operatingSystemPC = 'operatingSystemPC',
  practiceManagementSystem = 'practiceManagementSystem',
  otherPracticeManagementSystem = 'otherPracticeManagementSystem',
}

export const validateAlfredMaterImagingField = (field: string, value: FieldValue): boolean => {
  switch (field) {
    case alfredMaterImagingFieldNames.termsAndConditions:
    case alfredMaterImagingFieldNames.operatingSystemMac:
    case alfredMaterImagingFieldNames.operatingSystemPC:
    case alfredMaterImagingFieldNames.ahpranumber:
    case alfredMaterImagingFieldNames.providerNumber:
      return !value
    case alfredMaterImagingFieldNames.username:
    case alfredMaterImagingFieldNames.lastName:
    case alfredMaterImagingFieldNames.firstName:
      return !genericValidators(genericFieldNames.name, value)
    case alfredMaterImagingFieldNames.practiceName:
      return !genericValidators(genericFieldNames.fullName, value)
    case alfredMaterImagingFieldNames.referrerEmail:
      return !genericValidators(genericFieldNames.emailAddress, value)
    case alfredMaterImagingFieldNames.practicePhone:
    case alfredMaterImagingFieldNames.practiceFax:
    case alfredMaterImagingFieldNames.mobileNumber:
      return !genericValidators(genericFieldNames.contactNumber, value)
    case alfredMaterImagingFieldNames.practiceAddress:
      return !/[a-zA-Z]+/.test(value.toString())
    case alfredMaterImagingFieldNames.practiceSuburb:
      return !/[a-zA-Z]+/.test(value.toString())
    case alfredMaterImagingFieldNames.practiceState:
      return !/^(QLD|NSW|VIC|ACT|NT|WA|SA|TAS)$/.test(value.toString())
    case alfredMaterImagingFieldNames.practicePostcode:
      return !/^[0-9]{4}$/.test(value.toString())
    case alfredMaterImagingFieldNames.speciality:
      return !/^(General Practitioner|General Practitioner Registrar|General Practitioner Intern|Nurse Practitioner|Specialist|Allied Health|Hospital Registrar\/Intern|Dentist|Other)$/.test(
        value.toString(),
      )
    case alfredMaterImagingFieldNames.practiceManagementSystem:
      return !/^(Best Practice|Medical Director|Genie|Gentu|Other)$/.test(value.toString())
    default:
      return false
  }
}

function validateFormData(formData: AlfredMaterImagingFormDataModel): {
  errors: AlfredMaterImagingFormErrorModel
  isValid: boolean
} {
  const errors = validateAlfredMaterImagingFormValues(formData) as AlfredMaterImagingFormErrorModel
  const isValid = Object.keys(errors).find((value) => {
    const isOperatingSystemPair = value === 'operatingSystemMac' || value === 'operatingSystemPC'
    if (isOperatingSystemPair) {
      const isAtLeastOneValid = errors.operatingSystemMac || errors.operatingSystemPC
      const noError = false
      if (isAtLeastOneValid) {
        return noError
      }
    }
    return !!errors[value]
  })
  return { errors, isValid: !isValid }
}

const validateAlfredMaterImagingFormValues = (
  formData: AlfredMaterImagingFormDataModel,
): AlfredMaterImagingFormErrorModel => {
  const errors = {} as AlfredMaterImagingFormErrorModel
  for (const field in formData) {
    const value = formData[field]
    const notRequiredAndHasNoValue = !alfredMaterImagingRequiredFields[field] && !value
    errors[field] = validateAlfredMaterImagingField(field, value)
    if (notRequiredAndHasNoValue) {
      errors[field] = false
    }
  }
  return errors
}

export default validateFormData
