import { useLocation } from 'react-router'
import { UrlParams } from '../../_constants/variables'

export const isAccessible = (): boolean => {
  return new URLSearchParams(useLocation().search).get(UrlParams.BREAK_GLASS) !== 'true'
}

export const getAccessionNumber = (): string | undefined => {
  return new URLSearchParams(useLocation().search).get(UrlParams.ACCESSION_NUMBER)
}
