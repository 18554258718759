import {
  ADD_PRACTICE_FORM_VALIDATION_FAIL,
  ADD_PRACTICE_RESET,
  ADD_PRACTICE_SUBMIT_FAIL,
  ADD_PRACTICE_SUBMIT_SUCCESS,
  ADD_PRACTICE_UPDATE_DETAILS,
} from '../../actions/AddPractice/addPracticeActions'
import {
  AddPracticeDataAction,
  AddPracticeFormErrorsAction,
  AddPracticeFormFieldType,
} from '../../actions/AddPractice/addPracticeActions.types'
import { BaseAction } from '../../actions/commons'
import { LOGOUT_SUCCESS } from '../../actions/loginAction'
import { PracticeDetailsType, SubmitControls } from '../RegisterAccountReducer/RegisterAccountReducer.types'

type AddPracticeState = {
  practiceForm: PracticeDetailsType
  errors: Partial<Record<AddPracticeFormFieldType, boolean>>
  submit: SubmitControls
}

export const initialAddPracticeState: AddPracticeState = {
  practiceForm: {} as PracticeDetailsType,
  errors: {},
  submit: {
    inProgress: false,
    success: false,
    fail: false,
  },
}

const addPracticeReducer = (
  state: AddPracticeState = initialAddPracticeState,
  action: BaseAction,
): AddPracticeState => {
  switch (action.type) {
    case ADD_PRACTICE_UPDATE_DETAILS:
      const a = action as AddPracticeDataAction
      const errors = { ...state.errors }
      const updateKey = Object.keys(a.data)
      errors[updateKey[0]] = false
      return {
        ...state,
        practiceForm: {
          ...state.practiceForm,
          ...a.data,
        },
        errors: errors,
      }
    case ADD_PRACTICE_FORM_VALIDATION_FAIL:
      const errorsAction = action as AddPracticeFormErrorsAction
      return {
        ...state,
        errors: { ...errorsAction.data.errors },
      }
    case ADD_PRACTICE_SUBMIT_SUCCESS:
      return { ...state, submit: { inProgress: false, success: true, fail: false } }
    case ADD_PRACTICE_SUBMIT_FAIL:
      return { ...state, submit: { inProgress: false, fail: true, success: false } }
    case ADD_PRACTICE_RESET:
      return {
        ...state,
        practiceForm: {} as PracticeDetailsType,
        submit: {
          inProgress: false,
          fail: false,
          success: false,
        },
        errors: {},
      }
    case LOGOUT_SUCCESS:
      return { ...initialAddPracticeState }
    default:
      return state
  }
}

export default addPracticeReducer
