import {
  LoginFormFieldType,
  LOGIN_SUCCESS,
  LOGIN_UPDATE_DETAILS,
  LOGOUT,
  LOGIN_FAIL,
  LOGOUT_SUCCESS,
  LOGIN,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_FAIL,
  IMedJwtPayload,
  JwtTokenWithRefresh,
  LOGIN_OBTAINED_TOKEN,
  LogoutAction,
  SESSION_TIMED_OUT,
} from '../../actions/loginAction'

import { BaseAction, DataAction, UpdateFieldAction } from '../../actions/commons'
import { SubmitControls, submitStates } from '../commons/submitControls'

export type LoginState = {
  form: Record<LoginFormFieldType, string>
  groups: string[]
  hasSessionTimedOut: boolean
  isLoggedIn: boolean
  logoutSuccess?: boolean
  refreshToken: string
  refreshTokenSubmit: SubmitControls
  submit: SubmitControls
  token: AccessToken
  username: string
}

export type AccessToken = {
  token: string
  type: string
}

export const initialLoginState: LoginState = {
  form: {
    uid: '',
    password: '',
  },
  groups: [],
  hasSessionTimedOut: false,
  isLoggedIn: false,
  refreshToken: '',
  refreshTokenSubmit: { ...submitStates.default },
  submit: { ...submitStates.default },
  token: {
    token: '',
    type: '',
  },
  username: '',
}

const loginReducer = (state: LoginState = initialLoginState, action: BaseAction): LoginState => {
  switch (action.type) {
    case LOGIN: {
      return { ...state, submit: { ...submitStates.inProgress } }
    }
    case LOGIN_UPDATE_DETAILS:
      const a = action as UpdateFieldAction<LoginFormFieldType>
      return {
        ...state,
        form: {
          ...state.form,
          ...a.data,
        },
        submit: { ...submitStates.default },
      }
    case LOGIN_FAIL:
      return {
        ...state,
        form: {
          ...initialLoginState.form,
          uid: state.form.uid,
        },
        submit: { ...submitStates.fail },
      }
    case LOGIN_OBTAINED_TOKEN: {
      const a = action as DataAction<JwtTokenWithRefresh>
      return {
        ...state,
        token: { type: a.data.type, token: a.data.token },
        refreshToken: a.data.refreshToken,
        submit: { ...submitStates.inProgress },
      }
    }
    case LOGIN_SUCCESS: {
      const a = action as DataAction<IMedJwtPayload>
      return {
        ...state,
        form: {
          ...initialLoginState.form,
        },
        hasSessionTimedOut: false,
        username: a.data.username,
        groups: a.data.groups,
        isLoggedIn: true,
        submit: { ...submitStates.success },
      }
    }
    case REFRESH_TOKEN: {
      return {
        ...state,
        refreshTokenSubmit: { ...submitStates.inProgress },
      }
    }
    case REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        hasSessionTimedOut: false,
        refreshTokenSubmit: { ...submitStates.success },
      }
    }
    case REFRESH_TOKEN_FAIL: {
      return {
        ...state,
        logoutSuccess: false,
        refreshTokenSubmit: { ...submitStates.fail },
      }
    }
    case LOGOUT: {
      const a = action as DataAction<LogoutAction>
      const { hasSessionTimedOut } = a.data
      return {
        ...state,
        hasSessionTimedOut,
      }
    }
    case LOGOUT_SUCCESS:
      return { ...initialLoginState, hasSessionTimedOut: state.hasSessionTimedOut, logoutSuccess: true }
    case SESSION_TIMED_OUT:
      return { ...state, hasSessionTimedOut: true }
    default:
      return state
  }
}

export default loginReducer
