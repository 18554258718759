const formatDate = (date: string | Date): string => {
  const dateOptions = { year: 'numeric', month: 'long', day: 'numeric' } as {
    year: 'numeric' | '2-digit' | undefined
    month: 'numeric' | '2-digit' | 'long' | 'short' | 'narrow' | undefined
    day: 'numeric' | '2-digit' | undefined
  }
  return new Date(date).toLocaleDateString('en-AU', dateOptions)
}
export const formatSearchDate = (date: Date | string, separator = '/'): string => {
  const parsedDate = new Date(date)
  const year = parsedDate.getFullYear()
  const currentMonth = parsedDate.getMonth() + 1
  const month = currentMonth.toString().length == 1 ? '0' + currentMonth : currentMonth
  const day = parsedDate.getDate().toString().length == 1 ? '0' + parsedDate.getDate() : parsedDate.getDate()
  return `${year}${separator}${month}${separator}${day}`
}

export default formatDate
