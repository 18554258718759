import { PracticeDetailsType } from '../../reducers/RegisterAccountReducer/RegisterAccountReducer.types'
import { AddPracticeApiRequest } from './mapAddPracticeStateToApi.types'

const map = (practice: PracticeDetailsType, practitionerEmail: string): AddPracticeApiRequest => ({
  providerNumber: practice.providerNumber,
  name: practice.practiceName,
  phone: practice.practicePhone,
  fax: practice.practiceFax,
  street: practice.practiceAddress,
  suburb: practice.practiceSuburb,
  state: practice.practiceState,
  postcode: practice.practicePostcode,
  type: practice.practiceType,
  practitionerEmail,
})

export default map
