import { Col, Row } from 'react-bootstrap'
import moment from 'moment'
import text from '../../../_constants/text'
import { ProcedureWithStudyInfo } from '../../../selectors/studies'
import { Status } from '../../../domain/models/commons/Report'

type ProcedureRowProps = ProcedureWithStudyInfo

const ProcedureTableRow: React.FC<ProcedureRowProps> = ({
  appointment,
  practice,
  description,
  accessionNumber,
  status,
  modality,
}) => {
  const appointmentDate = moment(appointment)
  const appointmentDay = appointmentDate.format(text.DATE_FORMAT)
  const appointmentTime = appointmentDate.format('hh.mm a')

  const statusIndicatorType =
    {
      [Status.PENDING]: 'warning',
      [Status.APPOINTMENT_PENDING]: 'warning',
    }[status] || 'success'

  const statusMessage =
    {
      [Status.PENDING]: text.APPOINTMENT_STATUS_UPCOMING,
      [Status.APPOINTMENT_PENDING]: text.APPOINTMENT_STATUS_UPCOMING,
    }[status] || ''

  return (
    <>
      <Row data-testid="procedureRow" className="mx-0 my-1 px-0 py-2 procedure-table-row">
        <Col className="procedure-table-col">{appointmentDay}</Col>
        <Col className="procedure-table-col">{appointmentTime}</Col>
        <Col className="procedure-table-col text-truncate" title={practice} xs={3}>
          {practice}
        </Col>
        <Col className="procedure-table-col text-truncate" title={modality}>
          {modality}
        </Col>
        <Col className="procedure-table-col" title={description} xs={2}>
          {description}
        </Col>
        <Col className="procedure-table-col">{accessionNumber}</Col>
        <Col className="procedure-table-col">
          <div className="d-flex flex-row">
            <div className={`indicator ${statusIndicatorType}`} />
            {statusMessage}
          </div>
        </Col>
      </Row>
    </>
  )
}

export default ProcedureTableRow
