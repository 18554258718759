import { JwtToken } from '../../actions/loginAction'
import { TOKEN_TYPE } from '../../_constants/variables'

export const addLocalStorageValue = (key: string, value: string): void => {
  try {
    window.localStorage.setItem(key, value)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
export const getLocalStorageValue = (key: string): string | null => {
  try {
    return window.localStorage.getItem(key)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return null
  }
}
export const getLocalStorageAuthToken = (key: string): JwtToken | null => {
  try {
    return { type: TOKEN_TYPE, token: window.localStorage.getItem(key) }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
    return null
  }
}

export function deleteLocalStorageValue(key: string): void {
  try {
    window.localStorage.removeItem(key)
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error)
  }
}
